import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Chip, Tooltip, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    chipContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        flex: 1
    },
    chipWrapper: {
        paddingRight: "10px",
        paddingBottom: "10px"
    },
    chip: {
        fontWeight: "initial",
        fontSize: "large"
    },
});


class ObservationPicker extends React.Component {

    normalisedChipColor;

    constructor(props) {
        super(props);
        this.normalisedChipColor = "#BB86FC"
    }

    filterUniqueItems = (observations) => {
        let uniqueObservations = [];
        observations.filter(item => {
            let i = uniqueObservations.findIndex(x => (x.display === item.display));
            if(i <= -1){
                uniqueObservations.push(item);
            }
            return null;
        });

        return uniqueObservations

    }

    render() {
        let {classes, observations} = this.props;
        let uniqueObservations = this.filterUniqueItems(observations)

        return (
            <div className={classes.chipContainer}>
                {uniqueObservations.map(item => {
                    return (<div className={classes.chipWrapper}>
                        <Chip className={this.props.classes.chip}
                              label={item.display}
                              style={{backgroundColor: this.normalisedChipColor}}
                              onDelete={() => this.props.handleDelete(item)}
                        />
                    </div>)
                })}
            </div>
        );
    }
}

export default withStyles(styles)(ObservationPicker);