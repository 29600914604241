import React, {Component} from 'react';

import ClinicalSummaryJobContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Fab,
    Button,
    Chip,
    IconButton,
    Input,
    Popover, Popper,
    List,
    ListItem,
    ListItemLink, CircularProgress,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import {utilitiesApi} from "../../../../utils/services/utilities.api";
import EditableEntity from "./components/EditableEntity";
import EditableChip from "./components/EditableChip";
import EditableSection from "./components/EditableSection";

const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        padding: "30px 25px",
        display: "flex",
        flexDirection: "column",
    },

    fill: {
        flex: "1 1 auto",
    },

    summaryContainer: {
        padding: "4px 0",
    },

    title: {
        fontSize: "24px",
        fontWeight: "400",
        color: "#20516A",
        marginBottom: "7px",
    },
    saveBtnWrapper: {
        paddingBottom: "24px",
    },
    saveBtn: {
        width: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 20px #3E436912",
        font: "28px",
        fontWeight: "300",
        letterSpacing: "0",
        padding: "0px",
        color: "#696969",
        borderRadius: "30px",
    },
    saveBtnTxt: {
        padding: "20px 0px 20px 20px",
    },
    saveBtnIcon: {
        height: "64px",
        width: "64px",
        padding: "10px",
        borderRadius: "31px",
        background: "#96D9FCFA 0% 0% no-repeat padding-box",
        boxShadow: "-10px 0px 20px #47567917",
        '& svg': {
            height: "40px",
            width: "40px",
            color: "#fff",
        },
    }
});

class SummaryPanel extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {

        };
    }




    render() {

        let { classes } = this.props;
        let { job, focusedNamedEntity, focusOnEntity } = this.props.context;
        let { editSection } = this.state;

        const document = job.document;

        return (
            <div className={classes.root}>
                <Typography variant="h1" component="h2" className={classes.title}>Clinical Summary</Typography>

                <div className={classes.summaryContainer}>
                    { document.sections && document.sections.map(section => {
                        return (<EditableSection updateSection={this.props.updateSection} onClickEntity={focusOnEntity} job={job} section={section} />)
                    })}
                </div>

                <div className={classes.fill} />

                <div className={classes.saveBtnWrapper}>
                    <Button
                        className={classes.saveBtn}
                        color="secondary"
                        onClick={this.props.onSaveSummary}
                    ><span className={classes.saveBtnTxt}>Send clinical note to physician</span><span className={classes.fill} /><span className={classes.saveBtnIcon}><CheckIcon /></span></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ClinicalSummaryJobContext.Consumer>
        {value => {
            return (<SummaryPanel context={value} {...props} />);
        }
        }
    </ClinicalSummaryJobContext.Consumer>
));
