import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import AddIcon from '@material-ui/icons/Add';

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import {
    Typography,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    ButtonGroup
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DecodedComponent from "../../../../shared/DecodedComponent";
import AutocompleteField from "./AutocompleteField";
import DeleteIcon from "@material-ui/icons/Delete";
import {CheckBox} from "@material-ui/icons";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    context: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
        paddingBottom: "12px",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },
});



class NormalisePanel extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            loaded: false,
        };

        this.__removeConcept = this.__removeConcept.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }


    __setPresence = (presence) => () => {
        this.bloc.setPresence(presence);
    }


    __toggleNegated = () => {
        this.bloc.toggleNegated();
    }
    __removeConcept = (concept) => {
        return (event) => {
            this.bloc.removeConcept(concept.id);
        };
    }

    render() {

        let { classes } = this.props;
        let { busy, event } = this.state;

        if(busy || !event) return <><CircularProgress /></>

        let { data } = event;

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6' align='left' className={classes.context}>
                    <FormattedMessage id={"quinn.job.normalise.entity"} defaultMessage={"Context"} /> "{ data.content }"
                </Typography>
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <FormattedMessage id={"quinn.job.normalise.entity"} defaultMessage={"Normalise entity"} /> "{ data.entity.extract }"
                </Typography>
            </div>
            <div className={classes.content}>
                <List component="nav" aria-label="main mailbox folders">
                    { data && data.entity.concepts.map(_concept =>
                        <ListItem>
                            <AutocompleteField id={_concept.id}
                                               label="Concept"
                                               defaultValue=""
                                               setSelectedValue={this.bloc.onDropdownChange.bind(this, _concept.id)}
                                               getOptions={this.bloc.getConceptOptions} />

                            <ListItemSecondaryAction>
                                <IconButton onClick={this.__removeConcept(_concept)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)
                    }
                    <ListItem button onClick={this.bloc.addConcept}>
                        <AddIcon />
                    </ListItem>
                </List>
            </div>
            <div className={classes.fill} />
            <span className={classes.controlButtonWrapper}>
                        <ButtonGroup variant="contained" color="primary" className={classes.controlButtonGroup}>
                          <Button disabled={ !data.entity.negated } onClick={this.__toggleNegated} className={classes.controlButtonLeft}><FormattedMessage id={"affirmed"} defaultMessage={"affirmed"} /></Button>
                          <Button disabled={ data.entity.negated } onClick={this.__toggleNegated} className={classes.controlButtonRight}><FormattedMessage id={"negated"} defaultMessage={"negated"} /></Button>
                        </ButtonGroup>
                    </span>
            <span className={classes.controlButtonWrapper}>
                        <ButtonGroup variant="contained" color="primary" className={classes.controlButtonGroup}>
                          <Button disabled={ data.entity.presence === "endorse" } onClick={this.__setPresence("endorse")} className={classes.controlButtonLeft}><FormattedMessage id={"endorse"} defaultMessage={"endorses"} /></Button>
                          <Button disabled={ data.entity.presence === "deny" } onClick={this.__setPresence("deny")} className={classes.controlButtonRight}><FormattedMessage id={"denies"} defaultMessage={"denies"} /></Button>
                          <Button disabled={ data.entity.presence === "unsure" } onClick={this.__setPresence("unsure")} className={classes.controlButtonRight}><FormattedMessage id={"unsure"} defaultMessage={"unsure"} /></Button>
                        </ButtonGroup>
                    </span>
            <div className={classes.footer}>
                <div className={classes.fill}></div>
                <Button disabled={busy} onClick={this.bloc.submit}><KeyboardReturn fontSize="large" color="primary"/></Button>
            </div>
        </div>;
    }
}

export default withStyles(styles)(NormalisePanel);
