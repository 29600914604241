import {Headers} from "../../../../utils/events";

export class Preprocessor {

    preprocessSubmission = (metadata, entities, dynamicContext) => {

        if(dynamicContext?.menu && dynamicContext?.derived && dynamicContext?.derived.length > 0) {
            for(let i = 0; i < entities.length; i++) {
                if(dynamicContext.menu[entities[i].category]) {
                    dynamicContext.derived.forEach(_item => {
                        let intent = { start: entities[0].start, end: entities[0].end, extract: entities[0].extract, category: _item };
                        entities.push(intent);
                    });
                    break;
                }
            }
        }

        if(entities.length !== 0 && metadata[Headers.INTENT_SLOT_CODE] === "encounter_intent") {
            let intent = { ...entities[0] };
            intent.category = "encounter_intent";
            entities.push(intent);
        }
    }
}

export const preprocessor = new Preprocessor();
