import {withStyles} from "@material-ui/core/styles";
import {
    Checkbox, ClickAwayListener,
    Divider, FormControl,
    FormControlLabel, IconButton, InputLabel,

    ListItem, MenuItem, Select, TextField, Typography
} from "@material-ui/core";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";

import React, {Component} from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import CriticalButton from "../../../../../../shared/CriticalButton";

const styles = theme => ({
    dropdownOption: {
        width: "300px",
        flex: 0.8,
        justifyContent: "center",
        paddingRight: "1%"
    },
    input: {
        display: "flex",
    },
    list: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: "32px",
    },
    listItem: {
        flex: 1
    },
    categoryDropdown: {
        display: "flex",
        flex: 0.8,
        justifyContent: "center",
        paddingRight: "1%"
    },
    checkboxItem: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
    },
    delete: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        padding: "1%"
    }
})

class ApprovalInput extends Component {

    constructor(props) {
        super(props);

        this.bloc = props.bloc

    }

    __handleCheckboxChange = (event) => {
        this.bloc.onAnnotationChange(event.target.id, 'checked', event.target.checked)
    };

    __handleCategoryChange = (id, event) => {
        this.bloc.onAnnotationChange(id, 'category', event.target.value)
    }

    __handleTextboxChange = (id, event) => {

        this.bloc.onAnnotationChange(id, 'extract', event.target.value)
    };

    render() {
        const {classes, entity} = this.props;

        let deleteMessage = {content: "Are you sure you want to delete this annotation?"}

        let isValidNormalised = entity?.category !== undefined && (entity.category.toLowerCase() === "symptom" ||
            entity.category.toLowerCase() === "associated symptom" ||
            entity.category.toLowerCase() === "diagnosis" ||
            entity.category.toLowerCase() === "medication")

        return (<div key={entity.id}>
            <Divider/>
            <ListItem className={classes.list}>
                <div className={classes.listItem}>
                    {
                        entity.added ?
                            <TextField id="outlined-basic" defaultValue={entity.extract} onChange={this.__handleTextboxChange.bind(this, entity.id)} label="Entity" variant="outlined" color="default"/>
                            :
                            <Typography variant="h6">{entity.extract} </Typography>
                    }
                </div>
                <FormControl className={classes.categoryDropdown} variant="outlined">
                    <InputLabel id="category-standard-label">Category</InputLabel>
                    <Select
                        id="select"
                        labelId="demo-simple-select-standard-label"
                        defaultValue={entity.category}
                        label="Category"
                        onChange={this.__handleCategoryChange.bind(this, entity.id)}
                    >
                        <MenuItem value={"Symptom"}>Symptom</MenuItem>
                        <MenuItem value={"Associated symptom"}>Associated symptom</MenuItem>
                        <MenuItem value={"Onset"}>Onset</MenuItem>
                        <MenuItem value={"Onset context"}>Onset context</MenuItem>
                        <MenuItem value={"Diagnosis"}>Diagnosis</MenuItem>
                        <MenuItem value={"Medication"}>Medication</MenuItem>
                        <MenuItem value={"Anatomical location"}>Anatomical location</MenuItem>
                        <MenuItem value={"Quality"}>Quality</MenuItem>
                    </Select>
                </FormControl>
                <div className={classes.dropdownOption}>
                    {isValidNormalised ?
                        <AutocompleteField id={entity.id}
                                           label="Concept"
                                           defaultValue={entity.conceptName ? entity.conceptName : ""}
                                           setSelectedValue={this.bloc.onDropdownChange.bind(this, entity.id)}
                                           getOptions={this.bloc.getDropdownOptions}
                                           setDisabled={entity.checked}
                        /> :
                        <></>
                    }
                </div>
                <div className={classes.checkboxItem}>
                    {isValidNormalised ?
                        <FormControlLabel label={"Unable to normalise"} labelPlacement="end"
                                          control={
                                              <Checkbox id={entity.id}
                                                        checked={entity.checked}
                                                        onChange={this.__handleCheckboxChange}
                                                        color="default"/>
                                          }/> :
                        <></>
                    }
                </div>
                <div className={classes.delete}>
                    <CriticalButton startIcon={<DeleteIcon/>} dialog={deleteMessage} color={"secondary"}
                                    variant={"outlined"}
                                    onConfirmed={() => this.bloc.removeAnnotation(entity)}>DELETE</CriticalButton>
                </div>
            </ListItem>
        </div>);
    }
}

export default withStyles(styles)(ApprovalInput);