import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import CloseIcon from '@material-ui/icons/Close';

import {Typography, Button, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {Headers, publishHitlEvent, REPHRASE} from "../../../../utils/events";

import JobContext from './context';

import { FormattedMessage } from "react-intl";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {MESSAGE_ANNOTATION_EVENTS} from "../component/MessageAnnotationPanel/service/message.annotation.event.service";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        justifyContent: "space-start"
    },
    backButton: {

    },
    instruction: {
        marginTop: "0.4em",
        color: "#55CCFF",
    },
    content: {

    },
    message: {
        paddingTop: "50px",
    },
    control: {

        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "50%",
    },
    controlButtonGroupLeft: {
        width: "100%",
        paddingRight: "30px",
        height: "80px",
    },
    controlButtonGroupRight: {
        width: "100%",
        paddingLeft: "30px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "25%",
    },
    controlButtonCenter: {
        color: `#fff !important`,
        backgroundColor: `${theme.palette.primary.dark} !important`,
        width: "50%",
    },
    controlButtonRight: {
        width: "25%",
        countAvatar: {
            width: "2000px",
        }
    },
    footer: {
        marginTop: "25px",
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },
    footerRightButton: {
        fontSize: "40px",
        color: `${theme.palette.primary.dark} !important`,
    },
    countAvatarLeft: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "-15px",
        zIndex: "1",
    },
    countAvatarRight: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "15px",
        zIndex: "1",
    },
    lastQuestion: {
        marginTop: "30px",
        fontSize: "40px",
        color: "#55CCFF",
    },
    lastAnswer: {
        marginTop: "30px",
        fontSize: "30px",
    }
});

class Rephrase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: props.context.message,
            metadata: props.context.metadata,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(!this.props.context.message || this.props.context.message.messageId !== nextProps.context.message.messageId) {

            this.setState({
                message: nextProps.context.message,
                metadata: nextProps.context.metadata,
            });
        }
    }

    handleException = () => {

        let { message, metadata } = this.state;

        this.props.sendException(message, metadata);
    };

    handleRephraseChange = (event) => {

        let question = event.target.value;

        this.setState({
            question: question,
        });
    };

    handleSubmission = () => {

        let { event, message, question } = this.state;

        if(question === undefined) {

            this.props.submitErrorUpdate({ data: { text: "Please select a question to continue."}});
        } else {

            this.props.submitResultCallback(REPHRASE, {
                conversationId: message.conversationId,
                recipientId: message.userId,
                message: question,
                replyType: "REPHRASE",
                replyTo: message.messageId,
            });
        }
    };

    render() {

        let { classes, busy, context, goBack } = this.props;

        let { message, question } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Button disabled={busy} onClick={goBack} className={classes.backButton}>
                        <ArrowBackIcon fontSize="large" color="primary"/>
                    </Button>
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <FormattedMessage id={"rephrase.question"} defaultMessage={"Rephrase previous question."} />
                    </Typography>
                </div>
                <div className={classes.content}>
                    <Typography variant='h3' align='left' className={classes.lastAnswer}>
                        { context.metadata[Headers.LAST_ANSWER] }
                    </Typography>
                    <Typography variant='h3' align='right' className={classes.lastQuestion}>
                        { context.metadata[Headers.LAST_QUESTION] }
                    </Typography>
                    <Typography variant='h3' align='left' className={classes.message}>
                        { message.content }
                    </Typography>
                </div>
                <div className={classes.fill} />
                <div className={classes.control}>
                    <RadioGroup aria-label="position" name="position" value={question} onChange={this.handleRephraseChange}>
                        {
                            context.metadata[Headers.REITERATE_OPTIONS].split("~").map(value => (<FormControlLabel
                                value={ value }
                                control={<Radio />}
                                label={(<Typography variant='h3' align='left' className={classes.rephrase} >{ value }</Typography>)}
                                labelPlacement="end"
                            />))
                        }
                    </RadioGroup>
                </div>
                <div className={classes.footer}>
                    <Button disabled={busy}  onClick={this.handleException}><CloseIcon fontSize="large" color="primary"/> <FormattedMessage id="exception" defaultMessage="Exception" /></Button>
                    <div className={classes.fill}></div>
                    <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large" color="primary"/></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <JobContext.Consumer>
        { value => {
            return (<Rephrase context={ value } { ...props } />);
            }
        }
    </JobContext.Consumer>
));
