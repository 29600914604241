import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Chip, ClickAwayListener,
    Popper, Tooltip,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AnnotationPopper from "./AnnotationPopper";
import {AnnotationStatus} from "../annotation.bloc";


const styles = theme => ({
    root: {
        display: "inline",
    },
    chipWrapper: {
        display: "inline",
    },
    chip: {
        padding: "2px 5px",
        boxShadow: "3px 3px 12px #587BBC29",
        borderRadius: "8px",
        opacity: "1",
        fontSize: "18px"
    },
    typeaheadPopper: {
        color: "#424242",
        fontSize: "26px",
        backgroundColor: "#424242",
        minWidth: "350px",
        align: "left",
        borderRadius: "10px",
        zIndex: "1200",
    },
});

class SelectableEntity extends Component {

    automatedChipColor
    editedChipColor
    manualChipColor

    constructor(props) {
        super(props);

        this.automatedChipColor = "#6200EE"
        this.manualChipColor = "#BB86FC"
        this.annotatedChipColor = "#03DAC6"

        this.state = {
            open: false,
            anchor: undefined,
        };
    }

    componentDidMount() {
        document.addEventListener("keyup", this.keyPressed);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyPressed);
    }

    onDoubleClickTitle = (event) => {

        this.setState({
            open: true,
            anchor: event.currentTarget,
        })
    };

    onDelete = (event) => {

        this.setState({
            open: false,
            anchor: undefined
        });

        // delete entity from array
        this.props.deleteEntity(this.props.entity)
    };

    onEditCancel = (event) => {

        this.setState({
            open: false,
            anchor: undefined
        });
    };


    keyPressed = (event) => {

        if (event.key === 'Escape') {

            this.setState({
                open: false,
                anchor: undefined,
            });

            this.props.onClickEntity && this.props.onClickEntity(undefined);
        }
    };

    onClick = (event) => {
        this.props.onClickEntity && this.props.onClickEntity(undefined)
    };

    onBlur = (event) => {
        this.props.onClickEntity && this.props.onClickEntity(undefined);
    };

    _saveEntity = (entity) => {
        this.props.saveEntity(entity)
    }

    render() {

        let {classes, entity} = this.props;
        let {
            open,
            anchor,
        } = this.state;

        let {state, data} = entity

        let color;

        if (state === AnnotationStatus.AUTOMATED_ENTITY) {
            color = this.automatedChipColor
        } else if (state === AnnotationStatus.MANUAL_ENTITY) {
            color = this.manualChipColor
        } else if (state === AnnotationStatus.ANNOTATED) {
            color = this.annotatedChipColor
        }

        return (
            <span className={classes.root} contentEditable={false}>
                <div className={classes.chipWrapper}>

                    <Chip className={classes.chip}
                          style={{backgroundColor: color}}
                          onDoubleClick={this.onDoubleClickTitle}
                          onClick={this.onClick}
                          onBlur={this.onBlur}
                          label={data.extract}
                          onDelete={this.onDelete}
                          deleteIcon={open ? (
                              <Tooltip title="Delete entity" placement='start-top'><DeleteIcon/></Tooltip>) : (<></>)}
                    />

                        <Popper className={classes.typeaheadPopper}
                                id="id"
                                open={open}
                                anchorEl={anchor}
                                placement="bottom-start"
                        >
                            <AnnotationPopper entity={entity} saveEntity={this._saveEntity}
                                              closePopover={this.onEditCancel}/>
                        </Popper>

                </div>
            </span>
        );
    }
}

export default withStyles(styles)(SelectableEntity);
