import * as rxjs from 'rxjs';
import {notificationService} from "../../../utils/notification";
import {conversationApi} from "../../../utils/services/conversation.api";


export class ChatBloc {

    messagePollTimer;

    constructor(user, conversation, tenant) {

        this.subject = new rxjs.BehaviorSubject({
            tenant: tenant,
            user: user,
            conversation: conversation,
            lastMessage: "_",
            messages: [],
            messageIds: [],
            busy: false,
        });

        this.events = new rxjs.Subject();

        this.__pollForNewMessages = this.__pollForNewMessages.bind(this);
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    start = () => {
        this.__initialise();
    }

    __initialise = (user, conversation) => {

        this.messagePollTimer = setInterval(() => this.__pollForNewMessages(), 2000);
    }

    __pollForNewMessages = () => {

        const { busy, conversation, messages, messageIds, lastMessage, tenant } =  this.subject.value;

        if(busy) return;

        this.subject.next({
            ...this.subject.value,
            busy: true,
        });

        const update = new Date();

        conversationApi.tailConversationMessages(tenant, conversation, lastMessage)
            .then(value => {

                const _messages = value.data.items || [];
                let _new_array_ids = [...messageIds];
                let _new_array_messages = [...messages];

                _messages.reverse().forEach(_message => {

                    if(!messageIds.includes(_message.id)) {
                        _new_array_ids.push(_message.id);
                        _new_array_messages.unshift(_message);
                    }
                })

                const _new_last_message =  _new_array_messages.length > 0 ? _new_array_messages[0].id : lastMessage

                this.subject.next({
                    ...this.subject.value,
                    update: update,
                    messageIds: _new_array_ids,
                    messages: _new_array_messages,
                    lastMessage: _new_last_message,
                });
            }, reason => notificationService.error(reason.message))
            .finally(() => {
                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                });
            });
    }

    close = () => {
        clearInterval(this.messagePollTimer);
    }

    switchConversation = (user, conversation, tenant) => {

        this.subject.next({
            tenant: tenant,
            user: user,
            conversation: conversation,
            lastMessage: "_",
            messages: [],
            messageIds: [],
            busy: false,
        });

        this.__pollForNewMessages();
    }
}

export class ChatBlocEvent {
    static ACTIVE_CONVERSATION_CHANGED = "ACTIVE_CONVERSATION_CHANGED";
}
