import axios from 'axios';

class ConsumerApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getPersonSummary(tenant, consumerId, origin) {

        if(origin) {

            return axios.get(`${this.baseUrl}/internal/consumers/${encodeURI(consumerId)}?o=${encodeURI(origin)}`, {
                headers: {
                    'X-Tenant-ID': tenant,
                }
            });
        } else {

            return axios.get(`${this.baseUrl}/internal/consumers/${encodeURI(consumerId)}`,{
                headers: {
                    'X-Tenant-ID': tenant,
                }
            });
        }
    }
}

export const consumerApi = new ConsumerApi();
