import React, {Component} from 'react';

import DocumentPreparationJobContext from "../context";
import {withStyles} from '@material-ui/core/styles';
import {
    TextField,
    Typography,
} from '@material-ui/core';
import {dataPreparationApi} from "../../../../../utils/services/datapreparation.api";
import {DataPreparationBloc} from "../dataPreparation.bloc";

const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        padding: "30px 25px",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontSize: "24px",
        fontWeight: "400",
        color: "#fff",
        marginBottom: "7px",
    },
});

class DocumentPanel extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
        };
    }

    pdfIframe = (text) => {

        const iframe = `<iframe src="${text}" width="100%" height="800px" style="border: none;"/>`

        return {__html: iframe };
    }

    render() {

        let { classes } = this.props;
        let { bloc } = this.props.context
        let { document } = bloc.subject.value

        let pdfDocument = (<></>);
        let textDocument = (<></>);

        const documentReference = this.props.context.job.references.find(x => x.code.code === 'sourceDocument');

        if (documentReference.contentType === "application/pdf") {
            pdfDocument = (
                <div className={classes.root}>
                    { document && <div dangerouslySetInnerHTML={this.pdfIframe(document)}></div> }
                </div>
            );
        }

        if (documentReference.contentType === "text/plain") {
            textDocument = (
                <div className={classes.root}>
                    { document &&
                    <TextField disabled
                               id="text-original"
                               type="text"
                               label="Original Text"
                               multiline rows={40}
                               variant="outlined"
                               defaultValue={document}
                               margin="normal"
                    /> }
                </div>
            );
        }

        return (
            <>
                {pdfDocument}
                {textDocument}
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <DocumentPreparationJobContext.Consumer>
        {value => {
            return (<DocumentPanel context={value} {...props} />);
        }
        }
    </DocumentPreparationJobContext.Consumer>
));
