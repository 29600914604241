import axios from 'axios';

class AdminApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    publishEvent(event) {

        return axios.post(`${this.baseUrl}/internal/users/me/_events`, event);
    }

    health(tenant) {

        return axios.get(`${this.baseUrl}/internal/experiment/health/_emr_test`, {
            headers: {
                'X-Tenant-ID': tenant,
            }
        })
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }

    getUser(userId) {

        return axios.get(`${this.baseUrl}/internal/users/${btoa(userId)}`)
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }


    setPermissions(userId, permissions) {

        return axios.patch(`${this.baseUrl}/internal/users/${btoa(userId)}`, { permissions: permissions })
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }
}

export const adminApi = new AdminApi();
