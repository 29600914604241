import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputAdornment, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardHeader: {

    },
    subHeader: {
        fontSize: "1.5rem"
    },
    referenceHeader: {
        fontSize: "1rem"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    },
    linkReferenceHeader: {
        fontSize: "1rem",
        color: "#55CCFF"
    },
});

class ComplicationsApprovalComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;
        this.searchDataType = "complication"

        this.state = {
            initialising: true,
            name: "",
            cui: "",
            complications: [],
            complications_removed: [],
        };

        this.__remove = this.__remove.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Complications Approval Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __selectChanged = (event) => {

        if (event === null) {
            return
        }

        this.setState({
            name: event.canonicalName,
            cui: event.conceptId,
        });
    }

    __add = () => {

        let { complications, name, cui } = this.state;

        if(name === "" ) {
            notificationService.error("Please enter complication.");
            return;
        }

        complications.push({
            id: uuid().toString(),
            name: name,
            cui: cui
        });

        this.setState({
            complications: complications,
            name: undefined,
            cui: undefined
        })

    }
    __toggle = (_item) => () => {

        let { complications_removed } = this.state;

        const item = complications_removed.filter(item => item === _item.id);
        if(item.length > 0) {
            complications_removed = complications_removed.filter(item => item !== _item.id);
        } else {
            complications_removed.push(_item.id);
        }
        this.setState({
            complications_removed: complications_removed,
        });
    }


    __remove = (_item) => () => {

        let { complications } = this.state;

        complications = complications.filter(item => item.id !== _item.id);

        this.setState({
            complications: complications,
        });
    }

    __getOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({ query: _inputValue, dataType: [this.searchDataType] })
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        const aliases = item.index
                        aliases.unshift(item.canonicalName)
                        const uniqueNames =  [...new Set(aliases)]
                        const option = {
                            name: uniqueNames.join(', '),
                            conceptId: item.conceptId,
                            canonicalName: item.canonicalName
                        }

                        options.push(option)

                    })

                    resolve(options)
                }, error => {
                    notificationService.error("Error loading complications");
                    resolve(options)
                });
        })
    }


    __submit = () => this.bloc.submitTask({ data: {complications_removed: this.state.complications_removed, complications: this.state.complications.map(value => {
                return {
                    "complication": value.name,
                    "complicationCUI": value.cui
                }
            }) } });
    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const { pipeline, classes } = this.props;
        const { initialising, task, name, complications, complications_removed } = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please approve the below complications. Additionally, you can add more complications before approving.`}
                                        titleTypographyProps={{ className: classes.cardTitle }}
                                        subheader={`Use the following reference below to validate complication information`}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Condition: ${task.data.condition}`}
                            </ListSubheader>
                            { task.data.reference ?
                                <a href={task.data.reference} style={{ color: "#55CCFF", fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">
                                    <ListSubheader className={classes.linkReferenceHeader}>Link to reference</ListSubheader>
                                </a> : (<></>)
                            }
                            {task.data.notes ?
                                <ListSubheader className={classes.referenceHeader}>
                                    {`Notes: ${task.data.notes}`}
                                </ListSubheader> : (<></>)
                            }
                            <CardContent className={classes.select}>
                                <div className={classes.option}>
                                    <AutocompleteField id="id"
                                                       key={name}
                                                       defaultValue={name}
                                                       label="Complication"
                                                       setSelectedValue={this.__selectChanged}
                                                       getOptions={this.__getOptions}
                                    />
                                </div>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__add}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List dense={true}>
                                    {this.__renderItems(classes, task, complications_removed)}
                                    {this.__renderEdits(classes, complications)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderItems = (classes, task, removed) => {

       return task.condition.complications.map(complication => {

           const checked = removed.includes(complication.id)

           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText style={{ textDecoration: checked ? "line-through" : "none" }} primary={ complication.name }/>
                   <ListItemSecondaryAction>
                       <Switch
                           edge="end"
                           onChange={this.__toggle(complication)}
                           checked={ checked }
                           inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                       />
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, complications) => {

       return complications.map(complication => {
           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText primary={ complication.name }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__remove(complication)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<ComplicationsApprovalComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

