import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {CircularProgress} from "@material-ui/core";


const styles = theme => ({
    fullscreen: {
        display: "flex",
        position: "fixed",
        right: "0",
        bottom: "0",
        minWidth: "100%",
        minHeight: "100%",
        backgroundColor: "#ffffff",
        margin: "0 auto",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
      margin: "0 auto",
      height: "80px",
    },
});


class Loading extends Component {


    render() {

        let { classes } = this.props;

        return (
            <div className={ classes.fullscreen } >
                <CircularProgress className={classes.progress} />
            </div>
        );
    }
}

export default withStyles(styles)(Loading);
