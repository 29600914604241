import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
    Button,
    Card, CardActions,
    CardContent,
    CardHeader, Divider,
    FormControl,
    Grid, IconButton, Input,
    InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText,
    Paper, TextField,
    Typography
} from "@material-ui/core";

import {navigationTitleService} from "../../../../../../../utils/title";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import AsyncJobContext from "../../../context";
import PublishIcon from "@material-ui/icons/Publish";
import NormaliseInput from "./NormaliseInput";
import {NormaliseBloc, NormaliseBlocEvent} from "./normalise.bloc";
import CriticalButton from "../../../../../../shared/CriticalButton";



const styles = theme => ({
    root: {
        width: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    },
    dropdownOption: {
        padding: "1%",
        width: "300px"
    },

});
class AnnotateNormaliseComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.taskBloc = props.bloc;
        this.bloc = new NormaliseBloc(this.taskBloc.subject.value.task)

        this.state = {
        };
    }


    componentDidMount() {
        super.componentDidMount();
        navigationTitleService.update("Normalisation Job");
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => {

        this.setState({
            busy: true,
        });
        const { normalisedEntities } = this.state

        normalisedEntities.filter(e => e.checked).forEach(e => {
            delete e.conceptName
            delete e.conceptId
        })

        const data = {
            data: {
                normalised: {
                    entities: normalisedEntities.filter(e => e.hasOwnProperty('conceptId')).map(e =>
                        ({
                            id: e.id,
                            conceptId: e.conceptId,
                            conceptName: e.conceptName,
                            state: "NORMALISED",
                        }))
                }
            }
        }

        this.taskBloc.submitTask(data)

    };

    render() {

        const {classes} = this.props;
        const {initialising, task, readyToPublish, normalisedEntities} = this.state;

        if (initialising) return (<LinearProgress/>);

        return task ? (<div className={classes.root}>
            <Grid className={classes.container} container>
                <Grid item xs={"12"}>
                    <Typography className={classes.header} variant={"h4"}>Please normalise the text annotations
                        below</Typography>
                </Grid>
                <Grid item xs={"12"}>
                    <Typography className={classes.context}
                                variant={"h5"}>{task.data.text || "No text found"}</Typography>
                </Grid>
                <Grid item xs={"12"}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <List dense={true}>
                                {normalisedEntities.map(entity => {
                                    return <NormaliseInput bloc={this.bloc}
                                    entity={entity}
                                    />})}
                                <Divider />
                            </List>
                        </CardContent>
                        <CardActions>
                            <span className={classes.fill}/>
                            <CriticalButton disabled={!readyToPublish} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>) : (<></>)
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<AnnotateNormaliseComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

