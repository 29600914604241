import * as rxjs from 'rxjs';

import {notificationService} from "../../../../../utils/notification";
import {pipelinesApi} from "../../../../../utils/services/pipelines.api";

export class TaskBloc {

    constructor(taskIdentifier, task) {

        this.subject = new rxjs.BehaviorSubject({
            initialising: true,
            busy: false,
            taskIdentifier: taskIdentifier,
            task: task,
        });

        this.events = new rxjs.Subject();
    }

    initialise = () => {

        const { taskIdentifier } = this.subject.value;

        if (this.subject.value.task?.id === taskIdentifier.taskId) {

            this.__updateSubject({initialising: false,});
        } else if(this.subject.value.task) {

            this.events.next({ type: TaskBlocEvent.INCORRECT_TASK_LOADED, data: { } });
        } else {

            pipelinesApi.retrieveTask(taskIdentifier.pipelineId, taskIdentifier.step, taskIdentifier.taskId)
                .then((value) => {
                    this.__updateSubject({initialising: false, task: value.data});
                }, reason => {
                    notificationService.httpError(reason);
                    setTimeout(() => this.events.next({ type: TaskBlocEvent.INCORRECT_TASK_LOADED, data: { } }), 5000);
                });
        }
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
           ...this.subject.value,
           ...newProps,
        });
    }

    discardTask = (reason) => {
        let task = this.subject.value.task
        this.__updateSubject({busy: true});
        pipelinesApi.discardTask(task.lock.pipeline, task.lock.step, task.id, reason)
            .then(value => {
                this.events.next({ type: TaskBlocEvent.TASK_DISCARDED, data: { } })
            }, error => {
                notificationService.httpError(error);
            })
            .finally(() => this.__updateSubject({busy: false}));
    }

    submitTask = (data) => {
        let task = this.subject.value.task
        data["id"] = task.id;
        this.__updateSubject({ busy: true });
        pipelinesApi.submitTask(task.lock.pipeline, task.lock.step, data)
            .then(value => {
                this.events.next({ type: TaskBlocEvent.TASK_SUBMITTED, data: { } })
            }, error => {
                notificationService.httpError(error);
            })
            .finally(() => this.__updateSubject({busy: false}));
    }

}

export class TaskBlocEvent {
    static TASK_DISCARDED = "TASK_DISCARDED";
    static TASK_SUBMITTED = "TASK_SUBMITTED";
    static INCORRECT_TASK_LOADED = "INCORRECT_TASK_LOADED";
}
