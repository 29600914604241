import React, {Component} from 'react';

import DocumentPreparationJobContext from "../../context";
import AnnotationTextPanel from "./AnnotationTextPanel";
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, LinearProgress, Typography,} from "@material-ui/core";
import RelationshipPanel from "./RelationshipPanel";
import {FormattedMessage} from "react-intl";
import {AnnotationBloc, AnnotationStatus} from "../annotation.bloc";
import {navigationTitleService} from "../../../../../../utils/title";
import {notificationService} from "../../../../../../utils/notification";
import {MetadataState} from "../../dataPreparation.bloc";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        backgroundColor: "#fff",

    },
    centralPanel: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        backgroundColor: "#303030",
        display: "flex",
    },
    gridTop: {
        overflow: "auto",
        padding: "10px 25px",
    },
    topPanel: {
        overflow: "auto",
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        overflow: "auto",
        display: 'flex',
    },
    title: {
        fontSize: "32px",
        fontWeight: "400",
        color: "#fff",
        marginTop: "7px",
        marginBottom: "7px",
    },
    source: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#fff",
        marginBottom: "7px",
    },
    gridBottom: {
        display: "flex",
        flexDirection: "column",
        padding: "15px 25px"
    }
});

class AnnotationJobPanel extends Component {

    annotationBloc;

    constructor(props, context) {
        super(props, context);
        this.state = {
            busy: false
        };
        this.annotationBloc = new AnnotationBloc(this.props.context.bloc);
    }

    componentDidMount() {
        navigationTitleService.update("Annotation Task");
    }

    onSubmit = () => {
        let {entities} = this.annotationBloc.subject.value
        // Cannot submit if there are pending annotations
        entities.filter(item => {
            return (item.state === AnnotationStatus.MANUAL_ENTITY || item.state === AnnotationStatus.AUTOMATED_ENTITY)
        }).length > 0 ?
            notificationService.error('Please complete or remove incomplete annotations before submitting.') :
            this.annotationBloc.submitEntities()
    }

    setScreenBusy = (flag) => {
        this.setState({
            busy: flag
        })
    }


    render() {

        let {classes} = this.props;
        let {busy} = this.state

        let {displayName, provenance} = this.props.context.bloc.subject.value.job

        return (
            <div className={classes.root}>
                {(busy) && <LinearProgress/>}
                    <div className={classes.centralPanel}>
                        <Grid container>
                            <Grid container item xs={12} className={classes.gridTop}>
                                <Grid container item xs={6} direction="column">
                                    <Grid container item>
                                        <Typography display="block" className={classes.title} variant="h1"
                                                    component="h2">{displayName}</Typography>
                                    </Grid>
                                    <Grid container item>
                                        <Typography display="block" className={classes.source}
                                                    style={{fontStyle: 'italic'}}>{provenance[0].source}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.button} item xs={6} justify="flex-end">
                                    <Grid item>
                                        <Button size="large" onClick={this.annotationBloc.resetAnnotations}>
                                            <FormattedMessage id="reset-button"
                                                              defaultMessage={`RESET ANNOTATIONS`}/>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large" onClick={() => this.annotationBloc.saveAsDraft(MetadataState.ANNOTATED_DRAFT)}>
                                            <FormattedMessage id="draft-button" defaultMessage={`SAVE AS DRAFT`}/>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large" onClick={this.onSubmit}>
                                            <FormattedMessage id="submit-button"
                                                              defaultMessage={`SUBMIT DOCUMENT`}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={8}>
                                <AnnotationTextPanel setScreenBusy={this.setScreenBusy} annotationBloc={this.annotationBloc}/>
                            </Grid>
                            <Grid container className={classes.gridBottom} item xs={4}>
                                    <RelationshipPanel annotationBloc={this.annotationBloc}/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
        );
    }
}

export default withStyles(styles)(props => (
    <DocumentPreparationJobContext.Consumer>
        {value => {
            return (<AnnotationJobPanel context={value} {...props} />);
        }
        }
    </DocumentPreparationJobContext.Consumer>
));
