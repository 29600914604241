import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select, Switch
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",

    },
    listItems: {
        minHeight: "32px",
    },
    listItem: {
        flex: 1,
    },
    select: {
        display: "flex"
    },
    option: {
        flex: 1,
        margin: "0.5%"
    }

});

class MedicationsComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            text: "",
            medications: [],
            medications_removed: [],
        };

        this.__remove = this.__remove.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Medications Approval Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __inputChanged = (event) => {

        this.setState({
            text: event.target.value,
        });
    }

    __add = () => {

        let { medications, text } = this.state;

        if( text === "") {
            notificationService.error("Please add a medication.");
            return;
        }

        medications.push({
            id: uuid().toString(),
            text: text
        });

        this.setState({
            medications: medications,
            text: ""
        })

    }
    __toggle = (_item) => () => {

        let { medications_removed } = this.state;

        const item = medications_removed.filter(item => item === _item.id);
        if(item.length > 0) {
            medications_removed = medications_removed.filter(item => item !== _item.id);
        } else {
            medications_removed.push(_item.id);
        }
        this.setState({
            medications_removed: medications_removed,
        });
    }


    __remove = (_item) => () => {

        let { medications } = this.state;

        medications = medications.filter(item => item.id !== _item.id);

        this.setState({
            medications: medications,
        });
    }

    __submit = () => this.bloc.submitTask({
        data: {
            medications_removed: this.state.medications_removed,
            medications: this.state.medications.map(value => {
                return {
                    "text": value.text
                }
            })
        }
    });

    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const { pipeline, classes } = this.props;
        const { initialising, task, name, medications, medications_removed, text} = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please approve the below medications. Additionally, you can add more medications before approving.`}
                                        titleTypographyProps={{ className: classes.cardTitle }}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Condition: ${task.data.condition}`}
                            </ListSubheader>
                            <ListSubheader className={classes.subHeader}>
                                {`Url: ${task.data.reference}`}
                            </ListSubheader>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="medicationTxt">Medication Input</InputLabel>
                                    <Input
                                        id="medicationTxt"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={text}
                                        onChange={this.__inputChanged}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__add}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List dense={true}>
                                    {this.__renderItems(classes, task, medications_removed)}
                                    {this.__renderEdits(classes, medications)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderItems = (classes, task, removed) => {

        return task.condition.medications.map(medication => {

            const checked = removed.includes(medication.id)

            return (<>
                <Divider />
                <ListItem className={classes.listItem}>
                    <ListItemText style={{ textDecoration: checked ? "line-through" : "none" }} primary={ medication.name }/>
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={this.__toggle(medication)}
                            checked={ checked }
                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </>);
        });
    }

    __renderEdits = (classes, medications) => {

        return medications.map(medication => {
            return (<>
                <Divider />
                <ListItem className={classes.listItem}>
                    <ListItemText primary={ medication.text }/>
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.__remove(medication)} edge="end" aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </>);
        });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<MedicationsComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

