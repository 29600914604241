import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Wifi from "@material-ui/icons/Wifi";
import WifiOff from "@material-ui/icons/WifiOff"


const styles = theme => ({
    root: {
    },
    icon: {
    },
});


class ConnectionStatus extends Component {


    render() {

        let { classes, status } = this.props;

        const render = status === "on" ?
            (<Wifi className={classes.icon} />) :
            (<WifiOff className={classes.icon} />);

        return (
            <div className={ classes.root } >
                { render }
            </div>
        );
    }
}

export default withStyles(styles)(ConnectionStatus);
