import { Subject } from 'rxjs';

export class MessageAnnotationEventService {

    subject = new Subject();

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(type, data) {
        this.subject.next({type: type, data: data});
    }
}

export class MESSAGE_ANNOTATION_EVENTS {

    static MESSAGE_ANNOTATED = "MESSAGE_ANNOTATED";
    static MESSAGE_DISCARD = "MESSAGE_DISCARD";
    static QUESTION_REPHRASED = "QUESTION_REPHRASED";
    static EXCEPTION = "EXCEPTION";
}

export const messageAnnotationEventService = new MessageAnnotationEventService();
