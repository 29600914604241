import * as rxjs from "rxjs";
import {notificationService} from "../../../../utils/notification";
import {nlpApi} from "../../../../utils/services/nlp.api";

export class EntityRecognitionBloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            text: undefined,
            entities: [],
            phrases: [],
            normalisedObservations: [],
            normalisedConditions: [],
            resolvedEntities: [],
            resolvedPhrases: [],
        });

        this.events = new rxjs.Subject();
    }


    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    evaluateText = (text) => {

        let requestText = {
            "text": text
        }

        nlpApi.evaluate(requestText)
            .then(res => {
                return this.renderEntities(res)
            })
            .then((value) => {
                this.subject.next({
                    ...value,
                })
            })
            .then(() => {
                this.events.next({event: EntityRecognitionBlocEvent.ENTITIES_AVAILABLE})
            })
            .catch(() => {
                notificationService.error("Service unavailable");
                this.events.next({event: EntityRecognitionBlocEvent.ENTITIES_ERROR})
            });
    }

    renderEntities = (response) => {

        let {text, entities, phrases, conditions, observations} = response.data
        //
        // let result = []
        //
        // let sortedEntities = entities.sort((a, b) => (a.start > b.start) ? 1 : -1)
        // let index = 0
        // sortedEntities.forEach(entity => {
        //     let substring = text.substring(index, entity.start)
        //     if (substring.length > 0) {
        //         result.push(substring)
        //     }
        //
        //     let normalisedConditions = conditions.filter(item => item.extractId === entity.id)
        //     let normalisedObservations = observations.filter(item => item.extractId === entity.id)
        //
        //     if (normalisedConditions.length > 0 || normalisedObservations.length > 0)
        //         entity.normalised = true;
        //         entity.conditions = normalisedConditions
        //         entity.observations = normalisedObservations
        //
        //     result.push(entity)
        //     index = entity.end
        // })
        //
        // result.push(text.substring(index, text.length))
        //
        // let sortedPhrases = phrases.sort((a, b) => (a.start > b.start) ? 1 : -1)
        // let phraseIndex = 0
        // let phraseResult = []
        //
        // sortedPhrases.forEach(phrase => {
        //     let substring = text.substring(phraseIndex, phrase.start)
        //     if (substring.length > 0) {
        //         phraseResult.push(substring)
        //     }
        //
        //     let normalisedConditions = conditions.filter(item => item.extractId === phrase.id)
        //     let normalisedObservations = observations.filter(item => item.extractId === phrase.id)
        //
        //     if (normalisedConditions.length > 0 || normalisedObservations.length > 0)
        //         phrase.normalised = true;
        //     phrase.conditions = normalisedConditions
        //     phrase.observations = normalisedObservations
        //
        //     phraseResult.push(phrase)
        //     phraseIndex = phrase.end
        // })

        let resolvedEntities = this.resolveContent(text, entities, conditions, observations)
        let resolvedPhrases = this.resolveContent(text, phrases, conditions, observations)

        return {
            text: text,
            entities: entities,
            resolvedEntities: resolvedEntities,
            phrases: phrases,
            resolvedPhrases: resolvedPhrases,
            normalisedObservations: observations,
            normalisedConditions: conditions,
        }
    }

    resolveContent = (text, extract, conditions, observations) => {
        let sortedContent = extract.sort((a, b) => (a.start > b.start) ? 1 : -1)
        let index = 0
        let result = []

        sortedContent.forEach(item => {
            let substring = text.substring(index, item.start)
            if (substring.length > 0) {
                result.push(substring)
            }

            let normalisedConditions = conditions.filter(c => c.extractId === item.id)
            let normalisedObservations = observations.filter(o => o.extractId === item.id)

            if (normalisedConditions.length > 0 || normalisedObservations.length > 0)
                item.normalised = true;
            item.conditions = normalisedConditions
            item.observations = normalisedObservations

            result.push(item)
            index = item.end
        })

        result.push(text.substring(index, text.length))

        return result

    }


}


export class EntityRecognitionBlocEvent {
    static ENTITIES_AVAILABLE = "ENTITIES_AVAILABLE";
    static ENTITIES_ERROR = "ENTITIES_ERROR";
}