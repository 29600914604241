import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button} from "@material-ui/core";
import AnnotationContextMenu from "../../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../../utils/text.highlight";
import AnnotatedText from "../../shared/AnnotatedText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {preprocessor} from "../../shared/preprocessor";
import {notificationService} from "../../../../../utils/notification";
import Loading from "../../../../shared/Loading";
import TextAnnotationPanel from "./TextAnnotationPanel";
import {ConversationTurnBlocEvent} from "./bloc";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});




class AgendaAnnotation extends Component {

    constructor(props) {
        super(props);

        const { bloc } = props;

        this.bloc = bloc;

        this.state = {
            bloc: bloc,
            initialising: true,
            step: 0,
        };

        this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
    }

    componentDidMount() {

        this.subscription = this.bloc.subscribeToState(this.__handleStateUpdate);
        this.subscriptionEvents = this.bloc.subscribeToEvents(this.__handleEvent);
        this.bloc.initialise({
            contextMenu: {
                slot: "agenda",
            },
        });
    }

    componentWillUnmount() {

        this.subscription.unsubscribe();
        this.subscriptionEvents.unsubscribe();
    }

    __handleStateUpdate = (subject) => {

        this.setState({
            ...subject,
        });
    }

    __handleEvent = (_event) => {

        const { event, data } = _event;

        switch (event) {
            case ConversationTurnBlocEvent.INTENTS_UPDATED:
            case ConversationTurnBlocEvent.EXCEPTION:
                this.setState({
                    busy: false,
                });
                return;
        }
    }


    // _sendToException = () => {
    //
    //     let { exceptionEvent } = this.props;
    //
    //     let { event : { data } } = this.props.context;
    //
    //     let { lastTurn, slotSpecification } = data;
    //
    //     let { entities, contextOverride } = this.state;
    //
    //     let addedEntities = entities.filter((entity) => !entity.id);
    //     let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))
    //
    //     if (exceptionEvent !== "MESSAGE_DISCARD" && this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {
    //         notificationService.error("This message is correctly annotated.");
    //         return;
    //     }
    //
    //     messageAnnotationEventService.update(exceptionEvent || MESSAGE_ANNOTATION_EVENTS.EXCEPTION, {
    //         action: exceptionEvent ? "suggest_forget" : "suggest_escalation",
    //         conversationId: lastTurn.metadata.conversationId,
    //         messageId: lastTurn._id,
    //         tenantId: lastTurn.tenant,
    //         entityUpdates: {
    //             add: addedEntities,
    //             remove: removedEntities
    //         },
    //         original: data,
    //     });
    // };

    _submit = () => {

        let { event : { data : { slotSpecification } } } = this.props.context;

        let { requiresReword, entities, metadata, contextOverride } = this.state;

        if(!this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {
            notificationService.error("You need to annotate the target slot to mark this as successful.");
            return;
        }

        if(entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
            return;
        }

        preprocessor.preprocessSubmission(metadata, entities, contextOverride);

        let requiresRewording = this._deriveRewording(entities, this.props.context.event.data);

        if(!requiresReword && requiresRewording.length > 0) {

            this.setState({
                requiresReword: true,
                requiresRewording: requiresRewording,
            });
        } else {

            this._submitInternal(entities, contextOverride);
        }
    };


    _submitInternal = (entities, contextOverride) => {

        let { event : { data : { slotSpecification, slot, lastTurn } } } = this.props.context;

        if(!this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {

            notificationService.error("You need to annotate the target slot to mark this as successful or a single new intent.");
        } if(entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
        } else {

            let addedEntities = entities.filter((entity) => !entity.id);
            let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))

            // messageAnnotationEventService.update(MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED, {
            //     action: "answer",
            //     conversationId: lastTurn.metadata.conversationId,
            //     messageId: lastTurn._id,
            //     tenantId: lastTurn.tenant,
            //     entityUpdates: {
            //         add: addedEntities,
            //         remove: removedEntities
            //     }
            // });
        }
    }

    _correctlyAnnotated = (slotSpecification, entities, contextOverride) => {

        let correct = (entities && entities.length > 0 && entities.filter((item) => slotSpecification.code === item.category).length > 0);
        if(!correct && contextOverride?.derived?.length > 0) {
            correct = entities.filter((item) => contextOverride.menu[item.category]).length > 0;
        }

        if(correct) return correct;

        const intentList = entities.filter((item) => "intent" === item.category);

        if(intentList.length === 0) {
            return correct;
        }

        if(intentList.length > 1) {
            return false;
        }

        const intent = intentList[0];

        return (entities && entities.length > 0 && entities.filter((item) =>
            item.start === intent.start &&
            item.end === intent.end &&
            item.category !== "intent").length > 0);
    };

    __exception = () => {

        const { busy } = this.state;

        if(busy) {
            notificationService.error("Still processing last request.");
            return;
        }

        this.setState({
            busy: true,
        });

        this.bloc.answerQuinn();
    };

    __next = (annotations) => {

        const { busy } = this.state;

        if(busy) {
            notificationService.error("Still processing last request.");
            return;
        }

        if(!annotations.added || annotations.added.length === 0) {
            notificationService.error("Please ensure you annotate the agenda of the utterance.");
            return;
        }

        let targetIntent = annotations.added[0]

        const added = annotations.added.filter(_annotation => _annotation.category.includes('+'));
        if(added.length > 0) {
            targetIntent = added[0];
        }

        this.setState({ busy: true});

        this.bloc.setIntent([targetIntent]);

        this.setState({
            priorityIntent: targetIntent.category,
            step: 1 }
        );
    };

    __back = () => {

        this.setState({
            busy: true,
            step: 0
        });

        setTimeout(() => {
            this.setState({ busy: false });
        }, 100);
    };

    __submit = (annotations) => {

        const { busy } = this.state;

        if(busy) {
            notificationService.error("Still processing last request.");
            return;
        }

        this.setState({
            busy: true,
        });

        this.bloc.setEntities(annotations.added);

        this.bloc.answerQuinn();
    };


    render() {

        const { step, loading, busy } = this.state;

        if(loading || busy) {
            return <Loading />;
        }

        if(step === 0) {
            return this.renderAgenda();
        }

        return this.renderGeneral();
    }

    renderAgenda() {

        let { event : { data : { lastTurn, slotSpecification, slot, relatedSlots, relatedDomains, relatedSlotSpecifications, quinnLastTurn } } } = this.bloc.subject.value;
        let { intents, contextOverrides, busy }  = this.state;
        let { classes }  = this.props;

        const title = "Annotate the agenda of the utterance.";
        const subtitle = "Highlight and select to the lowest level possible.";

        const contextMenuProps = {
            targetSlot:  slot ? slot.code : "none",
            targetSlotSpecification: slotSpecification,
            relatedSlots: relatedSlots,
            relatedDomains: relatedDomains,
            relatedSlotSpecifications: relatedSlotSpecifications,
            menuOverride: contextOverrides && contextOverrides['agenda'],
            includeDefault: false,
        };

        return <TextAnnotationPanel
            id={'agenda-annotation-panel'}
            bloc={this.bloc}
            busy={ busy }
            title={ title }
            subtitle={ subtitle }
            text={ lastTurn.content }
            initial={ intents }
            classes={ classes }
            contextMenuProps={ contextMenuProps }
            handleSubmit={ this.__next }
            exceptionLabel={ <FormattedMessage id={"agenda.annotation.exception.label"} defaultMessage={"Unsure"} /> }
            handleException={ this.__exception }
        />;
    }

    renderGeneral() {

        let { event : { data : { lastTurn, slotSpecification, slot, relatedSlots, relatedDomains, relatedSlotSpecifications, quinnLastTurn } } } = this.bloc.subject.value;
        let { entities, busy, contextOverrides, priorityIntent }  = this.state;
        let { classes }  = this.props;

        const title = "Annotate any relevant information if available.";
        const subtitle = "If there is nothing to annotate please submit.";

        const contextMenuProps = {
            targetSlot:  slot ? slot.code : "none",
            targetSlotSpecification: slotSpecification,
            relatedSlots: relatedSlots,
            relatedDomains: relatedDomains,
            relatedSlotSpecifications: relatedSlotSpecifications,
            menuOverride: contextOverrides && contextOverrides[priorityIntent],
            includeDefault: false,
        };

        return <TextAnnotationPanel
            id={'general-annotation-panel'}
            bloc={this.bloc}
            busy={ busy }
            title={ title }
            initial={ entities }
            subtitle={ subtitle }
            text={ lastTurn.content }
            classes={ classes }
            contextMenuProps={ contextMenuProps }
            handleBack={ this.__back }
            handleSubmit={ this.__submit }
        />;
    }
}

export default withStyles(styles)(AgendaAnnotation);
