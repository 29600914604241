import React from 'react';
import {Component} from "react";

import {withStyles} from '@material-ui/core/styles';

import {
    Divider, Box, Paper, Typography
} from "@material-ui/core";


const styles = theme => ({
    conversation: {
        padding: "2%",
        overflow: "auto",
        height: "calc(100vh - 350px)"
    },
    conversationTitle: {
        padding: "2%",
    },
    utteranceQuestionContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    utteranceQuestion: {
        backgroundColor: "#90caf9",
        color: "black",
        margin: "3%",
        padding: "1%",
        rounded: 2,
        elevation: 10,
        marginRight: "10%",
    },
    utteranceAnswer: {
        backgroundColor: "#eeeeee",
        color: "black",
        margin: "3%",
        padding: "1%",
        rounded: 2,
        elevation: 10,
        marginLeft: "10%",
        alignItems: "flex-end"
    }
});

class Conversation extends Component {

    render() {

        const {classes, data} = this.props

        return (
            <Box>
                <Box className={classes.conversationTitle}>
                    <Typography fontWeight="bold" variant="h5">Conversation</Typography>
                </Box>
                <Divider/>
                <Box className={classes.conversation}>
                    {data.map(c => {
                        return (
                            <Box>
                                <Box className={classes.utteranceQuestionContainer}>
                                    <Paper className={classes.utteranceQuestion}>
                                        <Typography variant="body1">{c.question}</Typography>
                                    </Paper>
                                </Box>
                                <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                    <Paper className={classes.utteranceAnswer}>
                                        <Typography align="right" variant="body1">{c.text}</Typography>
                                    </Paper>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        );
    }
}



export default withStyles(styles)

(
    Conversation
)
;