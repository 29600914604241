import React from 'react';
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Visualise from './Visualise';
import {navigationTitleService} from "../../../utils/title";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
      minHeight: "100%",
    },
});

class Conversations extends React.Component {

    state = {
        open: false,
        user: {},
    };

    componentWillMount() {
        navigationTitleService.update("Conversations");
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.grid}>
                    <Route path="/conversations" exact component={ Visualise } />
                </Grid>
            </div>
        );
    }
}

Conversations.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Conversations));
