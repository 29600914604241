import { authService } from './auth'
import {CloudEvent} from "cloudevents";
import {adminApi} from "./services/admin.api";

export const EVENT = {

    "inbound": {
        "/quinn": {
            "quinn.action.ask_human": {  },
        }
    },
    "outbound": {
        "/quinn": {
            "quinn.action.answer_quinn": {  },
        },
    }
}

export function extractMetaData(event) {

    let metadata = {};

    for (const [key, value] of Object.entries(event)) {
        if(Headers.isValid(key)) {
            metadata[`${key}`] = `${value}`;
        }
    }

    return metadata;
}

export function fromString(string) {
    return fromJson(JSON.parse(string));
}

export function fromJson(json) {

    return new CloudEvent(json);
}

export function isValidQuinnEvent(event) {

    if(event.dataschema && event.dataschema.indexOf("https://schema.decodedhealth.com/events/quinn.json?version=20200718") > -1) {

        /* TODO: Add more validation. */

        return true;
    }

    return false;
}

export function isValidRpaEvent(event) {

    if(event.dataschema && event.dataschema.indexOf("https://schema.decodedhealth.com/rpa") > -1) {

        /* TODO: Add more validation. */

        return true;
    }

    return false;
}


export async function generateAnswerQuinn(tenant, data) {

    const headers = {};
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return baseEvent("quinn.action.answer_quinn", headers, data);
}

//HEADERS
export class Headers {
    static AUTHORISATION = "authorization";
    static TENANT_ID = "xdhtenantid";
    static CORRELATION_ID = "xdhcorrelationid";

    static AMC = "amc";

    static PIPELINE_HINT = "xdhquinnpipehint";

    static TARGET_PURPOSE = "xdhconvpurpose";

    static CONVERSATION_NEW = "xdhnewconv";
    static CONVERSATION_ID = "xdhconvid";
    static BOT_CONVERSATION_ID = "xdhbotconvid";
    static CONVERSATION_GOAL = "xdhconvgoal";
    static CONVERSATION_GOAL_ID = "xdhconvgoalid";

    static DECODED_USER_ID = "xdhdecodeduserid";
    static USER_ID = "xdhuserid";
    static MESSAGE_ID = "xdhmessageid";
    static TOP_INTENT = "xdhtopint";
    static TOP_INTENT_SCORE = "xdhtopintscore";

    static ORIGINAL_JOB = "xdhoriginaljob";

    static INTENT_SLOT_ID = "xdhintslotid";
    static INTENT_SLOT_CODE = "xdhintslotcode";
    static INTENT_SLOT_DATA_TYPE = "xdhintslotdatatype";
    static INTENT_SLOT_DATA_MULTIPLICITY = "xdhintslotmulti";
    static INTENT_SLOT_VALUE = "xdhintslotvalue";

    static LAST_QUESTION = "xdhlastques";
    static LAST_ANSWER = "xdhlastans";
    static REITERATE_OPTIONS = "xdhreitopts";
    static LOCK = "xdhlock";

    static PRIVACY_SETTINGS = "xdhprivacy";
    static COPY_CARE_TEAM = "xdhcopycareteam";
    static PLATFORM_CODE = "xdhplatformcode";

    static VALID = [
        Headers.AUTHORISATION,
        Headers.TENANT_ID,
        Headers.CORRELATION_ID,
        Headers.AMC,
        Headers.PIPELINE_HINT,
        Headers.TARGET_PURPOSE,
        Headers.CONVERSATION_NEW,
        Headers.CONVERSATION_ID,
        Headers.BOT_CONVERSATION_ID,
        Headers.MESSAGE_ID,
        Headers.TOP_INTENT,
        Headers.TOP_INTENT_SCORE,
        Headers.ORIGINAL_JOB,
        Headers.INTENT_SLOT_ID,
        Headers.INTENT_SLOT_CODE,
        Headers.INTENT_SLOT_DATA_TYPE,
        Headers.INTENT_SLOT_DATA_MULTIPLICITY,
        Headers.INTENT_SLOT_VALUE,
        Headers.LAST_QUESTION,
        Headers.LAST_ANSWER,
        Headers.REITERATE_OPTIONS,
        Headers.LOCK,
        Headers.PLATFORM_CODE,
        Headers.CONVERSATION_GOAL,
        Headers.CONVERSATION_GOAL_ID,
        Headers.DECODED_USER_ID,
        Headers.USER_ID,

        Headers.PRIVACY_SETTINGS,
        Headers.COPY_CARE_TEAM,
    ];

    static isValid = (key) => {
        return Headers.VALID.includes(key);
    }
}


//IN
export const RPA_MANUAL_STEP = "rpa.robot.error";

export const INTENT_CLASSIFICATION_JOB = "intent.classification.job";
export const MESSAGE_ANNOTATION_JOB = "message.annotation.job";
export const INTENT_HPI_PRIORITY_JOB =  "intent.hpi.priority.job";
export const EXCEPTION_JOB = "message.exception.job";

export const EXCEPTION_SESSION_JOB = "exception.job";

export const EXCEPTION = "exception";

//OUT
export const TECHNICIAN_SESSION_ACTIVATE = "technician.session.activate";
export const TECHNICIAN_SESSION_ACTIVATED = "technician.session.activated";
export const TECHNICIAN_SESSION_PAUSE = "technician.session.pause";
export const TECHNICIAN_SESSION_PAUSED = "technician.session.paused";
export const TECHNICIAN_SESSION_WAITING = "technician.session.waiting";
export const TECHNICIAN_SESSION_CONVERSATION_SWITCH = "technician.session.conversation.switch";
export const TECHNICIAN_SESSION_CONVERSATION_SWITCHED = "technician.session.conversation.switched";
export const TECHNICIAN_SESSION_CONVERSATION_ADDED = "technician.session.conversation.added";
export const TECHNICIAN_SESSION_CONVERSATION_MESSAGE_ADDED = "technician.session.conversation.message.added";
export const TECHNICIAN_SESSION_MANUAL_RESPONSE = "message.manual.response";
export const TECHNICIAN_SESSION_MANUAL_RESPONDED = "message.manual.responded";

export const TECHNICIAN_SESSION_CONVERSATION_HANDBACK = "technician.session.conversation.handback";
export const TECHNICIAN_SESSION_CONVERSATION_HANDEDBACK = "technician.session.conversation.handedback";
export const TECHNICIAN_SESSION_CONVERSATION_END = "technician.session.conversation.end";
export const TECHNICIAN_SESSION_CONVERSATION_ENDED = "technician.session.conversation.ended";

export const QUEUE_MESSAGE = "queue.message";

export const INTENT_CLASSIFIED = "message.intents";
export const INTENT_CLASSIFIED_EXCEPTION = "message.intents.exception";
export const HPI_PROIRITISED = "hpi.prioritised";
export const HPI_PROIRITISED_EXCEPTION = "hpi.prioritised.exception";
export const ANNOTATION_SUCCESS = "message.annotation";
export const ANNOTATION_EXCEPTION = "message.annotation.exception";
export const REPHRASE = "message.rephrase.question";

export const NEXT_MESSAGE = "message.next";
export const REDIRECT_MESSAGE = "message.redirect";
export const SET_AGENDA = "message.set.agenda";

export const RPA_CLEAR = "rpa.robot.clear";

export const HITL_EVENT = "dh.portal.hitl.event";

async function retrieveToken() {
    let token = await authService.getToken();
    if(!token) window.location.replace("/");
    return token;
}

async function baseEvent(type, extensions, data) {
    let token = await retrieveToken();

    const decodedAttributes = {}
    decodedAttributes[Headers.AUTHORISATION] = `Bearer ${token}`;

    for (const [key, value] of Object.entries(extensions)) {
        if(Headers.isValid(key)) {
            decodedAttributes[`${key}`] = `${value}`;
        }
    }

    let event = new CloudEvent({
        type: type,
        source: "/portal",
        datacontenttype: "application/json",
        dataschema: "https://schema.decodedhealth.com/events/quinn.json",
        data: data,
        ...decodedAttributes,
    });

    return event;
}

async function baseTextEvent(type, extensions, data) {
    let token = await retrieveToken();

    const decodedAttributes = {}
    decodedAttributes[Headers.AUTHORISATION] = `Bearer ${token}`;

    for (const [key, value] of Object.entries(extensions)) {
        if(Headers.isValid(key)) {
            decodedAttributes[`${key}`] = `${value}`;
        }
    }

    let event = new CloudEvent({
        type: type,
        source: "/portal",
        datacontenttype: "plain/text",
        dataschema: "https://schema.decodedhealth.com/events/quinn.json",
        data: data,
        ...decodedAttributes,
    });

    return event;
}

export async function generateStateChangeEvent(newState) {

    const eventType = newState === "paused" ? "pause" : "activate";

    return await baseEvent(`technician.session.${eventType}`, {}, {});
}

export async function generateJobDoneEvent(tenant, type, data) {

    let headers = {};
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(type, headers, data);
}

export async function generateAdminEvent(type, data) {
    let headers = {};
    return await baseEvent(type, headers, data);
}


export async function generateSwitchConversationEvent(data) {

    const headers = {};

    return await baseTextEvent(TECHNICIAN_SESSION_CONVERSATION_SWITCH, headers, data);
}


export async function generateManualResponseEvent(tenant, data) {

    const headers = {};
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(TECHNICIAN_SESSION_MANUAL_RESPONSE, headers, data);
}

export async function generateExceptionMessageAnnotatedEvent(tenant, data, metadata) {

    let headers = { };
    if(metadata) {
        headers = { ...metadata };
    }
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(ANNOTATION_SUCCESS, headers, data);
}


export async function generateExceptionIntentIdentifiedEvent(tenant, data, metadata) {

    let headers = { };
    if(metadata) {
        headers = { ...metadata };
    }
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(INTENT_CLASSIFIED, headers, data);
}

export async function generateHandbackConversationEvent(tenant, data) {

    let headers = { };
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(TECHNICIAN_SESSION_CONVERSATION_HANDBACK, headers, data);
}

export async function generateEndEvent(tenant, data) {

    let headers = { };
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(TECHNICIAN_SESSION_CONVERSATION_END, headers, data);
}

export async function generateNextMessageEvent(tenant, conversationId, userId) {

    let headers = { };
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(NEXT_MESSAGE, headers, { conversationId: conversationId, userId: userId });
}

export async function generateRedirectMessageEvent(tenant, conversationId, userId, messageId) {

    let headers = { };
    headers[`${Headers.TENANT_ID}`] = `${tenant}`;

    return await baseEvent(REDIRECT_MESSAGE, headers, { conversationId: conversationId, userId: userId, messageId: messageId });
}


export function publishHitlEvent(headers, data) {

    baseEvent(HITL_EVENT, headers || {}, data)
        .then(value => {
            adminApi.publishEvent(value)
                .then(value1 => {},
                        reason2 => { console.error("Error publishing hitl event - ", reason2) });
        }, reason => { console.error("Error publishing hitl event - ", reason) });
}
