import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';


import {Grid, IconButton, Typography} from "@material-ui/core";

import JobPipelineStep from "./Step";
import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {AddCircleOutline} from "@material-ui/icons";

const styles = theme => ({
    root: {
        width: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",

    },
});

class JobPipeline extends DecodedComponent {


    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;

        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __addJob = () => {
        const { pipeline } = this.props;
        this.bloc.openAddTask(pipeline);
    }


    render() {

        const { pipeline, classes } = this.props;
        const { definition } = pipeline;

        return (
            <div className={classes.root}>
                <Typography id={"job.async.pipeline.name"} variant={"h2"} className={classes.name}>{ definition.name } {definition.definition?.input?.form &&
                    <IconButton color={"primary"} onClick={this.__addJob} aria-label="add">
                    <AddCircleOutline />
                </IconButton>}</Typography>
                <Grid container spacing={2} className={classes.steps}>
                    { definition.definition.main.steps.map((_step) =>  <Grid item xs={12} sm={12} md={3}><JobPipelineStep pipeline={ pipeline } definition={_step} /></Grid>) }
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
                return (<JobPipeline context={value} {...props} />);
            }
        }
    </AsyncJobContext.Consumer>
));
