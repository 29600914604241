import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    LinearProgress,
    ListSubheader
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import {DeleteOutline} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import RelationshipTable from "./RelationshipTable";
import EditObservationDialog from "./EditObservationDialog";
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    referenceHeader: {
        fontSize: "1rem"
    },

    select: {
        display: "flex",
        flexDirection: "column"
    },
    addButton: {
        width: "fit-content",
        margin: "2%"
    },
    linkReferenceHeader: {
        fontSize: "1rem",
        color: "#55CCFF"
    },
});

class RelationshipsComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;
        this.classType = "observation"
        this.searchDataType = "observation"

        this.state = {
            initialising: true,
            busy: false,
            observations: props.bloc.subject.value.task.condition.observations,
            removedObservations: [],
            observationClasses: [],
            editingObservation: undefined,
        };

        this.__removeObservation = this.__removeObservation.bind(this);
        this.__addObservation = this.__addObservation.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Observation Relationships Job");
        this.__getClasses().then(res => {
            this.setState({
                observationClasses: res.data
            })
        })
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __addObservation = () => {

        const { observations } = this.state

        const newObservation = {
            id: uuid()
        }

        observations.push(newObservation)

        this.setState({
            busy: true,
            observations: observations,
            editingObservation: newObservation
        });
    }

    __removeObservation = (_observation) => () => {

        let {observations} = this.state;

        observations = observations.filter(observation => observation.id !== _observation.id);

        this.setState({
            observations: observations,
        });
    }

    __submit = () => this.bloc.submitTask({
        data: {
            removedObservations: this.state.removedObservations,
            observations: this.state.observations
        }
    });

    __discard = (message) => this.bloc.discardTask(message)

    __getClasses = () => {

        return new Promise(resolve => {
            let options = {"data": []}

            ontologyApi.classes(this.classType)
                .then(value => {
                    resolve(value)
                }, error => {
                    notificationService.error("Error loading observation classes");
                    resolve(options)
                }).catch(e => {
                    notificationService.error("Error loading observation classes");
                    resolve(options)
            });
        });
    }

    __editObservation = (id) => {
        const editObservation = this.state.observations.find(o => o.id === id)
        this.setState({
            busy: true,
            editingObservation: editObservation
        })
    }

    __deleteObservation = (id) => {

        const {observations, removedObservations} = this.state

        const removedObservation = observations.find(o => o.id === id)
        removedObservations.push(removedObservation.id)

        const updatedObservations = observations.filter(o => o.id !== id)


        this.setState({
            observations: updatedObservations,
            removedObservations: removedObservations
        })
    }

    __handleEditSave = (observation) => {
        const {observations} = this.state
        const editedObservation = this.state.observations.find(o => o.id === observation.id)
        editedObservation.name = observation.name
        editedObservation.cui = observation.cui
        editedObservation.observationClass = observation.observationClass
        editedObservation.correlation = observation.correlation
        editedObservation.weight = observation.weight

        this.setState({ busy: false, observations: observations, editingObservation: undefined });
    }

    __handleEditClose = () => {

        this.setState({ busy: false, editingObservation: undefined });
    }


    render() {

        const {classes} = this.props;
        const {initialising, busy, task, observations, editingObservation, observationClasses} = this.state;

        if (initialising) return (<LinearProgress/>);


        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please complete all observation relationships for the following condition`}
                                        titleTypographyProps={{className: classes.cardTitle}}
                                        subheader={`Use the following reference below to collect information`}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Condition: ${task.data.condition}`}
                            </ListSubheader>
                            { task.data.reference ?
                                <a href={task.data.reference} style={{ color: "#55CCFF", fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">
                                    <ListSubheader className={classes.linkReferenceHeader}>Link to reference</ListSubheader>
                                </a> : (<></>)
                            }
                            {task.data.notes ?
                                <ListSubheader className={classes.referenceHeader}>
                                    {`Notes: ${task.data.notes}`}
                                </ListSubheader> : (<></>)
                            }
                            <CardContent className={classes.select}>
                                <RelationshipTable
                                    observations={observations}
                                    edit={this.__editObservation}
                                    delete={this.__deleteObservation}
                                />
                                <Button className={classes.addButton} startIcon={ <AddIcon /> } variant={"outlined"} onClick={this.__addObservation}>
                                    Add Observation
                                </Button>
                            </CardContent>
                            <EditObservationDialog
                                open={busy}
                                observation={editingObservation}
                                observationClasses={observationClasses}
                                handleSave={this.__handleEditSave}
                                handleClose={this.__handleEditClose}/>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={<DeleteOutline/>} color={"primary"}
                                                        variant={"outlined"}
                                                        onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill}/>
                                <CriticalButton disabled={observations.length === 0 || observations.filter(o => o.name === undefined).length > 0 || busy} startIcon={<PublishIcon/>}
                                                color={"primary"} variant={"outlined"}
                                                onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<RelationshipsComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

