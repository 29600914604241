import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {navigationTitleService} from "../../../../utils/title";
import {Button, Grid, LinearProgress, Paper, TextField, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {EntityRecognitionBloc, EntityRecognitionBlocEvent} from "./entityrecognition.bloc";
import EntityRecognitionContext from "./context";
import ResultPanel from "./ResultPanel";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        backgroundColor: "#303030",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
    gridTop: {
        display: "flex",
        flexDirection: "column"
    },
    gridBottom: {
        display: "flex"
    },
    inputText: {
        width: "100%",
        height: "100%",
        margin: "auto",
        display: "flex",
        padding: "5%",
        paddingBottom: "2%",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
    },
    inputButton: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "6%"
    },
    submitButton: {
        color: "#fff"
    }

})

class EntityRecognition extends React.Component {

    bloc;

    constructor() {
        super();

        this.state = {
            inputText: '',
            loading: false,
        }


        this.bloc = new EntityRecognitionBloc()

        this.__handleBlocState = this.__handleBlocState.bind(this);
        this.__handleBlocEvent = this.__handleBlocEvent.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("Entity Recognition");

        this.stateSubscription = this.bloc.subscribeToState(this.__handleBlocState);
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleBlocEvent);
    }

    __handleBlocState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleBlocEvent = (event) => {
        const type = event.event;

        switch (type) {
            case EntityRecognitionBlocEvent.ENTITIES_AVAILABLE:
            case EntityRecognitionBlocEvent.ENTITIES_ERROR:
                this.setState({
                    loading: false,
                });
                break;
        }
    }


    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    handleSubmit = event => {
        this.setState({
            loading: true
        })
        let inputText = this.state.inputText
        this.bloc.evaluateText(inputText)
        event.preventDefault()
    }



    render() {
        let {classes} = this.props;

        let {inputText, loading, text} = this.state

        let context = {
            bloc: this.bloc
        }

        return (
            <EntityRecognitionContext.Provider value={context}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        {(loading) && <LinearProgress/>}
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <Grid container className={classes.gridTop}>
                                <div className={classes.inputText}>
                                    <TextField
                                        InputProps={{style: {fontSize: "x-large"}}}
                                        id="input-text"
                                        label="Input Text"
                                        placeholder="Enter text here"
                                        multiline
                                        rows={6}
                                        defaultValue={inputText}
                                        onChange={this.handleChange('inputText')}
                                        variant="outlined">
                                    </TextField>
                                </div>
                                <div className={classes.inputButton}>
                                    <Button className={classes.submitButton} type="submit" size="xlarge">
                                        <FormattedMessage id="submit-button" defaultMessage={`SUBMIT TEXT`}/>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid container className={classes.gridBottom}>
                                {text ? <ResultPanel resolvedEntities={this.bloc.subject.value.resolvedEntities}
                                                     resolvedPhrases={this.bloc.subject.value.resolvedPhrases}/>  : <></>}
                            </Grid>
                        </form>
                    </Paper>
                </div>
            </EntityRecognitionContext.Provider>
        )
    }

}

export default withStyles(styles)(EntityRecognition);