import axios from 'axios';

class QueuesApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getNextSummarisationJob() {

        return axios.get(`${this.baseUrl}/internal/job-types/hpi-summarisation/jobs/_next`);
    }

    getExceptionQueueSize() {

        return axios.get(`${this.baseUrl}/internal/queues/exceptions/_size`);
    }

    getFederatedJobQueueSize() {

        return axios.get(`${this.baseUrl}/internal/queues/federated/_size`);
    }

    getClinicalSummaryJobQueueSize() {

        return axios.get(`${this.baseUrl}/internal/queues/hpi-summarisation/_size`);
    }

    getExceptionRestrictedQueueSize() {

        return axios.get(`${this.baseUrl}/internal/queues/exceptions-restricted/_size`);
    }

    submitSummarisationJob(summary) {

        return axios.patch(`${this.baseUrl}/internal/job-types/hpi-summarisation/jobs/${summary.id}`, summary, {
            headers: {
                "X-Tenant-ID": summary["metaData"].tenant,
            }
        });
    }
}

export const queuesApi = new QueuesApi();
