import React from 'react';
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import EntityRecognition from "./EntityRecognition";
import DifferentialDiagnosis from "./DifferentialDiagnosis";
import ClinicalInference from "./ClinicalInference";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        minHeight: "100%",
    },
});

class Test extends React.Component {

    state = {
        open: false,
        user: {},
    };

    componentWillMount() {
        navigationTitleService.update("Test");
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.grid}>
                    <Route path="/test/entity-recognition" exact component={ EntityRecognition } />
                    <Route path="/test/differential-diagnosis" exact component={ DifferentialDiagnosis } />
                    <Route path="/test/clinical-inference" exact component={ ClinicalInference } />
                </Grid>
            </div>
        );
    }
}

Test.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Test));