import axios from 'axios';

class SessionsApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getFullSessionSummary() {

        return axios.get(`${this.baseUrl}/internal/sessions/_summary`);
    }

    getSessionCount() {

        return axios.get(`${this.baseUrl}/internal/sessions/_count`);
    }

    closeTechnicianSession(userId) {

        return axios.get(`${this.baseUrl}/internal/users/${btoa(userId)}/sessions/_close`)
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }

    removeTechnicianSession(userId) {

        return axios.get(`${this.baseUrl}/internal/users/${btoa(userId)}/sessions/_remove`)
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }

}

export const sessionsApi = new SessionsApi();
