import * as rxjs from "rxjs";

import {pipelinesApi} from "../../../../../../../utils/services/pipelines.api";
import {notificationService} from "../../../../../../../utils/notification";
import {v4 as uuidv4} from "uuid";
import {HITL_JOBS_ASYNC_ENTITY_NORMALISE} from "../jobs";

export class NormaliseBloc {

    constructor(task) {

        if (task.state === "PENDING_APPROVAL") {
            task.annotations.entities.map(e => e.checked = e.state !== "NORMALISED")
        }

        this.subject = new rxjs.BehaviorSubject({
            initialising: true,
            busy: true,
            task: task,
            normalisedEntities: task.annotations.entities.map(e => {
                return {...e,
                    added: false,
                    modified: false
                }}),
            readyToPublish: task.state === "PENDING_APPROVAL",
            deletedEntities: []
        });

        this.events = new rxjs.Subject();
    }

    initialise = () => {
        this.__updateSubject({initialising: false,});
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
            ...this.subject.value,
            ...newProps,
        });
    }

    onDropdownChange = (name, event) => {
        const {normalisedEntities} = this.subject.value

        const entityToUpdate = normalisedEntities.find(({id}) => id === name);

        if (event === null) {
            delete entityToUpdate.conceptName
            delete entityToUpdate.conceptId
        } else {
            entityToUpdate['conceptId'] = event['conceptId']
            entityToUpdate['conceptName'] = event['name']
            entityToUpdate.modified = true
        }

        this.__updateSubject({normalisedEntities: normalisedEntities})
        this.__isReadyToPublish(normalisedEntities)
    }

    __isReadyToPublish = (normalisedEntities) => {
        const {jobType} = this.subject.value.task
        if (jobType === HITL_JOBS_ASYNC_ENTITY_NORMALISE) {
            this.__updateSubject({readyToPublish: normalisedEntities.every(e => (e.hasOwnProperty('conceptId') || e.checked))})
        } else {
            this.__updateSubject({readyToPublish: true})
        }

    }

    onAnnotationChange = (_id, _key, _value) => {
        const {normalisedEntities} = this.subject.value
        const entityToUpdate = normalisedEntities.find(({id}) => _id === id);

        entityToUpdate[_key] = _value
        entityToUpdate.modified = true

        this.__updateSubject({normalisedEntities: normalisedEntities})
        this.__isReadyToPublish(normalisedEntities)
    }


    getDropdownOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            pipelinesApi.queryConcept(_inputValue)
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        item.aliases.forEach(alias => {

                            options.push({
                                name: alias,
                                conceptId: item.conceptId,
                            })
                        })
                    })

                    resolve(options)
                }, error => {
                notificationService.httpError("Error loading normalised concepts");
                    resolve(options)
            });
        })
    }

    removeAnnotation = (_annotation) => {
        const {normalisedEntities, deletedEntities} = this.subject.value

        const updatedEntities = normalisedEntities.filter(e => e.id !== _annotation.id);

        deletedEntities.push(_annotation)

        this.__updateSubject({normalisedEntities: updatedEntities, deletedEntities: deletedEntities})
        this.__isReadyToPublish(normalisedEntities)
    }

    createAnnotation = () => {
        const {normalisedEntities} = this.subject.value

        const entity = {
            id: uuidv4(),
            category: undefined,
            extract: undefined,
            start: undefined,
            end: undefined,
            checked: false,
            modified: false,
            added: true
        }

        normalisedEntities.push(entity)

        this.__updateSubject({normalisedEntities: normalisedEntities})
        this.__isReadyToPublish(normalisedEntities)
    }

}

export class NormaliseBlocEvent {
}
