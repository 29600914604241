import axios from 'axios';

class UtilitiesApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "/internal/utilities";
    }

    typeahead(terms) {

        return axios.get(`${this.baseUrl}/typeahead?t=${encodeURIComponent(terms)}`);
    }

    conceptTypeahead(terms) {

        return axios.get(`${this.baseUrl}/typeahead?t=${encodeURIComponent(terms)}&type=concept`);
    }

    conceptTrainedTypeahead(terms) {

        return axios.get(`${this.baseUrl}/typeahead?t=${encodeURIComponent(terms)}&type=trained`);
    }
}

export const utilitiesApi = new UtilitiesApi();
