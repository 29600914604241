import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withStyles} from "@material-ui/core/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const styles = theme => ({
    root: {
        width: "100%",
    },
});

class AutocompleteMultiselect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inputValue: ""
        }
    }

    setValue = (_id, _value) => {
        this.props.setSelectedValue(_id, _value)
    }

    setInputValue = (_value) => {
        this.setState({
            inputValue: _value,
        })
    }

    render() {
        let {id, options, defaultValue} = this.props
        let {value, inputValue} = this.state

        return (
            <div>
                <Autocomplete
                    multiple
                    value={value}
                    onChange={(event, newValue) => {
                        this.setValue(id, newValue);
                    }}
                    inputValue={inputValue}
                    defaultValue={defaultValue}
                    onInputChange={(event, newInputValue) => {
                        this.setInputValue(newInputValue);
                    }}
                    id={id}
                    options={options}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    sx={{width: 300}}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            {...params}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(option, {inputValue}) => {
                        const matches = match(option.name, inputValue);
                        const parts = parse(option.name, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}


export default withStyles(styles)

(
    AutocompleteMultiselect
)
;
