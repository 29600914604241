import * as React from 'react';
import EditIcon from '@material-ui/icons/Edit'
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CriticalButton from "../../../../../../shared/CriticalButton";

export default function RelationshipTable(props) {

    const deleteDialog = {
        content: "Are you sure you want to delete this observation?"
    }

    return (
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Observation</TableCell>
                        <TableCell align="left">Class</TableCell>
                        <TableCell align="left">Correlation</TableCell>
                        <TableCell align="left">Weight</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.observations.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="left">{row.observationClass}</TableCell>
                            <TableCell align="left">{row.correlation}</TableCell>
                            <TableCell align="left">{row.weight}</TableCell>
                            <TableCell align="right">

                                    <Button sx={{ mr: 2 }} startIcon={<EditIcon/>} color={"primary"} variant={"outlined"}
                                            onClick={() => props.edit(row.id)}>
                                        Edit
                                    </Button>
                            </TableCell>
                            <TableCell align="left">
                                    <CriticalButton startIcon={<DeleteIcon/>}
                                                    color={"secondary"} variant={"outlined"} dialog={deleteDialog}
                                                    onConfirmed={() => props.delete(row.id)}>Delete</CriticalButton>

                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
