import * as rxjs from 'rxjs';
import {pipelinesApi} from "../../../../utils/services/pipelines.api";
import {notificationService} from "../../../../utils/notification";
import {sysadminApi} from "../../../../utils/services/sysadmin.api";


export class Bloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            initialising: true,
            busy: false,
            stats: {},
        });

        this.events = new rxjs.Subject();
    }

    initialise = () => {

        sysadminApi.stats()
            .then(value => {
                this.__updateSubject({ stats: value.data });
            }, reason => notificationService.httpError(reason)).finally(() => {
            this.__updateSubject({
                initialising: false,
            });
        });
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
           ...this.subject.value,
           ...newProps,
        });
    }
}

export class RedisBlocEvent {

}
