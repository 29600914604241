export class TenantUtil {

    tenantId() {

        return process.env.REACT_APP_TENANT_ID || 'vituity-dvmfb';
    }

    tenant() {

        return this.tenantId().substring(0, this.tenantId().indexOf('-'));
    }
}

export const tenantUtil = new TenantUtil();
