import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import CloseIcon from '@material-ui/icons/Close';

import {Typography, Button, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";


import { FormattedMessage } from "react-intl";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MessageAnnotationContext from "./context";
import {notificationService} from "../../../../../utils/notification";
import {Headers, publishHitlEvent, REPHRASE} from "../../../../../utils/events";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        justifyContent: "space-start"
    },
    backButton: {

    },
    instruction: {
        marginTop: "0.4em",
        color: "#55CCFF",
    },
    content: {

    },
    message: {
        paddingTop: "50px",
    },
    control: {

        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "50%",
    },
    controlButtonGroupLeft: {
        width: "100%",
        paddingRight: "30px",
        height: "80px",
    },
    controlButtonGroupRight: {
        width: "100%",
        paddingLeft: "30px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "25%",
    },
    controlButtonCenter: {
        color: `#fff !important`,
        backgroundColor: `${theme.palette.primary.dark} !important`,
        width: "50%",
    },
    controlButtonRight: {
        width: "25%",
        countAvatar: {
            width: "2000px",
        }
    },
    footer: {
        marginTop: "25px",
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },
    footerRightButton: {
        fontSize: "40px",
        color: `${theme.palette.primary.dark} !important`,
    },
    countAvatarLeft: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "-15px",
        zIndex: "1",
    },
    countAvatarRight: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "15px",
        zIndex: "1",
    },
    lastQuestion: {
        marginTop: "30px",
        fontSize: "40px",
        color: "#55CCFF",
    },
    lastAnswer: {
        marginTop: "30px",
        fontSize: "30px",
    }
});

class Rephrase extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }


    handleException = () => {

        this.props.sendException();
    };

    handleRephraseChange = (event) => {

        const _id = event.target.value;

        const { event : { data : { slotUtteranceSpecification } } } = this.props.context;

        const option = slotUtteranceSpecification.options.filter((_option) => _option.id == _id);

        this.setState({
            option: option.length > 0 ? option[0] : undefined,
        });
    };

    handleSubmission = () => {

        let { event } = this.props.context;

        let { option } = this.state;

        if(option === undefined) {

            notificationService.error("Please select a question to continue.");
        } else {

            this.props.submit(option);

            const headers = {};
            headers[Headers.CORRELATION_ID] = event[Headers.CORRELATION_ID];
            headers[Headers.CONVERSATION_ID] = event[Headers.CONVERSATION_ID];
            headers[Headers.MESSAGE_ID] = event[Headers.MESSAGE_ID];

            publishHitlEvent(headers,
                { "event": "answered.quinn", "action": REPHRASE });
        }
    };

    render() {

        let { classes, busy, goBack } = this.props;

        let { event : { data : { slotUtteranceSpecification, lastTurn } } } = this.props.context;

        let { option } = this.state;

        const _optionId = option ? option.id : undefined;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Button disabled={busy} onClick={goBack} className={classes.backButton}>
                        <ArrowBackIcon fontSize="large" color="primary"/>
                    </Button>
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <FormattedMessage id={"rephrase.question"} defaultMessage={"Rephrase previous question."} />
                    </Typography>
                </div>
                <div className={classes.content}>
                    <Typography variant='h3' align='left' className={classes.lastAnswer}>
                        {/*{ context.metadata.lastAnswer }*/}
                    </Typography>
                    <Typography variant='h3' align='right' className={classes.lastQuestion}>
                        {/*{ context.metadata.lastQuestion }*/}
                    </Typography>
                    <Typography variant='h3' align='left' className={classes.message}>
                        { lastTurn.content }
                    </Typography>
                </div>
                <div className={classes.fill} />
                <div className={classes.control}>
                    <RadioGroup aria-label="position" name="position" value={ _optionId } onChange={this.handleRephraseChange}>
                        {
                            slotUtteranceSpecification.options.map(value => (<FormControlLabel
                                value={ value.id }
                                control={<Radio />}
                                label={(<Typography variant='h3' align='left' className={classes.rephrase} >{ value.content }</Typography>)}
                                labelPlacement="end"
                            />))
                        }
                    </RadioGroup>
                </div>
                <div className={classes.footer}>
                    <Button disabled={busy}  onClick={this.handleException}><CloseIcon fontSize="large" color="primary"/> <FormattedMessage id="exception" defaultMessage="Exception" /></Button>
                    <div className={classes.fill}></div>
                    <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large" color="primary"/></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <MessageAnnotationContext.Consumer>
        { value => {
            return (<Rephrase context={ value } { ...props } />);
            }
        }
    </MessageAnnotationContext.Consumer>
));
