import React from 'react';
import ReactDOM from 'react-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Component} from "react";


class TabPanel extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {

        const { children, value, index, ...other } = this.props;

        // eslint-disable-next-line react/react-in-jsx-scope
        return (<Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>);
    }
}

export default TabPanel;
