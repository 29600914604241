import React from 'react';

import ConversationViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';

import DecodedComponent from "../../../shared/DecodedComponent";
import {Box, CircularProgress, Typography} from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "100%",
        padding: "10px 25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fill: {
        flex: "1 1 auto",
    },
});

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display={"flex"} alignItems={"center"} padding={"16px"}>
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
            <Box position="relative" display="inline-flex" paddingLeft={"15px"}>
                <Typography variant="caption" component="div" color="primary">{`${props.text}`}</Typography>
            </Box>
        </Box>
    );
}

class LoadingGraph extends DecodedComponent {

    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;

        this.state = {
            loaded: {
                conditions: false,
                conditionRelationships: false,
                observations: false,
                ontology: false,
                conversation: false,
            },
            loading: {
                ontology: { size: 999999, current: 0 },
            },
        };
    }


    render() {

        let { classes } = this.props;
        let { loaded, loading, conditions, observations, conditionRelationships, ontology } = this.state;


        return (
            <div className={classes.root}>
                <CircularProgressWithLabel value={ loaded.conditions ? 100 : 0 } text={`Conditions (${conditions?.length || 0})`} />
                <CircularProgressWithLabel value={ loaded.observations ? 100 : 0 } text={`Observations (${observations?.length || 0})`} />
                <CircularProgressWithLabel value={ loaded.conditionRelationships ? 100 : 0 } text={`Relationships (${conditionRelationships?.length || 0})`} />
                <CircularProgressWithLabel value={ (loading.ontology.current / (loading.ontology.size - 1) * 100) } text={`Ontology (${ontology?.length || 0})`} />
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ConversationViewContext.Consumer>
        { value => {
            return (<LoadingGraph context={value} {...props} />);
        } }
    </ConversationViewContext.Consumer>
));
