import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Loading from "../../../../shared/Loading";
import AgendaAnnotation from "./AgendaAnnotation";
import EntityAnnotation from "./EntityAnnotation";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        height: "100%",
    },
});


class ConversationTurnPanel extends Component {

    constructor(props) {
        super(props);

        const { bloc } = props;

        this.bloc = bloc;

        this.state = {
            bloc: bloc,
            initialising: true,
        };

        this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
    }

    componentDidMount() {

        this.subscription = this.bloc.subscribeToState(this.__handleStateUpdate);
        this.bloc.initialise();
    }

    componentWillUnmount() {

        this.subscription.unsubscribe();
    }

    __handleStateUpdate = (subject) => {

        this.setState({
            ...subject,
        });
    }


    render() {

        let { initialising } = this.state;

        if(initialising) {
            return (<Loading />);
        }

        let { classes } = this.props;

        let slot = this.bloc.slot();

        if(slot === "intent") {

            return (
                <div className={classes.root}>
                    <AgendaAnnotation bloc={this.bloc} />
                </div>
            );
        }

        return (<div className={classes.root}>
            <EntityAnnotation bloc={this.bloc} />
        </div>);
    }
}

export default withStyles(styles)(ConversationTurnPanel);