import axios from "axios";

class PipelinesApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }


    pipelines() {
        return axios.get(`${this.baseUrl}/internal/async-jobs/pipelines`, { });
    }

    pipeline(pipeline) {
        return axios.get(`${this.baseUrl}/internal/async-jobs/pipelines/${pipeline.definition.id}`, { });
    }

    addTask(pipelineId, task) {
        return axios.post(`${this.baseUrl}/internal/async-jobs/pipelines/${pipelineId}/_add_task`, task);
    }

    popTask(pipeline, step) {
        return axios.get(`${this.baseUrl}/internal/async-jobs/pipelines/${pipeline.definition.id}/steps/${Object.keys(step)[0]}/_pop`);
    }

    retrieveTask(pipeline, step, taskId) {
        return axios.get(`${this.baseUrl}/internal/async-jobs/pipelines/${pipeline}/steps/${step}/tasks/${taskId}`);
    }

    submitTask(pipeline, step, taskResult) {
        return axios.post(`${this.baseUrl}/internal/async-jobs/pipelines/${pipeline}/steps/${step}/tasks/${taskResult.id}`, taskResult);
    }

    discardTask(pipeline, step, task, reason) {
        return axios.delete(`${this.baseUrl}/internal/async-jobs/pipelines/${pipeline}/steps/${step}/tasks/${task}`,{ params: { reason: reason } } );
    }

    queryConcept(query_string) {
        return axios.get(`${this.baseUrl}/internal/async-jobs/data/concept/_query`, { params: { q: query_string } });
    }
}

class PipelinesMock {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }


    pipelines() {

        return new Promise((resolve, reject) => {

            setTimeout(() => {

                const result = { "items": [{
                        "$schema": "https://schemas.decodedhealth.com/pipeline",
                        "version": "0.0.1",
                        "id": "1",
                        "status": "1",
                        "name": "Consumer utterance annotation pipeline",
                        "definition": {
                            "main": {
                                "vendor": "decoded",
                                "steps": [
                                    {
                                        "annotation_step": {
                                            "description": "Annotate entities in a text.",
                                            "call": "hitl_job",
                                            "args": {
                                                "job_type": "hitl.jobs.async.text.annotation",
                                                "context_menu": "id of menu",
                                                "permission": ["jobs:async:annotate"],
                                                "callback": "sentence_annotation_update"
                                            }
                                        }
                                    },
                                    {
                                        "normalisation_step": {
                                            "description": "Normalise entities identified in an utterance to UMLS and ICD-10.",
                                            "call": "hitl_job",
                                            "args": {
                                                "job_type": "hitl.jobs.async.entity.normalisation",
                                                "context_menu": "id of menu",
                                                "normalisation_space": "id of menu",
                                                "permission": ["jobs:async:annotate"],
                                                "callback": "annotation_normalisation_update"
                                            }
                                        }
                                    },
                                    {
                                        "approval_step": {
                                            "description": "Review and approve annotations and normalisations. You can also edit these if required.",
                                            "call": "hitl_job",
                                            "args": {
                                                "job_type": "hitl.jobs.async.annotation.approval",
                                                "context_menu": "id of menu",
                                                "normalisation_space": "id of menu",
                                                "permission": ["jobs:async:approve"],
                                                "callback": "annotation_normalisation_approval"
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    },
                        {
                            "$schema": "https://schemas.decodedhealth.com/pipeline",
                            "version": "0.0.1",
                            "id": "2",
                            "status": "1",
                            "name": "Consumer utterance rephrase",
                            "definition": {
                                "main": {
                                    "vendor": "decoded",
                                    "steps": [
                                        {
                                            "rephrase_step": {
                                                "description": "Annotate the all the entities you can within the medical domain. Once complete you can submit it for review.",
                                                "call": "hitl_job",
                                                "args": {
                                                    "job_type": "hitl.jobs.async.text.rephrase",
                                                    "context_menu": "id of menu",
                                                    "permission": ["jobs:async:rephrase"],
                                                    "callback": "sentence_rephrase_update"
                                                }
                                            }
                                        },
                                        {
                                            "approval_step": {
                                                "description": "Review and approve rephrases.",
                                                "call": "hitl_job",
                                                "args": {
                                                    "job_type": "hitl.jobs.async.rephrase.approval",
                                                    "context_menu": "id of menu",
                                                    "normalisation_space": "id of menu",
                                                    "permission": ["jobs:async:approve"],
                                                    "callback": "sentence_rephrase_approval"
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        }]
                };

                resolve( { "data": result });

            }, 1000);
        });
    }

    pipeline(pipeline) {
        const annotation_pipeline = {
            data: {
                "$schema": "https://schemas.decodedhealth.com/pipeline",
                "version": "0.0.1",
                "id": "1",
                "status": "1",
                "name": "Consumer utterance annotation pipeline",
                "definition": {
                    "main": {
                        "vendor": "decoded",
                        "steps": [
                            {
                                "annotation_step": {
                                    "description": "Annotate entities in a text.",
                                    "call": "hitl_job",
                                    "args": {
                                        "job_type": "hitl.jobs.async.text.annotation",
                                        "job_status": ["REGISTERED"],
                                        "context_menu": "id of menu",
                                        "permission": ["jobs:async:annotate"],
                                        "callback": "sentence_annotation_update"
                                    }
                                }
                            },
                            {
                                "normalisation_step": {
                                    "description": "Normalise entities identified in an utterance to UMLS and ICD-10.",
                                    "call": "hitl_job",
                                    "args": {
                                        "job_type": "hitl.jobs.async.text.annotation",
                                        "job_status": ["ANNOTATED"],
                                        "context_menu": "id of menu",
                                        "normalisation_space": "id of menu",
                                        "permission": ["jobs:async:annotate"],
                                        "callback": "annotation_normalisation_update"
                                    }
                                }
                            },
                            {
                                "approval_step": {
                                    "description": "Review and approve annotations and normalisations. You can also edit these if required.",
                                    "call": "hitl_job",
                                    "args": {
                                        "job_type": "hitl.jobs.async.text.annotation",
                                        "job_status": ["NORMALISED"],
                                        "context_menu": "id of menu",
                                        "normalisation_space": "id of menu",
                                        "permission": ["jobs:async:approve"],
                                        "callback": "annotation_normalisation_approval"
                                    }
                                }
                            }
                        ]
                    }
                },
                "stats": {
                    "steps": [
                        {
                            "annotation_step": {
                                "jobs": 1,
                            }
                        },
                        {
                            "normalisation_step": {
                                "jobs": 1,
                            }
                        },
                        {
                            "approval_step": {
                                "jobs": 1,
                            }
                        }
                    ]
                }
            }
        };


        const rephrase_pipeline = {
            data: {
                "$schema": "https://schemas.decodedhealth.com/pipeline",
                "version": "0.0.1",
                "id": "2",
                "status": "1",
                "name": "Consumer utterance rephrase",
                "definition": {
                    "main": {
                        "vendor": "decoded",
                        "steps": [
                            {
                                "rephrase_step": {
                                    "description": "Annotate the all the entities you can within the medical domain. Once complete you can submit it for review.",
                                    "call": "hitl_job",
                                    "args": {
                                        "job_type": "hitl.jobs.async.text.rephrase",
                                        "job_status": ["REGISTERED"],
                                        "context_menu": "id of menu",
                                        "permission": ["jobs:async:rephrase"],
                                        "callback": "sentence_rephrase_update"
                                    }
                                }
                            },
                            {
                                "approval_step": {
                                    "description": "Review and approve rephrases.",
                                    "call": "hitl_job",
                                    "args": {
                                        "job_type": "hitl.jobs.async.text.rephrase",
                                        "job_status": ["REPHRASED"],
                                        "context_menu": "id of menu",
                                        "normalisation_space": "id of menu",
                                        "permission": ["jobs:async:approve"],
                                        "callback": "sentence_rephrase_approval"
                                    }
                                }
                            }
                        ]
                    }
                },
                "stats": {
                    "steps": [
                        {
                            "rephrase_step": {
                                "jobs": 1,
                            }
                        },
                        {
                            "approval_step": {
                                "jobs": 1,
                            }
                        }
                    ]
                }
            }
        };

        return new Promise((resolve, reject) => {

            setTimeout(() => {

                if (pipeline.definition.id === "1") {
                    resolve(annotation_pipeline);
                } else if (pipeline.definition.id === "2") {
                    resolve(rephrase_pipeline);
                } else {
                    reject("Not found")
                }
            }, 1000);
        });
    }

    popTask(pipeline, step) {

        const annotation = {
            data: {
                "dataschema": "https://schema.decodedhealth.com/data/2021-06-08/async.annotation.json",
                "id": "12584588",
                "version": "0.0.1",
                "createdOn": "2021-09-02T21:40:22.287932Z",
                "jobType": "hitl.jobs.async.text.annotation",
                "jobClass": "general",
                "status": "REGISTERED",
                "data": {
                    "text": "I've had itchy eyes, runny nose, loss of smell and headache for the past 2 days. I saw my boyfriend 2 days ago so I also want to make sure I don't have COVID.",
                    "context": "Welcome. How may I help you today?"
                },
                "metadata": {
                    "conversationId": "108495ddd39f4b5993238dababd38d04",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "channel": "chat",
                    "tenant": "meememorial"
                },
                "annotations": {}
            }
        };

        const annotation_normalise = {
            data: {
                "dataschema": "https://schema.decodedhealth.com/data/2021-06-08/async.annotation.json",
                "id": "12584588",
                "version": "0.0.1",
                "createdOn": "2021-09-02T21:40:22.287932Z",
                "jobType": "hitl.jobs.async.entity.normalisation",
                "jobClass": "general",
                "status": "ANNOTATED",
                "data": {
                    "text": "I've had itchy eyes, runny nose, loss of smell and headache for the past 2 days. I saw my boyfriend 2 days ago so I also want to make sure I don't have COVID.",
                    "context": "Welcome. How may I help you today?"
                },
                "metadata": {
                    "conversationId": "108495ddd39f4b5993238dababd38d04",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "channel": "chat",
                    "tenant": "meememorial"
                },
                "annotations": {
                    "entities": [
                        {
                            "id": "0115de46-50d5-43d6-b32b-8f001e1e405a",
                            "extract": "itchy eyes",
                            "start": 9,
                            "end": 19,
                            "category": "Symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "55558f4a-9e74-4dcb-b980-6427dbd6ab3f",
                            "extract": "runny nose",
                            "start": 21,
                            "end": 31,
                            "category": "Symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "e48a2ba2-d589-4dd2-a5f0-b0e8bcb62030",
                            "extract": "loss of smell",
                            "start": 33,
                            "end": 46,
                            "category": "Onset",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "a20bb3f7-9e64-4971-b45c-e92953b1fb9e",
                            "extract": "headache",
                            "start": 51,
                            "end": 59,
                            "category": "Associated symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "f804006f-24a1-4d3f-ab32-3e28d14bb6fc",
                            "extract": "COVID",
                            "start": 152,
                            "end": 157,
                            "category": "Diagnosis",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        }
                    ]
                }
            }
        };

        const annotation_approval = {
            data: {
                "dataschema": "https://schema.decodedhealth.com/data/2021-06-08/async.annotation.json",
                "id": "12584588",
                "version": "0.0.1",
                "createdOn": "2021-09-02T21:40:22.287932Z",
                "jobType": "hitl.jobs.async.text.annotation",
                "jobClass": "general",
                "status": "NORMALISED",
                "data": {
                    "text": "I've had itchy eyes, runny nose, loss of smell and headache for the past 2 days. I saw my boyfriend 2 days ago so I also want to make sure I don't have COVID.",
                    "context": "Welcome. How may I help you today?"
                },
                "metadata": {
                    "conversationId": "108495ddd39f4b5993238dababd38d04",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "channel": "chat",
                    "tenant": "meememorial"
                },
                "annotations": {
                    "entities": [
                        {
                            "id": "0115de46-50d5-43d6-b32b-8f001e1e405a",
                            "extract": "itchy eyes",
                            "start": 9,
                            "end": 19,
                            "category": "Symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "55558f4a-9e74-4dcb-b980-6427dbd6ab3f",
                            "extract": "runny nose",
                            "start": 21,
                            "end": 31,
                            "category": "Symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "e48a2ba2-d589-4dd2-a5f0-b0e8bcb62030",
                            "extract": "loss of smell",
                            "start": 33,
                            "end": 46,
                            "category": "Symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "a20bb3f7-9e64-4971-b45c-e92953b1fb9e",
                            "extract": "headache",
                            "start": 51,
                            "end": 59,
                            "category": "Associated symptom",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        },
                        {
                            "id": "f804006f-24a1-4d3f-ab32-3e28d14bb6fc",
                            "extract": "COVID",
                            "start": 152,
                            "end": 157,
                            "category": "Diagnosis",
                            "createdOn": "1634674432532",
                            "modifiedOn": "1634674432532",
                            "state": "ANNOTATED"
                        }
                    ]
                }
            }
        };

        const rephrase = {
            data: {
                "dataschema": "https://schema.decodedhealth.com/data/2021-06-08/async.annotation.json",
                "id": "123",
                "version": "0.0.1",
                "createdOn": "2021-09-02T21:40:22.287932Z",
                "jobType": "hitl.jobs.async.text.rephrase",
                "jobClass": "general",
                "status": "REGISTERED",
                "data": {
                    "context": "Asked: What brings you here today?",
                    "text": "I feel dizzy and have a fever"
                },
                "metadata": {
                    "conversationId": "108495ddd39f4b5993238dababd38d04",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "channel": "chat",
                    "tenant": "meememorial"
                },
                "rephrases": [{
                    "id": "1234",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "text": "I am feeling hot and have vertigo",
                    "author": {
                        "system": "decoded",
                        "code": "id",
                        "value": "123"
                    }
                }]
            }
        };
        const rephrase_approval = {
            data: {
                "dataschema": "https://schema.decodedhealth.com/data/2021-06-08/async.annotation.json",
                "id": "123",
                "version": "0.0.1",
                "createdOn": "2021-09-02T21:40:22.287932Z",
                "jobType": "hitl.jobs.async.text.rephrase",
                "jobClass": "general",
                "status": "REPHRASED",
                "data": {
                    "context": "Asked: What brings you here today?",
                    "text": "I feel dizzy and have a fever"
                },
                "metadata": {
                    "conversationId": "108495ddd39f4b5993238dababd38d04",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "channel": "chat",
                    "tenant": "meememorial"
                },
                "rephrases": [{
                    "id": "1234",
                    "createdOn": "2021-08-02T21:40:22.287932Z",
                    "text": "I am feeling hot and have vertigo",
                    "author": {
                        "system": "decoded",
                        "code": "id",
                        "value": "123"
                    }
                }]
            }
        };

        return new Promise((resolve, reject) => {

            setTimeout(() => {

                if (pipeline.definition.id === "1" && Object.keys(step)[0] === "annotation_step") {
                    resolve(annotation);
                } else if (pipeline.definition.id === "1" && Object.keys(step)[0] === "normalisation_step") {
                    resolve(annotation_normalise);
                } else if (pipeline.definition.id === "1" && Object.keys(step)[0] === "approval_step") {
                    resolve(annotation_approval);
                } else if (pipeline.definition.id === "2" && Object.keys(step)[0] === "rephrase_step") {
                    resolve(rephrase);
                }  else if (pipeline.definition.id === "2" && Object.keys(step)[0] === "approval_step") {
                    resolve(rephrase_approval);
                } else {
                    reject("Not found")
                }

            }, 1000);
        });
    }

}

export const pipelinesApi = new PipelinesApi();
// export const pipelinesApi = new PipelinesMock();
