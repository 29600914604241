import * as rxjs from 'rxjs';
import {quinnApi} from "../../../../../../../../utils/services/quinn.api";
import {Headers, publishHitlEvent} from "../../../../../../../../utils/events";

export class ConversationTurnBloc {

    constructor(event, websocket) {

        this.websocket = websocket;

        this.subject = new rxjs.BehaviorSubject({
            event: event,
            entities: { added: [], removed: [] },
            intents: { added: [], removed: [] },
            contextOverrides: {},
            initialising: true,
            processing: false,
            loaded: false,
        });

        this.events = new rxjs.Subject();
    }

    initialise = (overrides) => {

        this.__loadContextMenu(overrides);
    }

    close = () => {
    }

    __loadContextMenu = (overrides) => {

        this.subject.next({
            ...this.subject.value,
            initialising: false,
            processing: false,
            contextOverrides: overrides,
        });

        // this.loadContextMenuOverride(slot);

    }

    __handleErrorUpdate = (event) => {

        this.events.next({ event: ConversationTurnBlocEvent.EXCEPTION, data: {}})
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    loadContextMenuOverride = (target, callback) => {

        let { contextOverrides } = this.subject.value;

        quinnApi.contextMenu(target, this.tenant())
            .then(value => {

                contextOverrides[target] = value.data === "" ? undefined : value.data;

                this.subject.next({
                    ...this.subject.value,
                    initialising: false,
                    processing: false,
                    contextOverrides: contextOverrides,
                });
            }).catch(reason => {

            contextOverrides[target] = undefined;

            this.subject.next({
                ...this.subject.value,
                initialising: false,
                processing: false,
                contextOverrides: contextOverrides,
            });
        }).finally(() => {
            if(callback) callback();
            this.events.next({ event: ConversationTurnBlocEvent.CONTEXT_MENU_LOADED, data: contextOverrides[target]})
        });
    }

    slot = () => {
        return this.subject.value.event.data.slotSpecification.code;
    }

    tenant = () => {
        return this.subject.value.event[Headers.TENANT_ID];
    }

    setIntent = (_intents) => {

        let { intents, event } = this.subject.value;
        intents.added = _intents;

        this.subject.next({
            ...this.subject.value,
            intents: intents,
            processing: true,
        });

        this.loadContextMenuOverride(_intents[0].category, () => {
            this.events.next({ event: ConversationTurnBlocEvent.INTENTS_UPDATED, data: {}})
        });

        const headers = {};
        headers[Headers.CORRELATION_ID] = event[Headers.CORRELATION_ID];
        headers[Headers.CONVERSATION_ID] = event[Headers.CONVERSATION_ID];
        headers[Headers.MESSAGE_ID] = event[Headers.MESSAGE_ID];

        publishHitlEvent(headers, { "event": "intents.set", "count":  _intents?.length || 0 });
    }

    setEntities = (_entities) => {

        let { entities, event } = this.subject.value;
        entities.added = _entities;

        this.subject.next({
            ...this.subject.value,
            entities: entities,
        });

        const headers = {};
        headers[Headers.CORRELATION_ID] = event[Headers.CORRELATION_ID];
        headers[Headers.CONVERSATION_ID] = event[Headers.CONVERSATION_ID];
        headers[Headers.MESSAGE_ID] = event[Headers.MESSAGE_ID];

        publishHitlEvent(headers, { "event": "entities.set", "count":  _entities?.length || 0 });
    }

    addEntity = (_entity) => {

        let { entities } = this.subject.value;
        entities.added.push(_entity)

        this.subject.next({
            ...this.subject.value,
            entities: entities,
        });
    }

    removeEntity = (_entity) => {

        let { entities } = this.subject.value;

        entities.added = entities.added.filter(e => e.id !== _entity.id);

        this.subject.next({
            ...this.subject.value,
            entities: entities
        });
    }
}

export class ConversationTurnBlocEvent {
    static CONTEXT_MENU_LOADED = "CONTEXT_MENU_LOADED";

    static INTENTS_UPDATED = "INTENTS_UPDATED";

    static MESSAGE_ANNOTATED = "MESSAGE_ANNOTATED";
    static MESSAGE_DISCARD = "MESSAGE_DISCARD";
    static QUESTION_REPHRASED = "QUESTION_REPHRASED";
    static EXCEPTION = "EXCEPTION";
    static ANNOTATION_COMPLETE = "ANNOTATION_COMPLETE";
}
