import axios from 'axios';

class AppointmentApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    schedule(request) {

        return axios.post(`${this.baseUrl}/internal/appointments/_schedule`, request);
    }

    appointmentsSummary() {

        return axios.get(`${this.baseUrl}/internal/experiment/appointments/_summary`);
    }
}

export const appointmentApi = new AppointmentApi();
