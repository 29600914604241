import * as rxjs from 'rxjs';
import {pipelinesApi} from "../../../../utils/services/pipelines.api";
import {notificationService} from "../../../../utils/notification";

export class Bloc {

    __pipelinesPoll;

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            initialising: true,
            busy: false,
            pipelines: {},
            currentTask: undefined,
        });

        this.events = new rxjs.Subject();
    }

    initialise = () => {

        Promise.all([pipelinesApi.pipelines()])
            .then((data) => {

                const pipelines = {};

                data[0].data.items.forEach((item) => { pipelines[item.id] = { definition: item, stats: {}}  } )

                this.__updateSubject({
                   initialising: false,
                   pipelines: pipelines,
                });
            }).finally(() => {
                this.refreshPipelines();
            });
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
           ...this.subject.value,
           ...newProps,
        });
    }

    startPipelinePolling = () => {
        this.refreshPipelines();
        this.__pipelinesPoll = setInterval(() => this.refreshPipelines(), 10000)
    }

    stopPipelinePolling = () => {
        clearInterval(this.__pipelinesPoll);
    }

    refreshPipelines = () => {

        const { pipelines } = this.subject.value;

        let requests = [];
        Object.keys(pipelines).forEach((_pipeline) => requests.push(pipelinesApi.pipeline((pipelines[_pipeline]))));

        Promise.all(requests)
            .then(values => {

                let { pipelines } =  this.subject.value;
                values.forEach(value => {
                    if(pipelines[value.data.id]) {
                        pipelines[value.data.id].stats = value.data.stats;
                    }
                });

                this.__updateSubject({ pipelines: pipelines });
            });
    }

    refreshPipeline = (pipeline) => {
        pipelinesApi.pipeline(pipeline)
            .then(pipeline => {

                this.__updateSubject({})
            }, reason => notificationService.error(reason))
    }

    openAddTask = (pipeline) => {

        this.events.next({ type: AsyncJobBlocEvent.NAVIGATE_TO, data: { next: `/jobs/async/pipelines/${pipeline.definition.id}/_add_task` } })
    }

    popTask = (pipeline, step) => {

        this.__updateSubject({ busy: true });

        pipelinesApi.popTask(pipeline, step)
            .then(value => {
                this.__updateSubject({ currentTask: value.data });
                this.events.next({ type: AsyncJobBlocEvent.TASK_POPPED, data: value.data });
            }).finally(() => this.__updateSubject({ busy: false }));
    }

    clearCurrentTask() {
        this.__updateSubject({ currentTask: undefined });
    }

    currentTask() {
        return this.subject.value.currentTask;
    }

    close = () => {
        if(this.__pipelinesPoll) {
            clearInterval(this.__pipelinesPoll);
        }
    }
}

export class AsyncJobBlocEvent {
    static NAVIGATE_TO = "NAVIGATE_TO";
    static TASK_POPPED = "TASK_POPPED";
}
