import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";
import {
    Paper,
    Button,
    Snackbar,
    IconButton,
    LinearProgress,
    Table, TableHead, TableRow, TableCell, TableBody
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import RefreshIcon from "@material-ui/icons/Refresh"
import {navigationTitleService} from "../../../../utils/title";
import {sessionsApi} from "../../../../utils/services/sessions.api";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paperFullscreen: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#000",
        zIndex: "100000",
    },
    paper: {
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100%)",
        maxHeight: "calc(100vh - 64px)",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        margin: "auto",
        padding: "15px 20px",
    },
    actionWrapper: {
      display: "flex",
    },
    paperContentFullscreen: {
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        paddingTop: "65px",
        backgroundColor: "#000",
        height: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        marginRight: "20px",
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});

class SessionManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: undefined,
            busy: false,
        };

        this.showSessions.bind(this);
        this._showSessions.bind(this);
        this.handleTechnicianSessionAction.bind(this);
        this.handleTechnicianSessionActionDialogOpen.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("Session Management");
    }

    showSessions = () => {

        const { busy } = this.state;
        if(busy) return;

        this._showSessions();
    };

    _showSessions = () => {

        const {userId} = this.state;
        this.setState({
            busy: true,
        });

        sessionsApi.getFullSessionSummary()
            .then(value => {

                if(value.status === 200) {

                    let data = value.data;

                    this.setState({
                        summary: data,
                        busy: false,
                        sessionUserId: undefined,
                    });
                } else {

                    this.setState({
                        summary: undefined,
                        busy: false,
                        sessionUserId: undefined,
                    });
                }
            });
    };

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    closeDialog = () => {

        this.setState({
            dialogOpen: undefined,
            busy: false,
        });
    };

    handleTechnicianSessionActionDialogOpen = (type, sessionUserId) => {
        return (event) => {

            let {busy} = this.state;

            if(busy) return;

            this.setState({
                dialogOpen: type,
                sessionUserId: sessionUserId,
            });
        }
    };

    handleTechnicianSessionAction = (action) => {

        return () => {
            var {sessionUserId} = this.state;

            var future = action === "technician_remove" ?
                sessionsApi.removeTechnicianSession(sessionUserId) :
                sessionsApi.closeTechnicianSession(sessionUserId);


            future.then((data) => {
                this.refreshSessions();
            }).catch((err) => {
                this.setState({
                    error: "Error trying to remove session.",
                    busy: false,
                    sessionUserId: undefined,
                });
            });

            this.setState({
                dialogOpen: undefined,
                busy: true,
            });
        }
    };

    refreshSessions = () => {

        setTimeout(() => {
            this._showSessions();
        }, 10000);
    };

    render() {

        let {classes} = this.props;
        let {error, busy, summary, dialogOpen} = this.state;

        let errorAlert = (<></>);

        if (error) {

            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    message={<span id="message-id">{error}</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            );
        }

        let items = summary ? summary.sessions || [] : [];

        let dialogTitle = <FormattedMessage id={'technician.session.close.title'} defaultMessage={"Close Technicians Session"} />;
        let dialogDescription = <FormattedMessage id={'technician.session.close.warning'} defaultMessage={"Are you sure you want to close the technicians session? This will take 5 seconds to propogate."} />;

        if( dialogOpen === "technician_remove" ) {
            dialogTitle = <FormattedMessage id={'technician.session.remove.title'} defaultMessage={"Remove Technicians Session"} />;
            dialogDescription = <FormattedMessage id={'technician.session.remove.warning'} defaultMessage={"Are you sure you want to remove the technicians session? This will take 5 seconds to propogate."} />;
        }

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {busy && <LinearProgress/>}
                    <div className={classes.paperContent}>
                        <div className={classes.actionWrapper}>
                            <div className={classes.fill}></div>
                            <div>
                        <Button color="primary" onClick={this.showSessions} className={classes.button}>
                            <RefreshIcon /> <FormattedMessage id={'refresh.button.label'} defaultMessage={"Refresh"} />
                        </Button>
                            </div>
                            <div className={classes.fill}></div>
                        </div>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Id</TableCell>
                                    <TableCell align="right">State</TableCell>
                                    <TableCell align="right">Source</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.technicianId}
                                        </TableCell>
                                        <TableCell align="right">{row.state}</TableCell>
                                        <TableCell align="right">{row.source}</TableCell>
                                        <TableCell align="right">
                                            { row.source.indexOf("federated") > 0 ? <>
                                                <Button color="primary" className={classes.button} onClick={this.handleTechnicianSessionActionDialogOpen("technician_close", row.technicianId)}>
                                                    <FormattedMessage id={'technician.session.end.button'} defaultMessage={"Close"} />
                                                </Button>
                                                <Button color="secondary" className={classes.button} onClick={this.handleTechnicianSessionActionDialogOpen("technician_remove", row.technicianId)}>
                                                    <FormattedMessage id={'technician.session.remove.button'} defaultMessage={"Force Remove"} />
                                                </Button>
                                                </>: <></> }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
                <Dialog
                    open={dialogOpen !== undefined}
                    keepMounted
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{dialogTitle}{"Close Technicians Session"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {dialogDescription}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            <FormattedMessage id={'cancel'} defaultMessage={"Cancel"} />
                        </Button>
                        <Button onClick={this.handleTechnicianSessionAction(dialogOpen)} color="primary">
                            <FormattedMessage id={'continue'} defaultMessage={"Continue"} />
                        </Button>
                    </DialogActions>
                </Dialog>
                {errorAlert}
            </div>
        );
    }
}

export default withStyles(styles)(SessionManagement);
