import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",

    },
    listItems: {
        minHeight: "32px",
    },
    listItem: {
        flex: 1,
    },
    select: {
        display: "flex"
    },
    option: {
        flex: 1,
        margin: "0.5%"
    }

});

class QuestionsComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;


        this.state = {
            initialising: true,
            text: "",
            questions: []

        };

        this.__remove = this.__remove.bind(this);
        this.__add = this.__add.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Observation Question Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __inputChanged = (event) => {

        this.setState({
            text: event.target.value,
        });
    }

    __add = () => {

        let { questions, text } = this.state;

        if( text === "") {
            notificationService.error("Please add a question.");
            return;
        }

        questions.push({
            id: uuid().toString(),
            text: text
        });

        this.setState({
            questions: questions,
            text: ""
        })

    }


    __remove = (_item) => () => {

        let { questions } = this.state;

        questions = questions.filter(item => item.id !== _item.id);

        this.setState({
            questions: questions,
        });
    }

    __submit = () => this.bloc.submitTask({ data: { questions: this.state.questions.map(value => {
        return {
            "text": value.text
        }
            }) } });

    __discard = (message) => this.bloc.discardTask(message)


    render() {

        const { classes } = this.props;
        const { initialising, busy, task, questions, text} = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                title={`Please add all questions related to the following observation`}
                                titleTypographyProps={{ className: classes.cardTitle }}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Observation: ${task.data.observation}`}
                            </ListSubheader>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="questionTxt">Question Input</InputLabel>
                                    <Input
                                        id="questionTxt"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={text}
                                        onChange={this.__inputChanged}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__add}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List subheader={"Questions"} dense={true}>
                                    {this.__renderItems(classes, task)}
                                    {this.__renderEdits(classes, questions)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton disabled={questions.length < 2 || busy} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderItems = (classes, task) => {

       return task.questions && task.questions.map(question => {
           return (<>
               <Divider />
               <ListItem className={classes.listItems}>
                   <ListItemText className={classes.listItem} primary={ question.text }/>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, questions) => {

       return questions.map(question => {
           return (<>
               <Divider />
               <ListItem className={classes.listItems}>
                   <ListItemText className={classes.listItem} primary={ question.text }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__remove(question)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<QuestionsComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

