import React, {Component} from 'react';
import {TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import AutocompleteField from "./AutocompleteField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    dropdownOption: {
        padding: "1%",
        width: "300px"
    },
    weightOption: {
        padding: "1%",
        paddingRight: "4%",
        width: "100px"
    },
    input: {
        display: "flex",
    }
})

class ObservationInput extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {classes} = this.props
        const {name, onObsChange, weight, defaultWeight, onWeightChange} = this.props;

        return (<div className={classes.input}>
            <div className={classes.dropdownOption}>
                <AutocompleteField id={name}
                                   label="Observation"
                                   defaultValue=""
                                   setSelectedValue={onObsChange}/>
            </div>
            <div className={classes.weightOption}>
                <TextField id={weight}
                           type="number"
                           label="Weight"
                           variant="outlined"
                           defaultValue={defaultWeight}
                           placeholder="Enter weight"
                           onChange={onWeightChange}
                />
            </div>

        </div>)
    }

}

export default withStyles(styles)(ObservationInput);