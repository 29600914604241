import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { FormattedMessage } from "react-intl";

import { LinearProgress } from "@material-ui/core";

import {navigationTitleService} from "../../../../../utils/title";
import AsyncJobContext from "../context";
import DecodedComponent from "../../../../shared/DecodedComponent";
import RephraseComponent from "./components/RephraseComponent";
import RephraseApprovalComponent from "./components/RephraseApprovalComponent";
import AnnotateComponent from "./components/AnnotateComponent";
import AnnotateNormaliseComponent from "./components/AnnotateNormaliseComponent";
import AnnotateApprovalComponent from "./components/AnnotateApprovalComponent";
import {TaskBloc, TaskBlocEvent} from "./bloc";
import {
    HITL_JOBS_ASYNC_TEXT_REPHRASE,
    HITL_JOBS_ASYNC_TEXT_ANNOTATION,
    HITL_JOBS_ASYNC_ENTITY_NORMALISE,
    HITL_JOBS_ASYNC_CONDITION_OBSERVATIONS,
    HITL_JOBS_ASYNC_CONDITION_COMPLICATIONS,
    HITL_JOBS_ASYNC_OBSERVATION_QUESTIONS,
    HITL_JOBS_ASYNC_CONDITION_RELATIONSHIPS,
    HITL_JOBS_ASYNC_CLINICAL_LOCATIONS,
    HITL_JOBS_ASYNC_CLINICAL_DIFFERENTIAL,
    HITL_JOBS_ASYNC_CONVERSATION_ANNOTATION,
    HITL_JOBS_ASYNC_CONDITION_MEDICATIONS, HITL_JOBS_ASYNC_CONDITION_SYMPTOMS
} from "./components/jobs";
import Unknown from "./components/Unknown";
import ObservationsComponent from "./components/ObservationsComponent";
import ComplicationsComponent from "./components/ComplicationsComponent";
import QuestionsComponent from "./components/QuestionsComponent";
import ComplicationsApprovalComponent from "./components/ComplicationsApprovalComponent";
import QuestionsApprovalComponent from "./components/QuestionsApprovalComponent";
import RelationshipsComponent from "./components/RelationshipsComponent";
import RelationshipsApprovalComponent from "./components/RelationshipsApprovalComponent";
import LocationComponent from "./components/LocationComponent";
import LocationReviewComponent from "./components/LocationReviewComponent";
import DifferentialComponent from "./components/DifferentialComponent";
import ConversationLabellingComponent from "./components/ConversationLabellingComponent";
import MedicationsComponent from "./components/MedicationsComponent";
import SymptomsComponent from "./components/SymptomsComponent";

// import RelationshipsComponent from "./components/RelationshipsComponent";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },

});

class Tasks extends DecodedComponent {

    constructor(props) {
        super(props);

        this.pipelineBloc = props.context.bloc;

        const taskIdentifier = {
            pipelineId: props.match.params.pipelineId,
            step: props.match.params.step,
            taskId: props.match.params.taskId,
        };

        this.bloc = new TaskBloc(taskIdentifier, this.pipelineBloc.currentTask());

        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();

        navigationTitleService.update("Asynchronous Jobs - Tasks");
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleEvent = (event) => {

        const { type, data } = event;

        switch (type) {
            case TaskBlocEvent.INCORRECT_TASK_LOADED:
            {
                this.returnToDashboard();
                this.pipelineBloc.clearCurrentTask();
                return;
            }
            case TaskBlocEvent.TASK_DISCARDED:
            case TaskBlocEvent.TASK_SUBMITTED:
            {
                this.returnToDashboard();
                this.pipelineBloc.clearCurrentTask();
                return;
            }
        }
    }

    render() {

        let { classes } = this.props;
        let { task } = this.state;

        return (
            <div className={classes.root}>
                {this.__renderTaskScreen(task)}
            </div>
        );
    }

    returnToDashboard = () => {
        this.props.history.replace("/jobs/async");
    };

    __renderTaskScreen = (task) => {

        if(task) {
            switch (task.jobType) {
                case HITL_JOBS_ASYNC_TEXT_REPHRASE: {
                    if(task.state === "PENDING_APPROVAL") {
                        return  (<RephraseApprovalComponent bloc={this.bloc}/>);
                    }
                    return (<RephraseComponent bloc={this.bloc}/>)
                }
                case HITL_JOBS_ASYNC_TEXT_ANNOTATION: {
                    if(task.state === "PENDING_APPROVAL") {
                        return  (<AnnotateApprovalComponent bloc={this.bloc}/>);
                    }
                    return (<AnnotateComponent bloc={this.bloc}/>)
                }
                case HITL_JOBS_ASYNC_ENTITY_NORMALISE: {
                    return (<AnnotateNormaliseComponent bloc={this.bloc}/>);
                }
                case HITL_JOBS_ASYNC_CONDITION_OBSERVATIONS: {
                    if(task.state === "OBSERVATIONS_ADDED") {
                        return (<RelationshipsComponent bloc={this.bloc}/>);
                    } else if(task.state === "PENDING_INTERNAL_APPROVAL") {
                        return (<RelationshipsApprovalComponent bloc={this.bloc}/>);
                    } else if(task.state === "PENDING_EXTERNAL_APPROVAL") {
                        return (<RelationshipsApprovalComponent bloc={this.bloc}/>);
                    }
                    return (<ObservationsComponent bloc={this.bloc}/>);
                }

                case HITL_JOBS_ASYNC_CONDITION_COMPLICATIONS: {
                    if(task.state === "PENDING_INTERNAL_APPROVAL") {
                        return  (<ComplicationsApprovalComponent bloc={this.bloc}/>);
                    } else if(task.state === "PENDING_EXTERNAL_APPROVAL") {
                        return  (<ComplicationsApprovalComponent bloc={this.bloc}/>);
                    }
                    return (<ComplicationsComponent bloc={this.bloc}/>);
                }
                case HITL_JOBS_ASYNC_OBSERVATION_QUESTIONS: {
                    if(task.state === "PENDING_INTERNAL_APPROVAL") {
                        return  (<QuestionsApprovalComponent bloc={this.bloc}/>);
                    } else if (task.state === "PENDING_EXTERNAL_APPROVAL") {
                        return  (<QuestionsApprovalComponent bloc={this.bloc}/>);
                    }
                    return (<QuestionsComponent bloc={this.bloc}/>);
                }
                case HITL_JOBS_ASYNC_CLINICAL_LOCATIONS: {
                    if(task.state === "PENDING_INTERNAL_REVIEW") {
                        return (<LocationReviewComponent bloc={this.bloc}/>);
                    } else if(task.state === "PENDING_EXTERNAL_REVIEW") {
                        return (<LocationReviewComponent bloc={this.bloc}/>);
                    }
                    return (<LocationComponent bloc={this.bloc}/>);
                }

                case HITL_JOBS_ASYNC_CLINICAL_DIFFERENTIAL: {
                    return (<DifferentialComponent bloc={this.bloc}/>);
                }

                case HITL_JOBS_ASYNC_CONVERSATION_ANNOTATION: {
                    return (<ConversationLabellingComponent bloc={this.bloc}/>);
                }

                case HITL_JOBS_ASYNC_CONDITION_MEDICATIONS: {
                    return (<MedicationsComponent bloc={this.bloc}/>);
                }

                case HITL_JOBS_ASYNC_CONDITION_SYMPTOMS: {
                    return (<SymptomsComponent bloc={this.bloc}/>);
                }

                default: {
                    return (<Unknown task={task} />);
                }
            }
        } else {
            return (<LinearProgress/>);
        }
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<Tasks context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

