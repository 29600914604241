import React from 'react';
import { withRouter } from 'react-router-dom'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Link} from "@material-ui/core";
import DecodedDrawer from '../shared/Drawer';
import Navigation from '../shared/Navigation';

import { authService } from '../../utils/auth';
import {PrivateRoute} from "../../utils/protected.route";
import Jobs from './Jobs';
import Admin from './Admin';
import Conversations from './Conversations';
import Telehealth from "./Admin/Telehealth";
import Scheduling from "./Admin/Scheduling";
import Communication from "./Communication";
import Test from "./Test";
import LocalNotifications from "../shared/Notifications";
import Dashboard from "./Admin/Dashboard";
import Inspect from "./Inspect";

export const drawerWidth = 256;

const styles = theme => ({
    root: {
        position: "absolute",
        top: "0",
        bottom: "0",
        display: 'flex',
        width: "100%",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerClosed: {
        width: 0,
        flexShrink: 1,
    },
    drawerContent:{
        width: "100%",
    },
    appContent: {
        maxHeight: "calc(100vh)",
        overflowY: "hidden",
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});

class Secure extends React.Component {

    state = {
        open: false,
        user: {},
        displayDialog: false
    };

    componentWillMount() {
        let user = authService.getUser();
        if(user) {
            authService.getRoles()
                .then((roles) => {
                    this.setState({
                       roles: roles,
                    });
                })
        }
        this.setState(
            {
                user: user,
            }
        );
    }

    doLogout = () => {

        authService.logout();
    };

    handleDrawerToggle = () => {

        this.setState(state => ({ open: !state.open }));
    };

    handleDialogClose = () => {
        this.setState({ displayDialog: false });
    }

    render() {

        const { classes } = this.props;

        const { user, roles, open, displayDialog } = this.state;

        const drawerStyle = open ? classes.drawer : classes.drawerClosed;

        let routes = (<></>);

        if(roles && roles.length > 0) {

            routes = (<>
                {
                    roles.includes("annotator") || roles.includes("medical") ? (<PrivateRoute path="/jobs" component={Jobs} />) : (<></>)
                }
                {
                    (roles.includes("admin") || roles.includes("sysadmin")) ? (<PrivateRoute path="/admin" component={Admin} />) : (<></>)
                }
                {
                    roles.includes("medical") ? (
                        <>
                        <PrivateRoute path="/" exact component={ Dashboard } />
                        <PrivateRoute path="/conversations" exact component={ Conversations } />
                        <PrivateRoute path="/test" component={ Test } />
                        <PrivateRoute path="/communication" component={ Communication } />
                        <PrivateRoute path="/scheduling" exact component={ Scheduling } />
                        <PrivateRoute path="/deep-link-generator" exact component={ Telehealth } />
                        <PrivateRoute path="/knowledge/inspect/graph" exact component={ Inspect } />
                        </>
                        ) : (<></>)
                }
            </>);
        }


        return (
            <div className={classes.root}>
                <nav className={drawerStyle}>
                    <DecodedDrawer
                        roles={roles}
                        open={this.state.open}
                        onClose={this.handleDrawerToggle}
                    />
                </nav>
                <div className={classNames(classes.appContent, {
                    [classes.contentShift]: open,
                })}>
                    <Navigation user={user} onDrawerToggle={this.handleDrawerToggle} />
                    { routes }
                </div>

                <Dialog
                    open={displayDialog}
                    onClose={this.handleDialogClose}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Disclaimer</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By using the app, you agree to our <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.decodedhealth.com/"
                        >
                            Terms of Use
                        </Link>.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <LocalNotifications />
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(Secure));
