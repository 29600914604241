
export const HITL_JOBS_ASYNC_TEXT_REPHRASE = "hitl.jobs.async.text.rephrase";
export const HITL_JOBS_ASYNC_TEXT_ANNOTATION = "hitl.jobs.async.text.annotation";
export const HITL_JOBS_ASYNC_ENTITY_NORMALISE = "hitl.jobs.async.entity.normalisation";
export const HITL_JOBS_ASYNC_CONDITION_OBSERVATIONS = "hitl.jobs.async.condition.observations";
export const HITL_JOBS_ASYNC_CONDITION_RELATIONSHIPS = "hitl.jobs.async.condition.relationships";
export const HITL_JOBS_ASYNC_CONDITION_COMPLICATIONS = "hitl.jobs.async.condition.complications";
export const HITL_JOBS_ASYNC_OBSERVATION_QUESTIONS = "hitl.jobs.async.observation.questions";
export const HITL_JOBS_ASYNC_CLINICAL_LOCATIONS = "hitl.jobs.async.clinical.location";
export const HITL_JOBS_ASYNC_CLINICAL_DIFFERENTIAL = "hitl.jobs.async.clinical.differential";
export const HITL_JOBS_ASYNC_CONVERSATION_ANNOTATION = "hitl.jobs.async.conversation.annotation";
export const HITL_JOBS_ASYNC_CONDITION_MEDICATIONS = "hitl.jobs.async.condition.medications";
export const HITL_JOBS_ASYNC_CONDITION_SYMPTOMS = "hitl.jobs.async.condition.symptoms";
