import * as rxjs from "rxjs";
import {uuid} from "uuidv4";
import {dataPreparationApi} from "../../../../../utils/services/datapreparation.api";
import {notificationService} from "../../../../../utils/notification";
import {DataPreparationBlocEvent, MetadataState} from "../dataPreparation.bloc";
import {nlpApi} from "../../../../../utils/services/nlp.api";


export class AnnotationStatus {
    static AUTOMATED_ENTITY = "automatedEntity";
    static MANUAL_ENTITY = "manualEntity";
    static ANNOTATED = "annotatedEntity"
}

export class AnnotationBloc {

    dataPreparationBloc;

    constructor(dataPreparationBloc) {
        this.dataPreparationBloc = dataPreparationBloc;

        this.subject = new rxjs.BehaviorSubject({
            entities: [],
            relationships: [],
        });

        this.events = new rxjs.Subject();
        this.system = "decoded-portal"
    }


    start = (jobType) => {

        // load annotations if they exist
        // todo fix job types and move to data preparation bloc
        if (jobType === "Annotation DRAFT" &&
            "annotationsDraft" in this.dataPreparationBloc.subject.value.job) {

            let annotationsDraft = this.dataPreparationBloc.subject.value.job.annotationsDraft

            if (annotationsDraft.provenance.system === this.system) {
                this.subject.next({
                    ...this.subject.value,
                    ...annotationsDraft,
                })
            }
        }
        this.events.next({event: AnnotationBlocEvent.ENTITIES_CHANGED})
    }

    close = () => {
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    getAutomatedEntities = (text) => {

        let data = {
            "text": text
        }

        nlpApi.getEntities(data, "true")
            .then(res => {
                let automatedEntities = res.data.entities
                let subset = []

                automatedEntities.forEach(item => {

                    let entityData =  {
                        id: uuid(),
                        start: item.start,
                        end: item.end,
                        extract: item.original
                    }

                    let bestMatchConcept = item.concepts.filter(c => {return c.bestMatch})

                    if (bestMatchConcept.length > 0) {
                        entityData =  {
                            ...entityData,
                            conceptName: bestMatchConcept[0].coding.display,
                            CUI: bestMatchConcept[0].coding.code,
                            TUI: item.semanticTypes,
                        }
                    }

                    subset.push({
                        data: {
                           ...entityData
                        },
                        state: AnnotationStatus.AUTOMATED_ENTITY,
                    })

                })

                let newEntities = this.subject.value.entities.concat(subset)

                this.subject.next({
                    ...this.subject.value,
                    entities: newEntities
                })
            })
            .then(() => {
                this.events.next({event: AnnotationBlocEvent.ENTITIES_CHANGED})
            })
            .catch(reason => {
                this.events.next({event: AnnotationBlocEvent.ERROR})
                notificationService.error("Error applying automated entity recognition.");
            });
    }

    deleteAutomatedEntities = () => {
        let entities = this.subject.value.entities

        let newEntities = entities.filter(function (item) {
            return item.state !== AnnotationStatus.AUTOMATED_ENTITY
        });

        this.subject.next({
            ...this.subject.value,
            entities: newEntities
        })

        this.events.next({event: AnnotationBlocEvent.ENTITIES_CHANGED})
    }

    createEntity = (entity) => {
        let entities = this.subject.value.entities

        entities.push(entity)

        this.subject.next({
            ...this.subject.value,
            entities: entities
        })

        this.events.next({event: AnnotationBlocEvent.ENTITIES_CHANGED})
    }

    saveEntity = (entity) => {
        let entities = this.subject.value.entities

        let oldEntity = entities.find(x => x.data.id === entity.data.id)

        Object.assign(oldEntity, entity)

        if (entity.state === AnnotationStatus.ANNOTATED) {
            this.events.next({event: AnnotationBlocEvent.ENTITY_ANNOTATED, data: {"entity": entity}})
        }
    }

    deleteEntity = (entity) => {
        let entities = this.subject.value.entities

        let newEntities = entities.filter(function (item) {
            return item.data.id !== entity.data.id;
        });

        this.subject.next({
            ...this.subject.value,
            entities: newEntities
        })

        this.events.next({event: AnnotationBlocEvent.ENTITIES_CHANGED})
    }

    resetAnnotations = () => {
        this.subject.next({
            ...this.subject.value,
            entities: [],
            relationships: []
        })

        this.events.next({event: AnnotationBlocEvent.ENTITIES_RESET})
    }

    saveAsDraft = () => {
        let { entities, relationships } = this.subject.value

        let annotations = {
            entities: entities,
            relationships: relationships,
            provenance: {
                source: window.location.href,
                createdOn: new Date().toISOString(),
                system: this.system
            }
        }

        let job = this.dataPreparationBloc.subject.value.job

        // overwrite draft
        job.annotationsDraft = annotations

        job.status = MetadataState.ANNOTATED_DRAFT

        dataPreparationApi.submitMetadata(job).then(() => {

            notificationService.success('Successfully saved document as DRAFT')

            this.dataPreparationBloc.finishJob()
        })

    }

    submitEntities = () => {

        let { entities, relationships } = this.subject.value

        let data = []

        entities.forEach(item => [
            data.push(item.data)
        ])

        let annotations = {
            entities: data,
            relationships: relationships,
            provenance: {
                source: window.location.href,
                createdOn: new Date().toISOString(),
                system: this.system
            }
        }

        let job = this.dataPreparationBloc.subject.value.job
        job.annotations = annotations

        // remove draft annotations if they exist
        if ("annotationsDraft" in job) {
            delete job.annotationsDraft
        }

        job.status = MetadataState.ANNOTATED_FOR_REVIEW

        dataPreparationApi.submitMetadata(job).then(() => {

            notificationService.success('Successfully submitted document!')

            this.dataPreparationBloc.finishJob()
        })
    }

    handleWeightChangeCommitted = ({ id, field, props })  => {
        let relationships = this.subject.value.relationships
        const editedRelationships = relationships.map(obj =>
            obj.id === id ? { ...obj, weight: props.value } : obj
        );

        this.subject.next({
            ...this.subject.value,
            relationships: editedRelationships
        })
    }

    deleteRelationship = (id) => {

        let relationships = this.subject.value.relationships

        const editedRelationships = relationships.filter(obj => {
            return obj.id !== id;
        });

        this.subject.next({
            ...this.subject.value,
            relationships: editedRelationships
        })
    }

}

export class AnnotationBlocEvent {
    static ENTITIES_CHANGED = "ENTITIES_CHANGED";
    static ENTITIES_RESET = "ENTITIES_RESET";
    static ENTITY_ANNOTATED = "ENTITY_ANNOTATED";
    static ERROR = "ERROR";
}