import React, {Component} from 'react';

import ClinicalSummaryJobContext from "./context";
import ConversationPanel from './ConversationPanel';
import SummaryPanel from "./SummaryPanel";

import {withStyles} from '@material-ui/core/styles';
import {
    Tabs,
    Tab,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    ListItemSecondaryAction,
    Button,
    Paper,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField, LinearProgress, Grid, Drawer,
} from "@material-ui/core";
import TabPanel from "../../../shared/Tabs/TabPanel";


import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        background: "transparent linear-gradient(133deg, #FDFDFD 0%, #F5F5F5 100%) 0% 0% no-repeat padding-box",
    },
    wrapper: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
    },
    centralPanel: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        width: "100%",
    },
    grid: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    gridLeft: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%"
    },
    gridMiddle: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%"
    },
    gridRight: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        overflow: "auto",
    },
    summaryPanelHolder: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        overflow: "auto",
    },
    editorPanelHolder: {
        minHeight: "30%",
        height: "30%",
        maxHeight: "30%",
        padding: "18px 20px",
    },
    conversationPanelHolder: {
        minHeight: "60%",
        height: "60%",
        maxHeight: "60%",
    },
    additionalInformationPanelHolder: {
        minHeight: "40%",
        height: "40%",
        maxHeight: "40%",
    },
    drawer: {
        height: "100vh",
        overflow: "hidden",
    },
    tabs: {
        minWidth: "60px",
    },
    tab: {
        padding: "20px",
        minWidth: "50px",
        '&[aria-selected=true]' : {
            opacity: "1",
        },
        '&[aria-selected=false]' : {
            opacity: "0.56",
        },
    },
});

class JobPanel extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            tab: 0,
        };
    }

    onTabChange = (event, newValue) => {

        this.setState({
            tab: newValue,
        });
    };

    render() {

        let { classes } = this.props;
        let { busy } = this.props.context;
        let { tab } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.centralPanel}>
                        <Grid container className={classes.grid}>
                            <Grid item xs={9} className={classes.gridLeft}>
                                <div className={classes.summaryPanelHolder}>
                                    <SummaryPanel
                                        updateSection={this.props.updateSection}
                                        onSaveSummary={this.props.onSaveSummary} />
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.gridRight}>
                                <Tabs value={tab} onChange={this.onTabChange} TabIndicatorProps={{
                                    style: {
                                        height:"0px",
                                    }
                                }} aria-label="information tabs" className={classes.tabs}>
                                    <Tab icon={<ForumOutlinedIcon fontSize={"default"}/>} className={classes.tab} />
                                </Tabs>
                                <TabPanel value={tab} index={0}>
                                    <div className={classes.conversationPanelHolder}>
                                        <ConversationPanel />
                                    </div>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ClinicalSummaryJobContext.Consumer>
        {value => {
            return (<JobPanel context={value} {...props} />);
        }
        }
    </ClinicalSummaryJobContext.Consumer>
));