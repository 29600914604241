import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {
    TextField,
    Paper,
    Snackbar,
    IconButton,
    LinearProgress, Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import {navigationTitleService} from "../../../../utils/title";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paper: {
        overflowY: "auto",
        backgroundColor: "#000",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        margin: "auto",
        paddingTop: "15px",
    },
    paperContentFullscreen: {
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        padding: '2px 4px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    linkDisplay: {
        width: "100%",
        marginTop: "50px",
        padding: "40px",
        maxWidth: "100%",
        wordBreak: "break-all",
    },
});

class Telehealth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deeplink: {},
            busy: false,
        };
    }

    componentWillMount() {
        navigationTitleService.update("Telehealth");
    }

    handleInputChange = (name) => (event) => {

        let {deeplink} = this.state;
        deeplink[name] = event.target.value;

        this.setState({
            deeplink: deeplink,
        });
    };

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    render() {

        let {classes} = this.props;
        let {error, busy, deeplink} = this.state;

        let errorAlert = (<></>);

        if (error) {

            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    message={<span id="message-id">{error}</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            );
        }


        return (
            <div className={classes.root}>

                <Paper className={classes.paper}>
                    {busy && <LinearProgress/>}
                    <div className={classes.paperContent}>
                        <div className={classes.container}>
                            <TextField
                                label="Meeting number"
                                id="deeplink.meeting.number"
                                className={classes.textField}
                                value={deeplink.number}
                                onChange={this.handleInputChange('number')}
                            />
                            <TextField
                                id="deeplink.meeting.password"
                                className={classes.textField}
                                label="Meeting password"
                                value={deeplink.password}
                                onChange={this.handleInputChange('password')}
                            />
                            <TextField
                                id="deeplink.meeting.username"
                                className={classes.textField}
                                label="Username"
                                value={deeplink.username}

                                onChange={this.handleInputChange('username')}
                            />
                            <TextField
                                id="deeplink.meeting.title"
                                className={classes.textField}
                                label="Title"
                                value={deeplink.title}
                                onChange={this.handleInputChange('title')}
                            />
                        </div>
                        <div className={classes.linkDisplay}>
                        <Typography variant="h3" component="h4">
                            https://qvmd.page.link/?link=https://qvmd.page.link/telehealth/{deeplink.number}/{deeplink.password}/{encodeURIComponent(deeplink.username)}/{encodeURIComponent(deeplink.title)}&apn=com.decodedhealth&isi=1482851446&ibi=com.qvmd.app
                        </Typography>
                        </div>
                    </div>

                </Paper>
                {errorAlert}
            </div>
        );
    }
}

export default withStyles(styles)(Telehealth);
