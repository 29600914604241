import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from "@material-ui/core/styles";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
const styles = theme => ({});

class AutocompleteField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            options: [],
            loading: false,
        }
    }

    setOpen = (_state) => {
        this.setState({
            open: _state,
        })
    }

    handleInputChange = (_inputValue, getOptions) => {

        this.setState({
            inputValue: _inputValue,
        })

        if (_inputValue.length < 3) {
            this.setState({
                options: [],
                loading: false
            })
            return
        }

        this.setState({
            loading: true,
        })

        getOptions(_inputValue).then(res => {
            this.setState({
                options: res,
                loading: false,
            })
        })

    }

    handleValueChange = (_newValue, setSelectedValue) => {

        setSelectedValue(_newValue)
    }

    render() {
        let {label, id, defaultValue, getOptions, setSelectedValue, setDisabled, key} = this.props
        let {open, inputValue, loading, options} = this.state

        return (
            <Autocomplete
                id={id}
                key={key}
                disabled={setDisabled}
                variant="outlined"
                open={open}
                onOpen={() => {
                    this.setOpen(true);
                }}
                onClose={() => {
                    this.setOpen(false);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    this.handleInputChange(newInputValue, getOptions)
                }}
                defaultValue={{ name : defaultValue}}
                onChange={(event, newValue) => {
                    this.handleValueChange(newValue, setSelectedValue);
                }}
                getOptionSelected={(option, value) => options.some(option => option.name === value.name) }
                getOptionLabel={(option) => option.name }
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                renderOption={(option, {inputValue}) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                        {part.text}
                                    </span>
                            ))}
                        </div>
                    );
                }}
            />
        );
    }
}


export default withStyles(styles)(AutocompleteField);
