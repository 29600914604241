import React, {Component} from 'react';

import DocumentPreparationJobContext from "../context";
import TextEditorPanel from './TextEditorPanel';

import {withStyles} from '@material-ui/core/styles';
import {Grid, LinearProgress} from "@material-ui/core";
import DocumentPanel from "./DocumentPanel";
import {navigationTitleService} from "../../../../../utils/title";
import {JobTypesEnum} from "../dataPreparation.bloc";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        backgroundColor: "#fff",
    },
    centralPanel: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        backgroundColor: "#303030",
        display: "flex",
        overflow: "auto",
    }
});

class PreparationJobPanel extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            busy: false
        };
    }

    componentWillMount() {
        const documentReference = this.props.context.job.references.find(x => x.code.code === 'sourceDocument');
        const documentName = documentReference.reference.substring(documentReference.reference.lastIndexOf('/')+1)
        navigationTitleService.update(`Data ${this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? "Preparation Review" : "Preparation Job"}: ${documentName}`);
    }

    setBusy = (flag) => {
        this.setState({
            busy: flag
        })
    }

    render() {

        let { classes } = this.props;
        let { busy } = this.state
        return (
            <div className={classes.root}>
                    <div className={classes.centralPanel}>
                        { busy && <LinearProgress/> }
                        <Grid container>
                            <Grid item xs={6}>
                                    <DocumentPanel/>
                            </Grid>
                            <Grid item xs={6}>
                                    <TextEditorPanel busy={busy} setBusy={this.setBusy}/>
                            </Grid>
                        </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <DocumentPreparationJobContext.Consumer>
        {value => {
            return (<PreparationJobPanel context={value} {...props} />);
        }
        }
    </DocumentPreparationJobContext.Consumer>
));
