import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {
    TextField,
    Paper,
    Snackbar,
    IconButton,
    LinearProgress, Typography, List, ListSubheader, ListItem, ListItemText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import {navigationTitleService} from "../../../../utils/title";
import DecodedComponent from "../../../shared/DecodedComponent";
import {Bloc} from "./bloc";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paper: {
        overflowY: "auto",
        backgroundColor: "#000",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        margin: "auto",
        paddingTop: "15px",
    },
    paperContentFullscreen: {
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        padding: '2px 4px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    linkDisplay: {
        width: "100%",
        marginTop: "50px",
        padding: "40px",
        maxWidth: "100%",
        wordBreak: "break-all",
    },
});

class RedisDashboard extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = new Bloc();

        this.state = {
            initialising: true,
            busy: false,
        };
    }

    componentDidMount() {
        super.componentDidMount()
        this.bloc.initialise();
        navigationTitleService.update("Redis Dashboard");
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {

        let {classes} = this.props;
        let { initialising, busy, stats } = this.state;

        return (
            <div className={classes.root}>

                <Paper className={classes.paper}>
                    { (busy || initialising) && <LinearProgress/> }
                    <div className={classes.paperContent}>
                        <List subheader={<ListSubheader>Key - Memory(bytes) ( Keys: { (stats?.keys && Object.keys(stats.keys).length) || "-" } )</ListSubheader>} className={classes.root}>
                        { (!initialising && stats?.keys) && Object.keys(stats.keys).map(_key => <>
                            <ListItem>
                                <ListItemText id={_key} primary={`${_key} - ${stats.keys[_key]}`} />
                            </ListItem>
                        </>) }
                        </List>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(RedisDashboard);
