import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Chip, CircularProgress, Input, List, ListItem, Popper} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {utilitiesApi} from "../../../../../utils/services/utilities.api";


const styles = theme => ({
    root: {
        display: "block",
    },
    chipWrapper: {
        display: "block",
    },
    chip: {
        paddingLeft: "14px",
        paddingRight: "14px",
        height: "40px",
    },
    entity: {
        fontSize: "21px",
        color: "#32637C",
        fontWeight: "600",
        margin: "5px 0px",
    },
    entityInput: {
        backgroundColor: "transparent",
        fontSize: "21px",
        color: "#32637C",
        fontWeight: "600",
        margin: "5px 0px",
        border: "0",
    },
    entityLabel: {
        position: "relative",
        fontSize: "10px",
        color: "#32637C",
        top: "-50px",
        marginLeft: "20px",
        backgroundColor: "#fff"
    },
    typeaheadPopper: {
        color: "#3A4559",
        fontSize: "26px",
        backgroundColor: "#fff",
        align: "left",
        maxHeight: "40vh",
        minWidth: "100px",
        overflow: "auto",
        boxShadow: "0px 0px 20px #00000010",
        opacity: "1",
        backdropFilter: "blur(10px)",
        paddingTop: "8px",
        paddingLeft: "8px",
    },
});

class EditableEntity extends Component {

    //TODO: Add click away document click listener.
    constructor(props) {
        super(props);

        const entity = {
            id: "0",
            value: "",
            system: "",
            code: "",
            ...props.entity,
        };

        this.state = {
            entity: entity,
            original: entity,
            typeahead: {
                id: undefined,
                open: false,
                anchor: undefined,
                loading: false,
                options: [],
            },
            editing: false,
        };
    }

    componentDidMount() {
        document.addEventListener("keyup", this.keyPressed);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyPressed);
    }

    onChange = (event) => {

        let { entity, typeahead } = this.state;

        entity.value = event.target.value;
        typeahead.loading = event.target.value.length > 3;

        this.setState({
            entity: entity,
            typeahead: typeahead,
        });

        if (event.target.value.length > 3) {

            this.getTypeahead(entity.value);
        }
    };

    handleEnterPressed = (event) => {

        if (event.charCode === 13) {

            let { entity, editing } = this.state;

            if(editing) {
                this.setState({
                    original: entity,
                    editing: false,
                    typeahead: {
                        id: undefined,
                        open: false,
                        anchor: undefined,
                        loading: false,
                        options: [],
                    }
                });

                this.props.updateChiefComplaint(this.props.complaintId, entity);
            }
        }
    };


    onDoubleClickTitle = (event) => {

        let { original, editing } = this.state;

        if (!editing) {

            this.setState({
                entity: {
                    ...original,
                },
                editing: true,
                typeahead: {
                    id: "editing-" + original.id,
                    open: true,
                    anchor: event.currentTarget,
                    loading: true,
                    options: [],
                }
            });

            this.getTypeahead(original.value);
        }
    };


    onEditCancel = (event) => {

        let { original } = this.state;

        this.setState({
            entity: original,
            editing: false,
            typeahead: {
                id: undefined,
                open: false,
                anchor: undefined,
                loading: false,
                options: [],
            }
        });
    };


    handleTypeaheadClose = (event) => {

        this.setState({
            typeahead: {
                id: undefined,
                open: false,
                anchor: undefined,
                loading: false,
                options: [],
            },
        });
    };

    handleTypeaheadSelected = (option) => {

        const $this = this;

        return (event) => {

            let { original } = $this.state;

            original.value = option.pt.term;
            original.code = {
                system: "snomed",
                code: option.conceptId,
                value: option.fsn.term
            };

            $this.setState({
                original: original,
                entity: original,
                editing: false,
                typeahead: {
                    id: undefined,
                    open: false,
                    anchor: undefined,
                    loading: false,
                    options: [],
                },
            });

            this.props.updateChiefComplaint(this.props.complaintId, original);
        };
    };


    keyPressed = (event) => {

        if (event.key === 'Escape') {

            let { original } = this.state;

            this.setState({
                entity: original,
                editing: false,
                typeahead: {
                    id: undefined,
                    open: false,
                    anchor: undefined,
                    loading: false,
                    options: [],
                }
            });

            this.props.onClickEntity && this.props.onClickEntity(undefined);
        }
    };

    getTypeahead = (value) => {
        utilitiesApi.typeahead(value)
            .then(value => {

                let {typeahead} = this.state;

                typeahead.loading = false;
                typeahead.options = value.data.items;

                this.setState({
                    typeahead: typeahead,
                });
            }).catch(reason => console.log("Unable to load typeahead"));
    };

    onClick = (event) => {
        let { original } = this.state;
        this.props.onClickEntity && this.props.onClickEntity(original.id)
    };

    onBlur = (event) => {
        let { original } = this.state;
        this.props.onClickEntity && this.props.onClickEntity(undefined);
    };

    onFocus = (event) => {
        let { original } = this.state;
        this.props.onClickEntity && this.props.onClickEntity(original.id);
    };

    render() {

        let {classes, color} = this.props;
        let {
            typeahead,
            entity,
            editing,
            original,
        } = this.state;

        return (
            <span className={classes.root} contentEditable={false}>
                <div className={classes.chipWrapper}>
                    <Chip className={classes.chip}
                          color={color}
                          variant={!editing ? "default" : "outlined"}
                          onDoubleClick={this.onDoubleClickTitle}
                          onClick={this.onClick}
                          onBlur={this.onBlur}
                          label={(
                              <>
                                  {!editing ?
                                      <span className={classes.entity}>{original.value}</span> :
                                      <Input autoFocus={true}
                                             onFocus={this.onFocus}
                                             style={{width: "calc( 20px + " + (entity.value.length * 10) + "px )"}}
                                             className={classes.entityInput} onInput={this.onChange}
                                             onKeyPress={this.handleEnterPressed} value={entity.value}></Input>
                                  }
                              </>)
                          }
                          onDelete={this.onEditCancel}
                          deleteIcon={editing ? (<CloseOutlinedIcon/>) : (<></>)}
                    />
                    <Popper className={classes.typeaheadPopper}
                            id={typeahead.id}
                            open={typeahead.open}
                            anchorEl={typeahead.anchor}
                            placement="bottom-start"
                    >
                        {typeahead.loading  ? <CircularProgress /> : ""}
                        <List component="nav" aria-label="snowmed typeahead">
                            { typeahead.options && typeahead.options.map(option => (
                                <ListItem button onClick={this.handleTypeaheadSelected(option)}>{option.pt.term}</ListItem>
                            ))
                            }
                        </List>
                    </Popper>
                </div>
                <span className={classes.entityLabel}>{editing ? original.value : (" ")}</span>
            </span>
        );
    }
}

export default withStyles(styles)(EditableEntity);
