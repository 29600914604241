import * as rxjs from 'rxjs';

import { check } from "../../utils/auth/Allowed";
import rules from "../../utils/auth/rules";

export class GlobalBloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            entry: undefined,
        });

        this.events = new rxjs.Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    setEntry = (point) => {

        const current = this.subject.value;

        this.subject.next({
            ...current,
            entry: point,
        });
    }

    checkAllowed(action) {

        const roles = this.subject.value.permissions ? this.subject.value.permissions.roles : [];

        return check(rules, roles, action);
    }

    setMe = () => {
    }

    entry = () => {
        return this.subject.value.entry;
    }

    getMe = () => {
        return this.subject.value.profile;
    }
}

export class GlobalBlocEvent {
    static ACCOUNT_LOADED = "ACCOUNT_LOADED";
}


export const globalBloc = new GlobalBloc();
