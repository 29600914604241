import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from "@material-ui/core/styles";
const styles = theme => ({});

class AutocompleteField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            options: [],
            loading: false,
        }
    }

    handleInputChange = (_inputValue, getOptions) => {

        this.setState({
            inputValue: _inputValue,
        })

        if (_inputValue.length < 3) {
            this.setState({
                options: [],
                loading: false
            })
            return
        }

        this.setState({
            loading: true,
        })

        getOptions(_inputValue).then(res => {
            this.setState({
                options: res,
                loading: false,
            })
        })

    }

    handleValueChange = (_id, _newValue, setSelectedValue) => {

        setSelectedValue(_id, _newValue)
    }

    render() {
        let {label, id, defaultValue, getOptions, setSelectedValue, setDisabled, key} = this.props
        let {loading, options} = this.state

        return (
            <Autocomplete
                multiple
                variant="outlined"
                id={id}
                key={key}
                options={options}
                loading={loading}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                            this.handleValueChange(id, newValue, setSelectedValue);
                        }}
                onInputChange={(event, newInputValue) => {
                            this.handleInputChange(newInputValue, getOptions)
                        }}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        );
    }
}


export default withStyles(styles)(AutocompleteField);
