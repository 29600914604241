import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import InformationScreen from '../InformationScreen';
import {navigationTitleService} from "../../../../utils/title";
import {queuesApi} from "../../../../utils/services/queues.api";
import {Button, LinearProgress, Paper} from "@material-ui/core";
import ClinicalSummaryJobContext from "./context";
import JobPanel from "./JobPanel";
import {VERSION} from "../../../../utils/version";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        minHeight: "100%",
        maxHeight: "100%",
        height: "100%",
        backgroundColor: "#fff",
        color: "#000"
    },
    grid: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    gridLeft: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        borderRight: "1px solid #dddddd",
    },
    gridRight: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    messageWrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    messageContent: {
        display: "flex",
    },
    nextJobBtn: {
        width: "100%",
    },
});

class ClinicalSummaryJobs extends Component {

    queuePoller;

    constructor(props) {
        super(props);

        this.state = {
            busy: false,
            queueSize: 0,
            // job: {
            //     "id": "af05c44d-d2a1-4835-b06f-570295f10c88",
            //     "conversationId": "06-13",
            //     "subject": "dfef02b4-e9a0-4970-bd17-96125e9e8f01",
            //     "sourceName": "decoded-portal",
            //     "sourceVersion": "local-local-local-local-local",
            //     "participants": [],
            //     "complaints": [
            //         {
            //             "id": "3025d78e-12b1-40d8-98e0-14141c39a30b",
            //             "codes": [
            //                 {
            //                     "code": {
            //                         "system": "",
            //                         "code": "",
            //                         "value": "No"
            //                     },
            //                     "source": ""
            //                 }
            //             ],
            //             "sections": [
            //                 {
            //                     "id": "2578034a-c33d-4b86-a2d8-df2604015f9b",
            //                     "order": 0,
            //                     "code": {
            //                         "system": "decoded:clinical-summary:section-type",
            //                         "code": "hpi",
            //                         "value": "Clinical Summary"
            //                     },
            //                     "content": "Chief Complaint: No.\nBearish is a 19 year-old F with a complaint of No with onset No.\n.\nThere is uncertainty as to whether the patient has an advanced directive or DPOA.\nThe patient states that when the No.\nThe patient did not state that No is associated with any additional symptoms.\nThe patient does not specify a location for the symptoms.\nPatient reports pain.\nPatient has not addressed any pain radiation.\nFor the No, it has no frequency noted, with an unspecified duration, with unspecified timing.\nThe patient did not indicate any particualar provocation.\nThe patient did not indicate anything that makes No better.\nFor the No it is of unknown persistence. Patient did not address variation or evolution, and did not address evolution quality.\nThe patient did not address having found any treatment to alleviate their symptoms. The patient did not describe treatment effectiveness.\nThe patient did not deny any relevant symptoms.\nIn the review of systems, patient .\n",
            //                     "namedEntities": [
            //                         {
            //                             "id": "be604d72-1cae-425f-9d8b-156d9c437625",
            //                             "source": "",
            //                             "start": 17,
            //                             "end": 19
            //                         },
            //                         {
            //                             "id": "8b7250fa-9371-4887-a5ff-778d591492bb",
            //                             "source": "",
            //                             "start": 22,
            //                             "end": 29
            //                         },
            //                         {
            //                             "id": "c462aee7-3e11-4fe1-acd1-8e21acc1a835",
            //                             "source": "",
            //                             "start": 35,
            //                             "end": 37
            //                         },
            //                         {
            //                             "id": "27b013de-13fd-434d-9157-d26c3435e57c",
            //                             "source": "",
            //                             "start": 47,
            //                             "end": 48
            //                         },
            //                         {
            //                             "id": "21c0f02e-4a2f-447a-a3c2-6c16147bc27a",
            //                             "source": "",
            //                             "start": 69,
            //                             "end": 71
            //                         },
            //                         {
            //                             "id": "528af848-7271-4cc2-8e0e-8b2c1e73d258",
            //                             "source": "",
            //                             "start": 83,
            //                             "end": 85
            //                         },
            //                         {
            //                             "id": "04b09c9a-509a-418f-ba57-fa3b956c612f",
            //                             "source": "",
            //                             "start": 208,
            //                             "end": 210
            //                         },
            //                         {
            //                             "id": "0718edf4-82b6-45d9-8b12-58d3b260fba1",
            //                             "source": "",
            //                             "start": 244,
            //                             "end": 246
            //                         },
            //                         {
            //                             "id": "10ccfe5f-7210-4275-8c12-ed70e43eede3",
            //                             "source": "",
            //                             "start": 367,
            //                             "end": 371
            //                         },
            //                         {
            //                             "id": "11b08557-b7b6-4141-bc5d-3f77e2954a2f",
            //                             "source": "",
            //                             "start": 429,
            //                             "end": 431
            //                         },
            //                         {
            //                             "id": "89aea910-25da-4c42-b664-68476664a104",
            //                             "source": "",
            //                             "start": 624,
            //                             "end": 626
            //                         },
            //                         {
            //                             "id": "380bc260-7072-4fe8-b6c2-96c7d4afe9a8",
            //                             "source": "",
            //                             "start": 644,
            //                             "end": 646
            //                         }
            //                     ]
            //                 }
            //             ]
            //         }
            //     ],
            //     "ddx": [],
            //     "concepts": []
            // },
        };

        this.onSaveSummary.bind(this);
        this.updateChiefComplaint.bind(this);
        this.updateSection.bind(this);
        this.focusOnEntity.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Clinical Summary Review Jobs");

        queuesApi.getClinicalSummaryJobQueueSize()
            .then((data) => {

                if (data && data.status === 200 && data.data) {

                    this.setState({
                        busy: false,
                        queueSize: `${data.data.size}`,
                    });
                } else {
                    this.setState({
                        busy: false,
                        queueSize: "unknown",
                    });
                }
            }).finally(() => this.initialisePoller());
    }

    initialisePoller() {


        this.queuePoller = setInterval(() => {
            queuesApi.getClinicalSummaryJobQueueSize()
                .then((data) => {

                    if (data && data.status === 200 && data.data) {

                        this.setState({
                            queueSize: `${data.data.size}`,
                        });
                    } else {
                        this.setState({
                            queueSize: "unknown",
                        });
                    }
                });
        }, 5000);
    }

    componentWillUnmount() {
        if (this.queuePoller) {
            clearInterval(this.queuePoller);
        }
    }

    focusOnEntity = (id) => {

        this.setState({
            focusedNamedEntity: id,
        });
    };

    getNext = () => {

        let {busy} = this.state;

        if (!busy) {

            this.setState({
                busy: true,
            });

            queuesApi.getNextSummarisationJob()
                .then(value => {

                    this.setState({
                        job: value.data,
                        busy: false,
                    });
                })
                .catch(reason => {

                    this.setState({
                        job: {},
                        busy: false,
                    });
                });
        }
    };



    updateChiefComplaint = (complaintId, entity) => {

        console.log(entity)

        const { job } = this.state;

        let document = job.document;
        let code = document.codes.filter(code => code.source === entity.id)[0];

        code.code = {
            system: entity.code.system,
            code: entity.code.code,
            value: entity.value,
        };

        this.setState({
            job: job,
        });
    };

    updateSection = (sectionId, content, entity) => {

        const { job } = this.state;

        let section = undefined;

        for(let sidx in job.document.sections) {

            if(job.document.sections[sidx].id === sectionId) {

                section = job.document.sections[sidx];
            }
        }

        let newSectionValues = this.backToText(content);
        section.content = newSectionValues.content;

        if(Array.isArray(entity)) {
            console.log("Before:", section.annotations);
            section.annotations = section.annotations.filter((_entity) => !entity.includes(_entity.id))
            console.log("After:", section.annotations);
        }

        for(let eidx in section.annotations) {
            for(let neidx in newSectionValues.entities) {

                if(section.annotations[eidx].id === newSectionValues.entities[neidx].id) {

                    if(entity && !Array.isArray(entity) && entity.id === section.annotations[eidx].id) {
                        section.annotations[eidx].code = entity.code;
                    }

                    section.annotations[eidx].start = newSectionValues.entities[neidx].start;
                    section.annotations[eidx].end = newSectionValues.entities[neidx].end;
                }
            }
        }



        this.setState({
            job: job,
        });
    };


    backToText = (content) => {

        let pointer = 0;
        let string = "";
        let entities = [];

        for(let index in content) {
            const item = content[index];
            if(item.indexOf("{{") > -1) {

                const objects = item.replace('{{', '').replace("}}", '').split(":");
                const extract = objects[0];
                const id = objects[1];
                const start = pointer;
                const end = pointer + extract.length;
                pointer = end;

                const entity = {
                    id: id,
                    start: start,
                    end: end,
                };

                entities.push(entity);
                string += extract;
            } else {

                string += item;
                pointer += item.length;
            }
        }

        return {
            entities: entities,
            content: string,
        };
    };

    onSaveSummary = () => {

        let { busy, job } = this.state;

        if (busy) {
            this.setState({
                error: "Already processing previous submission.",
            });
            return;
        }

        this.setState({
            busy: true,
        });

        job.sourceName = "decoded-portal";
        job.sourceVersion = VERSION;

        queuesApi.submitSummarisationJob(job)
            .then(value => {
                this.setState({
                    busy: false,
                    job: undefined,
                });
            })
            .catch(reason => {

                this.setState({
                    busy: false,
                });
                console.log("Failed to update.")
            });
    };

    render() {

        let {classes} = this.props;
        let {busy, job, focusedNamedEntity, queueSize} = this.state;

        let screen = (
            <div className={classes.messageWrapper}>
                <div className={classes.fill}></div>
                <div className={classes.messageContent}>
                    <div className={classes.fill}></div>
                    <div>
                        <InformationScreen
                            message={`There ${queueSize != 1 ? "are" : "is"} ${queueSize} job${queueSize != 1 ? "s" : ""} in the queue.`}/>
                        {queueSize > 0 ? (<Button color={"primary"} variant={"outlined"} onClick={this.getNext}
                                                  className={classes.nextJobBtn}>
                            Get next job
                        </Button>) : (<></>)}
                    </div>
                    <div className={classes.fill}></div>
                </div>
                <div className={classes.fill}></div>
            </div>
        );

        const context = {
            job: job,

            focusOnEntity: this.focusOnEntity,
            focusedNamedEntity: focusedNamedEntity,
        };

        return (
            <ClinicalSummaryJobContext.Provider value={context}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        {busy && <LinearProgress/>}
                        {
                            job ? (<JobPanel
                                updateSection={this.updateSection}
                                onSaveSummary={this.onSaveSummary}
                            />) : (screen)
                        }
                    </Paper>
                </div>
            </ClinicalSummaryJobContext.Provider>
        );
    }
}

export default withStyles(styles)(ClinicalSummaryJobs);
