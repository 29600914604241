import axios from 'axios';

class SysadminApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    stats() {

        return axios.get(`${this.baseUrl}/internal/sysadmin/redis/_stats`);
    }

}

export const sysadminApi = new SysadminApi();
