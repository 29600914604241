import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Grid, LinearProgress} from "@material-ui/core";
import {DataGrid} from '@material-ui/data-grid';
import AuthoringContext from "../context";
import {navigationTitleService} from "../../../../../utils/title";
import DataPreparationJobPanel from "../Preparation/PreparationJobPanel";
import {DataPreparationBloc, DataPreparationBlocEvent, JobTypesEnum, MetadataState} from "../dataPreparation.bloc";
import AnnotationJobPanel from "./Annotation/AnnotationJobPanel";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        minHeight: "100%",
        maxHeight: "100%",
        height: "100%",
        overflow: "auto",
    },

    container: {
        padding: "20px",
        justifyContent: "center",
    },
    grid: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        backgroundColor: "#fff",
        "& .MuiDataGrid-main": {
            color: "#000"
        },
        "& .MuiTablePagination-root": {
            color: "#000"
        }
    }
});

class AuthoringTasks extends Component {

    bloc;
    eventSubscription;
    stateSubscription;

    constructor(props) {
        super(props);

        this.bloc = new DataPreparationBloc()

        this.state = {
            preparationReviewJobList: [],
            annotationJobList: [],
            busy: false,
            jobLoading: false,
            job: undefined,
            jobReady: false,
            jobType: undefined,
        };
        this.__handleDataPreparationState = this.__handleDataPreparationState.bind(this);
        this.__handleDataPreparationEvent = this.__handleDataPreparationEvent.bind(this);
    }

    componentDidMount() {
        navigationTitleService.update("Authoring Tasks");
        this.stateSubscription = this.bloc.subscribeToState(this.__handleDataPreparationState);
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleDataPreparationEvent);
        this.bloc.start();

        this.setState({
            busy: true,
        });
    }

    componentWillUnmount() {
        this.bloc.close();
        this.stateSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
    }

    __handleDataPreparationState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleDataPreparationEvent = (event) => {
        const type = event.event;

        switch (type) {
            case DataPreparationBlocEvent.JOBS_LOADED:
                this.setState({
                    busy: false,
                });
                break;
            case DataPreparationBlocEvent.JOB_REVIEW_SELECTED:
            case DataPreparationBlocEvent.JOB_ANNOTATION_SELECTED:
                this.setState({
                    jobLoading: false,
                    jobReady: true,
                });
                break;
            case DataPreparationBlocEvent.JOB_REVIEW_SELECTED_ERROR:
            case DataPreparationBlocEvent.JOB_ANNOTATION_SELECTED_ERROR:
                this.setState({
                    jobLoading: false,
                    jobReady: false,
                });
                break;
            case DataPreparationBlocEvent.JOB_FINISHED:
                this.setState({
                    jobReady: false,
                });
                navigationTitleService.update("Authoring Tasks");
                this.bloc.clearJob()
                break;
            case DataPreparationBlocEvent.JOBS_LOADED_ERROR:
                this.setState({
                    busy: false,
                });
                break;

        }
    }

    selectJob = (param) => {

        let {jobLoading} = this.state;
        if (!jobLoading) {

            let selectedJobType = param.row.jobType

            this.setState({
                jobLoading: true,
                jobType: selectedJobType,
            });

            selectedJobType === JobTypesEnum.PREPARATION_REVIEW ? this.bloc.selectPreparationReviewJob(param.id) : this.bloc.selectAnnotationJob(param.id)
        }
    }

    render() {

        let {classes} = this.props;
        let bloc = this.bloc
        let {busy, jobReady, preparationReviewJobList, annotationJobList, job, jobLoading, jobType} = this.state

        const columns = [
            {field: 'documentName', headerName: 'Document Name', flex: 1},
            {field: 'documentSource', headerName: 'Document Source', flex: 1},
            {field: 'jobType', headerName: 'Job Type', flex: 1},
        ];

        const rows = preparationReviewJobList.concat(annotationJobList).map(item => {

            let renderedJobType

            switch (item.status) {
                case MetadataState.PREPARED_FOR_REVIEW:
                    renderedJobType = JobTypesEnum.PREPARATION_REVIEW
                    break
                case MetadataState.DOCUMENT_APPROVED:
                    renderedJobType = JobTypesEnum.ANNOTATION
                    break
                case MetadataState.ANNOTATED_DRAFT:
                    renderedJobType = JobTypesEnum.ANNOTATION_DRAFT
                    break
                case MetadataState.ANNOTATED_FOR_REVIEW:
                    renderedJobType = JobTypesEnum.ANNOTATION_REVIEW
                    break
            }

            return {
                id: item.key,
                documentName: item.displayName,
                documentSource: item.source,
                jobType: renderedJobType
            }
        })

        let screen = (
            <Grid container className={classes.container}>
                <Grid item xs={8}>
                    <div className={classes.grid} style={{display: 'flex', width: '100%'}}>
                        <DataGrid autoHeight rows={rows} columns={columns} onRowClick={this.selectJob}/>
                    </div>
                </Grid>
            </Grid>
        )


        let context = {
            job: job,
            bloc: bloc,
            screen: jobType,
        }

        let panel = (<></>)

        if (jobType === JobTypesEnum.PREPARATION_REVIEW) {
            panel = (<DataPreparationJobPanel/>)
        } else if (jobType === JobTypesEnum.ANNOTATION || jobType === JobTypesEnum.ANNOTATION_DRAFT) {
            panel = (<AnnotationJobPanel/>)
        }

        return (
            <AuthoringContext.Provider value={context}>
                <div className={classes.root}>
                    {(busy || jobLoading) && <LinearProgress/>}
                    {
                        jobReady ? panel : screen
                    }
                </div>
            </AuthoringContext.Provider>
        );

    }

}

AuthoringTasks.propTypes = {classes: PropTypes.object.isRequired,};

export default withStyles(styles)(AuthoringTasks);
