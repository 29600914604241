import axios from 'axios';

class CommunicationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    sendPushNotificationTest(consumerId, userId, message, type, slots, flags) {

        return axios.post(`${this.baseUrl}/internal/consumers/${encodeURI(consumerId)}/communications/_test`, {
            medium: "push",
        }).catch(function (err) {
            console.error(err);
        });
    }

    sendAnnouncement(type, title, content) {

        return axios.post(`${this.baseUrl}/internal/notifications/_broadcast`, {
            type: type,
            pushNotificationOptions: {
                purpose: "announcement",
                title: title,
                content: content,
                data: {},
            },
        }).catch(function (err) {
            console.error(err);
        });
    }
}

export const communicationApi = new CommunicationApi();
