import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import {Typography, Button, Input, ButtonGroup} from "@material-ui/core";
import JobContext from "./context";
import {INTENT_CLASSIFIED, RPA_CLEAR} from "../../../../utils/events";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {
        display: "flex",
    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        width: "100%",
        display: "flex",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    bigButton: {
        fontSize: "42px",
        lineHeight: "58px",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});




class Rpa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bloc: this.props.context.bloc,
            event: this.props.context.event,
        };
    }

    __alertSupport = () => {

    }

    __clear = () => {

        let result = {};

        this.props.submitResultCallback(RPA_CLEAR, result);
    }

    render() {

        let { classes } = this.props;
        let { event } = this.props.context;

        let { busy } = this.state;

        const url = event.data.portal;

        return (<div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <FormattedMessage id="rpa.manual.step" defaultMessage="RPA manual step needed." />
                </Typography>
            </div>
            <div className={classes.fill} />
            <div className={classes.content}>
                <div className={classes.fill} />
                <Button className={classes.bigButton} color={"primary"} disabled={busy} onClick={() => window.open(url, '_blank')}><FormattedMessage id="rpa.manual.step.open.portal" defaultMessage="Open Portal" /></Button>
                <div className={classes.fill} />
            </div>
            <div className={classes.fill} />
            <div className={classes.footer}>
                {/*<Button disabled={busy} onClick={this.__alertSupport}><FormattedMessage id="rpa.manual.alert.support" defaultMessage="Alert support" /></Button>*/}
                <div className={classes.fill} />
                <Button disabled={busy} onClick={this.__clear}><KeyboardReturn fontSize="large" color="primary"/></Button>
            </div>
        </div>);
    }
}

export default withStyles(styles)(props => (
    <JobContext.Consumer>
        { value => {
            return (<Rpa context={ value } { ...props } />);
        }
        }
    </JobContext.Consumer>
));
