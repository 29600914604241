import React, {Component} from 'react';

import ClinicalSummaryJobContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {conversationApi} from "../../../../utils/services/conversation.api";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        color: "#3A4559",
    },
    fill: {
        flex: "1 1 auto",
    },
    in: {
        fontSize: "21px",
        fontWeight: "300",
    },
    out: {
        fontSize: "18px",
        fontWeight: "300",
    },
    highlight: {
        color: "#3A4559",
        padding: "2px 5px",
        background: "#00FFFF 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 12px #587BBC29",
        borderRadius: "8px",
        opacity: "1",
    },
});

function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}


function resolvePlaceholders(item) {

    const { content, metadata } = item;
    const entities = metadata.entities || [];

    let result = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {


                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    result.push(content.slice(count, currentEntity.start));
                }

                entityCount += 1;
                let ids = [];
                ids.push(currentEntity.id);

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {
                    ids.push(entities[entityCount].id);
                    entityCount += 1;
                }

                result.push(`{{${content.slice(currentEntity.start, currentEntity.end)}:${ids.join(",")}}}`);

                count = currentEntity.end;
            }

            if (count < content.length) {

                result.push(content.slice(count));
            }

        } else {

            chunk = content;
        }

        if (chunk.length > 0) {
            result.push(chunk);
        }
    } else {

        result.push(content)
    }

    return result;
}

function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}


class ConversationPanel extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            conversationId: this.props.context.job.metaData.conversationId,
            messages: [],
        };
    }

    componentDidMount() {
        this._getConversationInternal(this.props.context.job["metaData"].tenant, this.props.context.job.metaData.conversationId, 0, 10);
    }

    _getConversationInternal = (_tenant, _conversationId, page, size) => {

        conversationApi.conversationMessages(_tenant, _conversationId, page, size)
            .then(value => {

                const { conversationId, messages } = this.state;

                if(conversationId !== _conversationId) return;

                const data = value.data;
                const items = data.items;
                const pagination = data.page;

                let newState = {};

                if(items && items.length > 0) {

                    let processed = data.items.map(item => {
                        item.content = resolvePlaceholders(item);
                        return item;
                    });
                    newState.messages = messages.concat(processed);
                }

                if(pagination && pagination.total > ((page + 1) * size)) {

                    this._getConversationInternal(_tenant, _conversationId, page + 1, size);
                } else {

                    newState.busy = false;
                }

                this.setState({
                    ...newState,
                });
            }).catch((error) => {

            this.setState({
                error: `Conversation not found - ${error}`,
                busy: false,
            });
        });
    };


    shouldRenderWhenFocusedEntity = (focusedNamedEntity, messages, message, index) => {
        if(messages.length > index) {

            if(index === 0) {

                return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0 ||
                    (messages[index+1] && messages[index+1].metadata && messages[index+1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0);
            }

            return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0 ||
                (messages[index-1] && messages[index-1].metadata && messages[index-1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0) ||
                (messages[index+1] && messages[index+1].metadata && messages[index+1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0);
        } else {

            if(index === 0) {
                return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0;
            }

            return (messages[index-1] && messages[index-1].metadata && messages[index-1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0) ||
                message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0;
        }
    }


    render() {

        let { classes } = this.props;
        let { conversation, focusedNamedEntity } = this.props.context;
        let { messages } = this.state;

        return (
            <div className={classes.root}>
                { messages
                    .sort((a,b) => {

                        const first = Date.parse(a.createdOn);
                        const second = Date.parse(b.createdOn);

                        if(first === second) return 0;

                        return (first > second) ? 1 : -1
                    })
                    .filter((message, index) => {

                        if(focusedNamedEntity) {

                            return this.shouldRenderWhenFocusedEntity(focusedNamedEntity, messages, message, index);
                        } else {

                            return !message.id.endsWith("-DER");
                        }
                    }).map((message) => (<p className={message.direction === "IN" ? classes.in : classes.out} align={ message.direction === "IN" ? "left" : "right"}>{
                        message.content.map( text => text.startsWith("{{") ? <span className={classes.highlight}>{text.substring(2, text.indexOf(":"))}</span> : <>{text}</> )
                    }</p>))
                }
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ClinicalSummaryJobContext.Consumer>
        {value => {
            return (<ConversationPanel context={value} {...props} />);
        }
        }
    </ClinicalSummaryJobContext.Consumer>
));
