import React, {Component} from 'react';

import DirectMessagingViewContext from "./context";

import {
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    Paper, TextField, InputAdornment, LinearProgress
} from "@material-ui/core";

import {withStyles} from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import {FormattedMessage} from "react-intl";
import SendIcon from "@material-ui/icons/Send";
import {messagingApi} from "../../../../utils/services/messaging.api";
import {notificationService} from "../../../../utils/notification";

const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    messagePanel: {
        height: "calc(100% - 74px)",
        borderBottom: "1px solid #cccccc",
        overflow: "auto",
        padding: "20px 20px",
    },
    messageWrapper: {
        display: "flex",
    },
    in: {
        padding: "0px 0px 8px 20px",
    },
    messageItemInText: {
        color: "#55CCFF",
        fontSize: "24px",
    },
    out: {
        color: "#FFF",
        fontSize: "20px",
        padding: "0px 0px 8px 20px",
    },
    messageItemOutText: {
        color: "#FFF",
        fontSize: "20px",
    },
    inputPanel: {
        height: "74px",
    },
    inputField: {
        width: "100%",
    },
    messageContent: {
        padding: "0px 0px 12px 0px",
    },
    messageItemDate: {
        color: "#ccc",
        fontSize: "10px",
    },
    messageItemActions: {
        color: "#fff",
        fontSize: "12px",
    }
});

class MessagePanel extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            busy: false
        };
    }


    handleManualInputChange = (event) => {

        this.setState({message: event.target.value});
    };

    handleEnterPressed = (group, user, message) => (event) => {

        if (event.charCode === 13) {
            this.sendMessage(group, user, message)(event);
        }
    };

    sendMessage = (group, user, message) => (event) => {

        const { busy } = this.state;

        if(!busy && message && message.trim().length > 0) {

            this.setState({
                busy: true,
            });

            console.log(group, user, message);
            messagingApi.sendDirectMessage(group, user, message)
                .then(value => {

                    this.props.addThreadMessage(group, {
                        id: value.headers["location"],
                        content: message,
                        createdOn: new Date().toISOString(),
                        direction: 'OUT',
                    });

                    this.setState({
                        message: "",
                        busy: false,
                    });
                })
                .catch(reason => {

                    notificationService.update({
                        error: "Error sending message please try again later - " + reason,
                    });
                    this.setState({
                        busy: false,
                    });
                });
        }
    };

    render() {

        let { classes } = this.props;
        let { activeThread, openThreads } = this.props.context;
        let { message, busy } = this.state;

        if(!activeThread) {

            return (<></>);
        }

        var thread = openThreads.filter(value => value.id === activeThread)[0];
        let user = thread.customer;
        let letter = user && user.id ? user.name.given.charAt(0) : "U";

        if(!thread.messages) {
            return (<LinearProgress/>);
        }

        return (
            <div className={classes.root}>
                { busy ? <LinearProgress/> : <></> }
                <div className={classes.messagePanel}>
                    { thread.messages
                        .sort((a,b) => (Date.parse(a.createdOn) > Date.parse(b.createdOn)) ? 1 : -1)
                        .map((message) => {

                            if(message.direction === "IN") {

                                return (<div className={classes.messageWrapper}>
                                    <div className={classes.fill}></div>
                                    <Avatar>
                                        {letter}
                                    </Avatar>
                                    <div className={classes.in}>
                                        <Paper elevation={0} variant={'outlined'} className={classes.messageContent}>
                                            <div className={classes.messageItemInText}>{message.content}</div>
                                            <div className={classes.messageItemDate}>{message.createdOn}</div>
                                        </Paper>
                                        {/*<div className={classes.messageItemActions}>Send back to Quinn</div>*/}
                                    </div>
                                </div>);

                            } else {

                                return (
                                    <div className={classes.messageWrapper}>
                                        <Avatar>
                                            DH
                                        </Avatar>
                                        <div className={classes.out}>
                                            <div className={classes.messageItemOutText}>{message.content}</div>
                                            <div className={classes.messageItemDate}>{message.createdOn}</div>
                                        </div>
                                    </div>
                                );
                            }

                        })
                    }
                    
                </div>
                <div className={classes.inputPanel}>
                    <TextField
                        id="message"
                        autoComplete={"off"}
                        className={classes.inputField}
                        variant="filled"
                        type='text'
                        onChange={this.handleManualInputChange}
                        onKeyPress={this.handleEnterPressed(activeThread, user.id, message)}
                        value={message}
                        label={(<FormattedMessage id={'messaging.send.new.message'} defaultMessage={"Enter message to send."} />)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.sendMessage(activeThread, user.id, message)}
                                        edge="end"
                                        aria-label="toggle password visibility">
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <DirectMessagingViewContext.Consumer>
        {value => {
            return (<MessagePanel context={value} {...props} />);
        }
        }
    </DirectMessagingViewContext.Consumer>
));
