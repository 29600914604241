import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Chip, CircularProgress, Input, List, ListItem, Popper, Tooltip} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {utilitiesApi} from "../../../../../utils/services/utilities.api";
import CreateIcon from "@material-ui/icons/Create";
import SaveIcon from "@material-ui/icons/Save";
import EditableEntity from "./EditableEntity";
import AnnotatedText from "../../shared/AnnotatedText";


const styles = theme => ({
    root: {
        margin: "13px 0",
        display: "flex",
    },
    summarySectionLeft: {
        width: "11px",
        marginTop: "8px",
        borderTop: "1px solid #C5C5C560",
        borderLeft: "1px solid #C5C5C560",
        borderBottom: "1px solid #C5C5C560",
    },

    summarySectionRight: {
        width: "calc(100% - 15px)",
        paddingLeft: "8px",
        color: "#20516A",
    },

    summarySectionHint: {
        fontSize: "16px",
        color: "#B5B5B5",
        fontWeight: "300",
    },

    summarySectionContent: {
        paddingTop: "5px",
        paddingBottom: "10px",
        fontSize: "29px",
    },

    summarySectionContentEntity: {
        padding: "2px 5px",
        background: "#00FFFF 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 12px #587BBC29",
        borderRadius: "8px",
        opacity: "1",
    },
});


class EditableSection extends Component {

    referenceMap;

    constructor(props) {
        super(props);

        let { section } = this.props;

        this.state = {
            content: [section.content.replace(/\\n\\n/g, "\n")],
        };
    }

    onChange = (event) => {

        let { section } = this.props;

        const newContent = event.target.value;

        this.props.updateSection(section.id, newContent, []);

        this.setState({
            content: newContent,
        });
    };

    render() {

        let { classes } = this.props;
        let { content } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.summarySectionLeft}>
                </div>
                <div className={classes.summarySectionRight}>
                    <div className={classes.summarySectionHint}>
                        <CreateIcon fontSize={"small"} />
                    </div>
                    <Input style={{ width: "100%", color: "#20516A", lineHeight: "1.43", }} className={classes.summarySectionContent} onChange={this.onChange} multiline={true} value={content} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(EditableSection);
