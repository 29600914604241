import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {dataPreparationApi} from "../../../../../../utils/services/datapreparation.api";
import {withStyles} from "@material-ui/core/styles";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
const styles = theme => ({});


class AutocompleteField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            options: [],
            loading: false,
            inputValue: "",
            conceptName: props.conceptName
        }
    }

    setOpen = (_state) => {
        this.setState({
            open: _state,
        })
    }

    handleInputChange = (_inputValue) => {

        this.setState({
            inputValue: _inputValue,
        })

        if (_inputValue.length < 3) {
            this.setState({
                options: [],
                loading: false
            })
            return
        }

        this.setState({
            loading: true,
        })

        dataPreparationApi.query("umls_view", _inputValue)
            .then(value => {

                let items = value.data

                let options = []

                items.forEach(item => {

                    options.push({
                        name: item.canonical_name,
                        conceptId: item.concept_id,
                        relevance: item.score,
                        types: item.types,
                        sources: item.sources,
                    })
                    item.aliases.forEach(alias => {

                        if (alias === item.canonical_name) {
                            return;
                        }

                        options.push({
                            name: alias,
                            conceptId: item.concept_id,
                            relevance: item.score,
                            types: item.types,
                            sources: item.sources,
                        })
                    })
                })

                options.sort((a, b) => (a.score - b.score))

                this.setState({
                    options: options,
                    loading: false,
                })

            });
    }

    handleValueChange = (_newValue) => {

        this.props.setSelectedValue(_newValue)
    }

    render() {
        let {label} = this.props
        let {open, inputValue, loading, options, conceptName} = this.state

        return (
            <div>
                <Autocomplete
                    id="autocomplete-field"
                    variant="outlined"
                    open={open}
                    onOpen={() => {
                        this.setOpen(true);
                    }}
                    onClose={() => {
                        this.setOpen(false);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        this.handleInputChange(newInputValue)
                    }}
                    defaultValue={{ name : conceptName}}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        this.handleValueChange(newValue);
                    }}
                    getOptionSelected={(option, value) => options.some(option => option.name === value.name) }
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={label}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(option, {inputValue}) => {
                        const matches = match(option.name, inputValue);
                        const parts = parse(option.name, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}


export default withStyles(styles)(AutocompleteField);