import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {Grid, Button, InputAdornment, TextField} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import IconButton from "@material-ui/core/IconButton";

import SendIcon from '@material-ui/icons/Send';
import ExceptionContext from "./context";
import {exceptionSessionWebsocket} from "../../../../utils/services/session.websocket";
import {
    EVENT, TECHNICIAN_SESSION_MANUAL_RESPONDED
} from "../../../../utils/events";
import MessageAnnotator from "./MessageAnnotator";
import InformationScreen from "../InformationScreen";
import UserInformation from './UserInformation';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import MessageAnnotationPanel from "../component/MessageAnnotationPanel";
import MessageAnnotationContext from '../component/MessageAnnotationPanel/context';
import {MESSAGE_ANNOTATION_EVENTS} from "../component/MessageAnnotationPanel/service/message.annotation.event.service";
import ChatHistory from "../../../shared/ChatHistory";


const styles = theme => ({
    root: {
        maxHeight: "100%",
        minHeight: "100%",
        display: "flex",
    },
    fill: {
        flex: "1 1 auto",
    },
    grid: {
        maxHeight: "100%",
        minHeight: "100%",
        display: "flex",
    },
    content: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    right: {
        overflowY: "hidden",
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%",
    },

    messageView: {
        overflowY: "auto",
        display: "flex",
        height: "calc(100% - 220px)",
        maxHeight: "calc(100% - 220px)",
    },

    messageInput: {

        height: "140px",
    },

    button: {
        width: "100%",
        marginTop: theme.spacing(2),
    },

    contentGrid: {
        minHeight: "100%",
        height: "100%",
        display: "content",
    },

    conversationTask: {
        height: "100%",
        display: "flex",
    },

    conversationInput: {
        paddingLeft: "5px",
        paddingRight: "5px",
        height: "70px",
    },

    consumerInformation: {
        height: "80px",
    },

    manualInputField: {
        width: "100%",
    },

    informationScreen: {
        margin: "auto",
    },
});


class Conversation extends Component {


    constructor(props) {
        super(props);

        this.state = {
            dialogOpen:false,
        };

        this.handleUpdate.bind(this);
        this.jobCallbackSubscription = exceptionSessionWebsocket.registerJobCallback(this.handleUpdate);

        const { metadata } = this.props.context;

        if(!metadata) {
            this.state['canLeave'] = true;
        }
    }


    componentWillUnmount() {

        this.jobCallbackSubscription.unsubscribe();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        const { canLeave } = this.state;
        const { metadata, activeConversation } = this.props.context;

        if(metadata &&
            (!prevProps.context.metadata ||
            activeConversation.conversationId !== prevProps.context.activeConversation.conversationId)) {
            if (!metadata || (metadata && (metadata.amc || !metadata.messageId))) {
                this.setState({
                    canLeave: true,
                });
            } else {
                this.setState({
                    canLeave: false,
                });
            }
        } else if(prevProps.context.metadata && activeConversation.conversationId !== prevProps.context.activeConversation.conversationId) {
            this.setState({
                canLeave: true,
            });
        }
    }

    handleUpdate = (data) => {

        let response = {};

        // eslint-disable-next-line default-case
        switch (data.type) {
            case TECHNICIAN_SESSION_MANUAL_RESPONDED:
                response = { manualInputValue: "" };
                break;
        }

        let newState = {
            ...response,
        };

       this.setState(newState)
    };

    handleManualInputChange = (event) => {

        this.setState({manualInputValue: event.target.value});
    };

    handleEnterPressed = (event) => {

        if (event.charCode === 13) {

            let { manualInputValue } = this.state;

            this.props.sendManualResponse(manualInputValue)(event);
        }
    };

    closeDialog = () => {

        this.setState({
            endType: undefined,
            dialogOpen: false,
        });
    };

    showEndConversationDialog = (type) => {

        let $this = this;

        return () => {
            $this.setState({
                dialogOpen: true,
                endType: type,
            });
        };
    };

    endConversation = (endType) => {

        let $this = this;

        return () => {
            $this.props.endConversation(endType);
        };
    };

    render() {

        const { classes } = this.props;

        let { activeUser, activeConversation, metadata, busy, quinnAsk } = this.props.context;

        let { manualInputValue, dialogOpen, endType } = this.state;

        let goal = "";

        let canvas = (<></>);

        let messages = (<></>);


        if(metadata || quinnAsk) {

            if(quinnAsk) {

                let context = {
                    metadata: metadata,
                    event: quinnAsk
                };

                canvas =
                    (<MessageAnnotationContext.Provider value={context} >
                        <MessageAnnotationPanel
                            busy={busy}
                            eventId={quinnAsk.id}
                            exceptionLabel={"Discard"}
                            exceptionEvent={MESSAGE_ANNOTATION_EVENTS.MESSAGE_DISCARD}
                        />
                    </MessageAnnotationContext.Provider>);
            } else {

                goal = metadata.goal || "agenda";

                if (goal === "agenda") {

                    let slotCode = metadata.slotCode || "intents";
                    canvas = (<MessageAnnotator submitErrorUpdate={this.props.submitErrorUpdate} goal={goal}
                                                slotCode={slotCode} busy={busy} messageId={metadata.messageId}/>);
                } else if (goal === "exception") {

                    let slotCode = metadata.slotCode || "exception";
                    canvas = (<MessageAnnotator submitErrorUpdate={this.props.submitErrorUpdate} goal={goal}
                                                slotCode={slotCode} busy={busy} messageId={metadata.messageId}/>);
                } else {

                    canvas = (<MessageAnnotator submitErrorUpdate={this.props.submitErrorUpdate} goal={goal}
                                                slotCode={metadata.slotCode} busy={busy}
                                                messageId={metadata.messageId}/>);
                }
            }

        } else {

            canvas = (<div className={classes.informationScreen}><InformationScreen message={(<FormattedMessage id={"waiting.for.next"} defaultMessage={"Waiting for next message..."} />)} /></div>);
        }

        messages = (<div className={classes.messageView}>
            <ChatHistory tenant={activeConversation.tenant} conversationId={ activeConversation.conversationId }  />
        </div>);

        return (
            <div className={classes.root}>
                <Grid container className={classes.grid}>
                    <Grid item xs={7} lg={8} className={classes.content}>
                        <div className={classes.conversationTask}>
                            { canvas }
                        </div>
                        <div className={ classes.fill } />
                        <div className={classes.conversationInput}>

                            <TextField
                                id="manual-response"
                                autoComplete={"off"}
                                className={classes.manualInputField}
                                variant="filled"
                                type='text'
                                onChange={this.handleManualInputChange}
                                onKeyPress={this.handleEnterPressed}
                                value={manualInputValue}
                                label={(<FormattedMessage id={'handback.conversation'} defaultMessage={"Manual input (USE WITH CAUTION)"} />)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={this.props.sendManualResponse(manualInputValue)}
                                                edge="end"
                                                aria-label="toggle password visibility">
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5} lg={4} className={classes.right}>
                        <div className={classes.consumerInformation}>
                            <UserInformation activeUser={activeUser} />
                        </div>
                        <div className={classes.messageInput}>
                            <Button variant="contained" onClick={this.showEndConversationDialog("END")} color="primary" className={classes.button}>
                                <FormattedMessage id={'end.conversation'} defaultMessage={"End and schedule appointment"} />
                            </Button>
                            <Button variant="contained" onClick={this.showEndConversationDialog("TERMINATE")} color="secondary" className={classes.button}>
                                <FormattedMessage id={'end.conversation.terminate'} defaultMessage={"Terminate encounter"} />
                            </Button>
                        </div>
                        { messages }
                    </Grid>
                </Grid>
                <Dialog
                    open={dialogOpen}
                    keepMounted
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    { endType && endType === "TERMINATE" ? (<DialogTitle id="alert-dialog-slide-title">{"End conversation?"}</DialogTitle>) : (<DialogTitle id="alert-dialog-slide-title">{"End conversation and schedule appointment?"}</DialogTitle>) }
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        { endType && endType === "TERMINATE" ? (<FormattedMessage id={'end.conversation.dialog.terminate'} defaultMessage={"Are you sure you want to end the conversation WITHOUT scheduling an appointment?"} />) : (<FormattedMessage id={'end.conversation.dialog'} defaultMessage={"Are you sure you want to end the conversation and schedule an appointment?"} />) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            <FormattedMessage id={'cancel'} defaultMessage={"Cancel"} />
                        </Button>
                        <Button onClick={this.endConversation(endType)} color="primary">
                            <FormattedMessage id={'continue'} defaultMessage={"Continue"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ExceptionContext.Consumer>
        { value => {
            return (<Conversation context={ value } { ...props } />);
        }
        }
    </ExceptionContext.Consumer>
));
