import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {
    Grid,
    Paper,
    Snackbar,
    IconButton,
    LinearProgress,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close"
import DirectMessagingViewContext from "./context";
import CustomerPanel from "./CustomerPanel";
import MessagePanel from "./MessagePanel";
import {messagingApi} from "../../../../utils/services/messaging.api";
import {notificationService} from "../../../../utils/notification";
import {consumerApi} from "../../../../utils/services/consumers.api";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        minHeight: "100%",
        maxHeight: "100%",
        height: "100%",
    },
    grid: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    gridLeft: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        borderRight: "1px solid #dddddd",
    },
    gridRight: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
});

class DirectMessaging extends Component {

    openThreads = [];

    constructor(props) {
        super(props);

        this.state = {
            busy: true,
            error: undefined,
            openThreads: [],
        };

        this.setActiveThread.bind(this);
        this.addThread.bind(this);
        this.loadThreadMessages.bind(this);

        this.loadOpenThreads();
    }

    loadOpenThreads() {
        messagingApi.listOpenThreads()
            .then(data => {
                this.setState({
                    busy: false
                });
                data.data.items.forEach(thread => this.enrichThread(thread));
            }).catch(error => {
            notificationService.update({
                error: "Error loading your open threads. " + error,
            });
        });
    }

    enrichThread(thread) {

        consumerApi.getPersonSummary(thread.customer.value, thread.customer.system)
            .then(value => {

                thread.customer = value.data;
                this.openThreads.push(thread);

                this.setState({
                    openThreads: this.openThreads,
                    busy: false
                });
            }).catch(error => {
                notificationService.update({
                    error: "Error loading user. " + error,
                });
        });
    }

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    addThread = (thread) => {

        const { openThreads } = this.state;

        openThreads.push(thread);

        this.setState({
            openThreads: openThreads,
        });
    };

    addThreadMessage = (thread, message) => {

        const { openThreads } = this.state;
        var threads = openThreads.map(value => {
            if(value.id === thread) {
                value.messages.push(message);
            }
            return value;
        });

        this.setState({
            openThreads: threads,
        });
    };

    removeThread = (thread) => {

        const { openThreads, activeThread } = this.state;
        const newOpenThreads = openThreads.filter(value => value.id !== thread.id);
        this.setState({
            activeThread: activeThread === thread.id ? null : activeThread,
            openThreads: newOpenThreads,
        });
    };


    setActiveThread = (thread) => {

        let $this = this;

        return (event) => {

            if(this.state.activeThread !== thread.id) {
                $this.setState({
                    activeThread: thread.id,
                });

                $this.loadThreadMessages(thread);
            }
        }
    };

    loadThreadMessages = (thread) => {

        messagingApi.listOpenThreadMessages(thread.id)
            .then(value => {

                const threads = this.state.openThreads.map((item) => {
                    if( item.id === thread.id ) {

                        item.unreadIn = 0;
                        item.unreadOut = 0;
                        item.messages = value.data.items;
                    }

                    return item;
                });

                messagingApi.markMessagesAsRead(thread.id).finally(() => console.log("complete"));

                this.setState({
                    openThreads: threads,
                });
            }).catch(reason => {

            this.setState({
                messages: [],
            });

            notificationService.update({
                error: "Error loading your open thread messages, try again later. " + reason,
            });
        });
    };

    render() {

        let { classes } = this.props;
        let { openThreads, activeThread, busy, error } = this.state;

        let errorAlert = (<></>);

        if (error) {

            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    message={<span id="message-id">{error}</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            );
        }

        let context = {
            error: error,
            busy: busy,
            openThreads: openThreads,
            activeThread: activeThread,
        };

        return (
            <DirectMessagingViewContext.Provider  value={context}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        {busy && <LinearProgress/>}
                        <Grid container className={classes.grid}>
                            <Grid item xs={3} md={2} className={classes.gridLeft}>
                                <CustomerPanel removeThread={ this.removeThread } addThread={ this.addThread } setActiveThread={ this.setActiveThread } />
                            </Grid>
                            <Grid item xs={9} md={10} className={classes.gridRight}>
                                <MessagePanel  addThreadMessage={ this.addThreadMessage } />
                            </Grid>
                        </Grid>
                    </Paper>
                    {errorAlert}
                </div>
            </DirectMessagingViewContext.Provider>
        );
    }
}

export default withStyles(styles)(DirectMessaging);
