import * as rxjs from "rxjs";
import {notificationService} from "../../../../utils/notification";
import {clinicalInferenceApi} from "../../../../utils/services/clinicalinference.api";

export class DifferentialDiagnosisBloc {

    defaultWeight;
    constructor() {

        this.defaultWeight = 1;

        this.subject = new rxjs.BehaviorSubject({
            diagnosisPrediction: undefined
        });

        this.events = new rxjs.Subject();
    }

    predictDifferentialDiagnosis = (data) => {

        let input = Object.keys(data).map((key) => {
            return {
                "observation": data[key].observation,
                "weight": data[key].weight ? data[key].weight : this.defaultWeight
            }
        })

        clinicalInferenceApi.getDifferentialDiagnosis(input)
            .then((value) => {
                this.subject.next({
                    diagnosisPrediction: value.data,
                })
            })
            .then(() => {
                this.events.next({event: DifferentialDiagnosisBlocEvent.INFERENCE_AVAILABLE})
            })
            .catch(() => {
                notificationService.error("Clinical Inference service unavailable");
                this.events.next({event: DifferentialDiagnosisBlocEvent.INFERENCE_ERROR})
            });
    }


    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

}


export class DifferentialDiagnosisBlocEvent {
    static INFERENCE_AVAILABLE = "INFERENCE_AVAILABLE";
    static INFERENCE_ERROR = "INFERENCE_ERROR";
}