import axios from 'axios';

class ConversationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    respond(conversationId, userId, message, type, slots, flags) {

        return axios.post(`${this.baseUrl}/internal/conversations/${encodeURI(conversationId)}/_respond`, {
            conversationId: conversationId,
            recipientId: userId,
            message: message,
            replyType: type,
            slots: slots,
            flags: flags
        }).catch(function (err) {
            console.error(err);
        });
    }

    configuration(tenant, code) {

        return axios.get(`${this.baseUrl}/internal/configurations/${code}`, {
            headers: {
                "X-Tenant-ID": tenant,
            }
        })
            .then(function (res) { return res; })
            .catch(function (err) {
                console.error(err);
            });
    }

    queuedCount() {

        return axios.get(`${this.baseUrl}/internal/conversations/_queued`)
            .then(function (res) { return res; })
            .catch(function (err) {
                console.error(err);
            });
    }

    dequeueNext() {

        return axios.get(`${this.baseUrl}/internal/conversations/_dequeue`)
            .then(function (res) { return res; })
            .catch(function (err) {
                console.error(err);
            });
    }


    conversationMessages(_tenant, conversationId, page, size) {

        return axios.get(`${this.baseUrl}/internal/conversations/${encodeURI(conversationId)}/messages?order=createdOn&s=${size}&p=${page}`,
            {
            headers: {
                "X-Tenant-ID": _tenant,
            }
        }).then(function (res) { return res; });
    }

    tailConversationMessages(_tenant, conversationId, messageId) {

        return axios.get(`${this.baseUrl}/internal/conversations/${encodeURI(conversationId)}/messages?order=createdOn&last=${encodeURIComponent(messageId)}&s=100`,
            {
            headers: {
                "X-Tenant-ID": _tenant,
            }
        }).then(function (res) { return res; });
    }

    conversationMemory(conversationId, page, size) {

        return axios.get(`${this.baseUrl}/internal/conversations/${encodeURI(conversationId)}/memory?s=${size}&p=${page}`)
            .then(function (res) { return res; });
    }
}

export const conversationApi = new ConversationApi();
