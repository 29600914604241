import axios from "axios";


class AnalyticsApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getDashboardURL(redirect) {

        return axios.get(`${this.baseUrl}/internal/analytics/dashboard`);
    }
}

export const analyticsApi = new AnalyticsApi();
