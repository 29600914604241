import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputAdornment, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardHeader: {

    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    }
});

class RephraseApprovalComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            text: "",
            rephrases: [],
            rephrases_removed: [],
        };

        this.__removeRephrase = this.__removeRephrase.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Rephrase Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __textChanged = (event) => {
        this.setState({
            text: event.target.value,
        });
    }

    __selectChanged = (event) => {
        this.setState({
            intent: event.target.value,
        });
    }

    __addPhrase = () => {

        let { rephrases, text } = this.state;

        if(text < 3) {
            notificationService.error("Must be more than 3 characters.");
            return;
        }

        rephrases.push({
            id: uuid().toString(),
            text: text,
        });

        this.setState({
            rephrases: rephrases,
            text: "",
        })

    }
    __toggleRephrase = (rephrase) => () => {

        let { rephrases_removed } = this.state;

        const item = rephrases_removed.filter(_rephrase => _rephrase === rephrase.id);
        if(item.length > 0) {
            rephrases_removed = rephrases_removed.filter(_rephrase => _rephrase !== rephrase.id);
        } else {
            rephrases_removed.push(rephrase.id);
        }
        this.setState({
            rephrases_removed: rephrases_removed,
        });
    }


    __removeRephrase = (rephrase) => () => {

        let { rephrases } = this.state;

        rephrases = rephrases.filter(_rephrase => _rephrase.id !== rephrase.id);

        this.setState({
            rephrases: rephrases,
        });
    }

    __submit = () => this.bloc.submitTask({ data: { rephrases_removed: this.state.rephrases_removed, rephrases: this.state.rephrases.map(value => value.text), intents: [this.state.intent] } });
    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const { pipeline, classes } = this.props;
        const { initialising, task, text, intent, rephrases, rephrases_removed } = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Typography className={classes.header} variant={"h4"}>Please approve the below rephrases and verify the intent. Additionally, you can add more rephrases before approving.</Typography>
                    </Grid>
                    <Grid item xs={"12"}>
                        <Typography className={classes.context} variant={"h5"}>{task.data.context || "No context required"}</Typography>
                    </Grid>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader} title={`Text: ${task.data.text}`}/>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="intentSelect">Primary intent</InputLabel>
                                    <Select id="intentSelect" value={intent} onChange={this.__selectChanged}>
                                        <MenuItem value={"clinical+medical"}>Clinical Medical</MenuItem>
                                        <MenuItem value={"clinical+injury"}>Clinical Injury</MenuItem>
                                        <MenuItem value={"clinical+exposure"}>Clinical Exposure</MenuItem>
                                        <MenuItem value={"clinical+mental"}>Clinical Mental</MenuItem>
                                        <MenuItem value={"clinical+medication"}>Clinical Medication</MenuItem>
                                        <MenuItem value={"clinical+maintenance"}>Clinical Maintenance</MenuItem>
                                        <MenuItem value={"clinical+workers_comp"}>Clinical Workers Compensation</MenuItem>
                                        <MenuItem value={"clinical+screen"}>Clinical Screen</MenuItem>
                                        <MenuItem value={"clinical+cosmetic"}>Clinical Cosmetic</MenuItem>
                                        <MenuItem value={"clinical+other"}>Clinical Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </CardContent>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="rephraseTxt">Input</InputLabel>
                                    <Input
                                        id="rephraseTxt"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={text}
                                        onChange={this.__textChanged}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__addPhrase}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List dense={true}>
                                    {this.__renderRephrases(classes, task, rephrases_removed)}
                                    {this.__renderEdits(classes, rephrases)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton disabled={!intent} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderRephrases = (classes, task, rephrases_removed) => {

       return task.rephrases.map(rephrase => {

           const checked = rephrases_removed.includes(rephrase.id)

           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText style={{ textDecoration: checked ? "line-through" : "none" }} primary={ rephrase.text }/>
                   <ListItemSecondaryAction>
                       <Switch
                           edge="end"
                           onChange={this.__toggleRephrase(rephrase)}
                           checked={ checked }
                           inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                       />
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, rephrases) => {

       return rephrases.map(rephrase => {
           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText primary={ rephrase.text }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__removeRephrase(rephrase)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<RephraseApprovalComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

