import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    cardHeader: {

    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    },
});

class RephraseComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            text: "",
            rephrases: [],
        };

        this.__removeRephrase = this.__removeRephrase.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Rephrase Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __textChanged = (event) => {
        this.setState({
            text: event.target.value,
        });
    }

    __addPhrase = () => {

        let { rephrases, text } = this.state;

        if(text < 3) {
            notificationService.error("Must be more than 3 characters.");
            return;
        }

        rephrases.push({
            id: uuid().toString(),
            text: text,
        });

        this.setState({
            rephrases: rephrases,
            text: "",
        })

    }
    __removeRephrase = (rephrase) => () => {

        let { rephrases } = this.state;

        rephrases = rephrases.filter(_rephrase => _rephrase.id !== rephrase.id);

        this.setState({
            rephrases: rephrases,
        });
    }

    __submit = () => this.bloc.submitTask({ data: { rephrases: this.state.rephrases.map(value => value.text) } });
    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const { classes } = this.props;
        const { initialising, busy, task, text, rephrases } = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                title={`Please rephrase "${task.data.text}"`}
                                titleTypographyProps={{ className: classes.cardTitle }}
                                subheader={task.data.context || "No context required"}
                            />
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="rephraseTxt">Rephrase Input</InputLabel>
                                    <Input
                                        id="rephraseTxt"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={text}
                                        onChange={this.__textChanged}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__addPhrase}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List subheader={"Rephrases"} dense={true}>
                                    {this.__renderRephrases(classes, task)}
                                    {this.__renderEdits(classes, rephrases)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton disabled={rephrases.length < 2 || busy} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderRephrases = (classes, task) => {

       return task.rephrases && task.rephrases.map(rephrase => {
           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText primary={ rephrase.text }/>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, rephrases) => {

       return rephrases.map(rephrase => {
           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText primary={ rephrase.text }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__removeRephrase(rephrase)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<RephraseComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

