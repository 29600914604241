import React from 'react';

import ConversationViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';

import DecodedComponent from "../../../shared/DecodedComponent";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "100%",
        padding: "10px 25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fill: {
        flex: "1 1 auto",
    },
    input: {
        padding: "16px 0px",
        width: "100%",
        minWidth: "300px",
    },
    select: {
        width: "100%",
    },
    button: {
        width: "100%",
        padding: "16px 0px"
    }
});


class SelectConversations extends DecodedComponent {

    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;

        this.state = {
            loaded: {
                conversations: false,
            },
            local: {
                busy: false,
                targetConversation: undefined,
            },
            conversations: [],
        };
    }

    __updateTargetConversation = (event) => {

        let { local } = this.state;
        local.targetConversation = event.target.value;

        this.setState({ local: local });
    }

    __refreshConversations = (event) => {

        let { local } = this.state;
        local.targetConversation = undefined;

        this.setState({ local: local, conversations: [] });

        this.bloc.refreshConversations();
    }

    __submit = (event) => {

        event.preventDefault();

        let { local,  } = this.state;

        this.bloc.setConversation(local.targetConversation);
    }


    render() {

        let { classes } = this.props;
        let { busy, conversations, local, loaded } = this.state;

        return (
            <div className={classes.root}>
                <form onSubmit={this.__submit}>
                    <Grid container spacing={4}>
                        <Grid className={classes.input} item xs={12}>
                            <FormControl className={classes.select}>
                                <InputLabel id="targetConversationLabel">Conversation</InputLabel>
                                <Select
                                    labelId="targetConversation"
                                    id="targetConversation"
                                    value={local.targetConversation}
                                    onChange={this.__updateTargetConversation}
                                    required={true}
                                >
                                    { conversations.map(conversation => <MenuItem value={conversation}>{conversation.created}</MenuItem>) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.buttonWrapper} xs={6}>
                            <Button className={classes.button} type={"button"} variant={"contained"} color={"default"} onClick={this.__refreshConversations}>Refresh</Button>
                        </Grid>
                        <Grid item className={classes.buttonWrapper} xs={6}>
                            <Button className={classes.button} type={"submit"} variant={"contained"} color={"primary"} disabled={ busy || !loaded.ontology || !conversations || conversations.length === 0}>Open</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ConversationViewContext.Consumer>
        { value => {
            return (<SelectConversations context={value} {...props} />);
        } }
    </ConversationViewContext.Consumer>
));
