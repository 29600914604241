import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {
    Button,
    Container,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Snackbar,
    TextField,
    Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close"
import PushNotificationViewContext from "./context";
import {communicationApi} from "../../../../utils/services/communication.api";
import {green} from "@material-ui/core/colors";
import {notificationService} from "../../../../utils/notification";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FormattedMessage} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        paddingTop: "24px",
        paddingBottom: "100px",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    paper: {
        padding: "24px 16px",
        overflow: "auto",
    },
    or: {
        textAlign: "center",
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    snackbarSuccess: {
        backgroundColor: green[600],
        padding: theme.spacing(0.5),
    },
    closeError: {
        padding: theme.spacing(0.5),
    },
});

class PushNotification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: false,
            error: undefined,
            dialogOpen: false,
        };

        this.loadPatient.bind(this);
    }

    handleTextChange = (event) => {

        let change = {};
        change[event.target.name] = event.target.value;
        this.setState(change);
    };

    loadPatient = () => {

        const { consumerIdentifier } = this.state;

        this.setState({
            busy: true,
        });

        communicationApi.sendPushNotificationTest(consumerIdentifier)
            .finally(() => {
                this.setState({
                    busy: false,
                });
            })
    };

    handleSubmit = (event) => {

        const {
            busy,
            title,
            content,
        } = this.state;

        if(!busy) {

            this.setState({ busy: true });

            communicationApi.sendAnnouncement("PUSH_NOTIFICATION", title, content)
                .then((data) => {

                    if(data.status < 300) {
                        notificationService.update({
                            success: "Success the notification has been queued.",
                        });
                        this.resetState({});
                    } else {
                        notificationService.update({
                            error: "We were unable to send out the push notification.",
                        });
                    }

                }).catch(reason => {
                    notificationService.update({
                        error: "Error trying to queue. Try again. If the problem persists contact support. " + reason,
                    });
            });
        }
    };

    resetState = (extras) => {
        this.setState({
            title: "",
            content: "",
            busy: false,
            dialogOpen: false,
            ...extras
        });
    };

    reviewMessageDialog = () => {

        this.setState({
            dialogOpen: true,
        });
    };

    closeDialog = () => {

        this.setState({
            dialogOpen: false,
        });
    };

    render() {

        let { classes } = this.props;
        let { title, content, busy, dialogOpen } = this.state;

        let context = {
            busy: busy,
        };

        const valid = title && content && title.length > 9 && content.length > 9;
        const titleError = !title || title.length < 10;
        const contentError = !content || content.length < 10;

        return (
            <PushNotificationViewContext.Provider  value={context}>
                <div className={classes.root}>
                    <Container>
                        { busy && <LinearProgress />}
                        <Paper className={classes.paper}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h5">
                                        Broadcast Push Notification
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        error={titleError}
                                        id="title"
                                        name="title"
                                        value={title}
                                        onChange={this.handleTextChange}
                                        label="Title"
                                        fullWidth
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        error={contentError}
                                        id="content"
                                        name="content"
                                        value={content}
                                        onChange={this.handleTextChange}
                                        label="Content"
                                        fullWidth
                                        autoComplete='off'
                                        multiline
                                        rows="2"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className={classes.buttons}>
                                        <Button
                                            disabled={busy || !valid}
                                            variant="contained"
                                            color="primary"
                                            onClick={this.reviewMessageDialog}
                                            className={classes.button}>
                                            Send
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                    <Dialog

                        open={dialogOpen}
                        keepMounted
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Please review your announcement and continue if you are happy."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <p>{title}</p>
                                <p>{content}</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="primary">
                                <FormattedMessage id={'cancel'} defaultMessage={"Cancel"} />
                            </Button>
                            <Button onClick={this.handleSubmit} color="primary">
                                <FormattedMessage id={'continue'} defaultMessage={"Continue"} />
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </PushNotificationViewContext.Provider>
        );
    }
}

export default withStyles(styles)(PushNotification);
