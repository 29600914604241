import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {navigationTitleService} from "../../../../utils/title";
import {Button, Fab, Grid, LinearProgress, Paper, TextField, Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {DifferentialDiagnosisBloc, DifferentialDiagnosisBlocEvent} from "./differentialdiagnosis.bloc";
import DifferentialDiagnosisContext from "./context"
import ObservationInput from "./ObservationInput"
import {FormattedMessage} from "react-intl";
import {EntityRecognitionBlocEvent} from "../EntityRecognition/entityrecognition.bloc";
import {DataGrid} from "@material-ui/data-grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ResultGrid from "./InferenceResult";
import QuestionsResult from "./QuestionsResult";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        backgroundColor: "#303030",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
        paddingLeft: "4%",
        paddingRight: "4%"
    },
    gridTop: {
        display: "flex",
        flexDirection: "column",
    },
    gridBottom: {
        display: "flex",
        flexDirection: "column",
    },
    addButton: {
        display: "flex",
        marginTop: "4%",
        marginBottom: "2%",
        justifyContent: "space-between",
        alignItems: "center"
    },
    options: {
        margin: "1%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    predictionResults: {
        display: "flex",
    },
    inferenceResult: {
        flex: "2",
        padding: "2%",
    },
    questionResult: {
        flex: "1",
        padding: "2%",
    }

})

class DifferentialDiagnosis extends React.Component {

    bloc;

    constructor() {
        super();

        this.state = {
            loading: false,
            inputs: {},
            inputValues: {},
        }


        this.bloc = new DifferentialDiagnosisBloc()

        this.__handleBlocState = this.__handleBlocState.bind(this);
        this.__handleBlocEvent = this.__handleBlocEvent.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("Differential Diagnosis");

        this.stateSubscription = this.bloc.subscribeToState(this.__handleBlocState);
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleBlocEvent);
    }

    __handleBlocState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleBlocEvent = (event) => {
        const type = event.event;

        switch (type) {
            case DifferentialDiagnosisBlocEvent.INFERENCE_AVAILABLE:
            case DifferentialDiagnosisBlocEvent.INFERENCE_ERROR:
                this.setState({
                    loading: false,
                });
                break;
        }
    }

    onDelete() {

        const {inputValues, inputs} = this.state
        let index = Object.keys(inputs).length - 1

        delete inputValues[index]
        delete inputs[index]

        this.setState({
            inputValues: inputValues,
            inputs: inputs,
        })
    }

    onChange(name, event) {

        if (event == null) {
            return
        }

        let [index, type] = name.split('-')
        const {inputValues} = this.state

        if (type === "observation") {
            inputValues[index] = {
                ...inputValues[index],
                observation: event.name
            };
        } else if (type === "weight") {
            inputValues[index] = {
                ...inputValues[index],
                weight: event.target.value
            };
        }

        this.setState({inputValues})
    }

    handleAddObservation = () => {
        let inputs = this.state.inputs
        const index = Object.keys(inputs).length
        const name = `${index}-observation`;
        const weight = `${index}-weight`;
        inputs[index] = <ObservationInput name={name}
                                          weight={weight}
                                          defaultWeight={this.bloc.defaultWeight}
                                          onObsChange={this.onChange.bind(this, name)}
                                          onWeightChange={this.onChange.bind(this, weight)}/>
        this.setState({inputs});
    }

    handlePredict = () => {

        this.setState({
            loading: true
        })

        let data = this.state.inputValues;
        this.bloc.predictDifferentialDiagnosis(data)
    }

    render() {
        let {classes} = this.props;

        let {loading, inputValues, inputs, diagnosisPrediction} = this.state;

        let context = {
            bloc: this.bloc
        }

        let predictDisabled = Object.keys(inputValues).length === 0;

        return (
            <DifferentialDiagnosisContext.Provider value={context}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        {(loading) && <LinearProgress/>}
                        <Grid container className={classes.gridTop}>
                            <div className={classes.addButton}>
                                <Fab variant="extended" color="primary" aria-label="add"
                                     onClick={this.handleAddObservation}>
                                    <AddIcon/>
                                    ADD OBSERVATION
                                </Fab>
                                <div>
                                    <Button className={classes.submitButton} disabled={predictDisabled}
                                            onClick={this.handlePredict} type="submit" size="xlarge">
                                        <FormattedMessage id="predict-button" defaultMessage={`PREDICT`}/>
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.options}>
                                {
                                    Object.keys(inputs).map((key) => {
                                        return inputs[key]
                                    })
                                }
                                {
                                    Object.keys(inputs).length > 0 ?
                                        <IconButton aria-label="delete">
                                            <DeleteIcon onClick={() => {
                                                this.onDelete('delete')
                                            }}/>
                                        </IconButton> : (<></>)
                                }
                            </div>
                        </Grid>
                        <Grid container className={classes.gridBottom}>
                            {diagnosisPrediction &&
                            (<div className={classes.predictionResults}>
                                <div className={classes.inferenceResult}><ResultGrid
                                    diagnosisPrediction={diagnosisPrediction}/></div>
                                <div className={classes.questionResult}><QuestionsResult
                                    diagnosisPrediction={diagnosisPrediction}/></div>

                            </div>)}
                        < /Grid>
                    </Paper>
                </div>
            </DifferentialDiagnosisContext.Provider>
        )
    }

}

export default withStyles(styles)(DifferentialDiagnosis);