import {Promise} from "q";

export function determineSelection(fulltext, element) {

    return new Promise((resolve, reject) => {

        let text = "";
        let selection = undefined;
        let range = undefined;
        if (window.getSelection) {

            selection = window.getSelection();
            range = selection.getRangeAt(0);
            text = selection.toString();
        } else if (document.selection && document.selection.type !== "Control") {

            selection = window.selection;
            range = selection.createRange();
            text = range.text;
        }

        if (range && text.length >= 1 && !text.endsWith("\n")) {

            let oRect = range.getBoundingClientRect();
            let offset = resolveStartPositionInOriginalText(fulltext, text, range);

            // Handling the nested annotation
            if(range.startContainer !== range.endContainer){
                text = resolveSelectedTextWithAnnotation(range);
            }

            if(text.startsWith(" ")) {
                offset = offset + 1;
            }

            text = text.trim();

            resolve({
                selectedTextStart: offset,
                selectedText: text,
                popupPosition: {
                    left: oRect.left,
                    top: oRect.bottom
                },

            });
        } else {

            resolve({
                selectedTextStart: undefined,
                selectedText: undefined,
                popupPosition: undefined,

            });
        }
    });
}

function resolveSelectedTextWithAnnotation(range) {

    // Start the walk from the text that is being annotated to the end container of the highlighted texh
    let parentText = document.getElementsByClassName("annotationText")[0];
    let walk = document.createTreeWalker(parentText,NodeFilter.SHOW_TEXT,null,false);

    let result = "";
    let startNodeMet = false
    let endNodeMet = false
    let currentNode = walk.nextNode();
    while(currentNode) {

        // Get all highlighted texts except the annotation text
        if(!currentNode.parentElement.classList.contains("annotation")) {
            if(currentNode === range.startContainer) {
                // Only get the highlighted part of the text in the start container
                result += currentNode.nodeValue.slice(range.startOffset)
                startNodeMet = true
            }
            else if (currentNode === range.endContainer) {
                // Only get the highlighted part of the text in the end container
                result += currentNode.nodeValue.slice(0, range.endOffset)
                endNodeMet = true
            }
            else if (startNodeMet) {
                // Only get the the text included from after the start container, not from the beginning of the walk
                result += currentNode.nodeValue
            }
        }

        // Stop the loop if the end container has been encountered
        currentNode = endNodeMet ? null : walk.nextNode()
    }

    return result;
}

function resolveStartPositionInOriginalText(fullText, text, range) {

    let offset = range.startOffset;
    const startContainer = range.startContainer;

    let parentText = document.getElementsByClassName("annotationText")[0];
    let walk = document.createTreeWalker(parentText,NodeFilter.SHOW_TEXT,null,false);

    let adjustment = 0;
    let currentNode = walk.nextNode();
    while(currentNode) {
        if(currentNode !== startContainer ){
            if(!currentNode.parentElement.classList.contains("annotation")) {
                adjustment += currentNode.nodeValue.length
            }
            currentNode = walk.nextNode()
        }
        else {
            currentNode = null;
        }
    }

    offset += adjustment

    return offset;
}


export function getSelectedText(fulltext, element) {

    return new Promise((resolve, reject) => {

        let text = "";
        let selection = undefined;
        let range = undefined;
        if (window.getSelection) {

            selection = window.getSelection();
            range = selection.getRangeAt(0);
            text = selection.toString();
        } else if (document.selection && document.selection.type !== "Control") {

            selection = window.selection;
            range = selection.createRange();
            text = range.text;
        }

        if (range && text.length > 1) {


            let startRangeNode = range.startContainer;

            if (startRangeNode && startRangeNode.parentElement.classList.contains("annotationText")) {

                let oRect = range.getBoundingClientRect();
                resolve({
                    selectedText: text,
                    popupPosition: {
                        left: oRect.left,
                        top: oRect.bottom
                    },

                });
            } else {

                resolve({});
            }
        } else {

            resolve({
                selectedText: undefined,
                popupPosition: undefined,

            });
        }
    });
}
