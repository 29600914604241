import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import { format } from 'date-fns'
import { green } from '@material-ui/core/colors';
import {
    Container,
    TextField,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    IconButton,
    Button,
    LinearProgress,
    Typography,
    Grid
} from "@material-ui/core";
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from "@material-ui/icons/Close"
import {navigationTitleService} from "../../../../utils/title";
import {physicianApi} from "../../../../utils/services/physicians.api";
import {appointmentApi} from "../../../../utils/services/appointments.api";
import {notificationService} from "../../../../utils/notification";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        paddingTop: "24px",
        paddingBottom: "100px",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    paper: {
        padding: "24px 16px",
        overflow: "auto",
    },
    or: {
      textAlign: "center",
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    snackbarSuccess: {
        backgroundColor: green[600],
        padding: theme.spacing(0.5),
    },
    closeError: {
        padding: theme.spacing(0.5),
    },
});

class Scheduling extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: "GENERAL",
            busy: false,
            selectedDate: new Date(),
            duration: 15,
            timezone: 'America/Los_Angeles',
            physicianId: undefined,
            physicians: [],
            success: undefined,
            consumerEmail: "",
        };

        this.resetState.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("Scheduling");

        this.loadPhysicians();
    }

    resetState = (extras) => {
        this.setState({
            type: "GENERAL",
            busy: false,
            selectedDate: new Date(),
            duration: 15,
            timezone: 'America/Los_Angeles',
            conversationId: "",
            consumerEmail: "",
            consumerNote: "",
            appointmentNote: "",
            ...extras
        });
    };

    loadPhysicians = () => {

        physicianApi
            .list()
            .then((data) => {
                this.setState({
                    physicians: data.data.items,
                });
            });
    };

    handleTypeChange = (event) => {

        this.setState({
            type: event.target.value,
        });
    };

    handleTimezoneChange = (event) => {

        this.setState({
            timezone: event.target.value,
        });
    };

    handleDateChange = (event) => {

        this.setState({
            selectedDate: event,
        });
    };

    handlePhysicianChange = (event) => {

        this.setState({
            physicianId: event.target.value,
        });
    };


    handleTextChange = (event) => {

        let change = {};
        change[event.target.name] = event.target.value;
        this.setState(change);
    };

    handleSubmit = (event) => {

        const {
            busy,
            type,
            selectedDate,
            timezone,
            duration,
            conversationId,
            consumerEmail,
            physicianId,
            physicians,
            consumerNote,
            appointmentNote,
        } = this.state;

        if(!busy) {
            const staffMember = physicians.find(physician => physician.id === physicianId);

            const request = {
                type: type,
                fromDateTime: format(selectedDate, "yyyy-MM-dd'T'HH:mm"),
                duration: duration,
                timezone: timezone,
                conversationId: conversationId,
                consumerEmail: consumerEmail,
                staffFirstName: staffMember.firstName,
                staffLastName: staffMember.lastName,
                staffId: physicianId,
                consumerNote: consumerNote,
                appointmentNote: appointmentNote,
            };

            this.setState({
                busy: true
            });

            appointmentApi.schedule(request)
                .then((data) => {

                    if(data.status < 300) {
                        notificationService.update({
                            success: "Success the appointment has been scheduled.",
                        });

                    } else {
                        notificationService.update({
                            error: "Error trying to save. Try again. If the problem persists contact support about a server error.",
                        });
                    }
                    this.resetState({ });
                }).catch(reason => {

                    notificationService.update({
                        error: "Error trying to save. Try again. If the problem persists contact support. " + reason,
                    });

                    this.resetState({ });
            });
        }
    };

    render() {

        let {classes} = this.props;
        let {error,
            success,
            busy,
            type,
            selectedDate,
            timezone,
            duration,
            conversationId,
            consumerEmail,
            physicianId,
            physicians,
            consumerNote,
            appointmentNote} = this.state;

        return (
            <div className={classes.root}>

                <Container>
                    { busy && <LinearProgress />}
                    <Paper className={classes.paper}>


                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5">
                                    Schedule Appointment
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl className={classes.formControl}  fullWidth>
                                    <InputLabel id="appointment-type-label">
                                        Type
                                    </InputLabel>
                                    <Select
                                        labelId="appointment-type-label"
                                        id="appointment-type"
                                        value={type}
                                        onChange={this.handleTypeChange}
                                        fullWidth
                                    >
                                        <MenuItem value={"GENERAL"}>General</MenuItem>
                                        <MenuItem value={"HPI"}>Post HPI</MenuItem>
                                        <MenuItem value={"ONBOARDING"}>Onboarding</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker value={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    // disablePast
                                                    fullWidth
                                                    label="Date Time"
                                                    format="yyyy-MM-dd HH:mm"/>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    required
                                    id="duration"
                                    name="duration"
                                    value={duration}
                                    onChange={this.handleTextChange}
                                    label="Duration in minutes"
                                    type={'number'}
                                    fullWidth
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl className={classes.formControl}  fullWidth>
                                    <InputLabel id="appointment-timezone-label">
                                        Timezone
                                    </InputLabel>
                                    <Select
                                        labelId="appointment-timezone-label"
                                        id="appointment-timezone"
                                        value={timezone}
                                        onChange={this.handleTimezoneChange}
                                        fullWidth
                                    >
                                        <MenuItem value={'America/Los_Angeles'}>America/Los_Angeles</MenuItem>
                                        <MenuItem value={'Pacific/Auckland'}>Pacific/Auckland</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    id="consumerEmail"
                                    name="consumerEmail"
                                    value={consumerEmail}
                                    onChange={this.handleTextChange}
                                    label="Consumer Email Address"
                                    fullWidth
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <p className={classes.or}>OR</p>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    id="conversationId"
                                    name="conversationId"
                                    value={conversationId}
                                    onChange={this.handleTextChange}
                                    label="Conversation Id"
                                    fullWidth
                                    autoComplete='off'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.formControl}  fullWidth>
                                    <InputLabel id="physician-id-label">
                                        Care Team Member
                                    </InputLabel>
                                    <Select
                                        labelId="physician-id-label"
                                        id="physician-id"
                                        value={physicianId}
                                        onChange={this.handlePhysicianChange}
                                        fullWidth
                                    >
                                        {
                                            physicians.map((entry) => <MenuItem value={entry.id}>{ entry.firstName } { entry.lastName }</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    id="appointmentNote"
                                    name="appointmentNote"
                                    value={appointmentNote}
                                    onChange={this.handleTextChange}
                                    label="Appointment note"
                                    fullWidth
                                    autoComplete='off'
                                    multiline
                                    rows="8"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    id="consumerNote"
                                    name="consumerNote"
                                    value={consumerNote}
                                    onChange={this.handleTextChange}
                                    label="Consumer note"
                                    fullWidth
                                    autoComplete='off'
                                    multiline
                                    rows="8"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className={classes.buttons}>
                                    <Button
                                        disabled={busy}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                        className={classes.button}>
                                        Schedule
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(Scheduling);
