
export const AGENDA = "agenda";
export const CHIEF_COMPLAINTS = "chief_complaints";
// eslint-disable-next-line no-unused-vars
export const PRIORITY_COMPLAINT = "priority_complaint";
export const CHIEF_COMPLAINT = "current_complaint";
export const PATIENT_DESCRIPTION = "patient_description";
export const ONSET = "onset";
// eslint-disable-next-line no-unused-vars
export const SYMPTOM = "sx";
export const QUALITY = "quality";
export const QUALITY_VARIATION = "quality_variation";
export const ENCOUNTER_INTENT = "encounter_intent";
export const PAIN = "pain";
export const RADIATION = "radiation";
// eslint-disable-next-line no-unused-vars
export const TRIED_TREATMENT = "tried_treatment";
export const TREATMENT = "tx";
export const EVOLUTION = "evolution";
export const CONSISTENCY = "consistency";
export const EXCEPTION = "exception";

export const MENUS = {
    "intent": [
        {
            hotkey: '1',
            text: "Intent",
            code: "intent",
            next: "domain",
        },
    ],
    "enums_boolean": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
    ],
    "boolean": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
    ],
    "enums_boolean_optional": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
    ],
    "boolean_optional": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
    ],
    "enums_optional": [
        {
            hotkey: '1',
            text: "Unsure",
            code: "unsure",
        },
    ],
    "optional": [
        {
            hotkey: '1',
            text: "Unsure",
            code: "unsure",
        },
    ],
    "negated": [
        {
            hotkey: '1',
            text: "Deny",
            code: "deny",
        },
    ],
    "general": [
        {
            hotkey: 1,
            text: "Dx",
            code: "dx",
            next: "negated",
        },
        {
            hotkey: 2,
            text: "Sx",
            code: "sx",
            next: "negated",
        },
        {
            hotkey: 3,
            text: "Associated Sx",
            code: "associated_sx",
            next: "negated",
        },
        {
            hotkey: '4',
            text: "Quality",
            code: "quality",
            next: "quality_subset",
        },
        {
            hotkey: '5',
            text: "Radiation",
            code: "radiation",
            next: "radiation_location",
        },
        {
            hotkey: '6',
            text: "Time",
            code: "time",
        },
        {
            hotkey: 7,
            text: "Anatomical Location",
            code: "anatomical_location",
            next: "enums_boolean_optional",
        },
        {
            hotkey: 8,
            text: "Consistent",
            code: "consistent",
            next: "enums_boolean_optional",
        },
        {
            hotkey: 9,
            text: "Evolution",
            code: "evolution",
            next: "evolution_submenu",
        },
    ],
    "general_secondary": [
        {
            hotkey: '1',
            text: "Onset",
            code: "onset",
            next: "enums_optional",
        },
        {
            hotkey: '2',
            text: "Onset context",
            code: "onset_context",
            next: "enums_boolean",
        },
        {
            hotkey: '3',
            text: "Evolution",
            code: "evolution",
            next: "enums_boolean",
        },
        {
            hotkey: '4',
            text: "Provocation",
            code: "provocation",
            next: "enums_boolean",
        },
        {
            hotkey: '5',
            text: "Palliation",
            code: "palliation",
            next: "enums_boolean",
        },
    ],
    "general_other": [
        {
            hotkey: '1',
            text: "Other",
            code: "other",
        },
        {
            hotkey: '2',
            text: "Question",
            code: "question",
        },
    ],
    "general_toplevel": [
        {
            hotkey: 1,
            text: "Symptom",
            code: "sx",
        },
        {
            hotkey: 2,
            text: "Diagnosis",
            code: "dx",
        },
        {
            hotkey: 3,
            text: "Tried treatment",
            code: TRIED_TREATMENT,
        },
        {
            hotkey: 4,
            text: TREATMENT,
            code: TREATMENT,
            next: "enums_boolean",
        },
        {
            hotkey: 5,
            text: "Treatment effectiveness",
            code: "treatment_effectiveness",
            next: "enums_boolean",
        },
        {
            hotkey: 6,
            text: "Pain",
            code: "pain",
            next: "enums_boolean_optional",
        },
        {
            hotkey: 7,
            text: "Severity",
            code: "severity",
        },
    ],
    "evolution": [
        {
            hotkey: 1,
            text: "Evolution",
            code: "evolution",
            next: "evolution_submenu",
        },
        {
            hotkey: 2,
            text: "Evolution Quality",
            code: "evolution_quality",
        },
        {
            hotkey: 3,
            text: "Evolution Onset",
            code: "evolution_onset",
        },
    ],
    "consistency": [
        {
            hotkey: 1,
            text: "Consistent",
            code: "consistent",
        },
        {
            hotkey: 2,
            text: "Duration",
            code: "duration",
        },
        {
            hotkey: 3,
            text: "Frequency",
            code: "frequency",
        },
        {
            hotkey: 4,
            text: "Timing",
            code: "timing",
        },
    ],
    "evolution_submenu": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
        {
            hotkey: 4,
            text: "Evolution Quality",
            code: "evolution_quality",
        },
        {
            hotkey: 5,
            text: "Evolution Onset",
            code: "evolution_onset",
        },
    ],
    "time": [
        {
            hotkey: 1,
            text: "Frequency",
            code: "frequency",
        },
        {
            hotkey: 2,
            text: "Duration",
            code: "duration",
        },
        {
            hotkey: 3,
            text: "Timing",
            code: "timing",
        },
    ],
    "palliation": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
        {
            hotkey: 4,
            text: "Activities",
            code: "activities",
        },
        {
            hotkey: 5,
            text: "Situations",
            code: "situations",
        },
        {
            hotkey: 6,
            text: "Nothing",
            code: "nothing",
        },
    ],
    "type": [
        {
            hotkey: 1,
            text: "Chronic",
            code: "chronic",
            next: "problem",
        },
        {
            hotkey: 2,
            text: "Acute",
            code: "acute",
            next: "problem",
        },
        {
            hotkey: 3,
            text: "Injury",
            code: "injury",
        },
        {
            hotkey: 4,
            text: "Exposure",
            code: "exposure",
        },
        {
            hotkey: 5,
            text: "Behavioral",
            code: "mental",
        }
    ],
    "encounter_intent": [
        {
            hotkey: 1,
            text: "Acute medical",
            code: "acute_medical",
        },
        {
            hotkey: 2,
            text: "Persistent medical",
            code: "persistent_medical",
        },
        {
            hotkey: 3,
            text: "Dermatologic",
            code: "dermatologic",
        },
        {
            hotkey: 4,
            text: "Acute disability",
            code: "acute_disability",
        },
        {
            hotkey: 5,
            text: "Injury",
            code: "injury",
        },
        {
            hotkey: 6,
            text: "Exposure",
            code: "exposure",
        },
        {
            hotkey: 7,
            text: "Mental health",
            code: "mental",
        },
        {
            hotkey: 8,
            text: "Medication request",
            code: "medication_request",
        },
        {
            hotkey: 9,
            text: "Post-Discharge care",
            code: "medication_request",
        }
    ],
    "agenda": [
        {
            hotkey: 1,
            text: "Current complaint",
            code: "current_complaint",
            next: "current_complaint_sub",
        },
        {
            hotkey: 2,
            text: "Question",
            code: "question",
        },
        {
            hotkey: 3,
            text: "Other",
            code: "other",
        }
    ],
    "current_complaint_sub": [
        {
            hotkey: 1,
            text: "Symptom",
            code: "sx",
        },
        {
            hotkey: 2,
            text: "Diagnosis",
            code: "dx",
        },
    ],
    "problem": [
        {
            hotkey: 1,
            text: "Current complaint",
            code: "current_complaint",
            next: "general_toplevel",
        },
    ],
    "radiation": [
        {
            hotkey: 1,
            text: "Radiation",
            code: "radiation",
        },
        {
            hotkey: 2,
            text: "Radiate from",
            code: "radiation_from",
        },
        {
            hotkey: 3,
            text: "Radiate to",
            code: "radiation_to",
        },
    ],
    "quality": [
        {
            hotkey: 1,
            text: "Quality",
            code: "quality",
            next: "quality_subset",
        },
    ],
    "quality_variation": [
        {
            hotkey: 1,
            text: "Quality",
            code: "quality_variation",
            next: "quality_subset",
        },
    ],
    "quality_subset": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
        {
            hotkey: 4,
            text: "Looks like",
            code: "looks_like",
        },
        {
            hotkey: 5,
            text: "Tastes like",
            code: "tastes_like",
        },
        {
            hotkey: 6,
            text: "Smells like",
            code: "smells_like",
        },
        {
            hotkey: 7,
            text: "Feels like",
            code: "feels_like",
        },
        {
            hotkey: 8,
            text: "Sounds like",
            code: "sounds_like",
        },
    ],
    "exception": [
        {
            hotkey: 1,
            text: "Other",
            code: "other",
        },
        {
            hotkey: 2,
            text: "Disability",
            code: "disability",
        },
        {
            hotkey: 3,
            text: "Injury",
            code: "injury",
        },
        {
            hotkey: 4,
            text: "Exposure",
            code: "exposure",
        },
        {
            hotkey: 5,
            text: "Mental Health",
            code: "mental",
        }
    ],
    "problem_patient_description": [
        {
            hotkey: 1,
            text: "Patient description",
            code: "patient_description",
            next: "problem_patient_description_submenu",
        },
    ],
    "problem_patient_description_submenu": [
        {
            hotkey: 1,
            text: "Symptom",
            code: "sx",
        },
    ],
    "pain": [
        {
            hotkey: 1,
            text: "Pain",
            code: "pain",
        },
        {
            hotkey: 2,
            text: "Severity",
            code: "severity",
        },
        {
            hotkey: 3,
            text: "Radiation",
            code: "radiation",
        },
        {
            hotkey: 4,
            text: "Radiate from",
            code: "from_anatomical_location",
        },
        {
            hotkey: 5,
            text: "Radiate to",
            code: "to_anatomical_location",
        },
    ],
    "onset": [
        {
            hotkey: 1,
            text: "Onset",
            code: "onset",
        },
        {
            hotkey: 2,
            text: "Onset context",
            code: "onset_context",
        },
    ],
    "radiation_location": [
        {
            hotkey: '1',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '2',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '3',
            text: "Unsure",
            code: "unsure",
        },
        {
            hotkey: 4,
            text: "From location",
            code: "radiation_from",
        },
        {
            hotkey: 5,
            text: "To location",
            code: "radiation_to",
        },
    ],
    TREATMENT: [
        {
            hotkey: 1,
            text: TREATMENT,
            code: TREATMENT,
        },
        {
            hotkey: 2,
            text: "Treatment effectiveness",
            code: "treatment_effectiveness",
        },
        {
            hotkey: 3,
            text: "Tried treatment",
            code: TRIED_TREATMENT,
        }
    ],
    "persistent": [
        {
            hotkey: 1,
            text: "Persistent onset",
            code: "persistent_onset",
        }
    ],
    "symptom": [
        {
            hotkey: 1,
            text: "Onset",
            code: "onset",
        },
        {
            hotkey: 2,
            text: "Provocation",
            code: "provocation",
        },
        {
            hotkey: 3,
            text: "Palliation",
            code: "palliation",
            next: "palliation",
        },
        {
            hotkey: 4,
            text: "Quality",
            code: "quality",
        },
        {
            hotkey: 5,
            text: "Radiation",
            code: "radiation",
            next: "radiation_location"
        },
        {
            hotkey: 6,
            text: "Severity",
            code: "severity",
        },
        {
            hotkey: 7,
            text: "Time",
            code: "time",
        },
        {
            hotkey: 8,
            text: "Location",
            code: "location",
        }
    ],
    "negation": [
        {
            hotkey: 1,
            text: "Negation",
            code: "neg",
        }
    ],
    "ros_first": [
        {
            hotkey: 1,
            text: "Constitutional",
            code: "ROS_Constitutional"
        },
        {
            hotkey: 2,
            text: "ENT",
            code: "ROS_ENT"
        },
        {
            hotkey: 3,
            text: "Eyes",
            code: "ROS_Eyes"
        },
        {
            hotkey: 4,
            text: "Cardiovascular",
            code: "ROS_Cardiovascular"
        },
        {
            hotkey: 5,
            text: "Pulmonary",
            code: "ROS_Pulmonary"
        },
        {
            hotkey: 6,
            text: "Gastrointestinal",
            code: "ROS_Gastrointestinal"
        },
        {
            hotkey: 7,
            text: "Genitourinary",
            code: "ROS_Genitourinary"
        },
        {
            hotkey: 8,
            text: "Musculoskeletal",
            code: "ROS_Musculoskeletal"
        },
        {
            hotkey: 9,
            text: "Integument",
            code: "ROS_Integument"
        },
    ],
    "ros_second": [
        {
            hotkey: 1,
            text: "Neurologic",
            code: "ROS_Neurologic"
        },
        {
            hotkey: 2,
            text: "Psychiatric",
            code: "ROS_Psychiatric"
        },
        {
            hotkey: 3,
            text: "Hematologic",
            code: "ROS_Hematologic"
        },
        {
            hotkey: 4,
            text: "Endocrine",
            code: "ROS_Endocrine"
        },
        {
            hotkey: 5,
            text: "Immunologic",
            code: "ROS_Immunologic"
        }
    ],
    "ros_step": (step) => {

        return [
            {
                hotkey: 1,
                text: formatItem(step),
                code: `ROS_${step}`,
            },
        ];
    },


    "new_general": [
        {
            hotkey: 1,
            text: "Onset",
            code: "onset",
            next: "boolean",
        },
        {
            hotkey: 2,
            text: "Provocation",
            code: "provocation",
            next: "boolean",
        },
        {
            hotkey: 3,
            text: "Palliation",
            code: "palliation",
            next: "palliation",
        },
        {
            hotkey: 4,
            text: "Evolution",
            code: "evolution",
            next: "evolution_submenu",
        }
    ],
    "new_general_2": [
        {
            hotkey: 1,
            text: "Diagnosis",
            code: "dx",
            next: "boolean",
        },
        {
            hotkey: 2,
            text: "Symptom",
            code: "sx",
            next: "boolean",
        },
        {
            hotkey: 3,
            text: "Associated Sx",
            code: "associated_sx",
            next: "boolean",
        },
        {
            hotkey: 4,
            text: "Quality",
            code: "quality",
            next: "quality_subset",
        }
    ],
    "new_general_3": [
        {
            hotkey: 1,
            text: "Pain",
            code: "pain",
            next: "boolean",
        },
        {
            hotkey: 2,
            text: "Anatomical Location",
            code: "anatomical_location",
            next: "anatomical_location",
        },
        {
            hotkey: 3,
            text: "Severity",
            code: "severity",
            next: "boolean",
        },
        {
            hotkey: 4,
            text: "Radiation",
            code: "radiation",
            next: "radiation_location",
        }
    ],
    "new_general_4": [
        {
            hotkey: 1,
            text: "Tried treatment",
            code: TRIED_TREATMENT,
            next: "boolean",
        },
        {
            hotkey: 2,
            text: TREATMENT,
            code: TREATMENT,
            next: "boolean",
        },
        {
            hotkey: 3,
            text: "Treatment effectiveness",
            code: "treatment_effectiveness",
            next: "boolean",
        },
        {
            hotkey: 4,
            text: "Medication",
            code: "rx",
            next: "boolean",
        }
    ],

    "anatomical_location": [
        {
            hotkey: '1',
            text: "Has laterality",
            code: "anatomic_location_has_laterality",
        },
        {
            hotkey: '2',
            text: "Endorse",
            code: "endorse",
        },
        {
            hotkey: '3',
            text: "Deny",
            code: "deny",
        },
        {
            hotkey: '4',
            text: "Unsure",
            code: "unsure",
        },
    ],
};

export function resolveBackgroundColor(code) {

    switch (`${code}`) {
        case "2":
            return '#de02dd';
        case "3":
            return '#bd02cb';
        case "4":
            return '#ac02c3';
        case "5":
            return '#9b02ba';
        case "6":
            return '#8b01b1';
        case "7":
            return '#7a01a9';
        case "8":
            return '#590197';
        case "9":
            return '#49008f';
        case "0":
            return '#380086';
        case "`":
            return '#ff03ee';
        case "1":
        default:
            return '#ff03ee';
    }
}

export function resolveSecondLevel(dynamicMenu, overall, target, selected, targetSpecification, domains, specifications) {

    let menu = [];

    if(target === '__dynamic__') {

        return dynamicMenu[selected];
    }

    if(target === "enums") {

        const specification = specifications.filter((_specification) => _specification.code === selected);

        if(specification.length > 0) {

            specification[0].enums.forEach((_enum, index) => {
                menu.push({
                    hotkey: index + 1,
                    text: formatItem(_enum),
                    code: _enum,
                })
            });
        }

        return menu;
    }

    if(target === "domain") {

        if(domains) {
            domains.forEach((domain, index) => {

                let item = {
                    hotkey: index + 1,
                    text: formatItem(domain.code),
                    code: domain.code,
                };

                if(domain.enums) {
                    item['next'] = "domain_enums";
                }

                menu.push(item);
            });
        }

        return menu;
    }

    if(target === "domain_enums") {

        const domain = domains.filter((_domain) => _domain.code === selected);

        if(domain && domain.length > 0) {
            domain[0].enums.forEach((_enum, index) => {
                menu.push({
                    hotkey: index + 1,
                    text: formatItem(_enum),
                    code: _enum,
                })
            });
        }

        return menu;
    }

    if(target.startsWith('enums_')) {

        let menu = [...MENUS[target]];
        const _length = menu.length + 1;

        targetSpecification.enums.forEach((_enum, index) => {
            menu.push({
                hotkey: index + _length,
                text: formatItem(_enum),
                code: _enum,
            })
        });

        return menu;

    }

    return MENUS[target];
}

function buildOverride(menuOverride) {

    let menu = undefined;

    if(menuOverride) {

        let items = [];

        const keys = Object.keys(menuOverride.menu);

        for(let i =0; i < keys.length; i++) {

            const item = menuOverride.menu[`${keys[i]}`];
            const children = Object.keys(item);

            items.push({
                hotkey: `${i}`,
                text: formatItem(keys[i]),
                code: keys[i],
                next: children && children.length > 0 ? '__dynamic__' : undefined,
            });
        }

        menu =  { items: items };
    }

    return menu;
}

export function resolveEntries(menuOverride, target, targetSpecification, related, domainSlotSpecifications, includeDefault) {

    let menu = [];
    let includeToplevel = true;

    const firstMenu = buildOverride(menuOverride);

    if(firstMenu) {
        menu.push(firstMenu);
        includeToplevel = false;
    }

    if(includeDefault !== false) {

        if (related) {

            let topLevel = [];
            let domainLevel = [];

            let candidates = domainSlotSpecifications.filter((specification) => specification.enums.length > 0 && specification.code === target)

            let item = {
                hotkey: 1,
                text: formatItem(target),
                code: target,
            };

            if (target === "intent") {
                item['next'] = "domain";
            } else if (targetSpecification) {

                let _nextArr = []

                if (targetSpecification.enums && targetSpecification.enums.length > 0) {
                    _nextArr.push("enums");
                }

                if (targetSpecification.data_type === "boolean") {
                    _nextArr.push("boolean");
                }

                if (["0..1", "0..*"].includes(targetSpecification.multiplicity)) {
                    _nextArr.push("optional");
                }

                if (_nextArr.length > 0) {
                    item['next'] = _nextArr.join("_");
                }
            } else if (candidates.length > 0) {
                item['next'] = "enums";
            }

            topLevel.push(item);

            const relatedCandidates = related.filter((_related) => domainSlotSpecifications.filter((_specification) =>  _specification.code===_related.code).length === 0);

            for (let count = 0; count < relatedCandidates.length; count++) {

                const _item = relatedCandidates[count];

                item = {
                    hotkey: 2 + count,
                    text: formatItem(_item.code),
                    code: _item.code,
                };

                if (_item.code === "intent") {
                    item['next'] = "domain";
                }

                topLevel.push(item);
            }

            if (domainSlotSpecifications && domainSlotSpecifications.length > 0) {

                let isTopLevel = relatedCandidates.length + domainSlotSpecifications.length < 10;
                let position = 1;
                if (isTopLevel) position = position + relatedCandidates.length;

                let _index = 0;

                for (let count = 0; count < domainSlotSpecifications.length; count++) {

                    const _item = domainSlotSpecifications[count];

                    if (isTopLevel && topLevel.filter(value => value.code === _item.code).length > 0) {
                        continue;
                    }

                    _index++;

                    item = {
                        hotkey: position + _index,
                        text: formatItem(_item.code),
                        code: _item.code,
                    };

                    if(_item.data_type === "boolean") {
                        item['next'] = "boolean";
                    } else if (_item.enums.length > 0) {
                        item['next'] = "enums";
                    }

                    if (isTopLevel) {
                        topLevel.push(item);
                    } else {
                        domainLevel.push(item);
                    }
                }
            }

            if (includeToplevel) {
                menu.push({items: [...topLevel]});
            }

            if (domainLevel.length > 0) {
                menu.push({items: [...domainLevel]});
            }

        } else if (target) {
            if (target.toLowerCase().indexOf("ask") > -1) {

                menu.push({items: [...MENUS["ros_step"](target.replace("Ask", ""))]});
            } else {

                let targetEntry = {
                    hotkey: '1',
                    text: formatItem(target),
                    code: target,
                };

                switch (target) {
                    case EVOLUTION:
                        menu.push({items: [...MENUS[EVOLUTION]]});
                        break;
                    case TRIED_TREATMENT:
                    case TREATMENT:
                        menu.push({items: [...MENUS[TREATMENT]]});
                        break;
                    case PAIN:
                        menu.push({items: [...MENUS[PAIN]]});
                        break;
                    case QUALITY:
                        menu.push({items: [...MENUS[QUALITY]]});
                        break;
                    case EXCEPTION:
                        menu.push({items: [...MENUS[EXCEPTION]]});
                        break;
                    case CHIEF_COMPLAINT:
                    case AGENDA:
                        menu.push({items: [...MENUS[AGENDA]]});
                        break;
                    case CHIEF_COMPLAINTS:
                        menu.push({items: [...MENUS[AGENDA]]});
                        break;
                    case CONSISTENCY:
                        menu.push({items: [...MENUS[CONSISTENCY]]});
                        break;
                    case PATIENT_DESCRIPTION:
                        menu.push({items: [...MENUS["problem_patient_description"]]});
                        break;
                    case ONSET:
                        menu.push({items: [...MENUS[ONSET]]});
                        break;
                    case RADIATION:
                        menu.push({items: [...MENUS[RADIATION]]});
                        break;
                    case QUALITY_VARIATION:
                        menu.push({items: [...MENUS[QUALITY_VARIATION]]});
                        break;
                    case ENCOUNTER_INTENT:
                        menu.push({items: [...MENUS[ENCOUNTER_INTENT]]});
                        break;
                    default:
                        menu.push({items: [targetEntry]});
                }
            }
        }

        menu.push({
            items: [...MENUS["new_general"]],
        });
        menu.push({
            items: [...MENUS["new_general_2"]],
        });
        menu.push({
            items: [...MENUS["new_general_3"]],
        });
        menu.push({
            items: [...MENUS["new_general_4"]],
        });
    }

    return menu;
}

export function formatItem(code) {

    let formatted = code.replace(/_/g, ' ');
    return formatted.charAt(0).toUpperCase() + formatted.slice(1)
}
