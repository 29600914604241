import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
    Button,
    Card, CardActions,
    CardContent,
    CardHeader, Divider,
    FormControl,
    Grid, IconButton, Input,
    InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText,
    Paper, TextField,
    Typography
} from "@material-ui/core";

import {navigationTitleService} from "../../../../../../../utils/title";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import AsyncJobContext from "../../../context";
import PublishIcon from "@material-ui/icons/Publish";
import NormaliseInput from "../AnnotateNormaliseComponent/NormaliseInput";
import {NormaliseBloc, NormaliseBlocEvent} from "../AnnotateNormaliseComponent/normalise.bloc";
import CriticalButton from "../../../../../../shared/CriticalButton";
import ApprovalInput from "./ApprovalInput";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import {DeleteOutline} from "@material-ui/icons";
import {forEach} from "react-cytoscapejs/src/json";



const styles = theme => ({
    root: {
        width: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    },
    dropdownOption: {
        padding: "1%",
        width: "300px"
    },

});
class AnnotateApprovalComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.taskBloc = props.bloc;
        this.bloc = new NormaliseBloc(this.taskBloc.subject.value.task)

        this.state = {
        };
    }


    componentDidMount() {
        super.componentDidMount();
        navigationTitleService.update("Annotation Approval Job");
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => {

        this.setState({
            busy: true,
        });
        const { normalisedEntities, deletedEntities } = this.state

        normalisedEntities.filter(e => e.checked).forEach(e => {
            delete e.conceptName
            delete e.conceptId
        })

        const data = {
            data: {
                approved: {
                    entities: normalisedEntities.filter(e => !e.added && !e.modified).map(e =>
                        ({
                            id: e.id,
                            conceptId: e.conceptId,
                            conceptName: e.conceptName,
                            category: e.category,
                            extract: e.extract,
                            state: "APPROVED",
                        }))
                },
                added: {
                    entities: normalisedEntities.filter(e => e.added).map(e =>
                        ({
                            id: e.id,
                            conceptId: e.conceptId,
                            conceptName: e.conceptName,
                            category: e.category,
                            extract: e.extract,
                            state: "APPROVED",
                        }))
                },
                modified: {
                    entities: normalisedEntities.filter(e => e.modified && !e.added).map(e =>
                        ({
                            id: e.id,
                            conceptId: e.conceptId,
                            conceptName: e.conceptName,
                            category: e.category,
                            extract: e.extract,
                            state: "APPROVED",
                        }))
                },
                deleted: {
                    entities: deletedEntities.filter(e => !e.added).map(e =>
                        ({
                            id: e.id,
                            conceptId: e.conceptId,
                            conceptName: e.conceptName,
                            category: e.category,
                            extract: e.extract,
                            state: "DELETED",
                        }))
                }
            }
        }

        this.taskBloc.submitTask(data)

    };

    __addAnnotation = () => {
        this.bloc.createAnnotation()
    }

    __discard = (message) => this.taskBloc.discardTask(message)

    render() {

        const {classes} = this.props;
        const {initialising, task, readyToPublish, normalisedEntities} = this.state;
        

        if (initialising) return (<LinearProgress/>);

        return task ? (<div className={classes.root}>
            <Grid className={classes.container} container>
                <Grid item xs={"12"}>
                    <Typography className={classes.header} variant={"h4"}>Please review the normalised annotations
                        below</Typography>
                </Grid>
                <Grid item xs={"12"}>
                    <Typography className={classes.context}
                                variant={"body1"} color={"primary"}>{`Asked: ${task.data.context}` || "No text found"}</Typography>
                </Grid>
                <Grid item xs={"12"}>
                    <Typography className={classes.context}
                                variant={"h5"}>{task.data.text || "No text found"}</Typography>
                </Grid>
                <Grid item xs={"12"}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <List dense={true}>
                                {normalisedEntities.map(entity => {
                                    return <ApprovalInput bloc={this.bloc} entity={entity}/>
                                })}
                                <Divider />
                            </List>
                        </CardContent>
                        <CardActions>
                            <CancelWithReasonButton startIcon={ <DeleteOutline /> } color={"secondary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                            <span className={classes.fill}/>
                            <Button onClick={this.__addAnnotation} variant={"outlined"} color="primary">
                                <AddIcon/> Add Annotation
                            </Button>
                            <CriticalButton disabled={!readyToPublish} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>) : (<></>)
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<AnnotateApprovalComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

