import {Typography} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import React from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    resultTitle: {
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    questionsResult: {
        width: '100%',
        paddingBottom: "2%",
    }
})

class InferenceResult extends React.Component {

    render() {

        let {classes, diagnosisPrediction} = this.props;

        let columns = [
            {field: 'observation', headerName: 'Observation', flex: 1},
            {field: 'relevance', headerName: 'Relevance', flex: 1},
        ];

        let rows = diagnosisPrediction.differentiatingObservations
            .map((item, idx) => {
                return {
                    id: idx,
                    observation: item.observation,
                    relevance: item.relevance.toFixed(2),
                }
            })

        return (<div>
            <Typography className={classes.resultTitle} variant='h4' align='left'>
                Differentiating Observations
            </Typography>
            <div className={classes.questionsResult}>
                <DataGrid rows={rows} columns={columns} autoHeight={true} disableSelectionOnClick/>
            </div>
        </div>);
    }
}

export default withStyles(styles)(InferenceResult);