import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import { Bloc } from './bloc';

import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputAdornment, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import AutocompleteField from "./AutocompleteField";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardHeader: {

    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    }
});

class AddJobComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = new Bloc(props.context.bloc, {
            pipelineId: props.pipelineId,
        })

        this.state = {
            initialising: false,
        }
    }

    componentWillMount() {
        navigationTitleService.update("Add Async Job");
        super.componentDidMount();
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => this.bloc.addJob(this.state.job.pipeline);

    render() {

        const { classes } = this.props;
        const { initialised, job } = this.state;

        if(!initialised) return (<LinearProgress />);

        const jobType = job.pipeline === "condition.observations" ? "relationship" : "complication"

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Typography className={classes.header} variant={"h4"}>Add a new condition to {jobType} pipeline</Typography>
                    </Grid>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardContent>
                                <AutocompleteField id="condition"
                                                   label="Condition"
                                                   defaultValue=""
                                                   setSelectedValue={this.bloc.onDropdownChange.bind(this, job.condition?.id)}
                                                   getOptions={this.bloc.getConceptOptions} />
                            </CardContent>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="sourceMaterial">Source material</InputLabel>
                                    <Input
                                        id="sourceMaterial"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={job.sourceMaterial}
                                        onChange={this.bloc.setJobProperty('sourceMaterial')}
                                    />
                                </FormControl>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="notes">Notes</InputLabel>
                                    <Input
                                        id="notes"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={job.notes}
                                        onChange={this.bloc.setJobProperty('notes')}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <CriticalButton disabled={!job.sourceMaterial || !job.condition} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<AddJobComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));
