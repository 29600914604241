import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {LinearProgress} from "@material-ui/core";

import {navigationTitleService} from "../../../../utils/title";
import AsyncJobContext from "./context";
import DecodedComponent from "../../../shared/DecodedComponent";
import {AsyncJobBlocEvent, Bloc} from "./bloc";

import {Route, Switch, withRouter} from "react-router-dom";
import Dashboard from "./Dashboard";
import Tasks from "./Tasks";
import AddTask from "./AddTask";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
        padding: "30px 25px",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },

});

class AsyncJobs extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = new Bloc();

        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();

        navigationTitleService.update("Asynchronous Jobs");
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleEvent = (event) => {
        const { type, data } = event;

        switch (type) {
            case AsyncJobBlocEvent.TASK_POPPED:
            {
                this.props.history.push(`/jobs/async/pipelines/${data.lock.pipeline}/steps/${data.lock.step}/tasks/${data.id}`);
                break;
            }
            case AsyncJobBlocEvent.NAVIGATE_TO:
            {
                this.props.history.push(data.next);
            }
        }
    }


    render() {

        let { classes } = this.props;
        let { initialising, pipelines } = this.state;

        let context = {
            bloc: this.bloc,
        };

        if(initialising) return <LinearProgress />;

        return (
            <div className={classes.root}>
               <AsyncJobContext.Provider value={context}>
                   <Switch>
                       <Route path="/jobs/async/pipelines/:pipelineId/steps/:step/tasks/:taskId" exact component={ Tasks } />
                       <Route path="/jobs/async/pipelines/:pipelineId/_add_task" exact component={ AddTask } />
                       <Route path="/jobs/async" exact component={ Dashboard } />
                   </Switch>
               </AsyncJobContext.Provider>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(AsyncJobs));
