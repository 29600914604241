import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";
import {Button, IconButton, LinearProgress, Paper, Snackbar} from "@material-ui/core";

import PlayCircleOutlineOutlined from "@material-ui/icons/PlayCircleOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close"

import {
    Headers,
    EVENT,
    EXCEPTION_SESSION_JOB, extractMetaData, generateAnswerQuinn,
    TECHNICIAN_SESSION_CONVERSATION_ADDED,
    TECHNICIAN_SESSION_CONVERSATION_ENDED,
    TECHNICIAN_SESSION_CONVERSATION_HANDEDBACK,
    TECHNICIAN_SESSION_CONVERSATION_MESSAGE_ADDED,
    TECHNICIAN_SESSION_CONVERSATION_SWITCHED
} from "../../../../utils/events";

import {STATE_ACTIVE, STATE_PAUSED, STATE_WAITING} from "../state";
import InformationScreen from "../InformationScreen";
import ConnectionStatus from "../../../shared/ConnectionStatus";
import {exceptionSessionWebsocket} from "../../../../utils/services/session.websocket";
import Conversation from "./Conversation";
import MonitoredConversations from "./MonitoredConversations";
import ExceptionContext from "./context";
import {navigationTitleService} from "../../../../utils/title";
import {consumerApi} from "../../../../utils/services/consumers.api";
import {playSound} from "../../../../utils/sounds";
import {sessionsApi} from "../../../../utils/services/sessions.api";
import {
    MESSAGE_ANNOTATION_EVENTS,
    messageAnnotationEventService
} from "../component/MessageAnnotationPanel/service/message.annotation.event.service";

const sidebarWidth = "72px";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paperFullscreen: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#000",
        zIndex: "1200",
    },
    paper: {
        overflowY: "hidden",
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100% - 90px)",
        height: "calc(100% - 90px)",
        maxHeight: "calc(100% - 90px)",
    },
    paperContent: {
        overflowY: "auto",
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%",
        margin: "auto",
    },
    paperContentFullscreen: {
        overflowY: "auto",
        maxHeight: "calc(100% - 36px)",
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    conversation: {
        maxHeight: "100%",
        height: "100%",
        width: "calc(100% - " + sidebarWidth + ")",
    },

    patientQueue: {
        height: "100%",
        width: sidebarWidth,
    },
    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },
});

class ExceptionJobs extends Component {

    handleUpdateSubscription;
    handleStateUpdateSubscription;
    handleErrorUpdateSubscription;
    handleConnectionUpdateSubscription;

    sessionMonitor;

    _annotationEventSubscription;

    constructor(props) {
        super(props);

        this.state = {
            activeConversation: {},
            conversations: [],
            users: [],
            busy: false,
            metadata: undefined,
            message: undefined,
            quinnAsk: undefined,
            fullscreen: false,
            state: STATE_PAUSED,
            pauseOnNext: false,
            connectionStatus: "off",
            sessionCount: {
                federated: "0",
                exception: "0",
            },
        };

        this.handleUpdate.bind(this);
        this.handleUpdateSubscription = exceptionSessionWebsocket.registerJobCallback(this.handleUpdate);

        this.handleStateUpdate.bind(this);
        this.handleStateUpdateSubscription = exceptionSessionWebsocket.registerStateCallback(this.handleStateUpdate);

        this.handleErrorUpdate.bind(this);
        this.handleErrorUpdateSubscription = exceptionSessionWebsocket.registerExceptionCallback(this.handleErrorUpdate);

        this.handleConnectionUpdate.bind(this);
        this.handleConnectionUpdateSubscription = exceptionSessionWebsocket.registerConnectionCallback(this.handleConnectionUpdate);

        this.setConversation.bind(this);
        this.endConversation.bind(this);
        this.sendManualResponse.bind(this);
        this.handbackConversation.bind(this);
        this.markBusy.bind(this);

        this.submitResult.bind(this);
        this.submitEvent.bind(this);

        this.initSessionsCountIndicator();

        this._handleMessageAnnotationEvents = this._handleMessageAnnotationEvents.bind(this);

        this._initListenToMessageAnnotationEvents = this._initListenToMessageAnnotationEvents.bind(this);
        this._initListenToMessageAnnotationEvents();
    }

    _initListenToMessageAnnotationEvents = (event) => {

        this._annotationEventSubscription = messageAnnotationEventService.registerStateCallback(this._handleMessageAnnotationEvents);
    }

    _handleMessageAnnotationEvents = (_event) => {

        const {quinnAsk} = this.state;

        const { type, data } = _event;

        let newState = {};

        switch (type) {
            case MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED:
            case MESSAGE_ANNOTATION_EVENTS.MESSAGE_DISCARD:
            {
                newState.busy = true;
                this._answerQuinn(quinnAsk, data);
            }
                break;
        }

        this.setState({
            ...newState,
        });
    }


    _answerQuinn = (event, data) => {

        generateAnswerQuinn(event[Headers.TENANT_ID], data)
            .then(_event => {
                exceptionSessionWebsocket.send(_event);
            });
    }

    initSessionsCountIndicator() {

        this.sessionMonitor = setInterval(() => {

            sessionsApi.getSessionCount()
                .then((data) => {
                    if(data.status === 200) {

                        const _data = data.data;

                        this.setState({
                            sessionCount: {
                                federated: `${_data.federated}`,
                                exception: `${_data.exception}`,
                            },
                        });
                    } else {

                        this.setState({
                            sessionCount: {
                                federated: "unknown",
                                exception: "unknown",
                            },
                        });
                    }
                });

        }, 20000);
    }

    componentWillMount() {

        navigationTitleService.update("Exception Jobs");
    }

    componentWillUnmount() {

        this.handleUpdateSubscription.unsubscribe();
        this.handleStateUpdateSubscription.unsubscribe();
        this.handleErrorUpdateSubscription.unsubscribe();
        this.handleConnectionUpdateSubscription.unsubscribe();
        exceptionSessionWebsocket.close();

        if(this.sessionMonitor) {
            clearInterval(this.sessionMonitor);
        }

        if(this._annotationEventSubscription) {
            this._annotationEventSubscription.unsubscribe();
        }
    }

    setConversation = (data) => {

        let $this = this;

        return (event) => {

            let { activeConversation } = $this.state;

            if(!activeConversation || activeConversation.conversationId !== data.conversationId) {
                exceptionSessionWebsocket.switchConversation(data.conversationId);
            }
        }
    };

    handleStateUpdate = (newState) => {

        const {message, quinnAsk} = this.state;

        if(newState === STATE_WAITING) {
            this.setState({
                busy: false,
                state: newState,
                message: undefined,
                metadata: undefined,
                quinnAsk: undefined,
            });

        } else if(!message && !quinnAsk) {
            this.setState({
                busy: false,
                state: newState,
            });
        }
    };

    handleUpdate = (data) => {

        let response = {};

        // eslint-disable-next-line default-case
        switch (data.type) {
            case TECHNICIAN_SESSION_CONVERSATION_ADDED:
                playSound();
                response = this._handleSessionConversationAdded(extractMetaData(data));
                break;
            case TECHNICIAN_SESSION_CONVERSATION_SWITCHED:
                response = this._handleConversationSwitched(data);
                response = {
                    state: STATE_ACTIVE,
                    ...response,
                };
                break;
            case TECHNICIAN_SESSION_CONVERSATION_ENDED:
                response = this._handleSessionConversationEnded(data);
                break;
            case TECHNICIAN_SESSION_CONVERSATION_HANDEDBACK:
                response = this._handleSessionConversationHandedBack(data);
                break;
            case TECHNICIAN_SESSION_CONVERSATION_MESSAGE_ADDED:
                playSound();
                response = this._handleExceptionMessageAddedJob(extractMetaData(data));
                break;
            case EXCEPTION_SESSION_JOB:
                response = this._handleExceptionJob(data);
                response = {
                    state: STATE_ACTIVE,
                    ...response,
                };
                break;
            default: {
                if(EVENT.inbound[data.source] && EVENT.inbound[data.source][data.type]) {
                    switch (data.type) {
                        case "quinn.action.ask_human":
                            response = this._handleQuinnAsk(data);
                            response = {
                                state: STATE_ACTIVE,
                                ...response,
                            };
                    }
                }
            }
        }

        let newState = {
            busy: false,
            ...response,
        };

        this.setState(newState)
    };

    _handleQuinnAsk = (data) => {

        let { activeConversation, conversations, busy, quinnAsk } = this.state;

        let metadata = extractMetaData(data);

        let response = {};

        if(busy || this.state.state === STATE_WAITING || !quinnAsk) {

            if (metadata[Headers.CONVERSATION_ID] === activeConversation.conversationId) {

                activeConversation.messageId = metadata[Headers.MESSAGE_ID];

                response = {
                    metadata: metadata,
                    activeConversation: activeConversation,
                    message: data.data.lastTurn.content,
                    quinnAsk: data,
                }
            }
        }

        for(let index in conversations) {

            if(conversations[index].conversationId === metadata[Headers.CONVERSATION_ID] &&
                activeConversation.conversationId !== metadata[Headers.CONVERSATION_ID] ) {

                conversations[index].read = undefined;
            }
        }

        return {
            conversations: conversations,
            ...response,
        };
    }

    _handleExceptionMessageAddedJob = (data) => {

        let { activeConversation, conversations } = this.state;

        for(let index in conversations) {

            if(conversations[index].conversationId === data[Headers.CONVERSATION_ID] &&
                activeConversation.conversationId !== data[Headers.CONVERSATION_ID] ) {

                conversations[index].read = undefined;
            }
        }

        return {
            conversations: conversations,
        };
    };

    _handleExceptionJob = (data) => {

        let { activeConversation, conversations, busy, message } = this.state;

        let response = {};
        let metadata = extractMetaData(data);

        if(busy || this.state.state === STATE_WAITING || !message || ( metadata[Headers.INTENT_SLOT_CODE] === "complete" && metadata[Headers.CONVERSATION_GOAL] === "exception" )) {

            if (metadata[Headers.CONVERSATION_ID] === activeConversation.conversationId) {

                response = {
                    metadata: metadata,
                    message: data.data.content,
                }
            }
        }

        for(let index in conversations) {

            if(conversations[index].conversationId === metadata[Headers.CONVERSATION_ID] &&
                activeConversation.conversationId !== metadata[Headers.CONVERSATION_ID] ) {

                conversations[index].read = undefined;
            }
        }

        return {
            conversations: conversations,
            ...response,
        };
    };


    _handleSessionConversationAdded = (data) => {

        let {conversations} = this.state;

        const current = {
            userId: data[Headers.USER_ID],
            conversationId: data[Headers.CONVERSATION_ID],
            botConversationId: data[Headers.BOT_CONVERSATION_ID],
            tenant: data[Headers.TENANT_ID],
        };

        conversations.push(current);

        consumerApi.getPersonSummary(current.tenant, current.userId)
            .then(user => {
                let { users } = this.state;
                users.push(user.data);
                this.setState({
                   users: users,
                });
            });

        return {
            conversations: conversations,
        };
    };


    _handleConversationSwitched = (data) => {

        let {conversations} = this.state;

        let targetConversation = undefined;

        const conversationId = atob(data.data_base64);

        for (let index in conversations) {
            if (conversations[index].conversationId === conversationId) {
                targetConversation = conversations[index];
                conversations[index].read = true;
                break;
            }
        }

        if (targetConversation !== undefined) {
            return {
                conversations: conversations,
                activeConversation: targetConversation,
                message: undefined,
                metadata: undefined,
                quinnAsk: undefined,
            };
        }

        return {};
    };

    resolveTargetUser = (users, userId) => {

        for(let index in users) {

            let user = users[index];
            if(user.id === userId) {
                return user;
            }
        }

        return undefined;
    };


    _handleSessionConversationEnded = (data) => {

        let {conversations} = this.state;

        const comparison = data.data_base64 ? atob(data.data_base64) : data.data;

        let filtered = conversations.filter(function (value, index, arr) {
            return value.conversationId !== comparison;
        });

        return {
            activeConversation: {},
            metadata: undefined,
            message: undefined,
            quinnAsk: undefined,
            conversations: filtered,
        };
    };

    _handleSessionConversationHandedBack = (data) => {

        let {conversations} = this.state;

        conversations.push({
            userId: data.userId,
            conversationId: data.conversationId,
        });

        return {
            conversations: conversations,
        };
    };


    handleErrorUpdate = (data) => {

        this.setState({
            busy: false,
            error: data.data.text,
        });
    };

    handleConnectionUpdate = (data) => {

        let additionalProperties = {};

        if(data === "on") {
            exceptionSessionWebsocket.toggleState(STATE_WAITING);
        } else if(data === "closed") {
            additionalProperties = {
                activeConversation: {},
                conversations: [],
                users: [],
                metadata: undefined,
                message: undefined,
                quinnAsk: undefined,
                fullscreen: false,
            };
        }

        this.setState({
            ...additionalProperties,
            connectionStatus: data,
            busy: false,
        });
    };

    handbackConversation = () => {

        this.markBusy(() => {

            let {activeConversation, metadata} = this.state;

            exceptionSessionWebsocket.handback(activeConversation.tenant,{
                conversationId: activeConversation.conversationId,
                userId: activeConversation.userId,
                messageId: activeConversation.messageId,
            });
            return {};
        });
    };

    endConversation = (endType) => {

        this.markBusy(() => {

            let {activeConversation, metadata} = this.state;

            exceptionSessionWebsocket.end(activeConversation.tenant, {
                conversationId: activeConversation.conversationId,
                userId: activeConversation.userId,
                type: endType,
            });
            return {};
        });
    };

    sendManualResponse = (data) => (event) => {

        if(data && data.length > 0) {

            this.markBusy(() => {

                let { activeConversation, metadata } = this.state;

                exceptionSessionWebsocket.sendManualResponse(activeConversation.tenant, {
                    conversationId: activeConversation.conversationId,
                    recipientId: activeConversation.userId,
                    replyTo: activeConversation.messageId || (metadata ? metadata[Headers.MESSAGE_ID] : ""),
                    message: data,
                    replyType: "MANUAL",
                });
                return {};
            });
        }
    };

    markBusy = (callback) => {

        let {busy} = this.state;

        if (busy) {
            this.setState({
                error: "Already processing previous submission.",
            });
            return;
        }

        let response = callback();

        let stateUpdates = {
            busy: true,
            ...response,
        };

        this.setState(stateUpdates);
    };

    submitEvent = (event) => {

        let {busy} = this.state;

        if (busy) {
            this.setState({
                error: "Already processing previous submission.",
            });
            return;
        }

        let stateUpdates = {busy: true};

        if (this.state.state === STATE_PAUSED) {
            stateUpdates.pauseOnNext = true;
        }

        exceptionSessionWebsocket.send(event);

        this.setState(stateUpdates);
    };


    submitResult = (type, data) => {

        let { busy, metadata } = this.state;

        if (busy) {
            this.setState({
                error: "Already processing previous submission.",
            });
            return;
        }

        let stateUpdates = {busy: true};

        if (this.state.state === STATE_PAUSED) {
            stateUpdates.pauseOnNext = true;
        }
        exceptionSessionWebsocket.submitJobResult(metadata[Headers.TENANT_ID], type, data);
        this.setState(stateUpdates);
    };

    toggleSessionState = () => {

        const { busy, state, connectionStatus} = this.state;

        if(!busy) {
            const newState = (state === STATE_PAUSED) ? STATE_WAITING : STATE_PAUSED;
            if (newState === STATE_WAITING && connectionStatus === "off") {

                this.setState({
                    busy: true,
                });
                exceptionSessionWebsocket.connect();

            } else {

                exceptionSessionWebsocket.close();

                this.setState({
                    activeConversation: {},
                    conversations: [],
                    users: [],
                    busy: false,
                    metadata: undefined,
                    message: undefined,
                    fullscreen: false,
                    state: STATE_PAUSED,
                    pauseOnNext: false,
                    connectionStatus: "off",
                    quinnAsk: undefined,
                });
            }
        }
    };

    toggleFullscreen = () => {

        this.setState({fullscreen: !this.state.fullscreen});
    };

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    render() {

        let {classes} = this.props;
        let {activeConversation, users, fullscreen, busy, state, conversations, quinnAsk, message, metadata, connectionStatus, error, sessionCount} = this.state;

        let screen = (<><InformationScreen message="Waiting for a conversation to be allocated."/></>);

        if (activeConversation.conversationId) {

            let job = metadata ? metadata[Headers.ORIGINAL_JOB] : undefined;
            let messageId = metadata ? metadata[Headers.MESSAGE_ID] : undefined;

            screen =
                (<div className={classes.conversation}>
                    <Conversation sendManualResponse={this.sendManualResponse} submitErrorUpdate={this.handleErrorUpdate}
                                  endConversation={this.endConversation}
                                  handbackConversation={this.handbackConversation}
                                  conversationId={activeConversation.conversationId}
                                  botConversationId={activeConversation.botConversationId}
                                  state={state} job={job} messageId={messageId} quinnAsk={quinnAsk} />
                </div>);

        } else if (state === STATE_PAUSED) {

            screen = (
                <>
                    <InformationScreen message="Paused click resume to continue"/>
                    <Button onClick={this.toggleSessionState} className={classes.resumeIconBtn}><PlayCircleOutlineOutlined color="primary" fontSize="large"/></Button>
                </>
            );
        }



        let sidebar = (state === STATE_PAUSED) ? (<></>) : (<><div className={classes.fill}/><div className={classes.patientQueue}><MonitoredConversations setConversation={this.setConversation}/></div></>);

        let errorAlert = (<></>);
        if (error) {
            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    message={<span id="message-id">{error}</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            );
        }

        let context = {
            activeConversation: activeConversation,
            activeUser: (activeConversation) ? this.resolveTargetUser(users, activeConversation.userId) : undefined,
            conversations: conversations,
            users: users,
            message: message,
            metadata: metadata,
            quinnAsk: quinnAsk,
            busy: busy,
            submitEvent: this.submitEvent,
        };

        return (
            <ExceptionContext.Provider value={context}>
                <div className={classes.root}>
                    <div className={classes.header}>
                        <div className={classes.headerLeft}></div>
                        <div className={classes.fill}></div>
                        <div className={classes.headerCenter}>
                            <Button onClick={this.toggleFullscreen} size="small"><FormattedMessage id="fullscreen"
                                                                                                   defaultMessage={`Fullscreen`}/></Button>
                        </div>
                        <div className={classes.fill}></div>
                        <div className={classes.headerRight}>
                            <ConnectionStatus status={connectionStatus}/>
                        </div>
                    </div>

                    <Paper className={fullscreen ? classes.paperFullscreen : classes.paper}>
                        {busy && <LinearProgress/>}
                        {fullscreen &&
                        <Button onClick={this.toggleFullscreen} size="small"><FormattedMessage id="fullscreen.exit"
                                                                                               defaultMessage={`Exit fullscreen`}/></Button>}
                        <div className={fullscreen ? classes.paperContentFullscreen : classes.paperContent}>
                            <div className={classes.paperContentMessage}>
                                {screen}
                                {sidebar}
                            </div>
                        </div>
                    </Paper>

                    <div className={classes.footer}>
                        <div className={classes.footerLeft}>
                            <FormattedMessage id="active.federated.sessions.message" defaultMessage={`Federated sessions`} />: { sessionCount.federated }
                        </div>
                        <div className={classes.fill}></div>
                        <div className={classes.footerCenter}>
                            <Button onClick={this.toggleSessionState} size="small">
                                {
                                    (state === STATE_PAUSED) ? (
                                        <FormattedMessage id="resume" defaultMessage={`Resume`}/>) : (
                                        <FormattedMessage id="pause" defaultMessage={`Pause`}/>)
                                }
                            </Button>
                        </div>
                        <div className={classes.fill}></div>
                        <div className={classes.footerRight}>
                            <FormattedMessage id="active.exception.sessions.message" defaultMessage={`Exception sessions`} />: { sessionCount.exception }
                        </div>
                    </div>
                    {errorAlert}
                </div>
            </ExceptionContext.Provider>
        );
    }
}

export default withStyles(styles)(ExceptionJobs);
