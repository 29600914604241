import axios from "axios";

class NLPApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
        this.nlpServiceVersion = "0.1.0"
    }

    getEntities(data, resolve) {

        return axios.post(`${this.baseUrl}/internal/nlp/entities`, data, {
                headers: {
                    "Accept": `application/json;version=${this.nlpServiceVersion}`,
                },
                params: { resolve: resolve }
        })
            .then(function (res) {
                return res;
            })
            .catch(function (err) {
                return err;
            });
    }

    normalise(data) {
        return axios.post(`${this.baseUrl}/internal/nlp/normalise`, data, {
            headers: {
                "Accept": `application/json;version=${this.nlpServiceVersion}`,
            }
        })
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }

    evaluate(data) {
        return axios.post(`${this.baseUrl}/internal/nlp/evaluate`, data, {
            headers: {
                "Accept": `application/json;version=${this.nlpServiceVersion}`,
            }
        })
            .then(function (res) { return res; })
            .catch(function (err) {
                console.log(err)
                return err; });
    }
}


export const nlpApi = new NLPApi();