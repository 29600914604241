import React, {Component} from 'react';

import {Button, Dialog, DialogActions, DialogContent, MenuItem, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import {notificationService} from "../../../../../../../utils/notification";
import {withStyles} from "@material-ui/core/styles";
import AsyncJobContext from "../../../context";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },

    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",

    },
    listItems: {
        minHeight: "32px",
    },
    listItem: {
        flex: 1,
    },
    select: {
        display: "flex"
    },
    option: {
        flex: 1,
        margin: "0.5%"
    }

});

class EditObservationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            observation: undefined
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            observation: props.observation,
        })
    }

    __observationChanged = (event) => {
        if (event === null) {
            return
        }
        const {observation} = this.state
        this.setState({
            observation: {
                ...observation,
                name: event.canonicalName,
                cui: event.conceptId
            }
        });
    }

    __handleClassChange = (event) => {
        const {observation} = this.state
        this.setState({
            observation: {
                ...observation,
                observationClass: event.target.value,
            }
        });
    }

    __handleCorrelationChange = (event) => {
        const {observation} = this.state
        this.setState({
            observation: {
                ...observation,
                correlation: event.target.value,
            }
        });
    }

    __handleWeightChange = (event) => {
        const {observation} = this.state
        this.setState({
            observation: {
                ...observation,
                weight: event.target.value,
            }
        });
    }

    __saved = (observation) => {
        this.props.handleSave(observation);
    }

    __getOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({query: _inputValue, dataType: this.props.observationClasses.map(o => o.toLowerCase())})
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        const aliases = item.index
                        aliases.unshift(item.canonicalName)
                        const uniqueNames = [...new Set(aliases)]
                        const option = {
                            name: uniqueNames.join(', '),
                            conceptId: item.conceptId,
                            canonicalName: item.canonicalName
                        }

                        options.push(option)

                    })

                    resolve(options)
                }, error => {
                    notificationService.error("Error loading observations");
                    resolve(options)
                });
        })
    }


    render() {
        const {classes} = this.props;

        let {open, handleClose, observationClasses} = this.props;
        let {observation} = this.state
        return observation ? (
            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Edit Relationship</DialogTitle>
                    <DialogContent>
                        <div>

                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Class"
                                id="demo-simple-select-filled"
                                key={observation.observationClass}
                                defaultValue={observation.observationClass}
                                onChange={this.__handleClassChange}
                            >

                                {observationClasses.map(o => {
                                    return <MenuItem value={o}>{o}</MenuItem>
                                })
                                }
                            </TextField>
                            <AutocompleteField id="id"
                                               key={observation.name}
                                               label="Observation"
                                               defaultValue={observation.name}
                                               setSelectedValue={this.__observationChanged}
                                               getOptions={this.__getOptions}
                            />
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="select"
                                key={observation.correlation}
                                label="Correlation"
                                defaultValue={observation.correlation}
                                onChange={this.__handleCorrelationChange}
                            >
                                <MenuItem value="Positive">Positive</MenuItem>
                                <MenuItem value="Negative">Negative</MenuItem>
                            </TextField>

                            <TextField
                                select
                                id="select"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                key={observation.weight}
                                label="Weight"
                                defaultValue={observation.weight}
                                onChange={this.__handleWeightChange}
                            >
                                <MenuItem value="Low">Low</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="High">High</MenuItem>
                            </TextField>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                        <span className={classes.fill}/>
                        <Button onClick={() => this.__saved(observation)} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        ) : (<></>)
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<EditObservationDialog context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));
