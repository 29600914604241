import React, {Component} from 'react';

import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {authService} from '../../../utils/auth';
import {FormattedMessage} from "react-intl";


import {Menu, MenuItem, Button} from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import {queuesApi} from "../../../utils/services/queues.api";
import {playSoundAlert} from "../../../utils/sounds";
import {withRouter} from "react-router-dom";

const lightColor = 'rgba(255, 255, 255, 0.7)';

function colorEnvironment() {

    if(window.location.href.indexOf("staging") > 0) {

        return "#2121ff";
    } else if(window.location.href.indexOf("test") > 0) {

        return "#21ff21";
    } else if(window.location.href.indexOf("localhost") > 0) {

        return "#ff2121";
    }

    return "#212121";
}

const styles = theme => ({
    root: {
      overflowX: "hidden",
    },
    toolbar: {
      backgroundColor: colorEnvironment(),
      overflowX: "hidden",
    },
    title: {
      paddingLeft: "12px",
    },
    alerts: {
      marginRight: "12px",
    },
    avatar: {
      marginRight: "15px",
    },
    menuButton: {
        marginLeft: -theme.spacing.unit,
    },
    iconButtonAvatar: {
        padding: 4,
    },
    fill: {
        flex: "1 1 auto",
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    alertsBtn: {

    },
    chip: {
        marginRight: "8px",
    }
});


class Navigation extends Component {

    queuePoller;

    constructor(props) {
        super(props);

        this.state = {
            federatedJobQueueSize: 0,
            exceptionQueueSize: 0,
            summaryQueueSize: 0,
            menuOpen: false,
            anchorEl: null,
        };

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openNotificationsMenu = this.openNotificationsMenu.bind(this);
        this.closeNotificationsMenu = this.closeNotificationsMenu.bind(this);

        this.updateTitle = this.updateTitle.bind(this);
        navigationTitleService.registerStateCallback(this.updateTitle);

        this.initPollQueueSizes();
    }


    componentWillUnmount() {
        if(this.queuePoller) {
            clearInterval(this.queuePoller);
        }
        if(this.summaryPoller) {
            clearInterval(this.summaryPoller);
        }
        if(this.federatedPoller) {
            clearInterval(this.federatedPoller);
        }
    }

    initPollQueueSizes() {

        this.queuePoller = setInterval(() => {
            queuesApi.getExceptionQueueSize()
                .then((data) => {

                    if(data && data.status === 200 && data.data) {

                        if (data.data.size > 0) {
                            playSoundAlert();
                        }

                        this.setState({
                            exceptionQueueSize: `${data.data.size}`,
                        });
                    } else {
                        this.setState({
                            exceptionQueueSize: "unknown",
                        });
                    }
                });
        }, 5000);

        this.federatedPoller = setInterval(() => {
            queuesApi.getFederatedJobQueueSize()
                .then((data) => {

                    if(data && data.status === 200 && data.data) {

                        if (data.data.size > 0) {
                            playSoundAlert();
                        }

                        this.setState({
                            federatedJobQueueSize: `${data.data.size}`,
                        });
                    } else {
                        this.setState({
                            federatedJobQueueSize: "unknown",
                        });
                    }
                });
        }, 5000);

        this.summaryPoller = setInterval(() => {
            queuesApi.getClinicalSummaryJobQueueSize()
                .then((data) => {

                    if (data && data.status === 200 && data.data) {

                        this.setState({
                            summaryQueueSize: `${data.data.size}`,
                        });
                    } else {
                        this.setState({
                            summaryQueueSize: "unknown",
                        });
                    }
                });
        }, 5000);
    }

    updateTitle = (title) => {

        this.setState({
            title: title,
        });
    };

    doLogout = () => {

        authService
            .logout()
            .then(() => window.location = "/");
    };

    openNotificationsDropdown(event) {

        //TODO: Dropdown open with whats wrong.
    }

    openNotificationsMenu(event) {

        this.setState({
            notificationsAnchorEl: event.currentTarget,
            notificationsMenuOpen: true,
        });
    }

    closeNotificationsMenu() {

        this.setState({
            notificationsAnchorEl: null,
            notificationsMenuOpen: false,
        });
    }

    openMenu(event) {

        this.setState({
            anchorEl: event.currentTarget,
            menuOpen: true,
        });
    }

    closeMenu() {

        this.setState({
            anchorEl: null,
            menuOpen: false,
        });
    }

    navigateTo = (url) => {

        const $this = this;

        return () => {
            this.props.history.push(`${url}`);
            $this.setState({
                notificationsAnchorEl: null,
                notificationsMenuOpen: false,
            });
        }
    };

    render() {

        const { user, classes, onDrawerToggle } = this.props;
        const { title, federatedJobQueueSize, exceptionQueueSize, summaryQueueSize, menuOpen, anchorEl, notificationsMenuOpen, notificationsAnchorEl } = this.state;

        let totalQueueItems = 0;
        let totalSummaryItems = 0;
        if(federatedJobQueueSize && federatedJobQueueSize !== "unknown") totalQueueItems += parseInt(federatedJobQueueSize);
        if(exceptionQueueSize && exceptionQueueSize !== "unknown") totalQueueItems += parseInt(exceptionQueueSize);
        if(summaryQueueSize && summaryQueueSize !== "unknown") totalSummaryItems += parseInt(summaryQueueSize);

        return (
            <>
                <AppBar position="sticky" elevation={0} className={classes.root}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={onDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box display={{ xs: 'none', sm: 'block' }}>
                        <Typography className={classes.title} color="inherit" variant="h5">
                            { title || <FormattedMessage id="technician" defaultMessage={`Dashboard`} /> }
                        </Typography>
                        </Box>
                        <div className={classes.fill} />
                        <div>
                            <Tooltip title="Create a synthetic test case" aria-label="add">
                                <IconButton onClick={() => window.open('https://forms.gle/FpxM392k4C4rxeex9', '_blank') } aria-label="help"><AssignmentIndIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Help Center" aria-label="add">
                                <IconButton onClick={() => window.open('https://decodedhealth.atlassian.net/servicedesk/customer/portals', '_blank') } aria-label="help"><ContactSupportOutlinedIcon /></IconButton>
                            </Tooltip>
                            <IconButton aria-label="delete" className={classes.alerts} onClick={this.openNotificationsMenu}>
                                <Badge badgeContent={totalQueueItems + totalSummaryItems} color="primary" anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                    { totalQueueItems === 0 && totalSummaryItems === 0 ? (<NotificationsIcon fontSize="medium" />) : (<NotificationsActiveIcon fontSize="medium" />) }
                                </Badge>
                            </IconButton>
                            <Menu
                                id="menu-notifications"
                                anchorEl={notificationsAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={notificationsMenuOpen}
                                onClose={this.closeNotificationsMenu}
                            >
                                <MenuItem onClick={this.navigateTo('/jobs/federated')}>{ federatedJobQueueSize } Federated Job Queue</MenuItem>
                                <MenuItem onClick={this.navigateTo('/jobs/exception')}>{ exceptionQueueSize } Exceptions Queue</MenuItem>
                                <MenuItem onClick={this.navigateTo('/jobs/clinical-summary')}>{ totalSummaryItems } Summary Jobs</MenuItem>
                            </Menu>
                            <Button
                                aria-label="Account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={this.openMenu}
                                color="inherit"
                            >
                                <Avatar alt={ user.email.charAt(0).toLowerCase() } src={ user.photoURL || user.email.charAt(0).toLowerCase() } className={classes.avatar} /> {user.displayName || user.email }
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={menuOpen}
                                onClose={this.closeMenu}
                            >
                                <MenuItem onClick={this.doLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Navigation));
