import * as rxjs from "rxjs";
import {generateAnswerQuinn, Headers} from "../../../../../utils/events";

export class Bloc {

    constructor(event, websocket) {

        this.websocket = websocket;
        if(event?.data && (!event.data.entity.normalised || event.data.entity.normalised === "")) {
            event.data.entity.normalised = event.data.entity.extract;
        }

        this.subject = new rxjs.BehaviorSubject({
            event: event,
        });

        this.events = new rxjs.Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
            ...this.subject.value,
            ...newProps,
        });
    }

    onChange = (e) => {

        let { event } = this.subject.value;

        event.data.entity.normalised = e.target.value;

        this.__updateSubject({ event: event });
    }

    submit = () => {
        const { event } = this.subject.value;

        console.log(event)

        let request = {
            action: "suggest_entity_reword",
            conversationId: event[Headers.CONVERSATION_ID],
            messageId: event[Headers.MESSAGE_ID],
            tenantId: event[Headers.TENANT_ID],
            entityUpdates: {
                update: [event.data.entity],
            }
        };

        generateAnswerQuinn(event[Headers.TENANT_ID], request)
            .then(_event => {
                this.websocket.send(_event);
            });
    }
}

export class BlocEvent {
    static ACCOUNT_LOADED = "ACCOUNT_LOADED";
}
