import axios from 'axios';

class MessagingApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    startNewThread(email, message) {

        return axios.post(`${this.baseUrl}/internal/messaging/groups/_upsert`, {
            content: message,
            customer: {
                system: "email",
                code: "id",
                value: email,
            },
        });
    }

    listOpenThreads() {

        return axios.get(`${this.baseUrl}/internal/messaging/groups`);
    }

    listOpenThreadMessages(groupId) {

        return axios.get(`${this.baseUrl}/internal/messaging/groups/${groupId}/messages`);
    }

    archiveThread(groupId) {

        return axios.delete(`${this.baseUrl}/internal/messaging/groups/${groupId}`);
    }


    markMessagesAsRead(groupId) {

        return axios.patch(`${this.baseUrl}/internal/messaging/groups/${groupId}/messages/_mark_as_read`);
    }

    sendDirectMessage(groupId, consumerId, message) {

        return axios.post(`${this.baseUrl}/internal/messaging/groups/${groupId}/messages`, {
            content: message,
            customer: {
                system: "decoded",
                code: "id",
                value: consumerId,
            },
        }).catch(function (err) {
            console.error(err);
        });
    }
}

export const messagingApi = new MessagingApi();
