import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import {Grid, LinearProgress, Paper, Typography} from "@material-ui/core";

import DecodedComponent from "../../../../../../shared/DecodedComponent";

const styles = theme => ({
    root: {
        width: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",

    },
});

class Unknown extends Component {


    constructor(props) {
        super(props);

        this.state = {
            initialising: true,
        };
    }


    componentWillMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }


    render() {

        const { task, classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography id={"job.async.pipeline.name"} variant={"h2"} className={classes.name}>No job found for { task.type }</Typography>
            </div>
        );
    }
}

export default withStyles(styles)(Unknown);
