import axios from "axios";

class DataPreparationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }


    getpreparationJobQueueSize(status) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/metadata/_size`, { params: { status: status } });
    }

    getJob(id) {

        return axios.get(`${this.baseUrl}/internal/data-preparation/metadata/${id}`);
    }

    getJobSummary(status) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/metadata/summary`, { params: { status: status } });
    }

    getTextDocument(id) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/document/text/${id}`);
    }

    getPDFDocument(id) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/document/pdf/${id}`,{ responseType: 'application/pdf' });
    }

    getDocumentSignedURL(id) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/reference/${id}`);
    }

    submitEditedDocument(id, body) {
        return axios.post(`${this.baseUrl}/internal/data-preparation/document/${id}`, body);
    }

    submitMetadata(body) {
        return axios.post(`${this.baseUrl}/internal/data-preparation/metadata`, body);
    }

    query(collection, query) {
        return axios.get(`${this.baseUrl}/internal/data-preparation/query/${collection}`, { params: { q: query } });
    }

}

export const dataPreparationApi = new DataPreparationApi();
