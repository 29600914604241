import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App  from './components/App';
import * as serviceWorker from './serviceWorker';

import { authService } from './utils/auth';
import axios from 'axios';

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


axios.interceptors.request.use(async (config) => {

    const token = await authService.getToken();
    config.headers.Authorization =  `Bearer ${token}`;

    return config;
}, (error) => {

    return Promise.reject(error);
});
