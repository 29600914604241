import React, { Component } from 'react';

import OpenInNew from '@material-ui/icons/OpenInNew';

import { withStyles } from '@material-ui/core/styles';
import ExceptionContext from "./context";
import {Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {ELATION_BASE_URL} from "../../../../utils/elation";


const styles = theme => ({
    root: {
        height: "100%",
        display: "flex",
    },
    name: {
        marginTop: "auto",
        marginBottom: "auto",
        maxHeight: "80px",
        overflow: "hidden",
    },
    open: {
    },
});


class UserInformation extends Component {

    render() {

        let { classes } = this.props;

        let { activeUser } = this.props.context;

        if(activeUser) {

            let references = activeUser.externalReferences.filter(reference => reference.system === "elation");
            let reference = references.length > 0 ? references[0] : undefined;

            return (
                <div className={classes.root}>
                    <Typography className={classes.name} variant="h4" gutterBottom>
                        { reference ? (<a className={classes.open} href={`https://${ELATION_BASE_URL}/patient/${reference.reference}/`} target={"_blank"}><OpenInNew fontSize="default" color="primary" /></a>) : (<></>) }
                        &nbsp;&nbsp;{ activeUser.name.given + " " + activeUser.name.family }
                    </Typography>
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    <Typography className={classes.name} variant="h4" gutterBottom>
                        <FormattedMessage id={"unknown.user"} defaultMessage={"Unknown user"} />
                    </Typography>
                </div>
            );
        }
    }
}

export default withStyles(styles)(props => (
    <ExceptionContext.Consumer>
        {value => {
            return (<UserInformation context={value} {...props} />);
        }
        }
    </ExceptionContext.Consumer>
));
