import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FormattedMessage} from "react-intl";
import Allowed from "../../../utils/auth/Allowed";
import {VERSION} from "../../../utils/version";

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import HomeOutlineIcon from '@material-ui/icons/HomeOutlined';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {Collapse} from "@material-ui/core";


const drawerWidth = 256;

const styles = theme => ({
    root: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    collapsableDrawer: {
        minHeight: "auto"
    },
    primaryList: {
        width: '100%',
    },
    icon: {
        paddingTop: "10px",
        marginRight: "10px",
    },
    categoryHeader: {
        textTransform: "uppercase",
        fontSize: "1.2rem",
        paddingTop: 16,
        paddingBottom: 16,
        fontFamily: "Roboto,\"Helvetica\",Arial,sans-serif",
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        color: 'inherit',
        fontSize: theme.typography.fontSize,
        '&$textDense100%': {
            fontSize: theme.typography.fontSize,
        },
    },
    itemActionable: {
        '&:hover': {
            cursor: "hand",
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: 16,
        paddingBottom: 16,
    },
    divider: {
        marginTop: theme.spacing(2),
    },
});

class DecodedDrawer extends Component {

    constructor() {
        super();
        this.state = ({
            jobsOpen: true,
            testOpen: false,
            conversationsOpen: false,
            adminOpen: false,
            healthCheckOpen: false,
            sysadminOpen: false,
            // hide test and data prep screens for now
            hideTestScreens: false,
            hideDataPreparationScreens: false,
        })
    }


    navigateTo = (url) => {

        return () => {
            this.props.history.push(`${url}`);
        }
    };

    setJobsOpen = () => {
        let open = this.state.jobsOpen
        this.setState({
            jobsOpen: !open
        });
    }

    setTestOpen = () => {
        let open = this.state.testOpen
        this.setState({
            testOpen: !open
        });
    }

    setConversationsOpen = () => {
        let open = this.state.conversationsOpen
        this.setState({
            conversationsOpen: !open
        });
    }

    setInspectOpen = () => {
        let open = this.state.inspectOpen
        this.setState({
            inspectOpen: !open
        });
    }

    setAdminOpen = () => {
        let open = this.state.adminOpen
        this.setState({
            adminOpen: !open
        });
    }


    setHealthCheckOpen = () => {
        let open = this.state.healthCheckOpen
        this.setState({
            healthCheckOpen: !open
        });
    }
    setSysadminOpen = () => {
        let open = this.state.sysadminOpen
        this.setState({
            sysadminOpen: !open
        });
    }


    render() {

        const {roles, classes, staticContext, ...other} = this.props;

        let {jobsOpen, testOpen, conversationsOpen, adminOpen, sysadminOpen, inspectOpen,
            healthCheckOpen, displayTestScreens, displayDataPreparationScreens} = this.state

        let menus = (
            <></>
        );

        if (roles && roles.length > 0) {

            menus = (<>
                <Allowed roles={roles} priviledge="analytics:dashboard:view"
                         yes={() => (
                             <>
                                 <ListItem className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary}}><HomeOutlineIcon
                                         className={classes.icon}/><FormattedMessage id="home" defaultMessage={`Home`}/></ListItemText>
                                 </ListItem>
                                 <ListItem button onClick={this.navigateTo('/')}
                                           className={classNames(classes.item, classes.itemActionable)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                         id="analytics.dashboard" defaultMessage={`Dashboard`}/></ListItemText>
                                 </ListItem>
                             </>)}
                         no={() => (<></>)}
                />
                <Allowed roles={roles} priviledge="jobs:federated:perform"
                         yes={() => (
                             <>
                                 <Divider className={classes.divider}/>
                                 <ListItem button onClick={this.setJobsOpen}
                                           className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><WorkOutlineIcon
                                         className={classes.icon}/><FormattedMessage id="jobs" defaultMessage={`Jobs`}/></ListItemText>
                                     {jobsOpen ? <ExpandLess/> : <ExpandMore/>}
                                 </ListItem>
                             </>)}
                         no={() => (<></>)}
                />
                <div className={classes.collapsableDrawer}>
                    <Collapse in={jobsOpen} timeout="auto" unmountOnExit>
                        <Allowed roles={roles} priviledge="jobs:federated:perform"
                                 yes={() => (
                                     <>
                                         <ListItem button onClick={this.navigateTo('/jobs/federated')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="drawer.jobs.general" defaultMessage={`Federated`}/></ListItemText>
                                         </ListItem>
                                     </>)}
                                 no={() => (<></>)}
                        />
                        <Allowed roles={roles} priviledge="jobs:exception:perform"
                                 yes={() => (
                                     <>
                                         <ListItem button onClick={this.navigateTo('/jobs/exception')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="drawer.jobs.dialog.manager"
                                                 defaultMessage={`Exceptions`}/></ListItemText>
                                         </ListItem>
                                     </>)}
                                 no={() => (<></>)}
                        />
                        <Allowed roles={roles} priviledge="jobs:async:perform"
                                 yes={() => (
                                     <>
                                         <ListItem button onClick={this.navigateTo('/jobs/async')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}>
                                                 <FormattedMessage id="drawer.jobs.async" defaultMessage={`Async`}/>
                                             </ListItemText>
                                         </ListItem>
                                     </>)}
                                 no={() => (<></>)}
                        />
                        <Allowed roles={roles} priviledge="jobs:summarise:perform"
                                 yes={() => (
                                     <>
                                         <ListItem button onClick={this.navigateTo('/jobs/clinical-summary')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="drawer.jobs.hpi.summarise"
                                                 defaultMessage={`Clinical summary`}/></ListItemText>
                                         </ListItem>
                                     </>)}
                                 no={() => (<></>)}
                        />

                        <Allowed roles={roles} priviledge="jobs:preparation:perform"
                                 yes={() => (
                                     <div hidden={displayDataPreparationScreens}>
                                         <ListItem button onClick={this.navigateTo('/jobs/data-preparation')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="drawer.jobs.hpi.preparation"
                                                 defaultMessage={`Data Preparation`}/></ListItemText>
                                         </ListItem>
                                     </div>)}
                                 no={() => (<></>)}
                        />

                        <Allowed roles={roles} priviledge="jobs:preparation:review"
                                 yes={() => (
                                     <div hidden={displayDataPreparationScreens}>
                                         <ListItem button onClick={this.navigateTo('/jobs/authoring-tasks')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="drawer.jobs.hpi.preparation-review"
                                                 defaultMessage={`Authoring Tasks`}/></ListItemText>
                                         </ListItem>
                                     </div>)}
                                 no={() => (<></>)}
                        />
                    </Collapse>
                </div>
                <Allowed roles={roles} priviledge="test:entityrecognition:perform"
                         yes={() => (
                             <div hidden={displayTestScreens} >
                                 <Divider className={classes.divider}/>
                                 <ListItem button onClick={this.setTestOpen}
                                           className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><BuildIcon
                                         className={classes.icon}/><FormattedMessage id="test"
                                                                                     defaultMessage={`Test`}/></ListItemText>
                                     {testOpen ? <ExpandLess/> : <ExpandMore/>}
                                 </ListItem>
                                 <div className={classes.collapsableDrawer}>
                                     <Collapse in={testOpen} timeout="auto" unmountOnExit>
                                         <ListItem button onClick={this.navigateTo('/test/entity-recognition')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="test.entityrecognition"
                                                 defaultMessage={`Entity Recognition`}/></ListItemText>
                                         </ListItem>
                                         <ListItem button onClick={this.navigateTo('/test/differential-diagnosis')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="test.differentialdiagnosis"
                                                 defaultMessage={`Differential Diagnosis`}/></ListItemText>
                                         </ListItem>
                                         <ListItem button onClick={this.navigateTo('/test/clinical-inference')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="test.clinicalinference"
                                                 defaultMessage={`Clinical Inference`}/></ListItemText>
                                         </ListItem>
                                     </Collapse>
                                 </div>
                             </div>
                         )}
                         no={() => (<></>)}/>

                <Allowed roles={roles} priviledge="patient:conversations:read"
                         yes={() => (
                             <>
                                 <Divider className={classes.divider}/>
                                 <ListItem button onClick={this.setConversationsOpen}
                                           className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><QuestionAnswerIcon
                                         className={classes.icon}/><FormattedMessage id="conversations"
                                                                                     defaultMessage={`Conversations`}/></ListItemText>
                                     {conversationsOpen ? <ExpandLess/> : <ExpandMore/>}
                                 </ListItem>
                                 <div className={classes.collapsableDrawer}>
                                     <Collapse in={conversationsOpen} timeout="auto" unmountOnExit>
                                         <ListItem button onClick={this.navigateTo('/conversations')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="conversations.search"
                                                 defaultMessage={`Search`}/></ListItemText>
                                         </ListItem>
                                     </Collapse>
                                 </div>
                             </>
                         )}
                         no={() => (<></>)}/>

                <Allowed roles={roles} priviledge="graph:inspect:all"
                         yes={() => (
                             <>
                                 <Divider className={classes.divider}/>
                                 <ListItem button onClick={this.setInspectOpen}
                                           className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><QuestionAnswerIcon
                                         className={classes.icon}/><FormattedMessage id="knowledge.workbench"
                                                                                     defaultMessage={`KG Workbench`}/></ListItemText>
                                     {inspectOpen ? <ExpandLess/> : <ExpandMore/>}
                                 </ListItem>
                                 <div className={classes.collapsableDrawer}>
                                     <Collapse in={inspectOpen} timeout="auto" unmountOnExit>
                                         <ListItem button onClick={this.navigateTo('/knowledge/inspect/graph')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="inspect.graph"
                                                 defaultMessage={`Inspect Knowledge Graph`}/></ListItemText>
                                         </ListItem>
                                     </Collapse>
                                 </div>
                             </>
                         )}
                         no={() => (<></>)}/>


                {/*<Allowed roles={roles} priviledge="patient:communication:all"*/}
                {/*         yes={() => (*/}
                {/*             <>*/}
                {/*             <Divider className={classes.divider}/>*/}
                {/*             <List disablePadding className={classes.primaryList}>*/}
                {/*                 <ListItem className={classNames(classes.item, classes.categoryHeader)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><ChatIcon className={classes.icon} /><FormattedMessage id="communication" defaultMessage={`Communication`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*                 <ListItem button onClick={this.navigateTo('/communication/direct')} className={classNames(classes.item, classes.itemActionable)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage id="communication.secure.messaging" defaultMessage={`Secure Customer Messaging`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*                 <ListItem button onClick={this.navigateTo('/communication/push')} className={classNames(classes.item, classes.itemActionable)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage id="admin.session.management" defaultMessage={`Push Notifications`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*                 <ListItem button onClick={this.navigateTo('/scheduling')} className={classNames(classes.item, classes.itemActionable)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage id="scheduling" defaultMessage={`Telehealth Scheduling`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*             </List>*/}
                {/*             </>*/}
                {/*         )}*/}
                {/*         no={() => (<></>)} />*/}

                {/*<Allowed roles={roles} priviledge="patient:communication:all"*/}
                {/*         yes={() => (*/}
                {/*             <>*/}
                {/*                 <Divider className={classes.divider}/>*/}
                {/*             <List disablePadding className={classes.primaryList}>*/}
                {/*                 <ListItem className={classNames(classes.item, classes.categoryHeader)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><CodeIcon className={classes.icon} /><FormattedMessage id="admin" defaultMessage={`Utilities`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*                 <ListItem button onClick={this.navigateTo('/deep-link-generator')} className={classNames(classes.item, classes.itemActionable)}>*/}
                {/*                     <ListItemText classes={{primary: classes.itemPrimary,}}><FormattedMessage id="deeplink.generator" defaultMessage={`Deeplink Generator`} /></ListItemText>*/}
                {/*                 </ListItem>*/}
                {/*             </List>*/}
                {/*             </>*/}
                {/*         )}*/}
                {/*         no={() => (<></>)} />*/}


                <Allowed roles={roles} priviledge="users:permissions:update"
                         yes={() => (
                             <>
                                 <Divider className={classes.divider}/>
                                 <ListItem button onClick={this.setAdminOpen}
                                           className={classNames(classes.item, classes.categoryHeader)}>
                                     <ListItemText classes={{primary: classes.itemPrimary,}}><SettingsIcon
                                         className={classes.icon}/><FormattedMessage id="admin"
                                                                                     defaultMessage={`Admin`}/></ListItemText>
                                     {adminOpen ? <ExpandLess/> : <ExpandMore/>}
                                 </ListItem>
                                 <div className={classes.collapsableDrawer}>
                                     <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                                         <ListItem button onClick={this.navigateTo('/admin/user-management')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="admin.user.management"
                                                 defaultMessage={`User Management`}/></ListItemText>
                                         </ListItem>
                                         <ListItem button onClick={this.navigateTo('/admin/session-management')}
                                                   className={classNames(classes.item, classes.itemActionable)}>
                                             <ListItemText
                                                 classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                                 id="admin.session.management"
                                                 defaultMessage={`Session Management`}/></ListItemText>
                                         </ListItem>
                                     </Collapse>
                                 </div>
                             </>
                         )}
                         no={() => (<></>)}/>

                <Divider className={classes.divider}/>
                <ListItem button onClick={this.setSysadminOpen}
                          className={classNames(classes.item, classes.categoryHeader)}>
                    <ListItemText classes={{primary: classes.itemPrimary,}}><SettingsApplicationsIcon
                        className={classes.icon}/><FormattedMessage id="system.administration"
                                                                    defaultMessage={`Sysadmin`}/></ListItemText>
                    {sysadminOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <div className={classes.collapsableDrawer}>
                    <Collapse in={sysadminOpen} timeout="auto" unmountOnExit>
                        <Allowed roles={roles} priviledge="system:administration:health_check"
                                 yes={() => (
                                     <ListItem button onClick={this.navigateTo('/admin/system/health')}
                                               className={classNames(classes.item, classes.itemActionable)}>
                                         <ListItemText
                                             classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                             id="admin.health.emr.check"
                                             defaultMessage={`EMR Check`}/></ListItemText>
                                     </ListItem>
                                 )} no={() => (<></>)} />
                        <Allowed roles={roles} priviledge="system:redis:all"
                                 yes={() => (
                                     <ListItem button onClick={this.navigateTo('/admin/system/redis')}
                                               className={classNames(classes.item, classes.itemActionable)}>
                                         <ListItemText
                                             classes={{primary: classes.itemPrimary,}}><FormattedMessage
                                             id="admin.system.redis.nav.text"
                                             defaultMessage={`Redis`}/></ListItemText>
                                     </ListItem>
                                 )} no={() => (<></>)} />
                    </Collapse>
                </div>
            </>);
        }


        return (
            <Drawer variant="persistent" anchor="left" {...other} className={classes.root} classes={{
                paper: classes.drawerPaper,
            }}>
                <div className={classes.drawerHeader}>
                    <p>{VERSION}</p>
                </div>
                <Divider/>
                {menus}
            </Drawer>
        );
    }
}

DecodedDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DecodedDrawer));
