import React from 'react';
import Button from '@material-ui/core/Button';
import {
    TextField,
    MenuItem,
    Grid,
    Typography, Chip, Tooltip
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import AutocompleteField from "./AutocompleteField";
import {withStyles} from "@material-ui/core/styles";
import {AnnotationStatus} from "../annotation.bloc";


const styles = theme => ({
    form: {
        padding: 10,
    },
    formButtons: {
        padding: 10,
        overflow: "auto",
        display: 'flex',
        justifyContent: 'space-between',
    },
    toolTipText: {
        fontSize: "16px"
    },
    textField: {
        width: "100%",
    }
});

class AnnotationPopper extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            ...props.entity.data,
        }

        this.annotationTypes = Object.freeze({
            "ORGAN_SYSTEM": "Organ system",
            "ANATOMIC_LOCATION": "Anatomic location",
            "TISSUE_TYPE": "Tissue type",
            "PATHOGENESIS": "Pathogenesis",
            "PATHOPHYSIOLOGY": "Pathophysiology",
            "EPIDEMIOLOGY": "Epidemiology",
            "SYMPTOM": "Symptom",
            "SIGN": "Sign",
            "PERSONAL_FACTOR": "Personal factor",
            "ANTECEDENT_DISEASE": "Antecedent disease",
            "COMORBIDITY": "Comorbidity",
            "COMPLICATION": "Complication",
            "DIFFERENTIAL_DIAGNOSIS": "Differential diagnosis",
            "DIAGNOSTICS": "Diagnostics",
            "TREATMENT": "Treatment",
            "OTHER": "Other"
            })

    }

    setSelectedValue = (_newValue) => {
        if (_newValue == null) {
            this.setState({
                conceptName: undefined,
                CUI: undefined,
                TUI: undefined,
            })
        } else {
            this.setState({
                conceptName: _newValue.name,
                CUI: _newValue.conceptId,
                TUI: _newValue.types,
            })
        }
    }

    onSubmit = () => {
        let {entity} = this.props

        Object.assign(entity.data, this.state)
        entity.state = AnnotationStatus.ANNOTATED

        this.props.saveEntity(entity)
        this.props.closePopover()
    }

    onCancel = () => {
        this.props.closePopover()
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    handleDelete = (chipToDelete) => () => {

        let items = this.state.TUI

        items = items.filter( item => {
            return item.tui !== chipToDelete
        })

        this.setState({
            TUI: items
        })
    }

    render() {
        const {classes} = this.props
        let {category, preferredText, conceptName, CUI, TUI, notes} = this.state


        return (
            <div id={"123"}>
                <form autoComplete="off" onSubmit={this.onSubmit}>
                    <Grid container spacing={2} item direction="column" className={classes.form}>
                        <Grid item>
                            <TextField
                                id="category-select"
                                select
                                fullWidth
                                variant="outlined"
                                label="Category"
                                value={category ? category : undefined}
                                onChange={this.handleChange("category")}
                                required
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}>
                                {Object.entries(this.annotationTypes).map(type => {
                                    return <MenuItem key={type[0]} value={type[0]}>{type[1]}</MenuItem>
                                })}
                            </TextField>
                        </Grid>
                        <Grid item >
                            <TextField id="preferred-text"
                                       className={classes.textField}
                                       variant="outlined"
                                       label="Preferred Text"
                                       value={preferredText ? preferredText : ""}
                                       onChange={this.handleChange('preferredText')}
                            />
                        </Grid>
                        <Grid item>
                            <AutocompleteField
                                label="Concept"
                                conceptName={conceptName}
                                setSelectedValue={this.setSelectedValue}/>
                        </Grid>
                        <Grid item>
                            <TextField required
                                       className={classes.textField}
                                       id="CUI"
                                       variant="outlined"
                                       label="CUI"
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       value={CUI ? CUI : ""}
                            />
                        </Grid>
                        <Grid item>
                            <div>
                                { TUI  ? TUI.map(item => {
                                    return <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        placement="top-start"
                                        title={<Typography className={classes.toolTipText}>{item.description}</Typography>}
                                    >
                                    <Chip
                                        label={item.tui}
                                        onDelete={this.handleDelete(item.tui)}
                                    />
                                    </Tooltip>
                                }) : (<></>) }
                            </div>
                        </Grid>
                        <Grid item>
                            <TextField id="Notes"
                                       className={classes.textField}
                                       variant="outlined"
                                       value={notes ? notes : ""}
                                       onChange={this.handleChange('notes')}
                                       label="Notes"/>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" className={classes.formButtons}>
                        <Grid item>
                            <div>
                                <Button size="large" type="button" color={"secondary"} onClick={this.onCancel}>
                                    <FormattedMessage id="submit-button" defaultMessage={"CANCEL"}/>
                                </Button>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{justifyContent: "flex-end"}}>
                                <Button size="large" type="submit">
                                    <FormattedMessage id="submit-button" defaultMessage={"SUBMIT"}/>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(AnnotationPopper);
