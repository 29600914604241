import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputAdornment, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        paddingBottom: "16px",
    },
    context: {
        paddingBottom: "12px",
    },
    text: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    steps: {
        display: "flex",
    },
    card: {
        width: "100%",
    },
    cardHeader: {

    },
    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",
    },
    listItem: {
        minHeight: "32px",
    }
});

class QuestionsApprovalComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            text: "",
            questions: [],
            questions_removed: [],
        };

        this.__remove = this.__remove.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Questions Approval Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __inputChanged = (event) => {

        this.setState({
            text: event.target.value,
        });
    }

    __add = () => {

        let { questions, text } = this.state;

        if( text === "") {
            notificationService.error("Please add a question.");
            return;
        }

        questions.push({
            id: uuid().toString(),
            text: text
        });

        this.setState({
            questions: questions,
            text: ""
        })

    }
    __toggle = (_item) => () => {

        let { questions_removed } = this.state;

        const item = questions_removed.filter(item => item === _item.id);
        if(item.length > 0) {
            questions_removed = questions_removed.filter(item => item !== _item.id);
        } else {
            questions_removed.push(_item.id);
        }
        this.setState({
            questions_removed: questions_removed,
        });
    }


    __remove = (_item) => () => {

        let { questions } = this.state;

        questions = questions.filter(item => item.id !== _item.id);

        this.setState({
            questions: questions,
        });
    }

    __submit = () => this.bloc.submitTask({ data: { questions_removed: this.state.questions_removed, questions: this.state.questions.map(value => {
                return {
                    "text": value.text
                }
            }) } });
    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const { pipeline, classes } = this.props;
        const { initialising, task, name, questions, questions_removed, text} = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please approve the below questions. Additionally, you can add more questions before approving.`}
                                        titleTypographyProps={{ className: classes.cardTitle }}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Observation: ${task.data.observation}`}
                            </ListSubheader>
                            <CardContent>
                                <FormControl className={classes.input}>
                                    <InputLabel htmlFor="questionTxt">Question Input</InputLabel>
                                    <Input
                                        id="questionTxt"
                                        type="text"
                                        multiline
                                        maxRows={8}
                                        value={text}
                                        onChange={this.__inputChanged}
                                    />
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__add}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List dense={true}>
                                    {this.__renderItems(classes, task, questions_removed)}
                                    {this.__renderEdits(classes, questions)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderItems = (classes, task, removed) => {

       return task.questions.map(question => {

           const checked = removed.includes(question.id)

           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText style={{ textDecoration: checked ? "line-through" : "none" }} primary={ question.text }/>
                   <ListItemSecondaryAction>
                       <Switch
                           edge="end"
                           onChange={this.__toggle(question)}
                           checked={ checked }
                           inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                       />
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, questions) => {

       return questions.map(question => {
           return (<>
               <Divider />
               <ListItem className={classes.listItem}>
                   <ListItemText primary={ question.text }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__remove(question)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<QuestionsApprovalComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

