import React from 'react';

import AnnotatedText from "../../shared/AnnotatedText";
import {annotationHelpers} from "./shared/annotation";

export function TextAnnotation(props) {

    const { text, entities, handleRemoveAnnotation } = props;

    let renderArray = [];

    if (entities.length > 0) {

        entities.sort(annotationHelpers.compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {

                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    renderArray.push(<>{text.slice(count, currentEntity.start)}</>);
                }

                entityCount += 1;

                while (entities[entityCount] && annotationHelpers.encloses(currentEntity, entities[entityCount])) {

                    highlights.push(entities[entityCount]);
                    entityCount += 1;
                }

                renderArray.push(<AnnotatedText text={text} annotations={highlights} handleRemoveAnnotation={handleRemoveAnnotation}/>)

                count = currentEntity.end;
            }

            // Adding the rest of the text
            if (count < text.length) {

                renderArray.push(<>{text.slice(count)}</>);
            }

        } else {

            chunk = text;
        }

        if (chunk.length > 0) {
            renderArray.push(chunk);
        }
    } else {

        renderArray.push(text)
    }

    return (
        <>
            { renderArray }
        </>
    );
}