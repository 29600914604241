import React from 'react';
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import UserManagement from './UserManagement';
import SessionManagement from './SessionManagement';
import SystemHealth from "./SystemHealth";
import RedisDashboard from "./Redis";

const styles = theme => ({
    root: {
        maxHeight: "calc(100vh - 64px)",
        flexGrow: 1,
    },
    grid: {
      maxHeight: "calc(100vh - 64px)",
      minHeight: "100%",
    },
});

class Admin extends React.Component {

    state = {
        open: false,
        user: {},
    };

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.grid}>
                    <Route path="/admin/user-management" exact component={ UserManagement } />
                    <Route path="/admin/session-management" exact component={ SessionManagement } />
                    <Route path="/admin/system/health" exact component={ SystemHealth } />
                    <Route path="/admin/system/redis" exact component={ RedisDashboard } />
                </Grid>
            </div>
        );
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Admin));
