import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {formatItem, resolveBackgroundColor, resolveEntries, resolveSecondLevel} from "./contextMenuRules";


const styles = theme => ({
    root: {
    },
    menuItem: {
        paddingLeft: "30px",
        minHeight: "54px",
        height: "54px",
        "& p": {
            fontSize: "26px",
            fontWeight: "bold",
            lineHeight: "54px",
            margin: "0px",
        },
    },
});


class AnnotationContextMenuItems extends Component {

    //TODO: Add click away document click listener.
    constructor(props) {
        super(props);

        this.keyPressed.bind(this);
        this.menuPressed.bind(this);
        this.handleTabThroughMenu.bind(this);

        this.initialiseMenus(props, true);
    }

    componentWillMount() {
        document.addEventListener("keyup", this.keyPressed);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.keyPressed);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.targetslot && nextProps.targetslot !== this.props.targetslot) {

            this.initialiseMenus(nextProps);

        } else if(!nextProps.targetslot) {
            this.setState({
                target: undefined,
                entries: [],
            });
        }
    }

    __buildDynamicMenu(menuOverride) {

        let menu = {};

        if(menuOverride?.menu) {
            Object.keys(menuOverride.menu).forEach(key => {
                this.__buildDynamicMenuItem(menu, key, menuOverride.menu[key]);
            });
        }

        return menu;
    }

    __buildDynamicMenuItem(container, parent, children) {

        container[`${parent}`] = []
        const keys = Object.keys(children);
        for(let i = 0; i < keys.length; i++) {
            const child = children[keys[i]];

            let submenu = {
                hotkey: `${i}`,
                text: formatItem(keys[i]),
                code: keys[i],
                next: child.length > 0 ? '__dynamic__' : undefined,
            };

            if(Array.isArray(child) && child.length > 0) {
                submenu['next'] = '__dynamic__';
                container[`${keys[i]}`] = [];
                for(let x = 0; x < child.length; x++) {
                    container[`${keys[i]}`].push({
                        hotkey: `${x}`,
                        text: formatItem(child[x]),
                        code: child[x],
                    });
                }
            } else if(Object.keys(child).length > 0){
                submenu['next'] = '__dynamic__';
                this.__buildDynamicMenuItem(container, keys[i], child)
            }

            container[`${parent}`].push(submenu);
        }
    }

    initialiseMenus = (props, first) => {
        let menus = resolveEntries(props.menuOverride ,props.targetslot, props.targetSlotSpecification, props.relatedSlots, props.relatedSlotSpecifications, props.includeDefault);
        let dynamicMenu = this.__buildDynamicMenu(props.menuOverride);

        if(first) {

            // eslint-disable-next-line react/no-direct-mutation-state
            this.state = {
                menus: menus,
                menuIndex: 0,
                target: props.targetslot,
                entries: menus[0].items,
                dynamicMenu: dynamicMenu,
            };
        } else {

            this.setState({
                menus: menus,
                menuIndex: 0,
                target: props.targetslot,
                entries: menus[0].items,
                dynamicMenu: dynamicMenu,
            });
        }
    };

    menuPressed = (entry) => {

        return(event) => {

            this.sendSignal(entry, this.props.relatedDomains, this.props.relatedSlotSpecifications);
            event.stopPropagation();
        }
    };

    keyPressed = (event) => {

        if(event.key === 'Escape') {

            this.props.menuItemSelected({
                action: "close",
            });
        } else if(event.key === ' ') {

            this.handleTabThroughMenu(event);
            event.preventDefault();
        } else {

            let { entries } = this.state;

            for(let entryIndex in entries) {

                let entry = entries[entryIndex];

                if(`${entry.hotkey}` === `${event.key}`) {

                    this.sendSignal(entry, this.props.relatedDomains, this.props.relatedSlotSpecifications);
                    return;
                }
            }
        }
    };

    handleTabThroughMenu = (event) => {

        const { menus, menuIndex } = this.state;

        let newIndex = (menuIndex + 1) % menus.length;

        this.setState({
            menuIndex: newIndex,
            entries: menus[newIndex].items,
        });
    };

    sendSignal(item, domains, specifications) {

        if(item.next) {

            this.setState({
                entries: resolveSecondLevel(this.state.dynamicMenu, this.props.targetslot, item.next, item.code, this.props.targetSlotSpecification, domains, specifications),
            });
        }


        this.props.menuItemSelected({
            action: "found",
            item: item,
        });
    }

    render() {

        let { classes } = this.props;
        let { entries } = this.state;

        return (
            <div>
                {
                    entries.map((entry) => {
                        const style_item = {
                            backgroundColor: `${resolveBackgroundColor(entry.hotkey)}`,
                        };
                        return (
                            <div onClick={this.menuPressed(entry)} className={[classes.menuItem].join(' ')} style={style_item} >
                                <p>{entry.hotkey} : {entry.text}</p>
                            </div>);
                    })
                }
            </div>
            );
    }
}

export default withStyles(styles)(AnnotationContextMenuItems);
