

class AnnotationHelpers {

    encloses = (entity1, entity2) => {

        return entity1.start <= entity2.start &&
            (entity1.end) >= (entity2.end);
    }

    compareEntities = (a, b) => {

        if (b.start < a.start) {
            return 1;
        }

        if (b.start > a.start) {
            return -1;
        }

        if (b.end < a.end) {
            return -1;
        }

        if (b.end > a.end) {
            return 1;
        }

        return 0;
    }
}

export const annotationHelpers = new AnnotationHelpers();
