import React from 'react';
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

import FederatedJobs from './Federated';
import ExceptionJobs from './Exception';
import ClinicalSummaryJobs from './ClinicalSummaryJobs';
import DataPreparationJob from "./DataPreparationJobs/Preparation";
import AuthoringTasks from "./DataPreparationJobs/Authoring";
import AsyncJobs from "./Async";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
      minHeight: "100%",
    },
});

class Jobs extends React.Component {

    state = {
        open: false,
        user: {},
    };

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.grid}>
                    <Route path="/jobs/async" component={ AsyncJobs } />
                    <Route path="/jobs/federated" exact component={ FederatedJobs } />
                    <Route path="/jobs/exception" exact component={ ExceptionJobs } />
                    <Route path="/jobs/clinical-summary" exact component={ ClinicalSummaryJobs } />
                    <Route path="/jobs/data-preparation" exact component={ DataPreparationJob } />
                    <Route path="/jobs/authoring-tasks" exact component={ AuthoringTasks } />
                </Grid>
            </div>
        );
    }
}

Jobs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Jobs));
