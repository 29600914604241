import React, {Component} from 'react';

import DocumentPreparationJobContext from "../context";
import {withStyles} from '@material-ui/core/styles';
import {
    Button, Grid,
    TextField, Checkbox, FormControlLabel, CircularProgress, LinearProgress
} from '@material-ui/core';

import {FormattedMessage} from "react-intl";
import {dataPreparationApi} from "../../../../../utils/services/datapreparation.api";
import {notificationService} from "../../../../../utils/notification";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {DataPreparationBlocEvent, MetadataState, CodeSystems, JobTypesEnum} from "../dataPreparation.bloc";

import AutocompleteField from "../Authoring/Annotation/AutocompleteField";

const styles = theme => ({
    root: {
        width: "100%",
        minWidth: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        padding: "30px 25px",
        display: "flex",
        flexDirection: "column",
    },
    field: {
        margin: '1ch',
        minWidth: "95%"
    },
    extractField: {
        minWidth: "100%",
    },
    checkbox: {
        color: "#fff",
        paddingLeft: "10px",
        margin: '-0.5ch'
    },
    title: {
        fontSize: "24px",
        fontWeight: "400",
        color: "#fff",
        marginBottom: "7px",
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

});


class TextEditorPanel extends Component {

    bloc;
    eventSubscription;

    constructor(props, context) {
        super(props, context);

        this.state = this.getDefaultState();

        this.bloc = this.props.context.bloc;
    }

    getDefaultState = () => {
        return {
            documentName: '',
            documentSource: '',
            documentPublishedDate: '',
            documentPageNumbers: '',
            documentConcept: '',
            documentCUI: '',
            documentICD10: '',
            imagesChecked: false,
            tablesChecked: false,
            editedText: '',
            rejectionReason: '',
            openRejectDialog: false,
            rejectLoading: false,
        };
    }

    componentWillMount() {
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleEvent);
        this.reset()
    }

    componentWillUnmount() {
        this.eventSubscription.unsubscribe();
    }

    __handleEvent = (event) => {

        const type = event.event;
        const data = event.data;

        switch (type) {
            case DataPreparationBlocEvent.REVIEW_SCREEN_CLEARED:
                this.setState({
                    ...data
                });
                break;
        }
    }

    handleCheckboxChange = name => event => {
        this.setState({[name]: event.target.checked});
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    reset = () => {
        this.setState(this.getDefaultState());
        if (this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW) {
            this.props.context.bloc.resetReviewScreen()
        } else {
            this.setState({
                editedText: this.props.context.bloc.subject.value.text
            })
        }
    }

    onReject = event => {

        this.props.setBusy(true)

        let { context } = this.props
        let { job } = context
        let { rejectionReason } = this.state;
        job.status = "REJECTED"
        job.rejectionComment = rejectionReason

        dataPreparationApi.submitMetadata(job)
            .then(value => {

                this.props.setBusy(false)
                this.handleCloseRejectDialog()
                notificationService.success('Document rejected')
                context.bloc.finishJob()
            })
            .catch(reason => {

                this.props.setBusy(false)
                this.handleCloseRejectDialog()
                notificationService.error('Error rejecting document')

            })

        event.preventDefault()

    }

    handleSubmit = event => {

        this.props.setBusy(true)

        let { context } = this.props
        let { job } = context
        let { editedText,
            documentName,
            documentSource,
            documentPublishedDate,
            documentPageNumbers,
            documentConcept,
            documentCUI,
            documentICD10,
            imagesChecked,
            tablesChecked
            } = this.state;

        const preparedTextDocumentName = this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? `${job.code}.prepared.approved.txt` : `${job.code}.prepared.txt`
        const preparedTextDocumentCode = this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? "approvedTextExtractDocument" : "preparedTextExtractDocument";
        const preparedText = {"body": editedText};

        job.displayName = documentName;
        if (documentICD10 !== '') {

            // if icd10 classification did not exist
            if (job.classification.filter(function(code) { return code.system === CodeSystems.ICD10}).length === 0) {
                job.classification.push({
                    "code": documentICD10,
                    "system": CodeSystems.ICD10
                })
            }
            job.classification.filter(function(code) { return code.system === CodeSystems.ICD10})[0].code = documentICD10
        }

        if (documentCUI !== '' || documentConcept !== '') {

            // if umls classification did not exist
            if (job.classification.filter(function(code) { return code.system === CodeSystems.UMLS }).length === 0) {
                job.classification.push({
                    "code": documentCUI,
                    "display": documentConcept,
                    "system": CodeSystems.UMLS
                })
            }

            job.classification.filter(function(code) { return code.system === CodeSystems.UMLS })[0].code = documentCUI
            job.classification.filter(function(code) { return code.system === CodeSystems.UMLS })[0].display = documentConcept;
        }

        let provenance = {
            "source": documentSource,
            "pageNumber": documentPageNumbers,
            "publishedDate": documentPublishedDate,
        }

        job.provenance.push(provenance)

        let reference = {

            "code": {
                "system": "decoded/data",
                "code": preparedTextDocumentCode
            },
            "reference": `https://storage.cloud.google.com/dh-guidelines/registered/${preparedTextDocumentName}`,
            "contentType": "text/plain",
        }

        job.references.push(reference)
        job.containsImages = imagesChecked
        job.containsTables = tablesChecked

        job.status = this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? MetadataState.DOCUMENT_APPROVED :  MetadataState.PREPARED_FOR_REVIEW;

        Promise.all([dataPreparationApi.submitEditedDocument(preparedTextDocumentName, preparedText),
            dataPreparationApi.submitMetadata(job)])
            .then(value => {
                this.props.setBusy(false)
                notificationService.success('Successfully submitted document!')
                context.bloc.finishJob()
            })
            .catch(reason => {
                this.props.setBusy(false)

                notificationService.httpError(reason);
            })

        event.preventDefault()
    }

    handleOpenRejectDialog = () => {
        this.setState({
            openRejectDialog: true,
        })
    };

    handleCloseRejectDialog = () => {
        this.setState({
            openRejectDialog: false,
        })
    };


    setSelectedValue = (_newValue) => {
        if (_newValue == null) {
            this.setState({
                documentConcept: '',
                documentCUI: '',
            })
        } else {

            let sources = _newValue.sources.filter(item => {
                return item.system === "ICD10CM"
            })

            this.setState({
                documentConcept: _newValue.canonicalName,
                documentCUI: _newValue.conceptId,
                documentICD10: sources.map(item => item.code).join(', ')
            })
        }
    }

    render() {

        let {classes, busy } = this.props;
        let {
            documentName,
            documentSource,
            documentPublishedDate,
            documentPageNumbers,
            documentConcept,
            documentCUI,
            documentICD10,
            imagesChecked,
            tablesChecked,
            editedText,
            rejectionReason,
            openRejectDialog,
            rejectLoading
        } = this.state

        return (

            <div className={classes.root}>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container direction="row">
                        <Grid container item xs={6} direction="column">
                            <Grid item>
                                <TextField required
                                            id="document-name"
                                           type="text"
                                           label="Document Name"
                                           variant="outlined"
                                           placeholder="Enter document name"
                                           value={documentName}
                                           onChange={this.handleChange('documentName')}
                                           margin="normal"
                                           className={classes.field}
                                           disabled={busy}
                                />
                            </Grid>
                            <Grid item>
                                <TextField required
                                           id="document-source"
                                           type="text"
                                           label="Document Source"
                                           variant="outlined"
                                           placeholder="Enter document source"
                                           value={documentSource}
                                           onChange={this.handleChange('documentSource')}
                                           margin="normal"
                                           className={classes.field}
                                           disabled={busy}
                                />
                            </Grid>
                            <Grid item>
                                <TextField id="document-published-date"
                                           label="Document Published Date"
                                           type="text"
                                           variant="outlined"
                                           placeholder="Enter document published date"
                                           value={documentPublishedDate}
                                           onChange={this.handleChange('documentPublishedDate')}
                                           margin="normal"
                                           className={classes.field}
                                           disabled={busy}
                                />
                            </Grid>
                            <Grid item>
                                <TextField id="document-page-numbers"
                                           type="text"
                                           label="Page Numbers"
                                           variant="outlined"
                                           placeholder="Enter document page numbers"
                                           value={documentPageNumbers}
                                           onChange={this.handleChange('documentPageNumbers')}
                                           margin="normal"
                                           className={classes.field}
                                           disabled={busy}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} direction="column">
                            <Grid item className={classes.field}>
                                <AutocompleteField
                                    label="Concept"
                                    conceptName={documentConcept}
                                    setSelectedValue={this.setSelectedValue}/>
                            </Grid>
                            <Grid item>
                                <TextField required
                                           id="document-CUI"
                                           variant="outlined"
                                           label="CUI"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           value={documentCUI ? documentCUI : ""}
                                           className={classes.field}
                                           margin="normal"
                                           disabled={busy}
                                />
                            </Grid>
                            <Grid item>
                                <TextField id="document-ICD10"
                                           type="text"
                                           label="ICD10"
                                           variant="outlined"
                                           placeholder="Enter ICD10"
                                           value={documentICD10}
                                           onChange={this.handleChange('documentICD10')}
                                           margin="normal"
                                           className={classes.field}
                                           disabled={busy}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel className={classes.checkbox}
                                                  control={<Checkbox checked={imagesChecked}
                                                                     onChange={this.handleCheckboxChange('imagesChecked')}
                                                                     color="primary"
                                                                     name="images-checkbox"
                                                                     disabled={busy}
                                                  />}
                                                  label="Document contains images"
                                                  margin="normal"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel className={classes.checkbox}
                                                  control={<Checkbox checked={tablesChecked}
                                                                     onChange={this.handleCheckboxChange('tablesChecked')}
                                                                     color="primary"
                                                                     name="tables-checkbox"
                                                                     disabled={busy}
                                                  />}
                                                  label="Document contains tables"
                                                  margin="normal"

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <TextField id="text-extract"
                                       type="text"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       label={`${this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? "Extracted Text" : "Prepared Text"}`}
                                       multiline rows={25}
                                       variant="outlined"
                                       value={editedText}
                                       onChange={this.handleChange('editedText')}
                                       margin="normal"
                                       className={classes.extractField}
                                       disabled={busy}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="xlarge" color="secondary" disabled={busy}
                                    onClick={this.handleOpenRejectDialog}>
                                <FormattedMessage id="reject-button" defaultMessage={`REJECT DOCUMENT`}/>
                            </Button>
                        </Grid>
                        <Grid item justify="flex-end" container xs={4}>
                            <Button size="xlarge" onClick={this.reset} disabled={busy}>
                                <FormattedMessage id="reset-button" defaultMessage={`RESET`}/>
                            </Button>
                        </Grid>
                        <Grid item justify="flex-end" container xs={2}>
                            {busy && <LinearProgress/>}
                            <Button size="xlarge"
                                    type="submit"
                                    disabled={busy}
                            >
                                <FormattedMessage id="submit-button" defaultMessage={`${this.props.context.screen === JobTypesEnum.PREPARATION_REVIEW ? "APPROVE" : "SUBMIT"}`}/>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Dialog
                    open={openRejectDialog}
                    onClose={this.handleCloseRejectDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Reject Document</DialogTitle>
                    <form autoComplete="off" onSubmit={this.onReject}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please leave a comment on why this document is being rejected.
                            </DialogContentText>
                            <TextField required
                                       id="reject-comment"
                                       type="text"
                                       label="Comment"
                                       multiline rows={3}
                                       color="error"
                                       variant="outlined"
                                       value={rejectionReason}
                                       onChange={this.handleChange('rejectionReason')}
                                       className={classes.extractField}
                                       disabled={rejectLoading}>
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" disabled={rejectLoading} type="submit"> {
                                <FormattedMessage id="reject-button" defaultMessage={`REJECT`}/>
                            }
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <DocumentPreparationJobContext.Consumer>
        {value => {
            return (<TextEditorPanel context={value} {...props} />);
        }
        }
    </DocumentPreparationJobContext.Consumer>
));
