import React, {Component} from 'react';

import DocumentPreparationJobContext from "../../context";

import {withStyles} from '@material-ui/core/styles';
import {Checkbox, Chip, FormControlLabel, Grid, LinearProgress, Paper,} from '@material-ui/core';
import {AnnotationBlocEvent, AnnotationStatus} from "../annotation.bloc";
import HighlightableTextSection from "./HighlightableTextSection";


const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "83vh",
        minHeight: "83vh",
        padding: "15px 25px",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    summaryContainer: {
        height: "100%",
        maxHeight: "100%",
        overflow: "auto",
        padding: "4px 0",
    },
    textPanel: {
        overflow: "auto",
        maxHeight: "75vh",
    },
    chip: {
        margin: "8px",
        fontSize: "18px",
        justifyContent: "space-between",
    },
    chipsPanel: {
        marginBottom: "8px"
    }
});

class AnnotationTextPanel extends Component {

    eventSubscription;
    stateSubscription;

    constructor(props, context) {
        super(props, context);

        this.state = {
            resolvedContent: undefined,
            renderedEntities: [],
            referenceMap: {},
            automatedEntitiesChecked: false,
            checkboxDisabled: false,
        };

        this.__handleAnnotationState = this.__handleAnnotationState.bind(this);
        this.__handleAnnotationEvent = this.__handleAnnotationEvent.bind(this);
    }

    __handleAnnotationState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleAnnotationEvent = (event) => {
        const type = event.event;

        let updatedEntities = this.renderEntities()

        switch (type) {
            case AnnotationBlocEvent.ENTITIES_CHANGED:

                let checkboxState = this.isCheckboxDisabled()

                this.setState({
                    checkboxDisabled: checkboxState,
                    resolvedContent: updatedEntities.resolvedContent,
                    referenceMap: updatedEntities.referenceMap,
                });
                this.props.setScreenBusy(false)
                break;
            case AnnotationBlocEvent.ENTITIES_RESET:
                this.setState({
                    resolvedContent: updatedEntities.resolvedContent,
                    referenceMap: updatedEntities.referenceMap,
                    automatedEntitiesChecked: false,
                    checkboxDisabled: false,
                });
                this.props.setScreenBusy(false)
                break;
            case AnnotationBlocEvent.ERROR:
                this.props.setScreenBusy(false)
                break;
        }
    }

    renderEntities = () => {

        let {entities} = this.props.annotationBloc.subject.value
        let {preparedText} = this.props.annotationBloc.dataPreparationBloc.subject.value

        let result = []

        let sortedEntities = entities.sort((a, b) => (a.data.start > b.data.start) ? 1 : -1)
        let index = 0
        sortedEntities.forEach(entity => {
            let substring = preparedText.substring(index, entity.data.start)
            if (substring.length > 0) {
                result.push(substring)
            }

            result.push(entity)
            index = entity.data.end
        })

        result.push(preparedText.substring(index, preparedText.length))

        return {
            text: preparedText,
            entities: entities,
            resolvedContent: result
        }
    }

    componentDidMount() {
        this.stateSubscription = this.props.annotationBloc.subscribeToState(this.__handleAnnotationState);
        this.eventSubscription = this.props.annotationBloc.subscribeToEvents(this.__handleAnnotationEvent);
        this.props.annotationBloc.start(this.props.context.screen);
    }

    componentWillUnmount() {
        this.props.annotationBloc.close();
        this.stateSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
    }

    handleCheckboxChange = name => event => {
        this.setState({[name]: event.target.checked}, () => {
            let state = this.state.automatedEntitiesChecked
            let preparedText = this.props.annotationBloc.dataPreparationBloc.subject.value.preparedText

            state ?
                this.getAutomatedEntities(preparedText) :
                this.props.annotationBloc.deleteAutomatedEntities()
        });
    };

    getAutomatedEntities = (preparedText) => {
        this.props.setScreenBusy(true)
        this.props.annotationBloc.getAutomatedEntities(preparedText)
    }

    isCheckboxDisabled = () => {
        // disable checkbox if entities have already been created
        let {entities} = this.props.annotationBloc.subject.value

        return entities.length > 0
    }

    render() {

        let {classes} = this.props;
        let {resolvedContent, referenceMap, automatedEntitiesChecked, checkboxDisabled, busy} = this.state;
        let {job} = this.props.context;
        let {key} = job
        let {annotationBloc} = this.props
        let { entities, preparedText } = annotationBloc.subject.value

        return (
            <div className={classes.root}>
                {(busy) && <LinearProgress/>}
                <Grid container>
                    <Grid item xs={6} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={automatedEntitiesChecked}
                                    onChange={this.handleCheckboxChange('automatedEntitiesChecked')}
                                    name="automatedEntities"
                                    color="primary"
                                    disabled={checkboxDisabled}
                                />
                            }
                            label="Apply automated named entity recognition"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.chipsPanel}>
                        <Chip label="Automated" className={classes.chip} style={{backgroundColor: "#6200EE"}}/>
                        <Chip label="Manual" className={classes.chip} style={{backgroundColor: "#BB86FC"}}/>
                        <Chip label="Annotated" className={classes.chip} style={{backgroundColor: "#03DAC6"}}/>
                    </Grid>
                    <Grid item xs={12} className={classes.textPanel}>
                        <div className={classes.summaryContainer}>
                            {resolvedContent ? <HighlightableTextSection
                                id={key}
                                annotationBloc={annotationBloc}
                                content={preparedText}
                                entities={entities}
                                resolvedContent={resolvedContent}
                                referenceMap={referenceMap}
                            /> : (<></>)
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>

        );

    }
}

export default withStyles(styles)(props => (
    <DocumentPreparationJobContext.Consumer>
        {value => {
            return (<AnnotationTextPanel context={value} {...props} />);
        }
        }
    </DocumentPreparationJobContext.Consumer>
));
