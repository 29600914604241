import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";
import {
    FormGroup,
    Paper,
    Button,
    Snackbar,
    IconButton,
    LinearProgress,
    InputBase,
    FormControlLabel, Checkbox, Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from '@material-ui/icons/Search';
import {adminApi} from "../../../../utils/services/admin.api";
import {navigationTitleService} from "../../../../utils/title";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paperFullscreen: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#000",
        zIndex: "100000",
    },
    paper: {
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100%)",
        maxHeight: "calc(100vh - 64px)",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        margin: "auto",
        paddingTop: "15px",
    },
    paperContentFullscreen: {
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        paddingTop: "65px",
        backgroundColor: "#000",
        height: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        marginRight: "20px",
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});

class UserManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: false,
        };

        this.getUser.bind(this);
        this.updateUserPermissions.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("User Management");
    }

    getUser = () => {

        const {userId} = this.state;
        this.setState({
            busy: true,
        });

        adminApi.getUser(userId)
            .then(value => {

                let data = value.data;
                let response = {};

                if (!data) {

                    response = {
                        user: undefined,
                        error: `No one found with that key ${userId}..`,
                    };
                } else {

                    let roles = data.customClaims ? data.customClaims.roles || [] : [];

                    response = {
                        user: {
                            id: data.uid,
                            email: data.email,
                            displayName: data.displayName,
                            photoUrl: data.photoUrl,
                            roles: roles,
                        },
                    };
                }


                this.setState({
                    ...response,
                    busy: false,
                });
            });
    };

    updateUserPermissions = () => {

        const {user} = this.state;

        this.setState({
            busy: true,
        });

        adminApi.setPermissions(user.id, user.roles)
            .then(value => {

                this.setState({
                    error: "Saved.",
                    busy: false,
                });
            }).catch(reason => {

            this.setState({
                error: "Error updating, try again later.",
                busy: false,
            });
        });
    };

    handleUserIdInputChange = (event) => {

        this.setState({userId: event.target.value});
    };

    handleErrorUpdate = (data) => {

        this.setState({
            busy: false,
            error: data.data.text,
        });
    };

    handleChange = (name) => (event) => {

        let { user } = this.state;

        if(user.roles.includes(name)) {

            user.roles = user.roles.filter(item => item !== name);
        } else {

            user.roles.push(name);
        }

        this.setState({
            user: user,
        });
    };

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    render() {

        let {classes} = this.props;
        let {error, busy, userId, user} = this.state;

        let screen = (<>
            <Typography variant="h3" component="h4">
                <FormattedMessage id={"waiting"} defaultMessage={"Waiting..."} />
            </Typography>
        </>);

        if (user) {

            let admin = user.roles.includes("admin");
            let medical = user.roles.includes("medical");
            let annotator = user.roles.includes("annotator");
            let approver = user.roles.includes("approver");
            let sysadmin = user.roles.includes("sysadmin");

            screen = (
                <div className={classes.form}>

                    <Typography variant="h3" component="h4">
                        { user.displayName ?
                            (<><FormattedMessage id={"name"} defaultMessage={"Name"} /> : { user.displayName }</>) :
                            (<><FormattedMessage id={"email"} defaultMessage={"Email"} /> : { user.email }</>)
                        }
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={admin} onChange={this.handleChange('admin')} value="admin"/>}
                            label="Administrator"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={medical} onChange={this.handleChange('medical')}
                                               value="medical"/>}
                            label="Medical"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={annotator} onChange={this.handleChange('annotator')}
                                          value="annotator"/>
                            }
                            label="Annotator"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={approver} onChange={this.handleChange('approver')}
                                          value="approver"/>
                            }
                            label="Approver"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={sysadmin} onChange={this.handleChange('sysadmin')}
                                          value="sysadmin"/>
                            }
                            label="System Administrator"
                        />
                    </FormGroup>
                    <Button variant="contained" color="primary" onClick={this.updateUserPermissions} className={classes.button}>
                        Save
                    </Button>
                </div>);
        }


        let errorAlert = (<></>);

        if (error) {

            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    message={<span id="message-id">{error}</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            );
        }


        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {busy && <LinearProgress/>}
                    <div className={classes.paperContent}>
                        <Paper className={classes.inputPaper}>
                            <InputBase
                                onChange={this.handleUserIdInputChange}
                                value={userId}
                                className={classes.input}
                                placeholder="Retrieve by email or id"
                                inputProps={{'aria-label': 'user by email or id'}}
                            />
                            <IconButton onClick={this.getUser} className={classes.iconButton} aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                        </Paper>
                        <div className={classes.paperContentMessage}>
                            {screen}
                        </div>
                    </div>
                </Paper>
                {errorAlert}
            </div>
        );
    }
}

export default withStyles(styles)(UserManagement);
