import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {green} from '@material-ui/core/colors';
import {
    Paper,
    LinearProgress,
} from "@material-ui/core";
import {navigationTitleService} from "../../../../utils/title";
import {notificationService} from "../../../../utils/notification";
import {analyticsApi} from "../../../../utils/services/analytics.api";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        paddingTop: "24px",
        paddingBottom: "100px",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    paper: {
        height: "100%",
        width: "100%",
        minHeight: "fit-content",
        minWidth: "fit-content",
    },
    or: {
        textAlign: "center",
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    snackbarSuccess: {
        backgroundColor: green[600],
        padding: theme.spacing(0.5),
    },
    closeError: {
        padding: theme.spacing(0.5),
    },
    iframe: {
        height: "inherit",
        width: "inherit",
        padding: "25px"
    }
});

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: true,
            dashboardUrl: undefined,
        };
    }

    componentWillMount() {
        navigationTitleService.update("Dashboard");
        this._loadDashboard();
    }

    _loadDashboard = () => {

        analyticsApi.getDashboardURL()
            .then(value => {
                this.setState({
                    dashboardUrl: value.data.url,
                    busy: false,
                });
            }).catch(reason => {
            notificationService.update({"error": "Error loading dashboard - " + reason});
        });
    }

    render() {

        let {classes} = this.props;
        let {
            busy,
            dashboardUrl,
        } = this.state;

        console.log(dashboardUrl)

        const iframe = `<iframe src='${dashboardUrl}' style='height:100%;width:100%;border:none;overflow:hidden;'/>`

        return (
            <Paper className={classes.paper}>
                {busy && <LinearProgress />}
                {dashboardUrl && <div className={classes.iframe} dangerouslySetInnerHTML={{__html: iframe}}/>}
            </Paper>
        );
    }
}

export default withStyles(styles)(Dashboard);
