import React from 'react';

import {withStyles} from '@material-ui/core/styles';


import {

    Card,
    CardActions, CardContent,
    CardHeader, Checkbox,
    Divider, FormControlLabel,
    Grid,
    LinearProgress,
    ListSubheader, TextField, Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import {DeleteOutline} from "@material-ui/icons";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import {notificationService} from "../../../../../../../utils/notification";
import Conversation from "../DifferentialComponent/conversation";
import AutocompleteField from "./AutocompleteField";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px"
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        width: "100%",
        fontSize: "32px",
    },
    label: {
        marginBottom: "10px"
    },
    select: {
        margin: "24px",
        marginTop: "10px",
        flex: 1
    },
    conversation: {
        flex: 1,
        display: "flex",
        overflow: "auto",
    }
});

class ConversationLabellingComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            selectedDHObservations: [],
            selectedExtendedObservations: []
        }
    }

    componentWillMount() {
        navigationTitleService.update("Conversation labelling job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => {
        this.bloc.submitTask({
            data: {
                annotations: this.state.selectedDHObservations.concat(this.state.selectedExtendedObservations)
            }
        })
    }
    __getOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({
                query: _inputValue,
                dataType: ["trained"],
                criteria: {CONSUMER: {}, PROVIDER: {}}
            })
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {
                        const option = {
                            conceptId: item.conceptId,
                            name: item.canonicalName,
                            canonicalName: item.canonicalName
                        }
                        options.push(option)
                    })

                    resolve(options)
                }, error => {
                    notificationService.error("Error loading observations");
                    resolve(options)
                });
        })
    }

    __getExtendedOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({
                query: _inputValue, "criteria": {"SNOMED": {}, "ICD10": {}, "ICD9": {}}
            })
                .then(value => {
                    const items = value.data.results

                    items.forEach(item => {
                        const option = {
                            conceptId: item.conceptId,
                            name: item.canonicalName,
                            canonicalName: item.canonicalName
                        }

                        options.push(option)

                    })
                    resolve(options)
                }, error => {
                    notificationService.error("Error loading observations");
                    resolve(options)
                });
        })
    }

    __setValue = (_id, _value) => {

        if (_id === "dh_observations") {
            this.setState({
                selectedDHObservations: _value
            });
        } else if (_id === "extended_observations") {
            this.setState({
                selectedExtendedObservations: _value
            });
        }



    }

    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const {classes} = this.props;
        const {
            initialising,
            busy,
            task,
        } = this.state;

        if (initialising) return (<LinearProgress/>);

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                                title={`Label all observations for the following conversation`}
                                titleTypographyProps={{className: classes.cardTitle}}
                    />
                    <CardContent>
                        <Grid container direction="row" className={classes.textPanel}>
                            <Grid item xs={6}>
                                <Conversation data={task.data.conversation}/>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.select}>
                                    <Typography variant="h5" className={classes.label}>Annotations</Typography>
                                    <Typography className={classes.label}>Search the decoded vocabulary to annotate all clinical items in the
                                        conversation.
                                    </Typography>
                                    <AutocompleteField id="dh_observations"
                                                       label="Decoded Observations"
                                                       setSelectedValue={this.__setValue}
                                                       getOptions={this.__getOptions}/>
                                </div>
                                <div className={classes.select}>
                                    <Typography className={classes.label}>Search the extended vocabulary to add items missing from the decoded vocabulary.
                                    </Typography>
                                    <AutocompleteField id="extended_observations"
                                                       label="Extended Observations"
                                                       setSelectedValue={this.__setValue}
                                                       getOptions={this.__getExtendedOptions}/>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <CancelWithReasonButton disabled={busy} startIcon={<DeleteOutline/>} color={"primary"}
                                                variant={"outlined"}
                                                onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                        <span className={classes.fill}/>
                        <CriticalButton disabled={busy} startIcon={<PublishIcon/>} color={"primary"}
                                        variant={"outlined"}
                                        onConfirmed={this.__submit}>Publish</CriticalButton>
                    </CardActions>
                </Card>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<ConversationLabellingComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

