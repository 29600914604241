import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button, CircularProgress} from "@material-ui/core";
import AnnotationContextMenu from "../../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../../utils/text.highlight";
import {TextAnnotation} from "./TextAnnotation";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
        zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionSubtitle: {
        color: "#55CCFF",
        fontSize: "16px",
        fontWeight: "normal",
        paddingLeft: "81px",
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});




class TextAnnotationPanel extends Component {

    constructor(props) {
        super(props);

        const { bloc } = props;

        this.bloc = bloc;

        this.state = {
            bloc: bloc,
            added: props.initial?.added || [],
            removed: props.initial?.removed || [],
        };

        this._highlightAndOpenContext = this._highlightAndOpenContext.bind(this);
        this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
        this.__removeEntity = this.__removeEntity.bind(this);
    }

    componentDidMount() {

        this.subscription = this.bloc.subscribeToState(this.__handleStateUpdate);
    }

    componentWillUnmount() {

        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    __handleStateUpdate = (subject) => {

        this.setState({
            ...subject,
        });
    }


    _highlightAndOpenContext = (event) => {

        if (event.nativeEvent === "MouseEvent" && event.button !== 0) {
            if (this.state.popupPosition) {
                this.setState({
                    popupPosition: undefined,
                });
            }
            return;
        }

        const { message } = this.state;

        determineSelection(message, event.target)
            .then((value) => {

                Object.keys(value).forEach((key) => {

                    this.setState({
                        [key]: value[key],
                    });
                });
            });
    };

    noContextMenu = (event) => {

        event.preventDefault();
    };

    menuItemSelected = (event) => {

        if(event.action === 'close') {

            this.setState({
                selectedText: undefined,
                popupPosition: undefined,
            });
        } else if(event.action === 'found') {

            const { selectedText, selectedTextStart, added } = this.state;

            if(selectedText) {

                let _entity = {
                    start: selectedTextStart,
                    end: selectedTextStart + selectedText.length,
                    extract: selectedText,
                    category: event.item.code,
                };

                added.push(_entity);

                if(event.item.next) {

                    this.setState({
                        added: added,
                    })
                } else {

                    this.setState({
                        added: added,
                        selectedText: undefined,
                        popupPosition: undefined,
                    })
                }
            }
        }
    };

    __removeEntity = (annotatedText) => {
        const filterCallback = entity => entity.extract !== annotatedText.extract;

        this.setState((state, props) => ({
            added: state.added.filter(filterCallback)
        }));
    }

    __clear = () => {
        this.props.handleClear()
    };

    __back = () => {
        this.props.handleBack()
    };

    __exception = () => {
        this.props.handleException()
    };

    __submit = () => {
        const { added, removed } = this.state;
        this.props.handleSubmit({ added: added, removed: removed })
    };


    render() {

        const { busy,
            hint,
            title,
            subtitle,
            text,
            classes,
            contextMenuProps,
            handleSubmit,
            exceptionLabel,
            handleException,
            handleBack,
        } = this.props;

        let { added, popupPosition }  = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    { hint }
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <Button disabled={busy} onClick={this.__clear}><Undo fontSize="large" color="primary"/></Button>
                        { title }
                    </Typography>
                    <Typography variant='h7' align='left' className={classes.instructionSubtitle}>
                        { subtitle }
                    </Typography>
                </div>

                <div className={classes.content}>
                    { busy ? <CircularProgress/> :
                        <>
                            <h3 className={`${classes.message} annotationText`}
                                onContextMenu={this.noContextMenu}
                                onTouchEnd={this._highlightAndOpenContext}
                                onMouseUp={this._highlightAndOpenContext}
                            >
                                <TextAnnotation
                                    id={`this.props.id`}
                                    text={text}
                                    entities={added}
                                    handleRemoveAnnotation={this.__removeEntity}/>
                            </h3>
                            <AnnotationContextMenu
                                {...contextMenuProps}
                                menuItemSelected={this.menuItemSelected}
                                popupPosition={popupPosition}
                            />
                        </>
                    }
                </div>

                <div className={classes.fill} />

                <div className={classes.footer}>
                    { handleException  &&
                    <Button disabled={ busy } onClick={this.__exception}>
                        <Close fontSize="large" color="primary"/> { exceptionLabel ? <>{ exceptionLabel }</> : <FormattedMessage id="exception" defaultMessage="Exception" /> }
                    </Button>
                    }
                    { handleBack  &&
                    <Button disabled={ busy } onClick={this.__back}>
                        <ArrowBackIcon fontSize="large" color="primary"/> <FormattedMessage id="back" defaultMessage="Back" />
                    </Button>
                    }
                    <div className={classes.fill} />
                    { handleSubmit  &&
                    <Button disabled={ busy } onClick={this.__submit}>
                        <KeyboardReturn fontSize="large" color="primary"/>
                    </Button>
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(TextAnnotationPanel);