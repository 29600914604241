import React from 'react';

import * as THREE from "three";

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { CSS3DRenderer, CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";

import ConversationViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';

import DecodedComponent from "../../../shared/DecodedComponent";
import {Box, Button} from "@material-ui/core";
import {ConversationVisualBlocEvent} from "./bloc";

const styles = theme => ({
    root: {
        padding: "10px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    three: {
        minHeight: '100%',
        background: "radial-gradient(circle at 50% 50%,rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));",
    },
    css: {
        position: "absolute",
        top: "0",
        zIndex: "-1",
    },
    textElementHighlight: {
        color: 'rgba(255,255,255,1.0) !important',
        backgroundColor: 'rgba(99,251,255,0.5) !important',
    },
    textElement: {
        padding: "0 12px",
        borderRadius: "50px",
        color: 'rgba(255,255,255,0.1)',
    },
});

class VisualiseConversation extends DecodedComponent {

    pointer = new THREE.Vector2();

    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;

        this.threeRef = React.createRef();
        this.tempRef = React.createRef();

        this.state = {
            cameraProps :{
                x: 0,
                rotation: 90,
                rotationSpeed: 0.05,
            },
            quinn: { x: "100px" },

        };

        this.__renderThree = this.__renderThree.bind(this);
    }

    degInRad = (deg) => {
        return deg * Math.PI / 180;
    }

    componentDidMount() {

        super.componentDidMount();

        document.body.addEventListener( 'pointermove', this.__onPointerMove );

        const { classes } = this.props;
        const { cameraProps } = this.state;

        this.scene = new THREE.Scene();
        this.cssScene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1.0, 10000 );
        this.camera.position.set(0, 0, 100);
        this.camera.lookAt(0, 0, 0);
        this.camera.rotateOnAxis('X', this.degInRad(cameraProps.rotation));
        this.camera.updateProjectionMatrix();

        this.__createWebglRenderer();
        this.__createCssRenderer();

        let light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( 0, 0, 1000 );
        this.scene.add( light );

        light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( 0, 1000, 0 );
        this.scene.add( light );

        light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( 1000, 0, 0 );
        this.scene.add( light );

        light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( 0, 0, -1000 );
        this.scene.add( light );

        light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( 0, -1000, 0 );
        this.scene.add( light );

        light = new THREE.DirectionalLight( 0xffffff);
        light.position.set( -1000, 0, 0 );
        this.scene.add( light );

        this.threeRef.current.appendChild( this.cssRenderer.domElement );
        this.cssRenderer.domElement.appendChild(this.renderer.domElement);

        let raycaster = new THREE.Raycaster();
        raycaster.params.Points.threshold = 0.1;

        const { conversation, ontology, observations, conditions, conditionRelationships } = this.props.context.bloc.subject.value;

        this.cache = {};

        this.__setupWall(this.scene, this.cache, conversation, ontology, observations, conditions, conditionRelationships)

        this.camera.position.z = 1000;

        const controls = new OrbitControls( this.camera, this.cssRenderer.domElement );
        // controls.enablePan = false;
        controls.autoRotate = true;
        controls.maxAzimuthAngle = 0;

        this.__renderThree(this.renderer, this.cssRenderer, this.scene, this.cssScene, this.camera, controls, raycaster)

        this.bloc.pollCurrentConversation();
    }

    __createCssRenderer = () => {
        this.cssRenderer = new CSS3DRenderer();
        this.cssRenderer.setSize( window.innerWidth, window.innerHeight );
        this.cssRenderer.domElement.style.position = 'absolute';
        this.cssRenderer.domElement.style.zIndex = 0;
        this.cssRenderer.domElement.style.top = 0;
    }

    __createWebglRenderer = () => {
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setSize(window.innerWidth,window.innerHeight);
        this.renderer.domElement.style.position = 'absolute';
        this.renderer.domElement.style.zIndex = 1;
        this.renderer.domElement.style.top = 0;
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.bloc.stopPollCurrentConversation();
        document.body.removeEventListener( 'pointermove', this.__onPointerMove );
    }



    __onPointerMove = ( event ) => {

        this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    }


    __renderThree = (renderer, cssRenderer, scene, cssScene, camera, controls, raycaster) => {

        const $this = this;

        let animate = function () {

            const { cameraProps } = $this.state;

            let x = camera.rotation.x;
            let y = camera.rotation.y;
            let z = camera.rotation.z;

            // let position = new THREE.Euler(x, y, z, 'XYZ')
            // console.log(THREE.Math.radToDeg(position));
            //
            // if(camera.position.x > cameraProps.x) {
            //     camera.position.x = x * Math.cos(cameraProps.rotationSpeed) - z * Math.sin(cameraProps.rotationSpeed);
            //     camera.position.z = z * Math.cos(cameraProps.rotationSpeed) + x * Math.sin(cameraProps.rotationSpeed);
            // }

            // raycaster.setFromCamera( $this.pointer, camera );

            // let intersects = raycaster.intersectObject( $this.pointCloud );

            // console.log(intersects, $this.pointer);

            controls.update();

            renderer.render( scene, camera );
            cssRenderer.render( cssScene, camera );
            requestAnimationFrame( animate );
        };
        animate();
    }

    __addLine = (scene, from, to) => {

        const lineMaterial = new THREE.LineBasicMaterial( { color: 0x19DBFF, transparent: true, opacity: 0.25 } );

        const points = [];
        points.push( new THREE.Vector3( from.x, from.y, from.z ) );
        points.push( new THREE.Vector3( to.x, to.y, to.z ) );

        const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );

        const line = new THREE.Line( lineGeometry, lineMaterial );

        scene.add( line );
    }

    __addActiveLine = (scene, from, to) => {

        const lineMaterial = new THREE.LineBasicMaterial( { color: 0xCFF7FE, transparent: true, opacity: 1.0 } );

        const points = [];
        points.push( new THREE.Vector3( from.x, from.y, from.z ) );
        points.push( new THREE.Vector3( to.x, to.y, to.z ) );

        const lineGeometry = new THREE.BufferGeometry().setFromPoints( points );

        const line = new THREE.Line( lineGeometry, lineMaterial );

        scene.add( line );
    }

    __addSphere = (scene, point) => {

        let geometry = new THREE.SphereGeometry(5, 32, 26);

        const material = new THREE.MeshStandardMaterial( {

            color: new THREE.Color().setHex(0x63FBFF),
            roughness: 0.5,
            metalness: 0,
            // flatShading: true,
            wireframe: true,

        } );

        const sphere = new THREE.Mesh(geometry, material);


        sphere.position.setX(point.x)
        sphere.position.setY(point.y)
        sphere.position.setZ(point.z)

        scene.add(sphere)
    }

    __addText = (content, name, point) => {

        const element = document.createElement( 'div' );
        element.name = name;
        element.className = this.props.classes.textElement;

        const symbol = document.createElement( 'div' );
        symbol.className = 'symbol';
        symbol.textContent = content;
        element.appendChild( symbol );

        this.tempRef.current.appendChild(element);
        const width = element.clientWidth;
        this.tempRef.current.removeChild(element);

        const objectCSS = new CSS3DObject( element );
        objectCSS.position.x = point.x;
        objectCSS.position.y = point.y;
        objectCSS.position.z = point.z - ( ( width / 2 ) + 32 );
        objectCSS.rotateY(THREE.Math.degToRad(-90));
        this.cssScene.add( objectCSS );
    }

    __rotate = () => {
        let { cameraProps } = this.state;
        if(cameraProps.rotation === 90) {
            cameraProps.rotation = 45;
            cameraProps.x = -1600;
            this.setState({
                cameraProps: cameraProps
            });
        }
    }

    __setupWall = (scene, cache, conversation, ontology, observations, conditions, conditionRelationships) => {

        const sprite = new THREE.TextureLoader().load( process.env.PUBLIC_URL + '/assets/bubble_inactive.svg' );
        sprite.wrapS = THREE.RepeatWrapping;
        sprite.wrapT = THREE.RepeatWrapping;

        let geometry = new THREE.BufferGeometry();

        const points = []

        let nodesspace = 20;
        const nodesize = 10;

        const observationsLength = Math.ceil(Math.sqrt(observations.length));
        let halfLength = Math.ceil(observationsLength / 2);

        let start = -halfLength
        let index = 0;
        let zIndex = -250;
        for (let i = start; i < halfLength; i++) {
            for (let j = start; j < halfLength; j++) {

                if(observations.length <= index) {
                    break;
                }

                const observation = observations[index];

                let v = new THREE.Vector3();
                v.x = i * nodesspace
                v.y = j * nodesspace
                v.z = zIndex
                points.push(v);

                cache[`${v.x}-${v.y}-${v.z}`] = observation;
                cache[`observation-${observation._key}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }};
                cache[`observation-${observation.bid}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }};

                index++;

                let observationLookup = [];

                observation.codes.forEach((code) => {

                    cache[`observation-${code.code}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }};
                    observationLookup.push(code.code)
                });

                if(observationLookup.length > 0) {

                    cache[`observation-${observation.bid}-lookup`] = observationLookup;
                }
            }
        }


        const ontologySkip = 50;
        let ontologyMissed = 0;
        let filteredOntology = ontology.filter(_item => {

            const match = cache[`observation-${_item.c}`];
            if(match) return true;

            ontologyMissed++;
            return ontologyMissed % ontologySkip === 0;
            });

        const ontologyLength = Math.ceil(Math.sqrt(filteredOntology.length));
        halfLength = Math.ceil(ontologyLength / 2);

        start = -halfLength
        index = 0;
        zIndex = 0;

        nodesspace = 10;

        for (let i = start; i < halfLength; i++) {
            for (let j = start; j < halfLength; j++) {

                if(filteredOntology.length <= index) {
                    break;
                }

                const ontologyElement = filteredOntology[index];

                let v = new THREE.Vector3();
                v.x = i * nodesspace
                v.y = j * nodesspace
                v.z = zIndex

                cache[`${v.x}-${v.y}-${v.z}`] = ontologyElement;
                cache[`ontology-${ontologyElement.c}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }};


                const observationElement = cache[`observation-${ontologyElement.c}`];
                if(observationElement) {
                    this.__addLine(scene, { x: observationElement.point.x, y: observationElement.point.y, z: observationElement.point.z }, { x: v.x, y: v.y, z: v.z })
                }

                index++;
                points.push(v);
            }
        }

        const conditionsLength = Math.ceil(Math.sqrt(conditions.length));
        halfLength = Math.ceil(conditionsLength / 2);

        nodesspace = 30;

        start = -halfLength
        index = 0;
        zIndex = -500;
        for (let i = start; i < halfLength; i++) {
            for (let j = start; j < halfLength; j++) {

                if(conditions.length <= index) {
                    break;
                }

                const condition = conditions[index];

                let v = new THREE.Vector3();
                v.x = i * nodesspace
                v.y = j * nodesspace
                v.z = zIndex

                cache[`${v.x}-${v.y}-${v.z}`] = condition;
                cache[`condition-${condition._key}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }, display: condition.display, name: condition.name };
                cache[`condition-${condition.bid}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }, display: condition.display, name: condition.name };

                index++;

                condition.codes.forEach((code) => {

                    cache[`condition-${code.code}`] = { index: `${v.x}-${v.y}-${v.z}`, point: { x: v.x, y: v.y, z: v.z }, display: condition.display, name: condition.name };

                    const observation = cache[`observation-${code.code}`];
                    if(observation) {
                        this.__addLine(scene, { x: observation.point.x, y: observation.point.y, z: observation.point.z }, { x: v.x, y: v.y, z: v.z })
                    }
                });

                points.push(v);
            }
        }

        conditionRelationships.forEach((relationship) => {

            const from = cache[`condition-${relationship.from}`];
            const to = cache[`observation-${relationship.to}`];

            if(from && to) {

                let condition = cache[from.index];
                let observation = cache[to.index];

                observation.codes.forEach((_code) => {

                    let links = cache[`observation-${_code.code}-conditions`];
                    if(!links) {
                        links = [];
                        cache[`observation-${_code.code}-conditions`] = links;
                    }

                    condition.codes.forEach((_conditionCode) => {
                        links.push(_conditionCode.code);
                    });
                });
                this.__addLine(scene, { ...from.point }, { ...to.point });
            }
        });


        zIndex = 400;

        let v = new THREE.Vector3();
        v.x = 0
        v.y = 0
        v.z = zIndex
        points.push(v);

        this.__addSphere(scene, { x: v.x, y: v.y, z: v.z });


        geometry.setFromPoints( points )
        geometry.computeVertexNormals()

        // const material = new THREE.MeshStandardMaterial( {
        //
        //     color: new THREE.Color().setHSL( Math.random(), 1, 0.75 ),
        //     roughness: 0.5,
        //     metalness: 0,
        //     flatShading: true
        //
        // } );

        const material = new THREE.PointsMaterial({
            map: sprite,
            size: nodesize,
            alphaTest: 0.5,
            transparent: true,
            sizeAttenuation: true,
        });


        // //
        // const material = new THREE.ShaderMaterial( {
        //
        //     uniforms: {
        //         amplitude: { value: 1.0 },
        //         color: { value: new THREE.Color( 0xffffff ) },
        //         pointTexture: { value: sprite }
        //     },
        //     vertexShader: document.getElementById( 'vertexshader' ).textContent,
        //     fragmentShader: document.getElementById( 'fragmentshader' ).textContent
        //
        // } );

        // Create a new particle system based on the provided geometry
        this.pointCloud = new THREE.PointCloud(geometry, material);

        // add the particle system to the scene
        scene.add(this.pointCloud);
    }

    __traceConcept = (scene, cache, concept, from) => {

        let ontologyElement = cache[`ontology-${concept}`];
        if(!ontologyElement) {
            const observationLookup = cache[`observation-${concept}-lookup`]
            if(observationLookup) {
                for (let i = 0; i < observationLookup.length; i++) {
                    ontologyElement = cache[`ontology-${observationLookup[i]}`];
                    if(ontologyElement) break;
                }
            }
        }

        if(ontologyElement) {
            this.__addActiveLine(scene, { ...from }, { ...ontologyElement.point });

            if(!cache[`sphere-${ontologyElement.point.x}-${ontologyElement.point.y}-${ontologyElement.point.z}`]) {
                cache[`sphere-${ontologyElement.point.x}-${ontologyElement.point.y}-${ontologyElement.point.z}`] = true;
                this.__addSphere(scene, { ...ontologyElement.point });
            }

            const observation = cache[`observation-${concept}`];

            if(observation) {
                this.__addActiveLine(scene, { ...ontologyElement.point }, { ...observation.point });
                if(!cache[`sphere-${observation.point.x}-${observation.point.y}-${observation.point.z}`]) {
                    cache[`sphere-${observation.point.x}-${observation.point.y}-${observation.point.z}`] = true;
                    this.__addSphere(scene, { ...observation.point });
                }

                const links = cache[`observation-${concept}-conditions`]

                if(links) {
                    links.forEach((link) => {
                        const condition = cache[`condition-${link}`];

                        if(condition) {
                            this.__addActiveLine(scene, {...observation.point}, {...condition.point});
                            if (!cache[`sphere-${condition.point.x}-${condition.point.y}-${condition.point.z}`]) {
                                cache[`sphere-${condition.point.x}-${condition.point.y}-${condition.point.z}`] = true;
                                this.__addSphere(scene, {...condition.point});
                                this.__addText( condition.display,  condition.name, {...condition.point});
                            }
                        }
                    });
                }
            }
        }

    }

    __handleEvent = (event) => {

        const { type, data } = event;

        switch (type) {
            case ConversationVisualBlocEvent.NEW_MESSAGES_LOADED: {
                this.__renderNewMessages(data.conversationMessages, data.conversationMessageIds);
                break;
            }
            case ConversationVisualBlocEvent.DIFFERENTIAL_LOADED: {
                this.__highlightDifferential(data);
                break;
            }
        }
    }

    __highlightDifferential = (differential) => {

        const { classes } = this.props;

        const inference = differential.value.inference.map(_condition => _condition.conditionName);

        this.cssScene.children.forEach(child => {
            const element = child.element;
            if(!inference.includes(element.name)) {
                element.classList.remove(classes.textElementHighlight);
            } else if(!element.classList.contains(classes.textElementHighlight)) {
                element.classList.add(classes.textElementHighlight);
            }
        });
    }

    __renderNewMessages = (conversationMessages, conversationMessageIds) => {

        let cache = this.cache;

        let newMessages = conversationMessages.filter((_message) => !cache[`messages-${_message.id}`]);

        if(newMessages.length === 0) return;

        const conversationMessagesLength = Math.ceil(Math.sqrt(conversationMessages.length));
        let halfLength = Math.ceil(conversationMessagesLength / 2);

        let nodesspace = 40;

        let start = -halfLength
        let index = 0;
        let zIndex = 200;

        let entities = [];



        for (let i = start; i < halfLength; i++) {
            for (let j = start; j < halfLength; j++) {

                if(newMessages.length <= index) {
                    break;
                }

                const conversationMessage = newMessages[index];

                let current = cache[`${i * nodesspace}-${j * nodesspace}-${zIndex}`]

                if(current) continue;

                cache[`${i * nodesspace}-${j * nodesspace}-${zIndex}`] = conversationMessage;
                cache[`messages-${conversationMessage.id}`] = `${i * nodesspace}-${j * nodesspace}-${zIndex}`;

                index++;

                let v = new THREE.Vector3();
                v.x = i * nodesspace
                v.y = j * nodesspace
                v.z = zIndex
                this.__addSphere(this.scene, { x: v.x, y: v.y, z: v.z });
                this.__addActiveLine(this.scene, { x: 0, y: 0, z: 400 }, { x: v.x, y: v.y, z: v.z });

                if(conversationMessage.metadata.entities) {

                    conversationMessage.metadata.entities.forEach((entity) => {

                        let codes = [];
                        if(entity.concepts?.length > 0) {
                            codes = entity.concepts.map(_concept => _concept.code.code);
                        }

                        entities.push({
                            from: {
                                x: v.x,
                                y: v.y,
                                z: v.z,
                            },
                            codes: codes,
                            c: entity.normalised === "" ? entity.extract : entity.normalised,
                            n: entity.normalised,
                        });
                    });
                }
            }
        }

        const entitiesLength = Math.ceil(Math.sqrt(entities.length));
        halfLength = Math.ceil(entitiesLength / 2);

        start = -halfLength
        index = 0;
        zIndex = 150;
        for (let i = start; i < halfLength; i++) {
            for (let j = start; j < halfLength; j++) {

                if(entities.length <= index) {
                    break;
                }

                const entity = entities[index];

                cache[`${i * nodesspace}-${j * nodesspace}-${zIndex}`] = entity;
                cache[`entities-${entity.c}`] = `${i * nodesspace}-${j * nodesspace}-${zIndex}`;

                index++;

                let v = new THREE.Vector3();
                v.x = i * nodesspace
                v.y = j * nodesspace
                v.z = zIndex

                this.__addActiveLine(this.scene, { ...entity.from }, { x: v.x, y: v.y, z: v.z });
                this.__addSphere(this.scene, { x: v.x, y: v.y, z: v.z });

                const concepts = entity['codes'];

                if(concepts) {
                    concepts.forEach((concept) => {

                        this.__traceConcept(this.scene, cache, concept, { x: v.x, y: v.y, z: v.z })
                    });
                }
            }
        }
    }

    render() {

        let { classes } = this.props;
        let {graph, graphIds} = this.props.context;

        return (
            <Box item xs={12} className={classes.three}>
                <div className={classes.three} ref={this.threeRef}></div>
                <div className={classes.css} ref={this.tempRef}></div>
            </Box>
        );
    }
}

export default withStyles(styles)(props => (
    <ConversationViewContext.Consumer>
        { value => {
            return (<VisualiseConversation context={value} {...props} />);
        } }
    </ConversationViewContext.Consumer>
));
