import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {uuid} from "uuidv4";
import {AnnotationStatus} from "../annotation.bloc";
import SelectableEntity from "./SelectableEntity";
import {determineSelection} from "../../../../../../utils/text.highlight";
import {notificationService} from "../../../../../../utils/notification";


const styles = theme => ({
    summarySection: {
        width: "calc(100% - 15px)",
        paddingLeft: "8px",
        color: "#fff",
        display: "flex",
    },

    summarySectionContent: {
        paddingTop: "5px",
        paddingBottom: "10px",
        fontSize: "24px",
    },
});


class HighlightableTextSection extends Component {

    handleMouseUp = event => {

        let selection = window.getSelection()

        // if selection is just a click - ignore
        if (selection.anchorOffset === selection.focusOffset) {
            return
        }
        // handle left to right select or right to left select
        let selectionStart = selection.anchorOffset < selection.focusOffset ? selection.anchorOffset : selection.focusOffset
        let selectionEnd = selection.anchorOffset < selection.focusOffset ? selection.focusOffset : selection.anchorOffset

        let offset = 0

        let children = document.getElementById("annotationPanel").childNodes
        let currentNode = document.getSelection().anchorNode

        if (selection.anchorNode !== selection.focusNode) {
            notificationService.error("Unable to annotate entity that already exists.")
            return
        }

        for (let item of children) {
            if (item === currentNode) {
                break;
            }
            offset += item.textContent.length
        }

        let entity = {
            data: {
                id: uuid(),
                extract: selection.toString(),
                start: selectionStart + offset,
                end: selectionEnd + offset,
            },
            state: AnnotationStatus.MANUAL_ENTITY
        }

        this.props.annotationBloc.createEntity(entity)

    }

    saveEntity = (entity) => {
        this.props.annotationBloc.saveEntity(entity)
    }

    deleteEntity = (entity) => {
        this.props.annotationBloc.deleteEntity(entity)
    }

    render() {

        let {classes, onClickEntity, resolvedContent} = this.props;

        return (
            <div className={classes.summarySection}>
                <div id={"annotationPanel"} className={classes.summarySectionContent} onMouseUp={this.handleMouseUp}>
                    {resolvedContent.map((item) => {
                        return typeof (item) == "object" ? (
                            <SelectableEntity onClickEntity={onClickEntity}
                                              entity={item}
                                              saveEntity={this.saveEntity}
                                              deleteEntity={this.deleteEntity}/>
                        ) : (<>{item}</>)
                    })
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(HighlightableTextSection);
