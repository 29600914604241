import React from 'react';

import {withStyles} from '@material-ui/core/styles';


import {

    Card,
    CardActions, CardContent,
    CardHeader, Checkbox,
    Divider, FormControlLabel,
    Grid,
    LinearProgress,
    ListSubheader, TextField, Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import {DeleteOutline} from "@material-ui/icons";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import {notificationService} from "../../../../../../../utils/notification";
import AutocompleteMultiselectFreeSolo from "./AutocompleteMultiselectFreeSolo";
import Conversation from "./conversation";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px"
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        width: "100%",
        fontSize: "32px",
    },
    label: {
        marginBottom: "10px"
    },
    select: {
        margin: "24px",
        marginTop: "10px",
        flex: 1
    },
    conversation: {
        flex: 1,
        display: "flex",
        overflow: "auto",
    }
});

class DifferentialComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            initialising: true,
            selectedDiagnosis: [],
            diagnosis: [],
        };
    }

    componentWillMount() {
        navigationTitleService.update("Differential Job");

        this.__getClasses("conditions").then(res => {
            this.setState({
                diagnosis: res.data.map(d => {
                    return {name: d.display, code: d.bid}
                }).sort((a, b) => a.name.localeCompare(b.name))
            })
        })
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => {
        const addedDiagnosis = this.state.selectedDiagnosis.filter(i => i.inputValue).map(i => {
            return {
                ...i,
                'rank': this.state.selectedDiagnosis.indexOf(i)+1
            }}).map(({inputValue, code, rank})=>({name: inputValue, code, rank}))
        const selectedDiagnosis = this.state.selectedDiagnosis.filter(i => i.code !== "").map(i => {
            return {
                ...i,
                'rank': this.state.selectedDiagnosis.indexOf(i)+1
            }})
        this.bloc.submitTask({
            data: {
                diagnosis: addedDiagnosis.concat(selectedDiagnosis)
        }
    })
    }

    __getClasses = (classType) => {

        return new Promise(resolve => {
            ontologyApi.listModels(classType)
                .then(value => {
                    resolve(value)
                }, error => {
                    notificationService.error(`Error loading ${classType} classes`);
                    resolve({"data": []})
                }).catch(e => {
                notificationService.error(`Error loading ${classType} classes`);
                resolve({"data": []})
            });
        });
    }

    setValue = (_id, _value) => {

        if (_id === "diagnosis") {
            this.setState({
                selectedDiagnosis: _value
            });
        }
    }

    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const {classes} = this.props;
        const {
            initialising,
            busy,
            task,
            diagnosis
        } = this.state;

        if (initialising) return (<LinearProgress/>);

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                                title={`Complete the differential diagnosis for the following conversation`}
                                titleTypographyProps={{className: classes.cardTitle}}
                    />
                    <ListSubheader className={classes.subHeader}>
                        {task.data.age && task.data.gender ? `${task.data.age} year old ${task.data.gender}` : (<></>)}
                    </ListSubheader>
                    <CardContent>
                        <Grid container direction="row" className={classes.textPanel}>
                            <Grid item xs={6}>
                                <Conversation data={task.data.conversation}/>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.select}>
                                    <Typography variant="h5" className={classes.label}>Differential
                                        Diagnosis</Typography>
                                    <Typography className={classes.label}>Please add five or more diagnoses to the
                                        differential. Ensure these are in the order of most likely to least likely.</Typography>
                                    <AutocompleteMultiselectFreeSolo id="diagnosis"
                                                             setSelectedValue={this.setValue}
                                                             options={diagnosis}/>
                                </div>
                            </Grid>
                        </Grid>

                    </CardContent>
                    <CardActions>
                        <CancelWithReasonButton disabled={busy} startIcon={<DeleteOutline/>} color={"primary"}
                                                variant={"outlined"}
                                                onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                        <span className={classes.fill}/>
                        <CriticalButton disabled={busy} startIcon={<PublishIcon/>} color={"primary"}
                                        variant={"outlined"}
                                        onConfirmed={this.__submit}>Publish</CriticalButton>
                    </CardActions>
                </Card>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<DifferentialComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

