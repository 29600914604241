import {Typography} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import React from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    resultTitle: {
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    inferenceResult: {
        width: '100%',
        paddingBottom: "2%",
    }
})

class InferenceResult extends React.Component {

    render() {

        let {classes, diagnosisPrediction} = this.props;

        let columns = [
            {field: 'condition', headerName: 'Condition', flex: 2},
            {field: 'likelihood', headerName: 'Likelihood', flex: 1},
            {field: 'associatedObservations', headerName: 'Associated Observations', flex: 5},
        ];

        let rows = diagnosisPrediction.inference
            .filter(item => item.associatedObservations.length > 0)
            .map((item, idx) => {
                return {
                    id: idx,
                    condition: item.condition,
                    likelihood: item.probability.toFixed(2),
                    associatedObservations: item.associatedObservations.map(item => {
                        return ` ${item.observation} (${item.weight})`
                    })
                }
            })

        return (<div>
            <Typography className={classes.resultTitle} variant='h4' align='left'>
                Differential Diagnosis Prediction
            </Typography>
            <div className={classes.inferenceResult}>
                <DataGrid rows={rows} columns={columns} autoHeight={true} disableSelectionOnClick/>
            </div>
        </div>);
    }
}

export default withStyles(styles)(InferenceResult);