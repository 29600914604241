import axios from "axios";

class DemoApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    currentConversations() {

        return axios.get(`${this.baseUrl}/internal/intelligence/demo/conversations`)
    }

    conversationsDifferential(conversationId) {

        return axios.get(`${this.baseUrl}/internal/intelligence/conversations/${conversationId}/clinical_inference/differential_diagnosis`)
    }

    conversationsClinicalSummary(conversationId, tenant) {
        return axios.get(`${this.baseUrl}/internal/intelligence/conversations/${conversationId}/clinical_summaries/_current`, {
            headers: {
                "X-Tenant-ID": tenant,
            }
        })
    }

}


export const demoApi = new DemoApi();
