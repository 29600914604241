import * as rxjs from 'rxjs';

import { check } from "../../../../utils/auth/Allowed";
import rules from "../../../../utils/auth/rules";
import {clinicalInferenceApi} from "../../../../utils/services/clinicalinference.api";
import {notificationService} from "../../../../utils/notification";
import {demoApi} from "../../../../utils/services/demo.api";
import {quinnApi} from "../../../../utils/services/quinn.api";
import {conversationApi} from "../../../../utils/services/conversation.api";

export class ConversationVisualBloc {

    initialisingIndexDB = false;

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            initialised: false,
            entry: undefined,
            busy: false,
            loaded: {
                conditions: false,
                conditionRelationships: false,
                observations: false,
                ontology: false,
                conversation: false,
            },
            loading: {
              ontology: { size: 999999, current: 0 },
            },
            ontology: [],
            conditions: [],
            conditionRelationships: [],
            observations: [],
            conversations: [],
            edges: [],
            patient: undefined,
            conversation: undefined,
        });

        this.events = new rxjs.Subject();

        this.__handleIndexDBSuccess = this.__handleIndexDBSuccess.bind(this);
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    initialise = () => {

        this.indexDB = window.indexedDB.open("decoded_console", 4);
        this.indexDB.onsuccess = this.__handleIndexDBSuccess;
        this.indexDB.onupgradeneeded = this.__handleIndexDBUpgradeNeeded;
        this.indexDB.onerror = this.__handleIndexDBError;
    }

    __handleIndexDBSuccess = (event) => {
        if(this.initialisingIndexDB) return;
        this.db = event.target.result;
        this.initialisingIndexDB = true;
        this.__initialise();
    }

    __handleIndexDBUpgradeNeeded = (event) => {
        if(this.initialisingIndexDB) return;
        this.initialisingIndexDB = true;
        this.db = event.target.result;
        this.db.createObjectStore("ontology", { keyPath: "c" });
        this.__initialise();
    }

    __handleIndexDBError = (event) => {
        console.log("IndexDB Error", event)
    }

    __initialise = () => {
        const $this = this;

        Promise.all([this.__loadCurrentConversations(), this.__loadConditions(), this.__loadObservations(), this.__loadConditionRelationships()])
            .then(values => {

                const { loaded, } = $this.subject.value;
                loaded.observations = true;
                loaded.conditions = true;
                loaded.conditionRelationships = true;
                this.__updateSubject({ loaded: loaded });

                $this.__readOntologyThroughCache($this);
            }, reason => notificationService.httpError(reason));
    }

    __updateSubject = (newProps) => {
        this.subject.next({
            ...this.subject.value,
            ...newProps,
        });
    }

    __readOntologyThroughCache = ($this) => {

        let objectStore = this.db.transaction("ontology").objectStore("ontology");

        objectStore.getAll().onsuccess = function(event) {
            console.log("Got all entries: " + event.target.result);
            if(!event.target.result || event.target.result.length === 0) {
                $this.__loadOntology($this);
            } else {

                const { loading, loaded } = $this.subject.value;
                loading.ontology.current = 1;
                loading.ontology.size = 2;
                loaded.ontology = true;
                let newOntology = event.target.result;
                $this.__updateSubject({ ontology: newOntology, loading: loading, loaded: loaded });
                $this.events.next({ type: ConversationVisualBlocEvent.ONTOLOGY_LOADED, data: {} });
            }
        };
    }

    __loadOntology = ($this) => {

        clinicalInferenceApi.getOntologyCount()
            .then(value => {

                const count = value.data;
                const size = 10000;
                const sections = Math.ceil(count / size);

                let { loading } = $this.subject.value;
                loading.ontology.size = sections;

                $this.__updateSubject({ loading: loading });

                $this.__loadOntologyPage($this, sections, 0, size);

            }, reason => notificationService.httpError(reason));
    }

    __saveOntology = (ontology) => {

        let transaction = this.db.transaction(["ontology"], "readwrite");

        transaction.oncomplete = function(event) {
            console.log("All done!");
        };

        transaction.onerror = function(event) {
            // Don't forget to handle errors!
            console.log("Error saving", event)
        };

        let objectStore = transaction.objectStore(["ontology"]);

        ontology.forEach(function(item) {
            let request = objectStore.add(item);
            request.onsuccess = function(event) {
                // event.target.result === customer.ssn;
            };
        });
    }

    __loadOntologyPage = ($this, pages, page, size) => {


        const current = sessionStorage.getItem("demo-ontology")

        if(current) {
            const { loading, loaded } = $this.subject.value;
            let value = JSON.parse(current);
            loading.ontology.current = page;
            loaded.ontology = true;
            this.__updateSubject({ ontology: value, loading: loading, loaded: loaded });
            $this.events.next({ type: ConversationVisualBlocEvent.ONTOLOGY_LOADED, data: {} });
            return;
        }

        clinicalInferenceApi.getOntology({ p: page, s: size })
            .then((value) => {

                let nextPage = page + 1;

                const { ontology, loading, loaded } = $this.subject.value;
                loading.ontology.current = page;
                loaded.ontology = !(nextPage<pages);
                let newOntology = [...ontology, ...value.data];
                this.__updateSubject({ ontology: newOntology, loading: loading, loaded: loaded });

                if(nextPage<pages) {
                    $this.__loadOntologyPage($this, pages, nextPage, size);
                } else {
                    this.__saveOntology(newOntology);
                    $this.events.next({ type: ConversationVisualBlocEvent.ONTOLOGY_LOADED, data: {} });
                }
            }, reason => notificationService.httpError(reason));
    }



    __loadConditions = () => {

        const $this = this;

        return new Promise((resolve, reject) => {
            clinicalInferenceApi.getConditions({ metadata: true })
                .then(data => {
                    $this.__updateSubject({ conditions: data.data });
                    resolve(data.data);
                }, reason => {
                    notificationService.httpError(reason);
                    reject(reason);
                });
        });
    }

    __loadCurrentConversations = () => {

        const $this = this;

        return new Promise((resolve, reject) => {
            demoApi.currentConversations()
                .then(data => {
                    $this.__updateSubject({ conversations: data.data });
                    resolve(data.data);
                }, reason => {
                    notificationService.httpError(reason);
                });
        });
    }

    __loadObservations = () => {

        const $this = this;

        return new Promise((resolve, reject) => {
            clinicalInferenceApi.getObservations({ metadata: true })
                .then(data => {
                    $this.__updateSubject({ observations: data.data });
                    resolve(data.data);
                }, reason => {
                    notificationService.httpError(reason);
                    reject(reason);
                });
        });
    }

    __loadConditionRelationships = () => {

        const $this = this;

        return new Promise((resolve, reject) => {
            clinicalInferenceApi.getConditionRelationships({ metadata: true })
                .then(data => {
                    $this.__updateSubject({ conditionRelationships: data.data });
                    resolve(data.data);
                }, reason => {
                    notificationService.httpError(reason);
                    reject(reason);
                });
        });
    }

    loadVisualisationData = (conversationId) => {
        this.__loadConversation(conversationId);
    }

    __loadConversation = (conversationId) => {

    }

    setEntry = (point) => {

        const current = this.subject.value;

        this.subject.next({
            ...current,
            entry: point,
        });
    }

    checkAllowed(action) {

        const roles = this.subject.value.permissions ? this.subject.value.permissions.roles : [];

        return check(rules, roles, action);
    }

    setMe = () => {
    }

    entry = () => {
        return this.subject.value.entry;
    }

    refreshConversations = () => {
        this.__loadCurrentConversations()
            .finally(() => this.events.next({ type: ConversationVisualBlocEvent.CONVERSATIONS_LOADED, data: {}}))
    }

    setConversation = (conversation) => {

        this.__updateSubject({
            conversation: conversation,
            conversationMessageIds: [],
            conversationMessages: [] });
    }

    pollCurrentConversation = () => {

        if(!this.messagePollTimer) {
            this.messagePollTimer = setInterval(() => this.__pollForNewMessage(), 5000);
            this.inferencePollTimer = setInterval(() => this.__pollForNewDifferential(), 5000);
        }
    }

    __pollForNewDifferential = () => {

        const { conversation, } =  this.subject.value;

        if(!conversation) return;

        demoApi.conversationsClinicalSummary(conversation.conversationId, conversation.tenant).then(value => {
            this.__updateSubject({
                clinicalSummary: value.data,
            });
        }, reason => {});

        demoApi.conversationsDifferential(conversation.conversationId)
            .then(value => {

                this.__updateSubject({
                    conversationInference: value.data,
                });

                this.events.next({ type: ConversationVisualBlocEvent.DIFFERENTIAL_LOADED, data: value.data });

            }, reason => notificationService.httpError(reason));
    }

    __pollForNewMessage = () => {

        const { conversation, conversationMessageIds, conversationMessages } =  this.subject.value;

        if(!conversation) return;

        conversationApi.tailConversationMessages(conversation.tenant, conversation.conversationId, conversationMessageIds?.length > 0 ? conversationMessageIds[conversationMessageIds.length-1] : "_" )
            .then(value => {

                const _messages = value.data.items || [];

                if(_messages.length === 0) return;

                let messageIds = [...conversationMessageIds];
                let messages = [...conversationMessages];


                _messages.reverse().forEach(_message => {
                    if(!messageIds.includes(_message.id)) {
                        messageIds.push(_message.id);
                        messages.unshift(_message);
                    }
                });

                this.__updateSubject({
                    conversationMessageIds: messageIds,
                    conversationMessages: messages });

                this.events.next({type: ConversationVisualBlocEvent.NEW_MESSAGES_LOADED, data: {
                        conversationMessageIds: messageIds,
                        conversationMessages: messages } })
            }, reason => notificationService.error(reason.message));
    }

    stopPollCurrentConversation = () => {
        if(this.messagePollTimer) {
            clearInterval(this.messagePollTimer);
            clearInterval(this.inferencePollTimer);
        }
    }
}

export class ConversationVisualBlocEvent {
    static DATABASE_LOADED = "DATABASE_LOADED";
    static ONTOLOGY_LOADED = "ONTOLOGY_LOADED";
    static CONVERSATIONS_LOADED = "CONVERSATIONS_LOADED";
    static NEW_MESSAGES_LOADED = "NEW_MESSAGES_LOADED";
    static ACCOUNT_LOADED = "ACCOUNT_LOADED";
    static DIFFERENTIAL_LOADED = "DIFFERENTIAL_LOADED";
}

