import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from "@material-ui/core/styles";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {notificationService} from "../../../../utils/notification";
import {clinicalInferenceApi} from "../../../../utils/services/clinicalinference.api";
const styles = theme => ({});


class AutocompleteField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            options: [],
            loading: false,
        }
    }

    setOpen = (_state) => {
        this.setState({
            open: _state,
        })
    }

    handleInputChange = (_inputValue) => {

        this.setState({
            inputValue: _inputValue,
        })

        if (_inputValue.length < 3) {
            this.setState({
                options: [],
                loading: false
            })
            return
        }

        this.setState({
            loading: true,
        })

        clinicalInferenceApi.getObservations()
            .then(value => {

                let items = value.data

                let options = []

                items.forEach(item => {
                    options.push({
                        "name": item
                    })
                })

                this.setState({
                    options: options,
                    loading: false,
                })
            })
            .catch(() => {
                notificationService.error("Service unavailable");
            });


    }

    handleValueChange = (_newValue) => {

        this.props.setSelectedValue(_newValue)
    }

    render() {
        let {label, id, defaultValue} = this.props
        let {open, inputValue, loading, options} = this.state

        return (
            <Autocomplete
                    id={id}
                    variant="outlined"
                    open={open}
                    onOpen={() => {
                        this.setOpen(true);
                    }}
                    onClose={() => {
                        this.setOpen(false);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        this.handleInputChange(newInputValue)
                    }}
                    defaultValue={{ name : defaultValue}}
                    onChange={(event, newValue) => {
                        this.handleValueChange(newValue);
                    }}
                    getOptionSelected={(option, value) => options.some(option => option.name === value.name) }
                    getOptionLabel={(option) => option.name }
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={label}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(option, {inputValue}) => {
                        const matches = match(option.name, inputValue);
                        const parts = parse(option.name, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
        );
    }
}


export default withStyles(styles)(AutocompleteField);