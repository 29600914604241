import React, { Component } from 'react';
import { IntlProvider } from "react-intl";

import { Route, Switch, withRouter } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';

import './App.css';

import Login from '../Login';
import Secure from '../Secure';

import { PrivateRoute } from '../../utils/protected.route';
import {blue} from "@material-ui/core/colors";


//TODO: Add font sizes here and remove from components.
const theme = createTheme({
  root: {
    fontFamily: 'Roboto',
    minHeight: "calc(100% - 96px)",
    maxHeight: "calc(100% - 96px)",
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
    ].join(', '),
  },
  palette: {
    type: 'dark',
    primary: blue,
    secondary: {
      main: '#f44336',
    },
  },
});

const styles = {
  root: {
    display: 'flex',
    minHeight: "100%",
    width: "100%",
    maxHeight: "100%",
  },
};


class App extends Component {

  render() {

    const { classes } = this.props;

    return (
        <MuiThemeProvider theme={theme} >
          <IntlProvider  locale='en'>
            <div className={classes.root}>
              <CssBaseline />
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={Secure} />
              </Switch>
            </div>
          </IntlProvider>
        </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
