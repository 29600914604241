import React, {Component} from 'react';

import Avatar from '@material-ui/core/Avatar';

import {withStyles} from '@material-ui/core/styles';
import {Badge} from "@material-ui/core";
import ExceptionContext from "./context";

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(0.5),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
    },
    avatar: {
        width: "48px",
        height: "48px",
        margin: theme.spacing(0.5),
    },
    avatarIcon: {},
    avatarIconHighlight: {
        '& div': {
            border: "2px solid #55CCFF",
        },
    },
});

const AvatarBadge = withStyles(theme => ({
    badge: {
        top: '15%',
        right: '85%',
    },
}))(Badge);


class MonitoredConversations extends Component {

    resolveUser = (users, userId) => {

        for(let index in users) {

            let user = users[index];
            if(user.id === userId) {
                return user;
            }
        }
        return undefined;
    };


    render() {

        const {classes, context} = this.props;

        const {activeConversation, conversations, users} = context;

        return (
            <div className={classes.root}>
                {
                    conversations.map(conversation => {

                        let clazz = classes.avatarIcon;
                        if(activeConversation && activeConversation.conversationId === conversation.conversationId ) {
                            clazz = classes.avatarIconHighlight;
                        }

                        let user = this.resolveUser(users, conversation.userId);

                            return (
                                <AvatarBadge button onClick={this.props.setConversation(conversation)}
                                             className={clazz} badgeContent={' '}
                                             color={conversation.read ? "secondary" : "primary"}>
                                    <Avatar className={classes.avatar}>{ user ? user.name.given.charAt(0) : "U" }</Avatar>
                                </AvatarBadge>);
                        }
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ExceptionContext.Consumer>
        {value => {
            return (<MonitoredConversations context={value} {...props} />);
        }
        }
    </ExceptionContext.Consumer>
));
