import * as rxjs from 'rxjs';
import {dataPreparationApi} from "../../../../utils/services/datapreparation.api";
import {notificationService} from "../../../../utils/notification";

export class MetadataState {
    static REGISTERED = "REGISTERED";
    static PREPARED_FOR_REVIEW = "PREPARED_FOR_REVIEW";
    static DOCUMENT_APPROVED = "DOCUMENT_APPROVED";
    static ANNOTATED_DRAFT = "ANNOTATED_DRAFT"
    static ANNOTATED_FOR_REVIEW = "ANNOTATED_FOR_REVIEW";
}

export class JobTypesEnum {
    static PREPARATION_REVIEW = "Document Preparation Review";
    static ANNOTATION = "Annotation";
    static ANNOTATION_DRAFT = "Annotation DRAFT";
    static ANNOTATION_REVIEW = "Annotation Review";
}

export class CodeSystems {
    static UMLS = "http://www.nlm.nih.gov/research/umls";
    static ICD10 = "http://hl7.org/fhir/sid/icd-10";
}


export class DataPreparationBloc {

    queuePoller;

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            job: undefined,
            registeredJobList: [],
            preparationReviewJobList: [],
            annotationJobList: [],
            text: undefined,
            preparedText: undefined,
            document: undefined,
        });

        this.events = new rxjs.Subject();
    }

    start = () => {

        this.__pollDataPreparationQueue();
    }

    __pollDataPreparationQueue = () => {

        this.__callDataPreparationQueue();
        this.queuePoller = setInterval(this.__callDataPreparationQueue, 5000);
    }


    __callDataPreparationQueue = () => {

        dataPreparationApi.getJobSummary()
            .then((value) => {
                this.subject.next({
                    ...this.subject.value,
                    registeredJobList: value.data.filter(item => item.status === MetadataState.REGISTERED),
                    preparationReviewJobList: value.data.filter(item => item.status === MetadataState.PREPARED_FOR_REVIEW),
                    annotationJobList: value.data.filter(item => (item.status === MetadataState.DOCUMENT_APPROVED ||
                        item.status === MetadataState.ANNOTATED_DRAFT)),
                });

                this.events.next({event: DataPreparationBlocEvent.JOBS_LOADED})

            }, reason => {
                this.events.next({event: DataPreparationBlocEvent.JOBS_LOADED_ERROR})
                notificationService.httpError(reason);
            });
    }

    close = () => {

        clearInterval(this.queuePoller);
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    selectDataPreparationJob = (key) => {

        dataPreparationApi.getJob(key)
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    job: value.data[0],
                });
            })
            .then(this.loadOriginalText)
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    text: value,
                });
            })
            .then(this.loadDocument)
            .then(value => {
                let document = (value) ? value : this.subject.value.text

                this.subject.next({
                    ...this.subject.value,
                    document: document
                })
            })
            .then(() => {
                this.events.next({event: DataPreparationBlocEvent.JOB_SELECTED})
            })
            .catch(reason => {
                this.events.next({event: DataPreparationBlocEvent.JOB_SELECTED_ERROR})
                notificationService.httpError(reason);
            });

    }

    selectPreparationReviewJob = (key) => {

        dataPreparationApi.getJob(key)
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    job: value.data[0],
                });
            })
            .then(() => {
                return this.loadText('preparedTextExtractDocument')
            })
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    preparedText: value,
                });
            })
            .then(this.loadOriginalText)
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    text: value,
                });
            })
            .then(this.loadDocument)
            .then(value => {
                let document = (value) ? value : this.subject.value.text

                this.subject.next({
                    ...this.subject.value,
                    document: document
                })
            })
            .then(() => {
                this.events.next({event: DataPreparationBlocEvent.JOB_REVIEW_SELECTED})
            })
            .catch(reason => {
                this.events.next({event: DataPreparationBlocEvent.JOB_REVIEW_SELECTED_ERROR})
                notificationService.httpError(reason);
            });

    }

    selectAnnotationJob = (key) => {

        dataPreparationApi.getJob(key)
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    job: value.data[0],
                });
            })
            .then(() => {
                return this.loadText('approvedTextExtractDocument')
            })
            .then(value => {
                this.subject.next({
                    ...this.subject.value,
                    preparedText: value,
                    originalText: value
                });
            })
            .then(() => {
                this.events.next({event: DataPreparationBlocEvent.JOB_ANNOTATION_SELECTED})
            })
            .catch(reason => {
                this.events.next({event: DataPreparationBlocEvent.JOB_ANNOTATION_SELECTED_ERROR})
                notificationService.httpError(reason);
            });

    }

    resetReviewScreen = () => {

        let documentName = this.subject.value.job.displayName;
        let documentSource = "";
        let documentPublishedDate = "";
        let documentPageNumbers = "";
        let imagesChecked = this.subject.value.job.containsImages;
        let tablesChecked = this.subject.value.job.containsTables;
        let editedText = this.subject.value.preparedText;
        let documentConcept = "";
        let documentCUI = "";
        let documentICD10 = "";

        let provenance = this.subject.value.job.provenance

        if (provenance && provenance.length > 0) {
            documentSource = provenance[0].source;
            documentPublishedDate = provenance[0].publishedDate;
            documentPageNumbers = provenance[0].pageNumber;
        }

        let umls_classifications = this.subject.value.job.classification.filter(
            item => item.system === CodeSystems.UMLS);

        if (umls_classifications && umls_classifications.length > 0) {
            documentConcept = umls_classifications[0].display;
            documentCUI = umls_classifications[0].code;
        }

        let icd10_classifications = this.subject.value.job.classification.filter(
            item => item.system === CodeSystems.ICD10);
        if (icd10_classifications && icd10_classifications.length > 0) {
            documentICD10 = icd10_classifications[0].code;
        }

        this.events.next({
            event: DataPreparationBlocEvent.REVIEW_SCREEN_CLEARED,
            data: {
                documentName: documentName,
                documentSource: documentSource,
                documentPublishedDate: documentPublishedDate,
                documentPageNumbers: documentPageNumbers,
                documentConcept: documentConcept,
                documentCUI: documentCUI,
                documentICD10: documentICD10,
                imagesChecked: imagesChecked,
                tablesChecked: tablesChecked,
                editedText: editedText
            }
        });
    }

    finishJob = () => {
        this.events.next({event: DataPreparationBlocEvent.JOB_FINISHED})
    }

    clearJob = () => {
        this.subject.next({
            ...this.subject.value,
            job: undefined,
            text: undefined,
            document: undefined,
        })
    }

    loadText = (code) => {
        const documentReference = this.subject.value.job.references.find(x => x.code.code === code);
        if (documentReference) {
            let documentFilename = documentReference.reference.substring(documentReference.reference.lastIndexOf('/') + 1)

            return dataPreparationApi.getTextDocument(documentFilename)
                .then(value => {
                    return value.data.body
                })
        }
        return undefined;
    }

    loadOriginalText = () => {
        const documentReference = this.subject.value.job.references.find(x => x.code.code === 'registeredDocument');
        let documentFilename;
        if (documentReference.contentType === "application/pdf") {
            const automatedExtractReference = this.subject.value.job.references.find(x => x.code.code === 'automatedTextExtractDocument');
            documentFilename = automatedExtractReference.reference.substring(documentReference.reference.lastIndexOf('/') + 1)
        } else if (documentReference.contentType === "text/plain") {
            documentFilename = documentReference.reference.substring(documentReference.reference.lastIndexOf('/') + 1)
        }

        return dataPreparationApi.getTextDocument(documentFilename)
            .then(value => {
                return value.data.body
            })
    }

    loadDocument = () => {
        const registeredDocumentReference = this.subject.value.job.references.find(x => x.code.code === 'registeredDocument');
        let documentFilename = registeredDocumentReference.reference.substring(registeredDocumentReference.reference.lastIndexOf('/') + 1)

        if (registeredDocumentReference.contentType === "application/pdf") {
            return dataPreparationApi.getDocumentSignedURL(documentFilename)
                .then(value => {
                    return value.data.url
                });
        }

        return undefined;

    }
}

export class DataPreparationBlocEvent {
    static JOBS_LOADED = "JOBS_LOADED"
    static JOBS_LOADED_ERROR = "JOBS_LOADED_ERROR"
    static JOB_SELECTED = "JOB_SELECTED";
    static JOB_SELECTED_ERROR = "JOB_SELECTED_ERROR";
    static JOB_REVIEW_SELECTED = "JOB_REVIEW_SELECTED";
    static JOB_REVIEW_SELECTED_ERROR = "JOB_REVIEW_SELECTED_ERROR";
    static JOB_ANNOTATION_SELECTED = "JOB_ANNOTATION_SELECTED";
    static JOB_ANNOTATION_SELECTED_ERROR = "JOB_ANNOTATION_SELECTED_ERROR";
    static JOB_FINISHED = "JOB_FINISHED";
    static REVIEW_SCREEN_CLEARED = "REVIEW_SCREEN_CLEARED";
}
