import React, {Component} from 'react';

import ConversationViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DecodedComponent from "../../../shared/DecodedComponent";

const styles = theme => ({
    root: {
        width: "25vw",
        maxHeight: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    accordion: {
        background: "transparent",
        boxShadow: 'none',
    },
    heading: {
        color: "#4A6A87",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",
        textTransform: "uppercase",
        marginTop: "24px",
        marginBottom: "16px",
    },
    detail: {
        maxHeight: "75vh",
        background: "rgba(16, 29, 41, 0.4)",
        boxShadow: "0px 30px 20px rgba(15, 28, 39, 0.3)",
        backdropFilter: "blur(10px)",
        borderRadius: "12px",
        overflow: "auto",
    },
    messages: {
        width: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "4px",
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: "inset 0 0 4px grey",
        },
        '&::-webkit-scrollbar-thumb': {
            background: "#000",
            borderRadius: "4px",
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: "#ccc",
            borderRadius: "4px",
        },
    },
    in: {
        display: "flex",
        marginBottom: "24px",
        padding: "0 12px",
    },
    inMessage: {
        color: "#FFFFFF",
        border: "1px solid #406487",
        padding: "10px 20px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "28px",
        borderRadius: "6px",
    },
    out: {
        marginBottom: "24px",
        padding: "0 12px",
    },
    outMessage: {
        padding: "10px 20px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "28px",
        background: "#2D3E56",
        mixBlendMode: "normal",
        borderRadius: "6px",
    },
});

class ConversationPanel extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;
        this.state = { conversationMessages: [] }
    }


    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {

        let { classes } = this.props;
        let { conversationMessages } = this.state;

        return (
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Quinn Chat</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detail}>
                        <div className={classes.messages}>
                            {
                                conversationMessages.map(_message => {

                                    if(_message.direction === "OUT") {

                                        return (<div className={classes.out}>
                                                <Avatar alt="Quinn" src="https://storage.googleapis.com/dh-web-asssets/assistant/quinn.png" />
                                                <div className={classes.outMessage}>{ _message.content }</div>
                                            </div>);
                                    } else {

                                        let content = _message.content;
                                        if(content.startsWith("/") && content.includes("{")) {
                                            content = content.split("{")[0]
                                        }

                                        return (<div className={classes.in}>
                                                <div className={classes.fill}></div>
                                                <div className={classes.inMessage}>{ content }</div>
                                            </div>);
                                    }
                                })

                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ConversationViewContext.Consumer>
        {value => {
            return (<ConversationPanel context={value} {...props} />);
        }
        }
    </ConversationViewContext.Consumer>
));
