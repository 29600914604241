import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const styles = theme => ({
    root: {
        display: "inline-grid",
    },
    annotationHolder: {
        fontSize: "18px",
        zIndex: "100",
    },
    annotation: {
        fontSize: "18px",
        zIndex: "100",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "-o-user-select": "none",
        "user-select": "none",
    },
    deleteButton: {
        boxShadow: "none",
        '&:active' : {
            boxShadow: "none",
            backgroundColor: "transparent"
        },
        '&:hover' : {
            boxShadow: "none",
            backgroundColor: "transparent"
        },
    },
});


class AnnotatedText extends Component {

    isEnclosed = (entity1, entity2) => {
        return entity1.start <= entity2.start &&
            (entity1.end) >= (entity2.end);
    }

    RenderChildAnnotation = () => {
        const { annotations, handleRemoveAnnotation } = this.props;

        const enclosingEntity = annotations[0];
        // Get all child (enclosed) entities
        const entities = annotations.filter(entity => entity.extract !== enclosingEntity.extract);
        // Get the text that contains the child entities
        let text = enclosingEntity.extract;
        if(text === undefined) {
            if(enclosingEntity.source?.value.includes("admin-api")) {
                text = enclosingEntity.normalised
            }
        }

        let parentOffset = entities[0] ? enclosingEntity.start : 0;

        let renderArray = [];

        //Test
        if (entities.length > 0) {

            let entityCount = 0;
            let count = 0
            let chunk = "";

            if (entities.length > 0) {

                while (entityCount < entities.length) {
                    let currentEntity = entities[entityCount];
                    let highlights = [currentEntity];

                    //Add all text up until the current entity to the render array
                    if (count < currentEntity.start - parentOffset) {
                        renderArray.push(
                            <React.Fragment>{text.slice(count, currentEntity.start - parentOffset)}</React.Fragment>
                        );
                    }

                    // Move to the next entity
                    entityCount += 1;

                    // Gather all similar entities and enclosed entities into highlights
                    while (entities[entityCount] && this.isEnclosed(currentEntity, entities[entityCount])) {
                        highlights.push(entities[entityCount]);
                        entityCount += 1;
                    }

                    // Render all highlights and push the resulted element to the render array
                    renderArray.push(React.createElement(withStyles(styles)(AnnotatedText),
                        {text: text, annotations: highlights, handleRemoveAnnotation:handleRemoveAnnotation, lower: true}));

                    count = currentEntity.end - parentOffset;
                }

                // Adding the rest of the text to the render array
                if (count < text.length) {
                    renderArray.push(
                        <React.Fragment>{text.slice(count)}</React.Fragment>
                    );
                }

            } else {
                chunk = text;
            }

            if (chunk.length > 0) {
                renderArray.push(chunk);
            }
        } else {
            renderArray.push(text)
        }

        return (
            <React.Fragment>
                { renderArray }
            </React.Fragment>
        );
    }

    resolveColour = () => {

        if(this.props.lower) {
            return "rgb(139, 1, 177)"
        }

        return "rgba(130,6,255,1)";
    };

    render() {

        let { classes, annotations, handleRemoveAnnotation } = this.props;

        let highlight = {
            backgroundColor: this.resolveColour(),
        };

        const parentAnnotations = annotations.filter(entity => entity.extract === annotations[0].extract)

        return (
            <span style={ highlight } className={ classes.root }>
                <span className={ classes.text }>{ this.RenderChildAnnotation() }</span>
                { parentAnnotations.map((annotation) => {
                        let style = {
                            backgroundColor: this.resolveColour(),
                            boxShadow: "10px -1px 4px 0 rgba(0,0,0,0.22)",
                        };
                        return (<span style={style} className={`${classes.annotation} annotation`}>{ annotation.category }</span>);
                    })
                }
                <IconButton
                    aria-label="delete"
                    className={classes.deleteButton}
                    variant="outlined"
                    onClick={() => handleRemoveAnnotation(annotations[0])}
                >
                    <HighlightOffIcon fontSize="small" />
                </IconButton>
            </span>
        );
    }
}

export default withStyles(styles)(AnnotatedText);
