import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import {Typography, Button, CircularProgress, Input} from "@material-ui/core";

import DecodedComponent from "../../../../shared/DecodedComponent";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    context: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    rewordInput: {
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },
});


class RewordPanel extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        this.state = {
            loaded: false,
        };
    }

    __handleRewordChange = (e) => {
        this.bloc.onChange(e);
    }

    render() {

        let { classes } = this.props;
        let { busy, event } = this.state;

        if(busy || !event) return <><CircularProgress /></>

        let { data } = event;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant='h6' align='left' className={classes.context}>
                        <FormattedMessage id={"quinn.job.rephrase.entity"} defaultMessage={"Context"} /> "{ data.content }"
                    </Typography>
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <FormattedMessage id={"rephrase.entity"} defaultMessage={"Reword : " + data.entity.category.replace("_", " ") } /> "{ data.entity.extract }"
                    </Typography>
                </div>
                <div className={classes.message}>
                    The patient at the time the <Input value={data.entity.normalised} onChange={this.__handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/> started, noted: 'Nothing significant happened'.
                </div>
                <div className={classes.message}>
                    When did the feeling of the { data.entity.normalised } start and did you notice anything that could have caused it?
                </div>
                <div className={classes.fill} />
                <div className={classes.footer}>
                    <div className={classes.fill}></div>
                    <Button disabled={busy} onClick={this.bloc.submit}><KeyboardReturn fontSize="large" color="primary"/></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(RewordPanel);
