import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button} from "@material-ui/core";
import AnnotationContextMenu from "../../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../../utils/text.highlight";
import {Headers} from "../../../../../utils/events";
import AnnotatedText from "../../shared/AnnotatedText";
import MessageAnnotationContext from "./context";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {preprocessor} from "../../shared/preprocessor";
import {MESSAGE_ANNOTATION_EVENTS, messageAnnotationEventService} from "./service/message.annotation.event.service";
import Rephrase from "./Rephrase";
import {notificationService} from "../../../../../utils/notification";
import {quinnApi} from "../../../../../utils/services/quinn.api";
import Loading from "../../../../shared/Loading";

const ANNOTATE_ONLY = "annotate-only";
const VERIFY_ANSWERED = "verify-answered";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
        zIndex: "1301",
    },

    header: {},
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    verifyInstruction: {
        color: "#BB86FC",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {},
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },
});

function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}

function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}

function RenderAnnotated(props) {

    const {text, entities, handleRemoveAnnotation} = props;

    let renderArray = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {

                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    renderArray.push(<>{text.slice(count, currentEntity.start)}</>);
                }

                entityCount += 1;

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {

                    highlights.push(entities[entityCount]);
                    entityCount += 1;
                }

                renderArray.push(<AnnotatedText text={text} annotations={highlights}
                                                handleRemoveAnnotation={handleRemoveAnnotation}/>)

                count = currentEntity.end;
            }

            // Adding the rest of the text
            if (count < text.length) {

                renderArray.push(<>{text.slice(count)}</>);
            }

        } else {

            chunk = text;
        }

        if (chunk.length > 0) {
            renderArray.push(chunk);
        }
    } else {

        renderArray.push(text)
    }

    return (
        <>
            {renderArray}
        </>
    );
}

const EXCEPTIONS = ['chief_complaint', 'dx', 'sx', 'associated_sx', 'nlp_sx', 'onset']

class MessageAnnotationPanel extends Component {

    constructor(props) {
        super(props);

        const {
            event: {
                data: {
                    lastTurn,
                }
            }
        } = this.props.context;

        const purpose = props.context.event?.data?.job ? props.context.event?.data?.job : "full"

        this.state = {
            loaded: false,
            purpose: purpose,
            metadata: props.context.metadata,
            original: props.context.message,

            targetEntities: [],
            targetEntity: undefined,
            targetEntityExtract: undefined,
            booleanValue: undefined,
            skipValue: undefined,

            message: lastTurn.content,
            entities: purpose === ANNOTATE_ONLY ? lastTurn.metadata.entities.filter((_entity) => !EXCEPTIONS.includes(_entity.category)) : lastTurn.metadata.entities,
        };

        this._highlightAndOpenContext = this._highlightAndOpenContext.bind(this);
        this._backFromRephrase = this._backFromRephrase.bind(this);
        this._submitRephrase = this._submitRephrase.bind(this);
    }

    componentDidMount() {

        const {xdhtenantid} = this.props.context.metadata;
        let code = this.props.context.message?.slot.code;
        if (!code && this.props.context.event) {
            code = this.props.context.event.data?.slot?.code;
        }

        this.__loadContextMenu(xdhtenantid, code);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        const {original, metadata} = this.state;

        if (nextProps.context.metadata && (nextProps.context.metadata[Headers.MESSAGE_ID] !== metadata[Headers.MESSAGE_ID])) {

            const {event: {data: {lastTurn}}} = nextProps.context;

            const purpose = nextProps.context.event?.data?.job ? nextProps.context.event?.data?.job : "full"

            this.setState({
                loaded: false,
                purpose: purpose,
                metadata: nextProps.context.metadata,
                original: nextProps.context.message,
                booleanValue: undefined,
                skipValue: undefined,
                targetEntities: [],
                targetEntity: undefined,
                targetEntityExtract: undefined,
                message: lastTurn.content,
                entities: purpose === ANNOTATE_ONLY ? lastTurn.metadata.entities.filter((_entity) => !EXCEPTIONS.includes(_entity.category)) : lastTurn.metadata.entities,
                rephrase: false,
            });

            const {xdhtenantid} = nextProps.context.metadata;
            const code = nextProps.context.message ? nextProps.context.message.slot : undefined;
            this.__loadContextMenu(xdhtenantid, code);
        }
    }

    __loadContextMenu = (tenant, intent) => {

        if (!intent) {
            this.setState({
                loaded: true,
                contextOverride: undefined,
            });
            return;
        }

        quinnApi.contextMenu(intent, tenant)
            .then(value => {
                this.setState({
                    loaded: true,
                    contextOverride: value.data,
                })
            }).catch(reason => {
            this.setState({
                loaded: true,
                contextOverride: undefined,
            })
        })
    }

    _highlightAndOpenContext = (event) => {

        if (event.nativeEvent === "MouseEvent" && event.button !== 0) {
            if (this.state.popupPosition) {
                this.setState({
                    popupPosition: undefined,
                });
            }
            return;
        }

        const {message} = this.state;

        determineSelection(message, event.target)
            .then((value) => {

                Object.keys(value).forEach((key) => {

                    this.setState({
                        [key]: value[key],
                    });
                });
            });
    };

    noContextMenu = (event) => {

        event.preventDefault();
    };

    menuItemSelected = (event) => {

        if (event.action === 'close') {

            this.setState({
                selectedText: undefined,
                popupPosition: undefined,
            });
        } else if (event.action === 'found') {

            const {selectedText, selectedTextStart, targetEntities, entities} = this.state;

            if (selectedText) {

                let _entity = {
                    start: selectedTextStart,
                    end: selectedTextStart + selectedText.length,
                    extract: selectedText,
                    category: event.item.code,
                };

                entities.push(_entity);

                if (event.item.code === "patient_description" || event.item.code === "onset" || event.item.code === "current_complaint") {
                    targetEntities.push(_entity);
                }

                if (event.item.next) {

                    this.setState({
                        entities: entities,
                    })
                } else {

                    this.setState({
                        entities: entities,
                        targetEntities: targetEntities,
                        selectedText: undefined,
                        popupPosition: undefined,
                    })
                }
            }
        }
    };

    clearAnnotations = () => {

        this.setState({
            booleanValue: undefined,
            targetEntities: [],
            entities: [],
        });
    };

    _tryRephrase = () => {
        let {exceptionLabel} = this.props;

        let {
            event: {
                data: {
                    slotSpecification,
                    slot,
                    relatedSlots,
                    relatedDomains,
                    slotUtteranceSpecification,
                    relatedSlotSpecifications,
                    lastTurn
                }
            }
        } = this.props.context;

        if (!exceptionLabel && slotUtteranceSpecification &&
            slotUtteranceSpecification.options &&
            slotUtteranceSpecification.options.length > 0) {

            this.setState({rephrase: true,});
        } else {

            this._sendToException();
        }
    };

    _sendToException = () => {

        let {exceptionEvent} = this.props;

        let {event: {data}} = this.props.context;

        let {lastTurn, slotSpecification} = data;

        let {entities, contextOverride} = this.state;

        let addedEntities = entities.filter((entity) => !entity.id);
        let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))

        if (exceptionEvent !== "MESSAGE_DISCARD" && this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {
            notificationService.error("This message is correctly annotated.");
            return;
        }

        messageAnnotationEventService.update(exceptionEvent || MESSAGE_ANNOTATION_EVENTS.EXCEPTION, {
            action: exceptionEvent ? "suggest_forget" : "suggest_escalation",
            conversationId: lastTurn.metadata.conversationId,
            messageId: lastTurn._id,
            tenantId: lastTurn.tenant,
            entityUpdates: {
                add: addedEntities,
                remove: removedEntities
            },
            original: data,
        });
    };

    _submitRephrase = (option) => {

        let {event: {data: {slot, lastTurn}}} = this.props.context;

        let {entities} = this.state;

        let addedEntities = entities.filter((entity) => !entity.id);
        let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))

        messageAnnotationEventService.update(MESSAGE_ANNOTATION_EVENTS.QUESTION_REPHRASED, {
            action: "suggest_rephrase",
            conversationId: lastTurn.metadata.conversationId,
            messageId: lastTurn._id,
            tenantId: lastTurn.tenant,
            entityUpdates: {
                add: addedEntities,
                remove: removedEntities
            },
            rephrase: {
                slot: {
                    slotId: slot._id || slot.id
                },
                option: option
            }
        });
    }

    _submit = () => {

        let {event: {data: {slotSpecification}}} = this.props.context;

        let {entities, metadata, contextOverride, purpose} = this.state;

        if (!this._correctlyAnnotated(slotSpecification, entities, contextOverride) && purpose !== VERIFY_ANSWERED) {
            notificationService.error("You need to annotate the target slot to mark this as successful.");
            return;
        }

        if (entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
            return;
        }

        preprocessor.preprocessSubmission(metadata, entities, contextOverride);

        this._submitInternal(entities, contextOverride);
    };

    _submitInternal = (entities, contextOverride) => {

        let {event: {data: {slotSpecification, slot, lastTurn}}} = this.props.context;
        let {purpose} = this.state;

        if (!this._correctlyAnnotated(slotSpecification, entities, contextOverride) && purpose !== VERIFY_ANSWERED) {

            notificationService.error("You need to annotate the target slot to mark this as successful or a single new intent.");
        }
        if (entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
        } else {

            let addedEntities = entities.filter((entity) => !entity.id);
            let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))

            let action;
            if (purpose === ANNOTATE_ONLY) {
                action = "suggest_annotations"
            } else if (purpose === VERIFY_ANSWERED) {
                action = "suggest_continue"
            } else {
                action = "answer"
            }

            messageAnnotationEventService.update(MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED, {
                action: action,
                conversationId: lastTurn.metadata.conversationId,
                messageId: lastTurn._id,
                tenantId: lastTurn.tenant,
                entityUpdates: {
                    add: addedEntities,
                    remove: removedEntities
                }
            });
        }
    }

    _correctlyAnnotated = (slotSpecification, entities, contextOverride) => {

        let correct = (entities && entities.length > 0 && entities.filter((item) => slotSpecification.code === item.category).length > 0);
        if (!correct && contextOverride?.derived?.length > 0) {
            correct = entities.filter((item) => contextOverride.menu[item.category]).length > 0;
        }

        if (correct) return correct;

        const intentList = entities.filter((item) => "intent" === item.category);

        if (intentList.length === 0) {
            return correct;
        }

        if (intentList.length > 1) {
            return false;
        }

        const intent = intentList[0];

        return (entities && entities.length > 0 && entities.filter((item) =>
            item.start === intent.start &&
            item.end === intent.end &&
            item.category !== "intent").length > 0);
    };

    setYes = () => {

        const {booleanValue} = this.state;

        if (booleanValue === "true") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "true",
            });
        }
    };

    setNo = () => {

        const {booleanValue} = this.state;

        if (booleanValue === "false") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "false",
            });
        }
    };


    handleRewordChange = (event) => {

        const {entities, targetEntity} = this.state;

        targetEntity.extract = event.target.value;

        entities.filter(entity => entity.start === targetEntity.start && entity.start === targetEntity.start).forEach((entity) => entity.extract = targetEntity.extract);

        this.setState({
            targetEntity: targetEntity,
            entities: entities,
        });
    };

    _backFromRephrase = () => {
        this.setState({
            rephrase: false,
        });
    }

    render() {

        let {rephrase} = this.state;

        if (rephrase) {
            return (<Rephrase goBack={this._backFromRephrase} sendException={this._sendToException}
                              submit={this._submitRephrase}/>);
        }

        return this.renderAnnotation();
    }

    openConfirmAndSkipDialog = () => {

        this.setState({
            confirmSkipOpen: true,
            skipValue: undefined,
        });
    }

    closeConfirmAndSkipDialog = () => {

        this.setState({
            confirmSkipOpen: false,
            skipValue: undefined,
        });
    }


    confirmAndSkip = () => {

        this.setState({
            confirmSkipOpen: false,
            skipValue: "__skipped__",
        });

        this.state.skipValue = "__skipped__";
        this.handleSubmission();
    }

    handleRemoveAnnotation = (annotatedText) => {
        const filterCallback = entity => entity.extract !== annotatedText.extract;

        this.setState((state, props) => ({
            entities: state.entities.filter(filterCallback),
            targetEntities: state.targetEntities.filter(filterCallback)
        }));
    }


    renderAnnotation() {

        let {classes, busy, exceptionLabel} = this.props;

        let {
            event: {
                data: {
                    slotSpecification,
                    slot,
                    relatedSlots,
                    relatedDomains,
                    relatedSlotSpecifications,
                    quinnLastTurn
                }
            }
        } = this.props.context;

        let {message, entities, popupPosition, confirmSkipOpen, contextOverride, loaded, purpose} = this.state;

        if (!loaded) {
            return (<Loading/>);
        }

        let annotation_message;
        let annotationStyle = classes.instruction
        if (purpose === ANNOTATE_ONLY) {
            annotation_message = "Annotate purpose: "
        } else if (purpose === VERIFY_ANSWERED) {
            annotation_message = "Verify answered: "
            entities = []
            annotationStyle = classes.verifyInstruction
        } else {
            annotation_message = "Annotate "
        }

        let lastQuestion = <><Typography variant='h4' align='left' className={classes.instructionLastQuestion}>
            "{this.__renderLastQuestion(quinnLastTurn)}"
        </Typography></>;



        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {lastQuestion}
                    <Typography variant='h6' align='left' className={annotationStyle}>
                        {slotSpecification ? <>
                            <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large"
                                                                                          color="primary"/></Button>
                            {annotation_message} {slotSpecification["short_description"]}</> : <>End
                            and schedule an appointment or terminate. The conversation is in an invalid state.</>}
                    </Typography>
                </div>

                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`}
                        onContextMenu={this.noContextMenu}
                        onTouchEnd={this._highlightAndOpenContext}
                        onMouseUp={this._highlightAndOpenContext}
                    >
                        <RenderAnnotated
                            text={message}
                            entities={entities}
                            handleRemoveAnnotation={this.handleRemoveAnnotation}/>
                    </h3>
                    {purpose !== VERIFY_ANSWERED ?
                        <AnnotationContextMenu
                            targetSlot={slot ? slot.code : "none"}
                            targetSlotSpecification={slotSpecification}
                            relatedSlots={relatedSlots}
                            relatedDomains={relatedDomains}
                            relatedSlotSpecifications={relatedSlotSpecifications}
                            menuOverride={contextOverride}
                            menuItemSelected={this.menuItemSelected}
                            popupPosition={popupPosition}
                        /> : (<></>)}
                </div>

                <div className={classes.fill}/>

                <div className={classes.footer}>
                    {slotSpecification ? <>
                        {purpose !== ANNOTATE_ONLY && <Button disabled={busy} onClick={this._tryRephrase}>
                            <Close fontSize="large" color="primary"/> {exceptionLabel ? <>{exceptionLabel}</> :
                            <FormattedMessage id="exception" defaultMessage="Exception"/>}
                        </Button>}
                        <div className={classes.fill}/>
                        <Button disabled={busy} onClick={this._submit}>
                            <KeyboardReturn fontSize="large" color="primary"/>
                        </Button></> : <></>}
                </div>
                <Dialog
                    className={classes.unsureDialog}
                    open={confirmSkipOpen}
                    keepMounted
                    onClose={this.closeConfirmAndSkipDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <FormattedMessage id={'skip.turn.confirmation.dialog.text'}
                                              defaultMessage={"Are you sure you want to move onto the next question?"}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmAndSkipDialog} color="primary">
                            <FormattedMessage id={'cancel'} defaultMessage={"Cancel"}/>
                        </Button>
                        <Button onClick={this.confirmAndSkip} color="primary">
                            <FormattedMessage id={'yes'} defaultMessage={"Yes"}/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    __renderLastQuestion = (lastTurn) => {

        let content = lastTurn.content;

        if (["", "en"].includes(lastTurn?.metadata?.translation?.language)) {
            content = lastTurn.metadata.translation.translation;
            console.log("Set to translated.", lastTurn.metadata.translation.translation)
        }

        return content;
    }
}

export default withStyles(styles)(props => (
    <MessageAnnotationContext.Consumer>
        {value => {
            return (<MessageAnnotationPanel context={value} {...props} />);
        }
        }
    </MessageAnnotationContext.Consumer>
));
