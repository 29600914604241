import axios from 'axios';

class PhysicianApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    list() {

        return axios.get(`${this.baseUrl}/internal/physicians`);
    }
}

export const physicianApi = new PhysicianApi();
