import React from 'react';

import {withStyles} from '@material-ui/core/styles';


import {

    Card,
    CardActions, CardContent,
    CardHeader, Checkbox,
    Divider, FormControlLabel,
    Grid,
    LinearProgress,
    ListSubheader, TextField, Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import {DeleteOutline} from "@material-ui/icons";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";

import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import {notificationService} from "../../../../../../../utils/notification";
import AutocompleteMultiselect from "../LocationComponent/AutocompleteMultiselect";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },

    subHeader: {
        fontSize: "1.5rem"
    },
    input: {
        width: "100%",
        fontSize: "32px",
    },
    label: {
        marginBottom: "10px"
    },
    select: {
        margin: "24px",
        marginTop: "10px",
        flex: 1
    },
});

class LocationReviewComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;

        const selectedPathologicalProcesses = props.bloc.subject.value.task.locations.pathologicalProcesses.map(d => {
            return {name: d.display, code: d.code}
        })

        const selectedAnatomicalLocations = props.bloc.subject.value.task.locations.anatomicalLocations.map(d => {
            return {name: d.display, code: d.code}
        })

        const selectedBodySystems = props.bloc.subject.value.task.locations.bodySystems.map(d => {
            return {name: d.display, code: d.code}
        })

        this.state = {
            initialising: true,
            selectedAnatomicalLocations: selectedAnatomicalLocations,
            selectedPathologicalProcesses: selectedPathologicalProcesses,
            selectedBodySystems: selectedBodySystems,
            anatomicalLocations: [],
            pathologicalProcesses: [],
            bodySystems: []
        };
    }

    componentWillMount() {

        navigationTitleService.update("Location Review Job");
        super.componentDidMount();

        this.__getClasses("pathological-processes").then(res => {
            this.setState({
                pathologicalProcesses: res.data.map(d => {
                    return {name: d.coding.display, code: d.bid}
                }).sort((a, b) => a.name.localeCompare(b.name))

            })
        }).then(this.__getClasses("body-systems").then(res => {
            this.setState({
                bodySystems: res.data.map(d => {
                    return {name: d.coding.display, code: d.bid}
                }).sort((a, b) => a.name.localeCompare(b.name))
            })
        })).then(this.__getClasses("anatomical-locations").then(res => {
            this.setState({
                anatomicalLocations: res.data.map(d => {
                    return {name: d.display, code: d.bid}
                })
            })
        }))
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __getClasses = (classType) => {

        return new Promise(resolve => {
            ontologyApi.listModels(classType)
                .then(value => {
                    resolve(value)
                }, error => {
                    notificationService.error(`Error loading ${classType} classes`);
                    resolve({"data": []})
                }).catch(e => {
                    notificationService.error(`Error loading ${classType} classes`);
                    resolve({"data": []})
            });
        });
    }

    __submit = () => this.bloc.submitTask({
        data: {
            locations: {
                pathologicalProcesses: this.state.selectedPathologicalProcesses,
                bodySystems: this.state.selectedBodySystems,
                anatomicalLocations: this.state.selectedAnatomicalLocations
            }
        }
    });

    setValue = (_id, _value) => {

        if (_id === "pathologicalProcess") {
            this.setState({
                selectedPathologicalProcesses: _value
            });
        } else if (_id === "bodySystem") {
            this.setState({
                selectedBodySystems: _value
            });
        } else if (_id === "anatomicalLocation") {
            this.setState({
                selectedAnatomicalLocations: _value
            });
    }}

    __discard = (message) => this.bloc.discardTask(message)

    render() {

        const {classes} = this.props;
        const {
            initialising,
            busy,
            task,
            pathologicalProcesses,
            bodySystems,
            anatomicalLocations,
            selectedPathologicalProcesses,
            selectedBodySystems,
            selectedAnatomicalLocations
        } = this.state;

        if (initialising) return (<LinearProgress/>);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please review all locations for the following ${task.data.class}`}
                                        titleTypographyProps={{className: classes.cardTitle}}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`${task.data.display}`}
                            </ListSubheader>
                            <CardContent>
                                <Divider/>
                                <div className={classes.select}>
                                    <Typography className={classes.label}>Pathological Process</Typography>
                                    <AutocompleteMultiselect id="pathologicalProcess" setSelectedValue={this.setValue} options={pathologicalProcesses} defaultValue={selectedPathologicalProcesses}/>
                                </div>
                                <Divider/>
                                <div className={classes.select}>
                                    <Typography className={classes.label}>Body System</Typography>
                                    <AutocompleteMultiselect id="bodySystem" setSelectedValue={this.setValue} options={bodySystems} defaultValue={selectedBodySystems}/>
                                </div>
                                <Divider/>
                                <div className={classes.select}>
                                    <Typography className={classes.label}>Anatomical Location</Typography>
                                    <AutocompleteMultiselect id="anatomicalLocation" setSelectedValue={this.setValue} options={anatomicalLocations} defaultValue={selectedAnatomicalLocations}/>
                                </div>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={<DeleteOutline/>} color={"primary"}
                                                        variant={"outlined"}
                                                        onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill}/>
                                <CriticalButton disabled={busy} startIcon={<PublishIcon/>} color={"primary"}
                                                variant={"outlined"}
                                                onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<LocationReviewComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

