import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import AnnotationContextMenuItems from "./AnnotationContextMenuItems";


const styles = theme => ({
    root: {
        position: "absolute",
        padding: "0px",
        width: "536px",
        boxShadow: "rgb(0, 0, 0) 0px 2px 5px 0px",
        zIndex: "100002",
    }
});

class AnnotationContextMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            popupPosition: props.popupPosition,
            targetSlot: props.targetSlot,
            relatedSlots: props.relatedSlots,
            entries: [],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(!nextProps.popupPosition) {

            this.setState({
                popupPosition: undefined,
            });
        } else if((!this.props.popupPosition && nextProps.popupPosition) ||
            this.props.entries !== nextProps.entries ||
            this.props.popupPosition.top !== nextProps.popupPosition.top ||
            this.props.popupPosition.left !== nextProps.popupPosition.left
        ) {

            this.setState({
                popupPosition: nextProps.popupPosition,
            });
        }
    }


    render() {

        let { classes, menuItemSelected, targetSlot, targetSlotSpecification, relatedDomains, relatedSlotSpecifications, relatedSlots, menuOverride, includeDefault } = this.props;

        let { popupPosition } = this.state;


        if (!popupPosition) {
            return (<></>);
        }

        const style = {
            top: popupPosition.top + 50,
            left: popupPosition.left,
        };

        return (
            <span style={ style } className={ classes.root }>
                <AnnotationContextMenuItems menuOverride={ menuOverride } targetslot={ targetSlot } targetSlotSpecification={ targetSlotSpecification } relatedDomains={ relatedDomains } relatedSlotSpecifications={ relatedSlotSpecifications } relatedSlots={ relatedSlots } menuItemSelected={ menuItemSelected } includeDefault={includeDefault} />
            </span>
        );
    }
}

export default withStyles(styles)(AnnotationContextMenu);
