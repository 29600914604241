import axios from "axios";

class ClinicalInferenceApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getOntologyCount() {

        return axios.get(`${this.baseUrl}/internal/intelligence/clinical_inference/ontology/_count`)
    }

    getOntology(params) {

        let options = {}
        if(params) options.params = params;

        return axios.get(`${this.baseUrl}/internal/intelligence/clinical_inference/ontology`, options)
    }

    getConditions(params) {

        let options = {}
        if(params) options.params = params;

        return axios.get(`${this.baseUrl}/internal/intelligence/clinical_inference/conditions`, options)
    }

    getObservations(params) {

        let options = {}
        if(params) options.params = params;

        return axios.get(`${this.baseUrl}/internal/intelligence/clinical_inference/observations`, options)
    }

    getConditionRelationships(params) {

        let options = {}
        if(params) options.params = params;

        return axios.get(`${this.baseUrl}/internal/intelligence/ontology/models/relationships`, options)
    }

    getDifferentialDiagnosis(data) {
        return axios.post(`${this.baseUrl}/internal/intelligence/clinical_inference/differential_diagnosis`, data)
    }
}


export const clinicalInferenceApi = new ClinicalInferenceApi();
