import axios from 'axios';

class QuinnApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    contextMenu(intent, tenant) {

        return axios.get(`${this.baseUrl}/internal/quinn/intents/${encodeURI(intent)}/_context_menu`, {
            headers: {
                "X-Tenant-ID": tenant,
            }
        }).then(function (res) { return res; }).catch(function (err) { return err; });
    }

    conversationMemory(conversationId, tenant) {

        return axios.post(`${this.baseUrl}/internal/quinn/conversations/${conversationId}/_memory`, {}, {
            headers: {
                "X-Tenant-ID": tenant,
            }
        })
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }
}

export const quinnApi = new QuinnApi();
