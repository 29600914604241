import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    LinearProgress,
    Paper,
    Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        width: "100%",
    },
    count: {
        marginRight: "12px",
    },
    card: {
        // padding: "24px 24px",
        // boxShadow: "12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3)",
        // borderRadius: "50px",
    },
});

class JobPipelineStep extends DecodedComponent {


    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;

        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleClick = () => {
        let { pipeline, definition } = this.props;
        let { initialising, busy } = this.state;

        if(initialising || busy) return;

        this.bloc.popTask(pipeline, definition);
    }

    render() {

        let { classes, pipeline, definition } = this.props;
        let { initialising, busy } = this.state;

        let count = this.__jobCount(definition, pipeline);

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardHeader className={classes.header} title={ this.__formatName(definition) } />
                    <CardContent className={classes.content}>
                        <Typography variant="body2" color="text.secondary">
                            { this.__description(definition) }
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={this.__handleClick} disabled={count < 1 || busy} size="small" variant={"outlined"} color="primary">
                            <Chip className={classes.count} size="small" label={count} color={ count < 1 ? "default" : "primary" }/> { count < 1 ? "Job queue empty" : "Take Next Job" }
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }

    __formatName = (definition) => {
        const name = Object.keys(definition)[0];
        return name.replaceAll("_", " ")
    }

    __description = (definition) => {
        const name = Object.keys(definition)[0];
        return definition[name].description;
    }

    __jobCount = (definition, pipeline) => {
        const name = Object.keys(definition)[0];
        let count = 0;
        if(pipeline.stats?.steps) {
            pipeline.stats.steps.forEach(value => {
                const key = Object.keys(value)[0];
                if(key === name) {
                   count = value[key].jobs;
                }
            });
        }
        return count;
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
                return (<JobPipelineStep context={value} {...props} />);
            }
        }
    </AsyncJobContext.Consumer>
));
