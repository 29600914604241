import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import PlayArrow from '@material-ui/icons/PlayArrow';
import CallSplit from '@material-ui/icons/CallSplit';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button, Checkbox, ButtonGroup, Input, FormControlLabel} from "@material-ui/core";
import AnnotationContextMenu from "../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../utils/text.highlight";
import {
    generateExceptionIntentIdentifiedEvent, generateExceptionMessageAnnotatedEvent,
    generateNextMessageEvent, generateRedirectMessageEvent, Headers, INTENT_CLASSIFICATION_JOB
} from "../../../../utils/events";
import AnnotatedText from "../shared/AnnotatedText";
import ExceptionContext from "./context";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {preprocessor} from "../shared/preprocessor";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {},
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {},
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {

        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
        "& svg": {
            fontSize: "4em",
        },
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    completeCheckbox: {
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        fontWeight: "200",
        textTransform: "none",
        "& svg": {
            fontSize: "1em",
        },
        "& span": {
            color: "#55CCFF",
            fontSize: "42px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
            "& span": {
                color: "#55CCFF",
                fontSize: "42px",
                lineHeight: "58px",
                fontWeight: "200",
                textTransform: "none",
            },
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});

function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}

function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}

function RenderAnnotated(props) {

    const {text, entities} = props;

    let renderArray = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {

                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    renderArray.push(<>{text.slice(count, currentEntity.start)}</>);
                }

                entityCount += 1;

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {

                    highlights.push(entities[entityCount]);
                    entityCount += 1;
                }

                renderArray.push(<AnnotatedText text={text} annotations={highlights}/>)

                count = currentEntity.end;
            }

            if (count < text.length) {

                renderArray.push(<>{text.slice(count)}</>);
            }

        } else {

            chunk = text;
        }

        if (chunk.length > 0) {
            renderArray.push(chunk);
        }
    } else {

        renderArray.push(text)
    }

    return (
        <>
            {renderArray}
        </>
    );
}


class MessageAnnotator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            metadata: props.context.metadata,
            message: props.context.message,
            booleanValue: undefined,
            entities: [],

            requiresReword: this.requiresReword(props.context.metadata),
            targetEntities: [],
            targetEntity: undefined,
            targetEntityExtract: undefined,
        };

        this.highlightAndOpenContext.bind(this);
        this.handleSubmission.bind(this);
        this.handleExceptionSubmission.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {


        const {metadata} = this.state;

        if (nextProps.context.metadata && (nextProps.context.metadata[Headers.MESSAGE_ID] !== metadata[Headers.MESSAGE_ID])) {

            this.setState({
                headers: undefined,
                metadata: nextProps.context.metadata,
                message: nextProps.context.message,
                booleanValue: undefined,
                entities: [],

                requiresReword: this.requiresReword(nextProps.context.metadata),
                targetEntities: [],
                targetEntity: undefined,
                targetEntityExtract: undefined,
            });
        }
    }

    requiresReword(metadata) {

        if (metadata === null) return false;

        let { goal } = this.props;

        return (goal === "intents" ||
            metadata[Headers.ORIGINAL_JOB] === INTENT_CLASSIFICATION_JOB ||
            ["current_complaint", "chief_complaints", "patient_description", "onset"].includes(metadata[Headers.INTENT_SLOT_CODE]));
    }


    highlightAndOpenContext = (event) => {

        if (event.button !== 0) {
            if (this.state.popupPosition) {
                this.setState({
                    popupPosition: undefined,
                });
            }
            return;
        }

        const {message} = this.state;

        determineSelection(message, event.target)
            .then((value) => {

                Object.keys(value).forEach((key) => {

                    this.setState({
                        [key]: value[key],
                    });
                });
            });
    };

    noContextMenu = (event) => {
        event.preventDefault();
    };

    menuItemSelected = (event) => {

        if (event.action === 'close') {

            this.setState({
                selectedText: undefined,
                popupPosition: undefined,
            });
        } else if (event.action === 'found') {

            const {selectedText, selectedTextStart, targetEntities, entities} = this.state;

            if (selectedText) {

                let _entity = {
                    start: selectedTextStart,
                    end: selectedTextStart + selectedText.length,
                    extract: selectedText,
                    category: event.item.code,
                };

                entities.push(_entity);

                if (event.item.code === "patient_description" || event.item.code === "onset" || event.item.code === "current_complaint") {
                    targetEntities.push(_entity);
                }

                if (event.item.next) {

                    this.setState({
                        entities: entities,
                    })
                } else {

                    this.setState({
                        entities: entities,
                        selectedText: undefined,
                        popupPosition: undefined,
                    })
                }
            }
        }
    };

    clearAnnotations = () => {

        this.setState({
            booleanValue: undefined,
            targetEntities: [],
            entities: [],
        });
    };

    handleSubmission = () => {

        let {goal, busy} = this.props;

        if (busy) {

            this.props.submitErrorUpdate({data: {text: "Busy processing."}});
            return;
        }

        let {metadata, booleanValue} = this.state;

        if (metadata[Headers.INTENT_SLOT_DATA_TYPE] === "boolean" && !booleanValue) {

            this.props.submitErrorUpdate({data: {text: "You need to make a selection of yes/no."}});
        } else if (metadata[Headers.AMC]) {

            this.setState({
                copyCareTeamConfirmation: true,
                popupPosition: undefined,
            });
        } else {

            this._handleSubmission(goal, {});
        }
    };

    handleSubmissionAfterReword = () => {
        let {goal, busy} = this.props;
        let { headers } = this.state;

        if (busy) {

            this.props.submitErrorUpdate({data: {text: "Busy processing."}});
            return;
        }

        this._handleSubmission(goal, headers);
    };

    handleSubmissionWithConfirmation = (confirmation) => {

        const $this = this;

        return () => {

            let { goal } = $this.props;

            $this._handleSubmission(goal, { "copyCareTeam" : `${confirmation}` });
            $this.setState({
                copyCareTeamConfirmation: false,
            });
        };
    };

    _handleSubmission(goal, headers) {
        let eventBuilder = undefined;

        switch (goal) {
            case "exception":
                eventBuilder = this.handleExceptionSubmission(headers);
                break;
            case "agenda":
            case "hpi":
            case "ros":
            default:
                eventBuilder = this.handleStandardSubmission(headers);
        }

        if (eventBuilder) {

            eventBuilder.then(event => this.props.context.submitEvent(event));
        }
    }

    correctlyAnnotated = () => {
        let {entities} = this.state;
        return ( entities && entities.length > 0 );
    };


    handleAgendaSubmission = (headers) => {

        let {submitErrorUpdate} = this.props;

        let {entities, metadata} = this.state;

        if (this.correctlyAnnotated()) {

            return this.handleGeneralSubmission((headers) => {

                return generateExceptionIntentIdentifiedEvent(metadata[Headers.TENANT_ID],{
                    message: {
                        conversationId: metadata[Headers.CONVERSATION_ID],
                        messageId: metadata[Headers.MESSAGE_ID],
                        userId: metadata[Headers.USER_ID],
                    },
                    entities: entities,
                }, headers);
            }, headers);
        } else {

            submitErrorUpdate({data: {text: "You need to annotate an intent."}});
            return undefined;
        }
    };


    handleExceptionSubmission = (headers) => {
        let {entities, metadata, booleanValue} = this.state;
        let {submitErrorUpdate} = this.props;

        if (metadata[Headers.CONVERSATION_GOAL] === "exception" && metadata[Headers.INTENT_SLOT_CODE] === "complete" && !booleanValue) {

            submitErrorUpdate({data: {text: "Can only complete by checking the box."}});
            return undefined;
        }

        return this.handleGeneralSubmission((headers) => {

            let result = {
                action: metadata[Headers.CONVERSATION_GOAL] || "execute",
                conversationId: metadata[Headers.CONVERSATION_ID],
                messageId: metadata[Headers.MESSAGE_ID],
                userId: metadata[Headers.USER_ID],
                entities: entities,
                slot: {
                    id: metadata[Headers.INTENT_SLOT_ID],
                    type: "",
                    code: metadata[Headers.INTENT_SLOT_CODE],
                    value: booleanValue,
                },
            };

            return generateExceptionMessageAnnotatedEvent(metadata[Headers.TENANT_ID], result, {...metadata, ...headers});
        }, headers);
    };


    handleStandardSubmission = (headers) => {
        let {entities, metadata, booleanValue} = this.state;
        let {submitErrorUpdate} = this.props;

        preprocessor.preprocessSubmission(metadata, entities);

        if(metadata[Headers.INTENT_SLOT_DATA_TYPE] === "boolean" && !booleanValue) {

            submitErrorUpdate({ data: { text: "You need to make a selection of endorses/denies."}});
        } else if( metadata[Headers.INTENT_SLOT_DATA_TYPE] !== "boolean" && !this.correctlyAnnotated()) {

            submitErrorUpdate({ data: { text: "You need to annotate an the slot or press pass"}});
        } else {

            return this.handleGeneralSubmission((headers) => {

                let result = {
                    action: metadata[Headers.CONVERSATION_GOAL] || "execute",
                    conversationId: metadata[Headers.CONVERSATION_ID],
                    messageId: metadata[Headers.MESSAGE_ID],
                    userId: metadata[Headers.USER_ID],
                    entities: entities,
                    slot: {
                        id: metadata[Headers.INTENT_SLOT_ID],
                        type: "",
                        code: metadata[Headers.INTENT_SLOT_CODE],
                        value: booleanValue,
                    },
                };

                return generateExceptionMessageAnnotatedEvent(metadata[Headers.TENANT_ID], result, headers);
            }, headers);
        }

        return undefined;
    };

    handleGeneralSubmission = (_callback, headers) => {

        let callback = undefined;

        // eslint-disable-next-line no-unused-vars
        let {requiresReword, targetEntity, targetEntities, booleanValue, slotCode, entities, metadata} = this.state;

        if (!requiresReword || targetEntities.length === 0) {

            callback = _callback(headers);

        } else {

            if (targetEntity) {

                entities.forEach((item, index) => {

                    if (item.start === targetEntity.start && item.end === targetEntity.end) {
                        item.extract = targetEntity.extract;
                    }
                });
            }

            //Cycle through target entities.
            let next = targetEntities.pop();
            this.setState({
                popupPosition: undefined,
                headers: headers,
                entities: entities,
                targetEntities: targetEntities,
                targetEntity: next,
                targetEntityExtract: next.extract,
            });
        }

        return callback;
    };


    setYes = () => {

        const {booleanValue} = this.state;

        if (booleanValue === "true") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "true",
            });
        }
    };

    setNo = () => {

        const {booleanValue} = this.state;

        if (booleanValue === "false") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "false",
            });
        }
    };

    toggleYesNo = () => {

        const {booleanValue} = this.state;

        this.setState({
            booleanValue: !booleanValue,
        });
    };

    nextMessage = () => {

        let {metadata} = this.state;

        generateNextMessageEvent(metadata[Headers.TENANT_ID], metadata[Headers.CONVERSATION_ID], metadata[Headers.USER_ID])
            .then(event => this.props.context.submitEvent(event));
    };

    redirectMessage = () => {

        let { metadata } = this.state;

        generateRedirectMessageEvent(metadata[Headers.TENANT_ID], metadata[Headers.CONVERSATION_ID], metadata[Headers.USER_ID], metadata[Headers.MESSAGE_ID])
            .then(event => this.props.context.submitEvent(event));
    };

    handleRewordChange = (event) => {

        const {entities, targetEntity} = this.state;

        targetEntity.extract = event.target.value;

        entities.filter(entity =>  entity.start === targetEntity.start && entity.start === targetEntity.start).forEach((entity) => entity.extract = targetEntity.extract);


        this.setState({
            targetEntity: targetEntity,
            entities: entities,
        });
    };

    handleDismissDialog = () => {
        this.setState({copyCareTeamConfirmation: false});
    };

    render() {

        let {classes, busy, goal, slotCode} = this.props;

        let {message, metadata, entities, popupPosition, booleanValue, copyCareTeamConfirmation} = this.state;

        let {targetEntity} = this.state;

        if (goal === "exception" && slotCode === "complete") {

            return this.renderCompleteExceptionSlot();
        }


        if (targetEntity) {

            return this.renderReword();
        }

        let booleanChoice = metadata[Headers.INTENT_SLOT_DATA_TYPE] === "boolean" ? (<div className={classes.control}>
                    <span className={classes.controlButtonWrapper}>
                        <ButtonGroup variant="contained" color="primary" className={classes.controlButtonGroup}>
                          <Button disabled={"true" === booleanValue} onClick={this.setYes}
                                  className={classes.controlButtonLeft}><FormattedMessage id={"yes"}
                                                                                          defaultMessage={"endorses"}/></Button>
                          <Button disabled={"false" === booleanValue} onClick={this.setNo}
                                  className={classes.controlButtonRight}><FormattedMessage id={"no"}
                                                                                           defaultMessage={"denies"}/></Button>
                        </ButtonGroup>
                    </span>
        </div>) : (<></>);

        let instructions = this.deriverHeader();

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {instructions}
                </div>
                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`} onContextMenu={this.noContextMenu}
                        onMouseUp={this.highlightAndOpenContext}>
                        <RenderAnnotated text={message} entities={entities}/>
                    </h3>
                    <AnnotationContextMenu targetSlot={metadata[Headers.INTENT_SLOT_CODE] || goal}
                                           menuItemSelected={this.menuItemSelected} popupPosition={popupPosition}/>
                </div>
                <div className={classes.fill}/>
                {booleanChoice}
                <div className={classes.footer}>
                    <Button disabled={busy} onClick={this.nextMessage}><PlayArrow fontSize="large" color="primary"/>
                        <FormattedMessage id="discard" defaultMessage="Discard"/></Button>
                    <div className={classes.fill}/>
                    { metadata[Headers.AMC] && (<Button disabled={busy} onClick={this.redirectMessage}><CallSplit fontSize="large" color="primary"/>
                        <FormattedMessage id="redirect" defaultMessage="Redirect to messaging"/></Button>) }
                    <div className={classes.fill}/>
                    <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large"
                                                                                            color="primary"/></Button>
                </div>
                <Dialog
                    open={copyCareTeamConfirmation}
                    keepMounted
                    onClose={this.handleDismissDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Copy care team?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <FormattedMessage id={'messaging.copy.careteam.description'} defaultMessage={"There is an active direct messaging session with the care team. Should we send them a copy of the message?"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSubmissionWithConfirmation(true)} color="primary">
                            <FormattedMessage id={'yes'} defaultMessage={"Yes"} />
                        </Button>
                        <Button onClick={this.handleSubmissionWithConfirmation(false)} color="primary">
                            <FormattedMessage id={'no'} defaultMessage={"No"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    deriverHeader() {

        let {classes, busy, goal, slotCode} = this.props;

        // eslint-disable-next-line no-unused-vars
        let {targetEntity, message, metadata, entities, popupPosition, booleanValue} = this.state;

        if (goal === "agenda" && !metadata[Headers.INTENT_SLOT_CODE]) {

            return (
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large"
                                                                                  color="primary"/></Button>
                    <FormattedMessage id="highlight.intents" defaultMessage="Highlight intents"/>
                </Typography>
            );
        }

        if (goal === "exception" && slotCode === "complete") {

            return (
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <FormattedMessage id="exception.conversation.intent"
                                      defaultMessage="Conversation Intent"/>:<br/>{this.props.context.metadata[Headers.INTENT_SLOT_VALUE]}
                </Typography>
            );
        }

        return (
            <Typography variant='h6' align='left' className={classes.instruction}>
                <FormattedMessage id="goal" defaultMessage="Goal"/>: {goal} <br/>
                <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large"
                                                                              color="primary"/></Button>
                <FormattedMessage id="annotation.title" defaultMessage="Annotate"/>: {metadata[Headers.INTENT_SLOT_CODE] || goal}
            </Typography>
        );
    }

    renderCompleteExceptionSlot() {

        // eslint-disable-next-line no-unused-vars
        let {classes, busy, goal, context} = this.props;

        let {message, booleanValue} = this.state;

        let instructions = this.deriverHeader();

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    {instructions}
                </div>
                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`} onContextMenu={this.noContextMenu}
                        onMouseUp={this.highlightAndOpenContext}>
                        {message}
                    </h3>
                </div>
                <div className={classes.fill} />
                <div className={classes.footer}>
                    <span className={classes.controlButtonWrapper}>
                        <FormControlLabel
                            className={classes.completeCheckbox}
                            value="complete"
                            control={<Checkbox value={booleanValue} onChange={this.toggleYesNo} color="primary"/>}
                            label="Complete"
                            labelPlacement="start"
                        />
                    </span>
                    <div className={classes.fill}/>
                    <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large"
                                                                                            color="primary"/></Button>
                </div>
            </div>
        );
    }


    //TODO: Move a lot to the server side...
    renderReword() {

        let {classes, busy} = this.props;

        let {entities, targetEntity, targetEntityExtract} = this.state;

        let question = (<>Just continue something went wrong.</>);

        let target = targetEntity.category;

        if(targetEntity.category === "current_complaint") {

            if(entities.filter(entity =>  entity.start === targetEntity.start && entity.start === targetEntity.start && entity.category === "sx").length > 0) {
                target = "patient_description";
            }
        }

        if(target === "current_complaint") {
            question = (<>For <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/> what is the main symptom you are having?</>)
        } else if(target === "patient_description") {
            question = (<>
                When did <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/> start?<br />
                Can you describe any symptoms that happen with <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/>?<br />
            </>);
        } else if(target === "onset") {
            question = (<>
                Is this a problem ongoing prior to <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/>?<br />
            </>);
        }

        return (<div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <FormattedMessage id="reword.sentence" defaultMessage="Reword sentence for"/> "{targetEntityExtract}"
                </Typography>
            </div>
            <div className={classes.content}>
                <h3 className={classes.message}>
                    {question}
                </h3>
            </div>
            <div className={classes.fill} />
            <div className={classes.footer}>
                <div className={classes.fill} />
                <Button disabled={busy} onClick={this.handleSubmissionAfterReword}><KeyboardReturn fontSize="large"
                                                                                        color="primary"/></Button>
            </div>
        </div>);
    }
}

export default withStyles(styles)(props => (
    <ExceptionContext.Consumer>
        {value => {
            return (<MessageAnnotator context={value} {...props} />);
        }
        }
    </ExceptionContext.Consumer>
));
