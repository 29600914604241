import React, {Component} from 'react';

import {DirectedGraph} from "graphology";
import Sigma from "sigma";
import ForceSupervisor from "graphology-layout-force/worker";

import {withStyles} from '@material-ui/core/styles';

import {green} from '@material-ui/core/colors';
import {
    Paper,
    LinearProgress, Select, MenuItem,
} from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import {notificationService} from "../../../utils/notification";
import {ontologyApi} from "../../../utils/services/ontology.api";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        paddingTop: "24px",
        paddingBottom: "100px",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    paper: {
        height: "100%",
        width: "100%",
        minHeight: "fit-content",
        minWidth: "fit-content",
    },
    or: {
        textAlign: "center",
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    snackbarSuccess: {
        backgroundColor: green[600],
        padding: theme.spacing(0.5),
    },
    closeError: {
        padding: theme.spacing(0.5),
    },
    iframe: {
        height: "inherit",
        width: "inherit",
        padding: "25px"
    },
    graph: {
        backgroundColor: "#fff",
        height: "inherit",
        width: "inherit",
    }
});

class Inspect extends Component {

    constructor(props) {
        super(props);

        this.graphRef = React.createRef();
        this.internal = new DirectedGraph();

        this.state = {
            busy: true,
        };
    }

    componentWillMount() {
        navigationTitleService.update("Inspect - Condition");

    }

    componentDidMount() {

        this.renderer = new Sigma(this.internal, this.graphRef.current, {
            renderEdgeLabels: true,
            labelRenderedSizeThreshold: 0,
            labelWeight: "bold",
        });
        this.layout = new ForceSupervisor(this.internal);
        this.layout.start();


        ontologyApi.listModels("conditions")
            .then(value => {

                this.setState({ conditions: value.data.sort(function(a, b){return a.display.localeCompare(b.display)}) });

            }, reason => notificationService.httpError(reason))
    }

    componentWillUnmount() {
        this.renderer.kill();
        this.layout.kill();
    }

    _conditionChange = (event) => {

        const conditionId = event.target.value;

        this.setState({ conditionId: conditionId });

        this.internal.clear();

        let params = new URLSearchParams();
        params.append("classExtensions", "medication");
        params.append("classExtensions", "action");
        params.append("classExtensions", "imaging");
        params.append("classExtensions", "procedure");
        params.append("classExtensions", "communication");
        params.append("classExtensions", "device");
        params.append("classExtensions", "labTest");
        params.append("classExtensions", "condition");
        params.append("classExtensions", "observation");
        params.append("classExtensions", "question");
        params.append("classExtensions", "anatomicalLocation");
        params.append("classExtensions", "bodySystem");
        params.append("classExtensions", "epidemiologicalFactor");
        params.append("classExtensions", "pathologicalProcess");
        params.append("classExtensions", "sex");
        params.append("classExtensions", "vocabulary");

        ontologyApi.subgraph(conditionId, "conditions", params)
            .then(value => {

                value.data.vertexes.forEach((_item) => {
                    if(!this.internal.hasNode(_item.bid)) {
                        if(_item.class === "question") {
                            _item.label = _item.content + " - " + _item.class;
                        } else {
                            _item.label = _item.display + " - " + _item.class;
                        }
                        this.internal.addNode(_item.bid, _item);
                    }
                });

                value.data.edges.forEach((_item) => {
                    if(!this.internal.hasEdge(_item.from, _item.to)) {
                        _item.label = `${_item.rel}:${_item.class}=${_item.weight}`;
                        this.internal.addEdgeWithKey(_item.bid, _item.from, _item.to, _item);
                    }
                });

                this.internal.nodes().forEach((node, i) => {
                    const angle = (i * 2 * Math.PI) / this.internal.order;
                    this.internal.setNodeAttribute(node, "x", 100 * Math.cos(angle));
                    this.internal.setNodeAttribute(node, "y", 100 * Math.sin(angle));
                });

            }, reason => notificationService.httpError(reason));
    }

    render() {

        let {classes} = this.props;
        let {
            busy,
            dashboardUrl,
            conditions,
            conditionId,
        } = this.state;

        return (
            <Paper className={classes.paper}>
                <Select labelId="condition-select"
                        id="condition-select"
                        value={conditionId}
                        onChange={this._conditionChange}>
                    { conditions && conditions.map(condition => <MenuItem key={`condition-${condition.bid}`} value={condition.bid}>{condition.display}</MenuItem>)}
                </Select>
                <div className={classes.graph}  ref={this.graphRef} ></div>
            </Paper>
        );
    }
}

export default withStyles(styles)(Inspect);
