import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {consumerApi} from "../../../../utils/services/consumers.api";

import ConversationViewContext from "./context";
import {conversationApi} from "../../../../utils/services/conversation.api";
import {quinnApi} from "../../../../utils/services/quinn.api";
import {notificationService} from "../../../../utils/notification";


import {ConversationVisualBloc} from "./bloc";
import DecodedComponent from "../../../shared/DecodedComponent";
import LoadingGraph from "./LoadingGraph";
import SelectConversation from "./SelectConversation";
import VisualiseConversation from "./VisualiseConversation";
import ConversationPanel from "./ConversationPanel";
import GraphDataPanel from "./GraphDataPanel";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    landing: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
    },
    fill: {
        flex: "1 1 auto",
    },
    graph: {
      height: "100%",
      minHeight: "100%",
      width: "100%",
    },
    paper: {
        backgroundColor: "#fff",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: "auto",
        paddingTop: "15px",
        display: "flex",
        flexDirection: "column",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        marginRight: "20px",
        padding: '2px 4px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    linkDisplay: {
        width: "100%",
        padding: "40px",
        maxWidth: "100%",
        wordBreak: "break-all",
    },

    content: {
        padding: "20px",
        flex: "1 1 auto",
    },

    panelContainer: {
        height: "100%",
    },

    panelColumn: {
        height: "100%",
    },

    patientPanel: {
        padding: "20px 24px",
        marginBottom: "20px",
    },

    insight: {
        position: "absolute",
        top: "80px",
        left: "20px",
        zIndex: "10000",
    },

    conversation: {
        position: "absolute",
        top: "80px",
        right: "20px",
        zIndex: "10000",
    },

    memoryPanel: {
    },
});

class Visualise extends DecodedComponent {

    _memoryReload;

    constructor(props) {
        super(props);

        this.bloc = new ConversationVisualBloc();

        this.state = {
            graph: {
                vertices: {
                    demographic: [],
                    conversation: [],
                    ontology: [],
                    features: [],
                    conditions: [],
                },
                edges: [],
            },
            tenant: 'jovive',
            patient: undefined,
            busy: false,
            initialised: false,
        };

        this.getConversation.bind(this);
        this.handleIdInputChange.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }


    loadPatientInformation = (patientId) => {

        consumerApi.getPersonSummary(patientId).then(patient => {
            this.setState({
                patientLookedUp: true,
                patient: patient.data,
            });
        }, (err) => {
            this.setState({
                patientLookedUp: true,
            });
        });
    };

    getConversation = () => {

        const { busy, conversationId, tenant } = this.state;

        if(!busy && conversationId && conversationId.length > 3) {

            this.setState({
                graph: {
                    vertices: [],
                    edges: [],
                },
                busy: true,
            });

            // this._getConversationInternal(conversationId, 0, 10);
            this._getMemoryInternal(conversationId, tenant, 0, 10);
        }
    };

    _getConversationInternal = (_conversationId, page, size) => {

        conversationApi.conversationMessages(_conversationId, page, size)
            .then(value => {

                const { conversationId, patientLookedUp, messages } = this.state;

                if(conversationId !== _conversationId) return;

                const data = value.data;
                const items = data.items;
                const pagination = data.page;

                let newState = {};

                if(items && items.length > 0) {

                    newState.messages = messages.concat(data.items);
                    if(!patientLookedUp) {
                        let inMessages = items.filter((item) => item.direction === "IN");
                        if(inMessages.length > 0) {
                            this.loadPatientInformation(inMessages[0].author);
                        }
                    }
                }

                if(pagination && pagination.total > ((page + 1) * size)) {

                    this._getConversationInternal(_conversationId, page + 1, size);
                } else {

                    newState.busy = false;
                }

                this.setState({
                    ...newState,
                });
            }).catch((error) => {

                this.setState({
                    error: `Conversation not found - ${error}`,
                    busy: false,
                });
            });
    };

    _getMemoryInternal = (_conversationId, tenant, page, size) => {

        this._updateGraph(_conversationId, tenant, true);

        this._memoryReload = setInterval(() => {
            this._updateGraph(_conversationId, tenant, false);
        }, 5000);
    };

    _updateGraph = (_conversationId, tenant,first) => {

        quinnApi.conversationMemory(_conversationId, tenant)
            .then(value => {

                let { graph, graphIds, graphEdgeIds } = this.state;

                let _update = first;

                const sortedNextVertices = value.data.vertices.sort((a, b) => (a.id > b.id) ? 1 : -1)
                const sortedNextEdges = value.data.edges.sort((a, b) => (a.id > b.id) ? 1 : -1)

                const sortedCurrentVertices = graph.vertices.sort((a, b) => (a.id > b.id) ? 1 : -1)
                const sortedCurrentEdges = graph.edges.sort((a, b) => (a.id > b.id) ? 1 : -1)

                if(sortedNextVertices.length !== sortedCurrentVertices.length) {
                    _update = true;
                }

                if(!_update) {
                    for (let i = 0; i < sortedNextVertices.length; i++) {

                        if (sortedNextVertices[i]._id !== sortedCurrentVertices[i].id &&
                            sortedNextVertices[i].value !== sortedCurrentVertices[i].value) {
                            _update = true;
                        }
                    }
                }

                if(!_update) {
                    for (let i = 0; i < sortedNextEdges.length; i++) {

                        if (sortedNextEdges[i]._id !== sortedCurrentEdges[i].id) {
                            _update = true;
                        }
                    }
                }

                if(_update) {

                    const newVertices = sortedNextVertices;
                    graphIds = newVertices.map(vertex => vertex._id);

                    const newEdges = sortedNextEdges.filter(edge => graphIds.includes(edge._from) || graphIds.includes(edge._to));
                    graphEdgeIds = newEdges.map(edge => edge._id);

                    this.setState({
                        busy:false,
                        graph: {
                            vertices: newVertices,
                            edges: newEdges,
                        },
                        graphIds: graphIds,
                        graphEdgeIds: graphEdgeIds,
                    });
                }
            })
            .catch(reason => {
                notificationService.error("Error loading conversation memory - " + reason);
                this.setState({
                    busy: false,
                });
            })
        ;
    }

    handleIdInputChange = (event) => {

        this.setState({conversationId: event.target.value});
    };

    handleErrorClose = (event, reason) => {

        this.setState({
            error: undefined,
        });
    };

    _handleTenantChange = (event) => {

        this.setState({ tenant: event.target.value});
    }

    _handleNodeClick = (event) => {

    }

    render() {

        let {classes} = this.props;
        let {conversation,} = this.state;

        let context = {
            bloc: this.bloc,
        };

        if(conversation) {

            return (<ConversationViewContext.Provider  value={context}>
                <div className={classes.root}>
                    <VisualiseConversation />
                    <span className={classes.insight}>
                        <GraphDataPanel />
                    </span>
                    <span className={classes.conversation}>
                        <ConversationPanel />
                    </span>
                </div>
            </ConversationViewContext.Provider>);
        }

        return (
            <ConversationViewContext.Provider  value={context}>
                <div className={classes.root}>
                    <div className={classes.landing}>
                        <div className={classes.fill}></div>
                        <LoadingGraph />
                        <SelectConversation />
                        <div className={classes.fill}></div>
                    </div>
                </div>
            </ConversationViewContext.Provider>
        );
    }
}

export default withStyles(styles)(Visualise);
