import * as rxjs from "rxjs";
import {notificationService} from "../../../../../../../utils/notification";
import { uuid } from "uuidv4";
import {utilitiesApi} from "../../../../../../../utils/services/utilities.api";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import {pipelinesApi} from "../../../../../../../utils/services/pipelines.api";
import {AsyncJobBlocEvent} from "../../../bloc";

export class Bloc {

    constructor(parent, props) {

        this.parent = parent;
        const pipelineId = props.pipelineId

        let pipeline = "none"
        if (pipelineId === "3") {
            pipeline = "condition.observations"
        } else if (pipelineId === "4") {
            pipeline = "condition.complications"
        }
        this.subject = new rxjs.BehaviorSubject({
            ...props,
            initialised: false,
            job: {
                pipeline: pipeline,
            },
        });

        this.events = new rxjs.Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
            ...this.subject.value,
            ...newProps,
        });
    }

    initialise = () => {
        this.__updateSubject({ initialised: true });
    }

    addConcept = () => {

        let { event } = this.subject.value;

        let newConcept = { id: uuid() };
        newConcept.id = uuid()
        event.data.entity.concepts.push(newConcept);

        this.__updateSubject({ event: event });
    }

    removeConcept = (conceptId) => {

        let { event } = this.subject.value;

        event.data.entity.concepts = event.data.entity.concepts.filter(_concept => _concept.id !== conceptId);

        this.__updateSubject({ event: event });
    }

    getConceptOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({ query: _inputValue, dataType: ["condition"] })
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        let display = item.index.join(", ");

                        options.push({
                            name: display,
                            canonicalName: item.canonicalName,
                            conceptId: item.conceptId,
                        })
                    })

                    resolve(options)
                }, error => {
                    notificationService.httpError(error);
                    resolve(options)
                });
        })
    }

    onDropdownChange = (_id, _event) => {

        let { job } = this.subject.value;

        if(_event?.conceptId) {

            job.condition = {
                code: {code: _event.conceptId, system: "http://www.nlm.nih.gov/research/umls"},
                text: _event.canonicalName,
                value: _event.canonicalName,
            }
        } else {
            job.condition = undefined;
        }

        this.__updateSubject({ job: job });
    }

    setJobProperty = (property) => (event) => {
        let { job } = this.subject.value;
        job[property] = event.target.value;
        this.__updateSubject({ job: job })
    }

    addJob = (jobType) => {

        const { pipelineId, job } = this.subject.value;

        this.__updateSubject({ busy: true });

        const task = {
            "data": {
                "condition": `${job.condition.text}`,
                "reference": `${job.sourceMaterial}`,
                "notes": typeof job?.notes == "undefined" ? "" : job.notes,
                "cui": `${job.condition.code.code}`
            },
            "createdOn": "2022-01-01 00:00:00.000000",
            "condition": {},
            "jobType": `hitl.jobs.async.${jobType}`,
            "jobClass": "clinical",
            "metadata": {
            },
            "state": "REGISTERED",
            "version": "0.0.1",
            "changeLog": []
        }

        pipelinesApi.addTask(pipelineId, task)
            .then(data => {
                notificationService.success("Success");
                this.parent.events.next({ type: AsyncJobBlocEvent.NAVIGATE_TO, data: { next: "/jobs/async" }});
            }, reason => {
                notificationService.httpError(reason);
                this.__updateSubject({ busy: false})
            });
    }
}

export class BlocEvent {
}
