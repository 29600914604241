import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button} from "@material-ui/core";
import AnnotationContextMenu from "../../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../../utils/text.highlight";
import AnnotatedText from "../../shared/AnnotatedText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {preprocessor} from "../../shared/preprocessor";
import {notificationService} from "../../../../../utils/notification";
import Loading from "../../../../shared/Loading";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});

function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}

function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}

function RenderAnnotated(props) {

    const { text, entities, handleRemoveAnnotation } = props;

    let renderArray = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {

                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    renderArray.push(<>{text.slice(count, currentEntity.start)}</>);
                }

                entityCount += 1;

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {

                    highlights.push(entities[entityCount]);
                    entityCount += 1;
                }

                renderArray.push(<AnnotatedText text={text} annotations={highlights} handleRemoveAnnotation={handleRemoveAnnotation}/>)

                count = currentEntity.end;
            }

            // Adding the rest of the text
            if (count < text.length) {

                renderArray.push(<>{text.slice(count)}</>);
            }

        } else {

            chunk = text;
        }

        if (chunk.length > 0) {
            renderArray.push(chunk);
        }
    } else {

        renderArray.push(text)
    }

    return (
        <>
            { renderArray }
        </>
    );
}



class IntentAnnotation extends Component {

    constructor(props) {
        super(props);

        const { bloc } = props;

        this.bloc = bloc;

        this.state = {
            bloc: bloc,
            initialising: true,
        };

        this._highlightAndOpenContext = this._highlightAndOpenContext.bind(this);
        this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
    }

    componentDidMount() {

        this.subscription = this.bloc.subscribeToState(this.__handleStateUpdate);
        this.bloc.initialise();
    }

    componentWillUnmount() {

        this.subscription.unsubscribe();
    }

    __handleStateUpdate = (subject) => {

        this.setState({
            ...subject,
        });
    }


    _highlightAndOpenContext = (event) => {

        if (event.nativeEvent === "MouseEvent" && event.button !== 0) {
            if (this.state.popupPosition) {
                this.setState({
                    popupPosition: undefined,
                });
            }
            return;
        }

        const { message } = this.state;

        determineSelection(message, event.target)
            .then((value) => {

                Object.keys(value).forEach((key) => {

                    this.setState({
                        [key]: value[key],
                    });
                });
            });
    };

    noContextMenu = (event) => {

        event.preventDefault();
    };

    menuItemSelected = (event) => {

        if(event.action === 'close') {

            this.setState({
                selectedText: undefined,
                popupPosition: undefined,
            });
        } else if(event.action === 'found') {

            const { selectedText, selectedTextStart, targetEntities, entities } = this.state;

            if(selectedText) {

                let _entity = {
                    start: selectedTextStart,
                    end: selectedTextStart + selectedText.length,
                    extract: selectedText,
                    category: event.item.code,
                };

                entities.push(_entity);

                if(event.item.code === "patient_description" || event.item.code === "onset" || event.item.code === "current_complaint") {
                    targetEntities.push(_entity);
                }

                if(event.item.next) {

                    this.setState({
                        entities: entities,
                    })
                } else {

                    this.setState({
                        entities: entities,
                        targetEntities: targetEntities,
                        selectedText: undefined,
                        popupPosition: undefined,
                    })
                }
            }
        }
    };

    clearAnnotations = () => {

        this.setState({
            booleanValue: undefined,
            targetEntities: [],
            entities: [],
        });
    };

    // _sendToException = () => {
    //
    //     let { exceptionEvent } = this.props;
    //
    //     let { event : { data } } = this.props.context;
    //
    //     let { lastTurn, slotSpecification } = data;
    //
    //     let { entities, contextOverride } = this.state;
    //
    //     let addedEntities = entities.filter((entity) => !entity.id);
    //     let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))
    //
    //     if (exceptionEvent !== "MESSAGE_DISCARD" && this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {
    //         notificationService.error("This message is correctly annotated.");
    //         return;
    //     }
    //
    //     messageAnnotationEventService.update(exceptionEvent || MESSAGE_ANNOTATION_EVENTS.EXCEPTION, {
    //         action: exceptionEvent ? "suggest_forget" : "suggest_escalation",
    //         conversationId: lastTurn.metadata.conversationId,
    //         messageId: lastTurn._id,
    //         tenantId: lastTurn.tenant,
    //         entityUpdates: {
    //             add: addedEntities,
    //             remove: removedEntities
    //         },
    //         original: data,
    //     });
    // };

    _submit = () => {

        let { event : { data : { slotSpecification } } } = this.props.context;

        let { requiresReword, entities, metadata, contextOverride } = this.state;

        if(!this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {
            notificationService.error("You need to annotate the target slot to mark this as successful.");
            return;
        }

        if(entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
            return;
        }

        preprocessor.preprocessSubmission(metadata, entities, contextOverride);

        let requiresRewording = this._deriveRewording(entities, this.props.context.event.data);

        if(!requiresReword && requiresRewording.length > 0) {

            this.setState({
                requiresReword: true,
                requiresRewording: requiresRewording,
            });
        } else {

            this._submitInternal(entities, contextOverride);
        }
    };


    _submitInternal = (entities, contextOverride) => {

        let { event : { data : { slotSpecification, slot, lastTurn } } } = this.props.context;

        if(!this._correctlyAnnotated(slotSpecification, entities, contextOverride)) {

            notificationService.error("You need to annotate the target slot to mark this as successful or a single new intent.");
        } if(entities.filter((item) => "patient_description" === item.category).length > 1) {

            notificationService.error("Only one patient description annotation is allowed.");
        } else {

            let addedEntities = entities.filter((entity) => !entity.id);
            let removedEntities = lastTurn.metadata.entities.filter((entity) => entity.id && !entities.some(_entity => _entity.id === entity.id))

            // messageAnnotationEventService.update(MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED, {
            //     action: "answer",
            //     conversationId: lastTurn.metadata.conversationId,
            //     messageId: lastTurn._id,
            //     tenantId: lastTurn.tenant,
            //     entityUpdates: {
            //         add: addedEntities,
            //         remove: removedEntities
            //     }
            // });
        }
    }

    _correctlyAnnotated = (slotSpecification, entities, contextOverride) => {

        let correct = (entities && entities.length > 0 && entities.filter((item) => slotSpecification.code === item.category).length > 0);
        if(!correct && contextOverride?.derived?.length > 0) {
            correct = entities.filter((item) => contextOverride.menu[item.category]).length > 0;
        }

        if(correct) return correct;

        const intentList = entities.filter((item) => "intent" === item.category);

        if(intentList.length === 0) {
            return correct;
        }

        if(intentList.length > 1) {
            return false;
        }

        const intent = intentList[0];

        return (entities && entities.length > 0 && entities.filter((item) =>
            item.start === intent.start &&
            item.end === intent.end &&
            item.category !== "intent").length > 0);
    };


    render() {

        let { initialising } = this.state;

        if(initialising) {
            return (<Loading />);
        }

        let slot = this.bloc.slot();

        if(slot === "intent") {

            return this.renderIntentAnnotation();
        }

        return this.renderAnnotation();
    }


    handleRemoveAnnotation = (annotatedText) => {
        const filterCallback = entity => entity.extract !== annotatedText.extract;

        this.setState((state, props) => ({
            entities: state.entities.filter(filterCallback),
            targetEntities: state.targetEntities.filter(filterCallback)
        }));
    }

    renderIntentAnnotation() {

        const { busy, classes } = this.props;

        let { event : { data : { lastTurn, slotSpecification, slot, relatedSlots, relatedDomains, relatedSlotSpecifications, quinnLastTurn } } } = this.state;
        let { contextOverride, entities, intents, popupPosition }  = this.state;

        let lastQuestion = <><Typography variant='h4' align='left' className={classes.instructionLastQuestion}>
            "{ this.__renderLastQuestion(quinnLastTurn) }"
        </Typography></>;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    { lastQuestion }
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        { slotSpecification ? <>
                            <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large" color="primary"/></Button>
                            <FormattedMessage id="annotation.title" defaultMessage="Annotate " /> {slotSpecification["short_description"]}</> : <>End and schedule an appointment or terminate. The conversation is in an invalid state.</> }
                    </Typography>
                </div>

                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`}
                        onContextMenu={ this.noContextMenu }
                        onTouchEnd={ this._highlightAndOpenContext }
                        onMouseUp={ this._highlightAndOpenContext }
                    >
                        <RenderAnnotated
                            text={lastTurn.content}
                            entities={entities}
                            handleRemoveAnnotation={this.handleRemoveAnnotation}/>
                    </h3>
                    <AnnotationContextMenu
                        targetSlot={ slot ? slot.code : "none" }
                        targetSlotSpecification={ slotSpecification }
                        relatedSlots={ relatedSlots }
                        relatedDomains={ relatedDomains }
                        relatedSlotSpecifications={ relatedSlotSpecifications }
                        menuOverride={ contextOverride }
                        menuItemSelected={ this.menuItemSelected }
                        popupPosition={ popupPosition }
                    />
                </div>

                <div className={classes.fill} />

                <div className={classes.footer}>
                    { slotSpecification ? <>
                        <div className={classes.fill} />
                        <Button disabled={ busy } onClick={this._submit}>
                            <KeyboardReturn fontSize="large" color="primary"/>
                        </Button></> : <></> }
                </div>
            </div>
        );
    }

    renderAnnotation() {

        let { classes, busy } = this.props;

        let { event : { data : { slotSpecification, slot, relatedSlots, relatedDomains, relatedSlotSpecifications, quinnLastTurn } } } = this.state.event;
        let { contextOverride, entities, intents, popupPosition }  = this.state;

        let lastQuestion = <><Typography variant='h4' align='left' className={classes.instructionLastQuestion}>
                "{ this.__renderLastQuestion(quinnLastTurn) }"
            </Typography></>;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    { lastQuestion }
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        { slotSpecification ? <>
                            <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large" color="primary"/></Button>
                        <FormattedMessage id="annotation.title" defaultMessage="Annotate " /> {slotSpecification["short_description"]}</> : <>End and schedule an appointment or terminate. The conversation is in an invalid state.</> }
                    </Typography>
                </div>

                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`}
                        onContextMenu={ this.noContextMenu }
                        onTouchEnd={ this._highlightAndOpenContext }
                        onMouseUp={ this._highlightAndOpenContext }
                    >
                        <RenderAnnotated
                            text={quinnLastTurn.text}
                            entities={entities}
                            handleRemoveAnnotation={this.handleRemoveAnnotation}/>
                    </h3>
                    <AnnotationContextMenu
                        targetSlot={ slot ? slot.code : "none" }
                        targetSlotSpecification={ slotSpecification }
                        relatedSlots={ relatedSlots }
                        relatedDomains={ relatedDomains }
                        relatedSlotSpecifications={ relatedSlotSpecifications }
                        menuOverride={ contextOverride }
                        menuItemSelected={ this.menuItemSelected }
                        popupPosition={ popupPosition }
                    />
                </div>

                <div className={classes.fill} />

                <div className={classes.footer}>
                    { slotSpecification ? <>
                    {/*<Button disabled={ busy }  onClick={this._tryRephrase}>*/}
                    {/*    <Close fontSize="large" color="primary"/> { exceptionLabel ? <>{ exceptionLabel }</> : <FormattedMessage id="exception" defaultMessage="Exception" /> }*/}
                    {/*</Button>*/}
                    <div className={classes.fill} />
                    <Button disabled={ busy } onClick={this._submit}>
                        <KeyboardReturn fontSize="large" color="primary"/>
                    </Button></> : <></> }
                </div>
            </div>
        );
    }

    __renderLastQuestion = (lastTurn) => {

        let content = lastTurn.content;

        if(["", "en"].includes(lastTurn?.metadata?.translation?.language)) {
            content = lastTurn.metadata.translation.translation;
            console.log("Set to translated.", lastTurn.metadata.translation.translation)
        }

        return content;
    }
}

export default withStyles(styles)(IntentAnnotation);
