import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {ChatBloc} from "./chat.bloc";

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: "rgb(242, 242, 242)",
        minHeight: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    chat: {
        padding: "16px 12px",
        height: "inherit",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
    },
    subjectMessageRow: {
        paddingTop: "24px",
        display: "flex",
    },
    subjectMessageContent: {
        paddingTop: "32px",
        paddingRight: "12px",
    },
    subjectMessage: {
        minWidth: "100px",
        borderRadius: "6px",
        padding: "10px 12px",
        backgroundColor: "rgba(194, 206, 212, 0.2)",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#002F59",
    },
    subjectMessageTimestamp: {
        paddingTop: "8px",
        float: "right",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#828282",
        textAlign: "right",
    },

    providerMessageRow: {
        paddingTop: "24px",
        display: "flex",
    },

    providerMessageContent: {
        paddingTop: "32px",
    },
    providerMessage: {
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '6px',
        marginTop: "8px",
        padding: "10px 12px",
        backgroundColor: "#00C3FF",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#ffffff",
    },
    providerMessageTimestamp: {
        paddingTop: "8px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#828282",
    },
});

class ChatHistory extends Component {

    bloc;
    blocStateSubscription;

    constructor(props) {
        super(props);

        this.bloc = new ChatBloc(props.user, props.conversationId, props.tenant);

        this.state = {
            loading: true
        };

        this.__stateUpdate = this.__stateUpdate.bind(this);
    }

    componentDidMount() {

        this.blocStateSubscription = this.bloc.subscribeToState(this.__stateUpdate);
        this.bloc.start();
    }

    componentWillUnmount() {

        this.blocStateSubscription.unsubscribe();
        this.bloc.close();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(this.props.conversationId !== nextProps.conversationId) {

            this.bloc.switchConversation(nextProps.user, nextProps.conversationId, nextProps.tenant);
        }
    }

    __stateUpdate = (state) => {

        this.setState({
            ...state,
        });
    }

    render() {

        const { classes } = this.props;
        const { messages } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.chat}>
                    { messages && messages.map(_message => <>
                        { this.__renderChat(classes, _message) }
                        </>)
                    }
                </div>
            </div>
        );
    }

    __renderChat(classes, message) {

        if(message.direction === "OUT") {
            return <>
                <div className={classes.subjectMessageRow}>
                    <div className={classes.fill}> </div>
                    <div className={classes.subjectMessageContent}>
                        <div className={classes.subjectMessage}>{message.content}</div>
                        <span className={classes.subjectMessageTimestamp}>{this.__renderMessageTime(message.createdOn)}</span>
                    </div>
                </div>
            </>;
        }

        return <>
            <div className={classes.providerMessageRow}>
                <div className={classes.providerMessageContent}>
                    <div className={classes.providerMessageTimestamp}>{this.__renderMessageTime(message.createdOn)}</div>
                    <div className={classes.providerMessage}>{message.content}</div>
                </div>
                <div className={classes.fill}> </div>
            </div>
        </>;
    }

    __renderMessageTime = (time) => {

        const displayTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return new Date(Date.parse(time)).toLocaleString('en-US', { timeZone: displayTimezone });
    }
}

export default withStyles(styles)(ChatHistory);
