import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";
import {Paper, Button, Snackbar, IconButton, LinearProgress} from "@material-ui/core";
import PlayCircleOutlineOutlined from "@material-ui/icons/PlayCircleOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close"

import MessageAnnotator from "./MessageAnnotator";

import MessageAnnotationPanel from "../component/MessageAnnotationPanel";
import MessageAnnotationContext from '../component/MessageAnnotationPanel/context';
import {
    MESSAGE_ANNOTATION_EVENTS,
    messageAnnotationEventService
} from '../component/MessageAnnotationPanel/service/message.annotation.event.service';

import PriorityComplaintSelection from './PriorityComplaintSelection';
import InformationScreen from '../InformationScreen';

import { federatedSessionWebsocket } from '../../../../utils/services/session.websocket'
import {
    Headers,
    EVENT, extractMetaData, generateAnswerQuinn,
    INTENT_CLASSIFICATION_JOB,
    INTENT_HPI_PRIORITY_JOB,
    MESSAGE_ANNOTATION_JOB, RPA_MANUAL_STEP
} from "../../../../utils/events";

import JobContext from './context';


import ConnectionStatus from "../../../shared/ConnectionStatus";
import {STATE_PAUSED, STATE_WAITING} from "../state";
import {navigationTitleService} from "../../../../utils/title";
import { playSound } from "../../../../utils/sounds";
import {sessionsApi} from "../../../../utils/services/sessions.api";
import Rpa from "./Rpa";
import {Bloc as RewordBloc} from "../component/RewordPanel/bloc";
import {Bloc as NormaliseBloc} from "../component/NormalisePanel/bloc";
import {ConversationTurnBloc} from "../component/ConversationTurnPanel/bloc";
import ConversationTurnPanel from "../component/ConversationTurnPanel";
import NormalisePanel from "../component/NormalisePanel";
import RewordPanel from "../component/RewordPanel";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },
    headerLeft: {

    },
    headerCenter: {

    },
    headerRight: {

    },
    paperFullscreen: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#000",
        zIndex: "1300",
    },
    paper: {
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100% - 90px)",
        height: "calc(100% - 90px)",
    },
    paperContent: {
        overflowY: "auto",
        minHeight: "100%",
        height: "100%",
        margin: "auto",
    },
    paperContentFullscreen: {
        overflowY: "auto",
        minHeight: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {

    },
    footerCenter: {

    },
    footerRight: {

    },

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },
});

class FederatedJobs extends Component {

    handleUpdateSubscription;
    handleStateUpdateSubscription;
    handleErrorUpdateSubscription;
    handleConnectionUpdateSubscription;

    _annotationEventSubscription;

    sessionMonitor;

    constructor(props) {
        super(props);

        this.reconnectWebsocket();

        this.state = {
            busy: false,
            metadata: undefined,
            message: undefined,
            fullscreen: false,
            state: STATE_PAUSED,
            pauseOnNext: false,
            connectionStatus: "off",
            sessionCount: {
                federated: "0",
                exception: "0",
            },
        };

        this.handleJobUpdate.bind(this);
        this.handleUpdateSubscription = federatedSessionWebsocket.registerJobCallback(this.handleJobUpdate);

        this.handleStateUpdate.bind(this);
        this.handleStateUpdateSubscription = federatedSessionWebsocket.registerStateCallback(this.handleStateUpdate);

        this.handleErrorUpdate.bind(this);
        this.handleErrorUpdateSubscription = federatedSessionWebsocket.registerExceptionCallback(this.handleErrorUpdate);

        this.handleConnectionUpdate.bind(this);
        this.handleConnectionUpdateSubscription = federatedSessionWebsocket.registerConnectionCallback(this.handleConnectionUpdate);

        this.submitResult.bind(this);

        this.initSessionsCountIndicator();

        this._handleMessageAnnotationEvents = this._handleMessageAnnotationEvents.bind(this);

        this._initListenToMessageAnnotationEvents = this._initListenToMessageAnnotationEvents.bind(this);
        this._initListenToMessageAnnotationEvents();
    }

    _initListenToMessageAnnotationEvents = (event) => {

        this._annotationEventSubscription = messageAnnotationEventService.registerStateCallback(this._handleMessageAnnotationEvents);
    }

    _handleMessageAnnotationEvents = (_event) => {

        let { busy, event } = this.state;

        if(busy) return;

        const { type, data } = _event;

        let newState = {};

        switch (type) {
            case MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED:
            case MESSAGE_ANNOTATION_EVENTS.QUESTION_REPHRASED:
            case MESSAGE_ANNOTATION_EVENTS.EXCEPTION:
                {
                    newState.busy = true;
                    this._answerQuinn(event, data);
                }
            break;
        }

        this.setState({
            ...newState,
        });
    }


    _answerQuinn = (event, data) => {

        generateAnswerQuinn(event[Headers.TENANT_ID], data)
            .then(_event => {
                federatedSessionWebsocket.send(_event);
            });
    }


    reconnectWebsocket = () => {
        federatedSessionWebsocket.connect();
    };



    initSessionsCountIndicator() {

        this.sessionMonitor = setInterval(() => {

            sessionsApi.getSessionCount()
                .then((data) => {
                    if(data.status === 200) {

                        const _data = data.data;

                        this.setState({
                            sessionCount: {
                                federated: `${_data.federated}`,
                                exception: `${_data.exception}`,
                            },
                        });
                    } else {

                        this.setState({
                            sessionCount: {
                                federated: "unknown",
                                exception: "unknown",
                            },
                        });
                    }
                });

        }, 20000);
    }

    componentWillMount() {

        navigationTitleService.update("Federated Jobs");
    }

    componentWillUnmount() {

        this.handleUpdateSubscription.unsubscribe();
        this.handleStateUpdateSubscription.unsubscribe();
        this.handleErrorUpdateSubscription.unsubscribe();
        this.handleConnectionUpdateSubscription.unsubscribe();
        federatedSessionWebsocket.close();

        if(this._annotationEventSubscription) {
            this._annotationEventSubscription.unsubscribe();
        }

        if(this.sessionMonitor) {
            clearInterval(this.sessionMonitor);
        }
    }

    handleStateUpdate = (newState) => {

        const { message } = this.state;

        if(!message) {

            this.setState({
               busy: false,
               state: newState,
            });
        } else if(message && ((newState === STATE_WAITING) || (this.state.pauseOnNext && newState === STATE_PAUSED))) {

            this.setState({
                busy: false,
                state: newState,
                message: undefined,
                pauseOnNext: false,
            });
        } else if(message && newState === STATE_PAUSED) {

            this.setState({
                busy: false,
                state: newState,
            });
        }
    };

    handleJobUpdate = (data) => {

        if([INTENT_CLASSIFICATION_JOB,  MESSAGE_ANNOTATION_JOB, INTENT_HPI_PRIORITY_JOB, RPA_MANUAL_STEP].includes(data.type) ||
            (EVENT.inbound[data.source] && EVENT.inbound[data.source][data.type])) {
            playSound();
        }

        this.setState({
            busy: false,
            event: data,
            job: data.type,
            metadata: extractMetaData(data),
            message: data.data,
        });
    };

    handleErrorUpdate = (data) => {

        this.setState({
            busy: false,
            error: data.data.text,
        });
    };

    handleConnectionUpdate = (data) => {

        let newState = {
        };

        if(data === "closed") {
            newState = {
                busy: false,
                state: STATE_PAUSED,
                message: undefined,
                pauseOnNext: false,
            };
        }

        this.setState({
            ...newState,
            connectionStatus: data,
        });
    };

    submitResult = (type, data) => {

        let { busy, metadata } = this.state;

        if(busy) {
            this.setState({
                error: "Already processing previous submission.",
            });
            return;
        }

        let stateUpdates = { busy: true };

        if(this.state.state === STATE_PAUSED) {
            stateUpdates.pauseOnNext = true;
        }
        federatedSessionWebsocket.submitJobResult(metadata[Headers.TENANT_ID], type, data);
        this.setState(stateUpdates);
    };

    toggleSessionState = () => {

        const { busy, state } = this.state;
        if(!busy) {
            this.setState({
                busy: true,
            });
            federatedSessionWebsocket.toggleState(state === STATE_PAUSED ? STATE_WAITING : STATE_PAUSED);
        }
    };

    toggleFullscreen = () => {

        this.setState({ fullscreen: !this.state.fullscreen });
    };

    handleErrorClose = (event, reason) => {

        this.setState({
           error: undefined,
        });
    };

    render() {

        let { classes } = this.props;
        let { fullscreen, busy, state, event, job, message, metadata, connectionStatus, error, sessionCount } = this.state;

        let screen = (<><InformationScreen message="Waiting for next message" /></>);

        if(message) {

            let context = {
                metadata: metadata,
                message: message,
                event: event,
            };

            if(job === MESSAGE_ANNOTATION_JOB) {
                screen = (
                    <JobContext.Provider value={context}>
                        <MessageAnnotator
                            busy={busy}
                            messageId={metadata[Headers.MESSAGE_ID]}
                            submitErrorUpdate={this.handleErrorUpdate}
                            submitResultCallback={this.submitResult}
                        />
                    </JobContext.Provider>);
            } else if(job === INTENT_HPI_PRIORITY_JOB) {
                screen = (
                    <JobContext.Provider value={context}>
                        <PriorityComplaintSelection
                            busy={busy}
                            messageId={metadata[Headers.MESSAGE_ID]}
                            submitResultCallback={this.submitResult}
                        />
                    </JobContext.Provider>);
            } else {

                if(EVENT.inbound[event.source] && EVENT.inbound[event.source][event.type]) {


                    if(event?.data?.job === "reword") {

                        const rewordBloc = new RewordBloc(event, federatedSessionWebsocket);
                        screen = (
                            <RewordPanel
                                busy={busy}
                                eventId={event.id}
                                bloc={rewordBloc} />
                        );

                    }  else if(event?.data?.job === "verify-answered" || event?.data?.job === "annotate-only") {

                        context["event"] = event;

                        screen = (
                            <MessageAnnotationContext.Provider value={context} >
                                <MessageAnnotationPanel
                                    busy={busy}
                                    eventId={event.id}
                                />
                            </MessageAnnotationContext.Provider>);

                    } else if(event?.data?.job === "normalise") {

                        const normaliseBloc = new NormaliseBloc(event, federatedSessionWebsocket);
                        screen = (
                            <NormalisePanel
                                busy={busy}
                                eventId={event.id}
                                bloc={normaliseBloc} />
                        );

                    } else if(event?.data?.slotSpecification.code === "intent") {

                        const turnBloc = new ConversationTurnBloc(event, federatedSessionWebsocket);
                        screen = (
                            <ConversationTurnPanel
                                busy={busy}
                                eventId={event.id}
                                bloc={turnBloc} />
                        );

                    } else {

                        context["event"] = event;

                        screen = (
                            <MessageAnnotationContext.Provider value={context} >
                                <MessageAnnotationPanel
                                    busy={busy}
                                    eventId={event.id}
                                />
                            </MessageAnnotationContext.Provider>);
                    }
                } else if(RPA_MANUAL_STEP === job) {

                    screen = (
                        <JobContext.Provider value={context} >
                            <Rpa
                                submitResultCallback={this.submitResult}
                                busy={busy}
                                eventId={event.id}
                            />
                        </JobContext.Provider>);
                }
            }
        } else if(state === STATE_PAUSED) {

            if(connectionStatus  === "on") {
                screen = (
                    <>
                        <InformationScreen message="Paused click resume to continue"/>
                        <Button onClick={this.toggleSessionState}
                                className={classes.resumeIconBtn}><PlayCircleOutlineOutlined color="primary"
                                                                                             fontSize="large"/></Button>
                    </>
                );
            } else {
                screen = (
                    <>
                        <InformationScreen message="Reconnect"/>
                        <Button onClick={this.reconnectWebsocket}
                                className={classes.resumeIconBtn}><PlayCircleOutlineOutlined color="primary"
                                                                                             fontSize="large"/></Button>
                    </>
                );
            }
        }

        let errorAlert = (<></>);

        if(error) {

            errorAlert = (
                <Snackbar
                    className={classes.closeError}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={ true }
                    autoHideDuration={ 6000 }
                    message={<span id="message-id">{ error }</span>}
                    onClose={this.handleErrorClose}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeError}
                            onClick={this.handleErrorClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            );
        }


        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.headerLeft}>
                    </div>
                    <div className={classes.fill} />
                    <div className={classes.headerCenter}>
                        <Button onClick={this.toggleFullscreen} size="small"><FormattedMessage id="fullscreen" defaultMessage={`Fullscreen`} /></Button>
                    </div>
                    <div className={classes.fill} />
                    <div className={classes.headerRight}>
                        <ConnectionStatus status={connectionStatus} />
                    </div>
                </div>

                <Paper className={fullscreen ? classes.paperFullscreen : classes.paper}>
                    { busy && <LinearProgress />}
                    { fullscreen && <Button onClick={this.toggleFullscreen} size="small" ><FormattedMessage id="fullscreen.exit" defaultMessage={`Exit fullscreen`} /></Button> }
                    <div className={fullscreen ? classes.paperContentFullscreen : classes.paperContent}>
                        <div className={classes.paperContentMessage}>
                            { screen }
                        </div>
                    </div>
                </Paper>

                <div className={classes.footer}>
                    <div className={classes.footerLeft}>
                        <FormattedMessage id="active.federated.sessions.message" defaultMessage={`Federated sessions`} />: { sessionCount.federated }
                    </div>
                    <div className={classes.fill}/>
                    <div className={classes.footerCenter}>
                        <Button onClick={this.toggleSessionState} size="small">
                            {
                                (state === STATE_PAUSED) ? (<FormattedMessage id="resume" defaultMessage={`Resume`}/>) : (<FormattedMessage id="pause" defaultMessage={`Pause`}/>)
                            }
                        </Button>
                    </div>
                    <div className={classes.fill}/>
                    <div className={classes.footerRight}>
                        <FormattedMessage id="active.exception.sessions.message" defaultMessage={`Exception sessions`} />: { sessionCount.exception }
                    </div>
                </div>

                { errorAlert }
            </div>
        );
    }
}

export default withStyles(styles)(FederatedJobs);
