import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {Typography} from "@material-ui/core";

const styles = theme => ({
    root: {
        paddingBottom: "25px",
    },
});

class InformationScreen extends Component {

    render() {

        let { classes, message } = this.props;

        return (
            <Typography variant='h3' align='left' className={classes.root}>
                { message }
            </Typography>);
    }
}

export default withStyles(styles)(InformationScreen);
