import React, {Component} from 'react';

import DirectMessagingViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    Button,
    Paper,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField, LinearProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import {FormattedMessage} from "react-intl";
import {messagingApi} from "../../../../utils/services/messaging.api";
import {notificationService} from "../../../../utils/notification";
import {consumerApi} from "../../../../utils/services/consumers.api";

const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        padding: "0px 0px",
    },
    fill: {
        flex: "1 1 auto",
    },
    button: {
        width: "100%",
        margin: theme.spacing(1),
    },
    notHighlightedListItem: {
    },
    highlightedListItem: {
        backgroundColor: "#ffffff33",
    }
});

class CustomerPanel extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            openDialog: false,
            activeThreads: [],
            newMessageDetail: {},
        };
    }

    openNewMessagingDialog = () => {
        this.setState({
            openDialog: true,
            newMessageDetail: {}
        });
    };

    openConfirmCloseDialog = (thread) => {

        return (event) => {
            this.setState({
                openConfirmationDialog: true,
                targetThread: thread,
            });
        }
    };

    handleArchiveGroup = () => {

        return (event) => {

            const { targetThread } = this.state;

            this.setState({
                busy: true,
            });

            messagingApi.archiveThread(targetThread.id)
                .then((data) => {
                    this.props.removeThread(targetThread);
                    this.setState({
                        openConfirmationDialog: false,
                        targetThread: undefined,
                        busy: false,
                    });
            }).catch(error => {
                notificationService.update({
                    error: "Error trying to archive thread. " + error,
                });
                this.setState({
                    openConfirmationDialog: false,
                    targetThread: undefined,
                    busy: false,
                });
            });
        };
    };


    handleStartNew = (event) => {

        let {newMessageDetail} = this.state;

        this.setState({
            busy: true,
        });

        messagingApi.startNewThread(newMessageDetail.email, newMessageDetail.message)
            .then(data => {

                let extra = {};

                if (data && data.status == 200) {

                    extra["openDialog"] = false;
                    consumerApi.getPersonSummary(data.data.customerId, "decoded")
                        .then(value => {

                            const newThread = {
                                id: data.data.id,
                                createdOn: new Date().toISOString(),
                                messages: [{
                                    id: data.data.messageId,
                                    content: newMessageDetail.message,
                                    createdOn: new Date().toISOString(),
                                }],
                                customer: value.data,
                            };

                            this.props.addThread(newThread);

                            this.setState({
                                busy: false,
                                openDialog: false,
                            });
                        }).catch(reason => {

                        notificationService.update({
                            error: "Error retrieving customer information - " + reason,
                        });

                        this.setState({
                            busy: false,
                            openDialog: false,
                        });
                    });
                } else {
                    this.setState({
                        busy: false,
                        ...extra,
                    });
                }
            }).catch(error => {

            if (error.response.status === 409) {

                notificationService.update({
                    error: "There are one or more open messaging threads. Please close it to continue.",
                });
            } else {

                notificationService.update({
                    error: "Error trying to start a direct messaging thread. " + error,
                });
            }

            this.setState({
                busy: false,
            });
        });

    };


    closeDialog = () => {
        this.setState({
            openConfirmationDialog: false,
            targetThread: undefined,
            openDialog: false,
            newMessageDetail: {}
        });
    };

    handleTextChange = (event) => {

        let {newMessageDetail} = this.state;
        newMessageDetail[event.target.name] = event.target.value;
        this.setState({newMessageDetail: newMessageDetail});
    };

    render() {

        let {classes} = this.props;
        let {openThreads, activeThread} = this.props.context;
        let {busy, openDialog, openConfirmationDialog} = this.state;

        return (
            <>
                <div className={classes.root}>

                    <List>
                        <ListItem>
                            <Button variant="outlined" color="primary" className={classes.button}
                                    onClick={this.openNewMessagingDialog}>
                                <FormattedMessage id="messaging.new.direct" defaultMessage={`New Messaging Thread`}/>
                            </Button>
                        </ListItem>
                        {
                            openThreads.map(thread => {

                                    let clazz = classes.notHighlightedListItem;
                                    if (activeThread && activeThread === thread.id) {
                                        clazz = classes.highlightedListItem;
                                    }

                                    let user = thread.customer;
                                    let name = user && user.id ? user.name.given + " " + user.name.family : "Unknown";

                                    let unread = thread.unreadIn;
                                    let avatar = unread && unread > 0 ?
                                        <>
                                            <Badge badgeContent={unread} color="primary">
                                                <Avatar className={clazz}>
                                                    { name.charAt(0) }
                                                </Avatar>
                                            </Badge>
                                        </> :
                                        <>
                                            <Avatar className={clazz}>
                                                { name.charAt(0) }
                                            </Avatar>
                                        </>;

                                    return (
                                        <>
                                            <ListItem className={clazz}>
                                                <ListItemAvatar onClick={this.props.setActiveThread(thread)}>
                                                    { avatar }
                                                </ListItemAvatar>
                                                <ListItemText
                                                    onClick={this.props.setActiveThread(thread)}
                                                    primary= { name }
                                                    secondary={`Started: ${thread.createdOn}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete" onClick={this.openConfirmCloseDialog(thread)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </>);
                                }
                            )
                        }
                    </List>
                </div>
                <Dialog open={openDialog} onClose={this.closeDialog} aria-labelledby="form-dialog-title">
                    {busy && <LinearProgress/>}
                    <DialogTitle id="form-dialog-title"><FormattedMessage id="messaging.new.direct"
                                                                          defaultMessage={`New Messaging Thread`}/></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormattedMessage id="messaging.new.direct.message"
                                              defaultMessage={`Start a new thread to a customer. Only one per customer is allowed at the same time. Please ensure when you are done you close the thread.`}/>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            onChange={this.handleTextChange}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="message"
                            name="message"
                            label="Message"
                            type="type"
                            onChange={this.handleTextChange}
                            multiline
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="secondary">
                            <FormattedMessage id="messaging.new.direct.cancel" defaultMessage={`Cancel`}/>
                        </Button>
                        <Button onClick={this.handleStartNew} color="primary">
                            <FormattedMessage id="messaging.new.direct.start" defaultMessage={`Start`}/>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={ openConfirmationDialog } onClose={this.closeDialog} aria-labelledby="confirmation-dialog-title">
                    {busy && <LinearProgress/>}
                    <DialogTitle id="confirm-dialog-title"><FormattedMessage id="messaging.new.direct"
                                                                             defaultMessage={`New Messaging Thread`}/></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormattedMessage id="messaging.close.thread.confirm.description"
                                              defaultMessage={`Are you sure you want to close the thread?`}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="secondary">
                            <FormattedMessage id="messaging.new.direct.cancel" defaultMessage={`Cancel`}/>
                        </Button>
                        <Button onClick={this.handleArchiveGroup()} color="primary">
                            <FormattedMessage id="messaging.new.direct.start" defaultMessage={`Confirm`}/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <DirectMessagingViewContext.Consumer>
        {value => {
            return (<CustomerPanel context={value} {...props} />);
        }
        }
    </DirectMessagingViewContext.Consumer>
));
