import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { LinearProgress, Paper} from "@material-ui/core";

import {navigationTitleService} from "../../../../../../../utils/title";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {ConversationTurnBloc, ConversationTurnBlocEvent} from "./shared/text.annotation.bloc";
import AsyncJobContext from "../../../context";
import {notificationService} from "../../../../../../../utils/notification";
import TextAnnotationPanel from "./shared/TextAnnotationPanel";
import DoneIcon from "@material-ui/icons/Done";
import {FormattedMessage} from "react-intl";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import {DeleteOutline} from "@material-ui/icons";
import CriticalButton from "../../../../../../shared/CriticalButton";
import PublishIcon from "@material-ui/icons/Publish";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)"
    },
    paper: {
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100% - 90px)",
        height: "calc(100% - 90px)",
    },
    paperContent: {
        overflowY: "auto",
        minHeight: "100%",
        height: "100%",
        margin: "auto",
    },
    paperContentMessage: {
        backgroundColor: "#000",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

});
class AnnotateComponent extends DecodedComponent{

    constructor(props) {
        super(props);

        this.parentBloc = props.bloc;

        this.contextOverrides = {
            "derived": [],
            "menu": {
                "Symptom": [],
                "Associated symptom": [],
                "Onset": [],
                "Onset context": [],
                "Diagnosis": [],
                "Medication": [],
                "Anatomical location": [],
                "Quality": []
            }
        }

        let { task } = this.parentBloc.subject.value

        this.bloc = new ConversationTurnBloc(task)
        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();
        this.bloc.initialise(this.contextOverrides)
        navigationTitleService.update("Annotation Job");
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __submit = () => {

        const { busy, entities } = this.state;

        if(busy) {
            notificationService.error("Still processing last request.");
            return;
        }

        this.setState({
            busy: true,
        });

        const data = {
            data: {
                annotations: {
                    entities: entities.added
                }
            }
        }
        this.parentBloc.submitTask(data)
    };

    __discard = (message) => {
        this.parentBloc.discardTask(message)
    }

    render() {

        let { event : { data } } = this.bloc.subject.value;
        let { entities, busy, contextOverrides }  = this.state;
        let { classes }  = this.props;

        const title = "Annotate the following information.";
        const subtitle = `Asked: ${data.context}`
        const contextMenuProps = {
            menuOverride: contextOverrides,
            includeDefault: false
        };

        const doneIcon = <CriticalButton startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
        const discardIcon = <CancelWithReasonButton disabled={busy} startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>


        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    { busy && <LinearProgress />}
                    <div className={classes.paperContent}>
                        <div className={classes.paperContentMessage}>
                            <TextAnnotationPanel
                                id={'general-annotation-panel'}
                                bloc={this.bloc}
                                busy={ busy }
                                title={ title }
                                initial={ entities }
                                subtitle={ subtitle }
                                text={ data.text }
                                classes={ classes }
                                contextMenuProps={ contextMenuProps }
                                handleSubmit={ this.__submit }
                                discardIcon={ discardIcon }
                                submitIcon={ doneIcon }
                                handleException={ this.__discard }
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<AnnotateComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));
