import * as rxjs from "rxjs";
import {notificationService} from "../../../../../utils/notification";
import { uuid } from "uuidv4";
import {utilitiesApi} from "../../../../../utils/services/utilities.api";
import {generateAnswerQuinn, Headers} from "../../../../../utils/events";

export class Bloc {

    constructor(event, websocket) {

        this.websocket = websocket;

        this.subject = new rxjs.BehaviorSubject({
            event: event,
        });

        this.events = new rxjs.Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateSubject = (newProps) => {
        this.subject.next({
            ...this.subject.value,
            ...newProps,
        });
    }

    addConcept = () => {

        let { event } = this.subject.value;

        let newConcept = { id: uuid() };
        newConcept.id = uuid()
        event.data.entity.concepts.push(newConcept);

        this.__updateSubject({ event: event });
    }

    removeConcept = (conceptId) => {

        let { event } = this.subject.value;

        event.data.entity.concepts = event.data.entity.concepts.filter(_concept => _concept.id !== conceptId);

        this.__updateSubject({ event: event });
    }

    toggleNegated = () => {
        let { event } = this.subject.value;

        event.data.entity.negated = !event.data.entity.negated;

        this.__updateSubject({ event: event });
    }

    setPresence = (presence) => {
        let { event } = this.subject.value;

        event.data.entity.presence = presence;

        this.__updateSubject({ event: event });
    }

    getConceptOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            utilitiesApi.conceptTrainedTypeahead(_inputValue)
                .then(value => {

                    const items = value.data.results || value.data.result

                    items.forEach(item => {

                        let display = item.index.join(", ");

                        options.push({
                            name: display || item.canonicalName,
                            canonicalName: item.canonicalName,
                            conceptId: item.conceptId,
                        })
                    })

                    resolve(options)
                }, error => {
                    notificationService.httpError("Error loading normalised concepts");
                    resolve(options)
                });
        })
    }

    onDropdownChange = (_id, _event) => {

        let { event } = this.subject.value;

        event.data.entity.concepts.filter(_concept => _concept.id === _id)
            .map(_concept => {

                _concept['code'] = { code: _event.conceptId, system: "http://decodedhealth.com/clinical-knowledge/identifier" };
                _concept['text'] = _event.canonicalName;
                _concept['value'] = _event.canonicalName;

                return _concept;
            });

        this.__updateSubject({ event: event });
    }

    submit = () => {
        const { event } = this.subject.value;

        let request = {
            action: "suggest_entity_normalisation",
            conversationId: event[Headers.CONVERSATION_ID],
            messageId: event[Headers.MESSAGE_ID],
            tenantId: event[Headers.TENANT_ID],
            entityUpdates: {
                update: [event.data.entity],
            }
        };

        generateAnswerQuinn(event[Headers.TENANT_ID], request)
            .then(_event => {
                this.websocket.send(_event);
            });
    }
}

export class BlocEvent {
}
