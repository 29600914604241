import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {navigationTitleService} from "../../../../utils/title";
import {Button, Grid, LinearProgress, Paper, TextField, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {EntityRecognitionBloc, EntityRecognitionBlocEvent} from "../EntityRecognition/entityrecognition.bloc";
import {
    DifferentialDiagnosisBloc,
    DifferentialDiagnosisBlocEvent
} from "../DifferentialDiagnosis/differentialdiagnosis.bloc";
import ResultGrid from "../DifferentialDiagnosis/InferenceResult";
import ObservationPicker from "./ObservationPicker";
import ResultPanel from "../EntityRecognition/ResultPanel";
import QuestionsResult from "../DifferentialDiagnosis/QuestionsResult";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        backgroundColor: "#303030",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
    gridTop: {
        display: "flex",
        flexDirection: "column"
    },
    gridBottom: {
        display: "flex",
        flexDirection: "column",
    },
    inputText: {
        width: "100%",
        height: "100%",
        margin: "auto",
        display: "flex",
        padding: "5%",
        paddingBottom: "2%",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
    },
    inputButton: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "6%"
    },
    submitButton: {
        color: "#fff"
    },
    differentialResultPanel: {
        flex: 1,
        padding: "5%",
        paddingBottom: "1%",
        paddingTop: "2%",
    },
    observationPicker: {
        display: "flex",
        flex: 1,
        padding: "5%",
        paddingBottom: "1%",
        paddingTop: "2%",
    },
    title: {
        flex: 0.95,
        paddingBottom: "2%",
    },
    observationResultsPanel: {
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "1%",
        flexDirection: "column"
    },
    predictionResults: {
        display: "flex",
        padding: "3%",
    },
    inferenceResult: {
        flex: "2",
        padding: "2%",
    },
    questionResult: {
        flex: "1",
        padding: "2%",
    }
})

class ClinicalInference extends React.Component {

    entityRecognitionBloc;
    differentialDiagnosisBloc;

    constructor() {
        super();

        this.state = {
            inputText: '',
            loading: false,
            selectedObservations: [],
        }

        this.entityRecognitionBloc = new EntityRecognitionBloc();
        this.differentialDiagnosisBloc = new DifferentialDiagnosisBloc();

        this.__handleBlocState = this.__handleBlocState.bind(this);
        this.__handleBlocEvent = this.__handleBlocEvent.bind(this);
    }

    componentWillMount() {
        navigationTitleService.update("Clinical Inference");

        this.stateSubscription = this.entityRecognitionBloc.subscribeToState(this.__handleBlocState);
        this.eventSubscription = this.entityRecognitionBloc.subscribeToEvents(this.__handleBlocEvent);
        this.stateSubscription = this.differentialDiagnosisBloc.subscribeToState(this.__handleBlocState);
        this.eventSubscription = this.differentialDiagnosisBloc.subscribeToEvents(this.__handleBlocEvent);
    }

    __handleBlocState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleBlocEvent = (event) => {
        const type = event.event;

        switch (type) {
            case EntityRecognitionBlocEvent.ENTITIES_AVAILABLE:
                let normalisedObservations = this.state.normalisedObservations
                this.setState({
                    selectedObservations: normalisedObservations,
                    loading: false,
                })
                break;
            case DifferentialDiagnosisBlocEvent.INFERENCE_AVAILABLE:
            case EntityRecognitionBlocEvent.ENTITIES_ERROR:
            case DifferentialDiagnosisBlocEvent.INFERENCE_ERROR:
                this.setState({
                    loading: false,
                });
                break;
        }
    }


    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    handleSubmit = event => {
        this.setState({
            loading: true,
            selectedObservations: []
        })
        let inputText = this.state.inputText
        this.entityRecognitionBloc.evaluateText(inputText)
        event.preventDefault()
    }

    handleDeleteObservation = (item) => {

        let selectedObservations = this.state.selectedObservations

        let updatedObservations = selectedObservations.filter(obs => {
            return obs.id !== item.id
        })

        this.setState({
            selectedObservations: updatedObservations
        })
    }

    handlePredict = () => {
        this.setState({
            loading: true
        })
        let selectedObservations = this.state.selectedObservations
        this.differentialDiagnosisBloc.predictDifferentialDiagnosis(
            selectedObservations.map(item => {
                return {
                    "observation": item.display,
                }
            }))
    }

    render() {
        let {classes} = this.props;

        let {inputText, loading, diagnosisPrediction, selectedObservations} = this.state

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {(loading) && <LinearProgress/>}
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                        <Grid container className={classes.gridTop}>
                            <div className={classes.inputText}>
                                <TextField
                                    InputProps={{style: {fontSize: "x-large"}}}
                                    id="input-text"
                                    label="Input Text"
                                    placeholder="Enter text here"
                                    multiline
                                    rows={5}
                                    defaultValue={inputText}
                                    onChange={this.handleChange('inputText')}
                                    variant="outlined">
                                </TextField>
                            </div>
                            <div className={classes.inputButton}>
                                <Button className={classes.submitButton} type="submit" size="xlarge">
                                    <FormattedMessage id="submit-button" defaultMessage={`SUBMIT TEXT`}/>
                                </Button>
                            </div>
                        </Grid>
                        <Grid container className={classes.gridBottom}>

                            {selectedObservations.length > 0 &&
                            <div className={classes.observationResultsPanel}>
                                <ResultPanel
                                    resolvedEntities={this.entityRecognitionBloc.subject.value.resolvedEntities}
                                    resolvedPhrases={this.entityRecognitionBloc.subject.value.resolvedPhrases}/>
                                <div className={classes.observationPicker}>
                                    <ObservationPicker observations={selectedObservations}
                                                       handleDelete={this.handleDeleteObservation}/>
                                    <Button className={classes.submitButton} onClick={this.handlePredict}
                                            size="xlarge">
                                        <FormattedMessage id="predict-button" defaultMessage={`PREDICT`}/>
                                    </Button>
                                </div>
                            </div>
                            }

                            {
                                diagnosisPrediction &&
                                (<div className={classes.predictionResults}>
                                    <div className={classes.inferenceResult}><ResultGrid
                                        diagnosisPrediction={diagnosisPrediction}/></div>
                                    <div className={classes.questionResult}><QuestionsResult
                                        diagnosisPrediction={diagnosisPrediction}/></div>
                                </div>)
                            }

                        </Grid>
                    </form>
                </Paper>
            </div>
        )
    }

}

export default withStyles(styles)(ClinicalInference);