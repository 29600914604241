import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import {Typography, Button, Input, ButtonGroup} from "@material-ui/core";
import AnnotationContextMenu from "../shared/AnnotationContextMenu";
import {determineSelection} from "../../../../utils/text.highlight";
import {Headers, ANNOTATION_EXCEPTION, ANNOTATION_SUCCESS} from "../../../../utils/events";
import AnnotatedText from "../shared/AnnotatedText";
import JobContext from "./context";
import Rephrase from "./Rephrase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {preprocessor} from "../shared/preprocessor";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },

    unsureDialog: {
      zIndex: "1301",
    },

    header: {

    },
    instruction: {
        color: "#55CCFF",
        fontSize: "37px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    instructionLastQuestion: {
        paddingBottom: "17px",
        color: "#fff",
        fontSize: "30px",
        fontWeight: "normal",
        "& svg": {
            fontSize: "4em",
        },
    },
    content: {

    },
    message: {
        paddingTop: "50px",
        fontSize: "50px",
    },
    control: {
        marginBottom: "22px",
        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "100%",
    },
    controlButtonGroup: {
        width: "100%",
        paddingRight: "0px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "50%",
    },
    controlButtonRight: {
        width: "50%",
    },
    footer: {
        height: "74px",
        display: "flex",
        color: "#878787",
        fontSize: "50px",
        lineHeight: "58px",
        "& svg": {
            fontSize: "4em",
        },
        "& span>span": {
            color: "#878787",
            fontSize: "50px",
            lineHeight: "58px",
            fontWeight: "200",
            textTransform: "none",
        },
    },
    footerButtonLabel: {
        paddingLeft: "20px",
        fontSize: "20px",
    },


    rewordInput: {
        fontSize: "50px",
        '& input': {
            textAlign: "center",
        },
    },
});

function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}

function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}

function RenderAnnotated(props) {

    const { text, entities , handleRemoveAnnotation} = props;

    let renderArray = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {

                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    renderArray.push(<>{text.slice(count, currentEntity.start)}</>);
                }

                entityCount += 1;

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {

                    highlights.push(entities[entityCount]);
                    entityCount += 1;
                }

                renderArray.push(<AnnotatedText text={text} annotations={highlights} handleRemoveAnnotation={handleRemoveAnnotation}/>)

                count = currentEntity.end;
            }

            // Adding the rest of the text
            if (count < text.length) {

                renderArray.push(<>{text.slice(count)}</>);
            }

        } else {

            chunk = text;
        }

        if (chunk.length > 0) {
            renderArray.push(chunk);
        }
    } else {

        renderArray.push(text)
    }

    return (
        <>
            { renderArray }
        </>
    );
}



class MessageAnnotator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            metadata: props.context.metadata,
            original: props.context.message,
            message: props.context.message.content,
            //TODO: Move to backend
            requiresReword: this.requiresReword(props.context.metadata),

            targetEntities: [],
            targetEntity: undefined,
            targetEntityExtract: undefined,
            booleanValue: undefined,
            skipValue: undefined,
            entities: [],
            rephrase: false,
        };

        this.highlightAndOpenContext.bind(this);
        this.sendException.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        const { original } = this.state;

        if(nextProps.context.message && (nextProps.context.message.messageId !== original.messageId)) {

            this.setState({
                metadata: nextProps.context.metadata,
                original: nextProps.context.message,
                message: nextProps.context.message.content,
                booleanValue: undefined,
                skipValue: undefined,
                requiresReword: this.requiresReword(nextProps.context.metadata),
                targetEntities: [],
                targetEntity: undefined,
                targetEntityExtract: undefined,
                entities: [],
                rephrase: false,
            });
        }
    }

    requiresReword(metadata) {

        if (metadata === null) return false;

        return ["chief_complaints", "current_complaint", "patient_description", "onset"].includes(metadata[Headers.INTENT_SLOT_CODE]);
    }

    highlightAndOpenContext = (event) => {

        if (event.nativeEvent === "MouseEvent" && event.button !== 0) {
            if (this.state.popupPosition) {
                this.setState({
                    popupPosition: undefined,
                });
            }
            return;
        }

        const { message } = this.state;

        determineSelection(message, event.target)
            .then((value) => {

                // Not allow annotating the new entity if it crosses existing entites.
                this.setState((state) => {
                    return this.checkCrossingEntities(value) ? {} : value
                });
            });
    };

    checkCrossingEntities = (newValue) => {

        let newEntity = {};
        if(newValue.selectedText) {
             newEntity = {
                start: newValue.selectedTextStart,
                end: newValue.selectedTextStart + newValue.selectedText.length,
                extract: newValue.selectedText
            }
        }

        // Check if the selected entity crosses any of the existing entities.
        for (const entity of this.state.entities) {
            if((newEntity.start > entity.start && newEntity.start < entity.end) ||
                (newEntity.end > entity.start && newEntity.end < entity.end)) {

                if(!encloses(entity,newEntity)){
                    return true
                }
            }
        }

        return false;
    }

    noContextMenu = (event) => {

        event.preventDefault();
    };

    menuItemSelected = (event) => {

        if(event.action === 'close') {

            this.setState({
                selectedText: undefined,
                popupPosition: undefined,
            });
        } else if(event.action === 'found') {

            const { selectedText, selectedTextStart, targetEntities, entities } = this.state;

            if(selectedText) {

                let _entity = {
                    start: selectedTextStart,
                    end: selectedTextStart + selectedText.length,
                    extract: selectedText,
                    category: event.item.code,
                };

                entities.push(_entity);

                if(event.item.code === "patient_description" || event.item.code === "onset" || event.item.code === "current_complaint") {
                    targetEntities.push(_entity);
                }

                if(event.item.next) {

                    this.setState({
                        entities: entities,
                    })
                } else {

                    this.setState({
                        entities: entities,
                        targetEntities: targetEntities,
                        selectedText: undefined,
                        popupPosition: undefined,
                    })
                }
            }
        }
    };

    clearAnnotations = () => {

        this.setState({
            booleanValue: undefined,
            targetEntities: [],
            entities: [],
        });
    };

    handleException = () => {

        let { metadata } = this.state;

        let reiterateOptions = metadata[Headers.REITERATE_OPTIONS];

        if(reiterateOptions && reiterateOptions.length > 0) {

            this.setState({rephrase: true,});
        } else {

            let { original, metadata } = this.state;

            this.sendException(original, metadata);
        }
    };

    sendException = (message, metadata) => {

        let result = {
            action: "exception",
            conversationId: message.conversationId,
            messageId: message.messageId,
            slot: {
                id: metadata[Headers.INTENT_SLOT_ID],
                code: metadata[Headers.INTENT_SLOT_CODE],
            },
        };

        this.props.submitResultCallback(ANNOTATION_EXCEPTION, result);
    };


    handleSubmission = () => {

        let { original, requiresReword, targetEntities, targetEntity, entities, metadata, booleanValue, skipValue } = this.state;

        preprocessor.preprocessSubmission(metadata, entities);

        if(metadata[Headers.INTENT_SLOT_DATA_TYPE] === "boolean" && !booleanValue) {

            this.props.submitErrorUpdate({ data: { text: "You need to make a selection of endorses/denies."}});
        } else if( skipValue !== "__skipped__" && metadata[Headers.INTENT_SLOT_DATA_TYPE] !== "boolean" && !this.correctlyAnnotated(metadata, entities)) {

            this.props.submitErrorUpdate({ data: { text: "You need to annotate the target slot to mark this as successful."}});
        } else {

            if(!requiresReword || targetEntities.length === 0) {

                let result = {
                    popupPosition: undefined,
                    action: metadata[Headers.CONVERSATION_GOAL] || "execute",
                    conversationId: original.conversationId,
                    messageId: original.messageId,
                    userId: origin.userId,
                    entities: entities,
                    slot: {
                        id: metadata[Headers.INTENT_SLOT_ID],
                        type: "",
                        code: metadata[Headers.INTENT_SLOT_CODE],
                        value: skipValue || booleanValue,
                    },
                };

                this.props.submitResultCallback(ANNOTATION_SUCCESS, result);
            } else {

                if(targetEntity) {

                    entities.forEach((item, index) => {

                        if(item.start === targetEntity.start && item.end === targetEntity.end) {
                            item.extract = targetEntity.extract;
                        }
                    });
                }

                //Cycle through target entities.
                let next = targetEntities.pop();
                this.setState({
                    popupPosition: undefined,
                    entities: entities,
                    targetEntities: targetEntities,
                    targetEntity: next,
                    targetEntityExtract: next.extract,
                });
            }
        }
    };

    correctlyAnnotated = (metadata, entities) => {

        if(!metadata[Headers.INTENT_SLOT_CODE]) return true;

        return ( entities && entities.length > 0 && entities.filter((item) => {

            if(metadata[Headers.INTENT_SLOT_CODE] === "patient_description") {

                return  ["patient_description"].includes(item.category);
            } else if(metadata[Headers.INTENT_SLOT_CODE] === "current_complaint" || metadata[Headers.INTENT_SLOT_CODE] === "chief_complaints") {

                return  ["current_complaint"].includes(item.category);
            } else {

                return  item.category === metadata[Headers.INTENT_SLOT_CODE];
            }
        }).length > 0);
    };

    setYes = () => {

        const { booleanValue } = this.state;

        if(booleanValue === "true") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "true",
            });
        }
    };

    setNo = () => {

        const { booleanValue } = this.state;

        if(booleanValue === "false") {
            this.setState({
                booleanValue: undefined,
            });
        } else {
            this.setState({
                booleanValue: "false",
            });
        }
    };


    handleRewordChange = (event) => {

        const { entities, targetEntity } = this.state;

        targetEntity.extract = event.target.value;

        entities.filter(entity =>  entity.start === targetEntity.start && entity.start === targetEntity.start).forEach((entity) => entity.extract = targetEntity.extract);

        this.setState({
            targetEntity: targetEntity,
            entities: entities,
        });
    };

    render() {

        let { targetEntity } = this.state;

        if(targetEntity) {

            return this.renderReword();
        }

        return this.renderAnnotation();
    }

    renderReword() {

        let { classes, busy } = this.props;

        let { entities, targetEntity, targetEntityExtract } = this.state;

        let question = (<>Just continue something went wrong.</>);

        let target = targetEntity.category;

        if(targetEntity.category === "current_complaint") {

            if(entities.filter(entity =>  entity.start === targetEntity.start && entity.start === targetEntity.start && entity.category === "sx").length > 0) {
                target = "patient_description";
            }
        }

        if(target === "current_complaint") {
            question = (<>For <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/> what is the main symptom you are having?</>)
        } else if(target === "patient_description") {
            question = (<>
                When did <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/> start?<br />
                Can you describe any symptoms that happen with <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/>?<br />
            </>);
        } else if(target === "onset") {
            question = (<>
                Is this a problem ongoing prior to <Input value={targetEntity.extract} onChange={this.handleRewordChange} className={classes.rewordInput} inputProps={{'aria-label': 'description',}}/>?<br />
            </>);
        }


        return (<div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6' align='left' className={classes.instruction}>
                    <FormattedMessage id="reword.sentence" defaultMessage="Reword sentence for" />  "{ targetEntityExtract  }"
                </Typography>
            </div>
            <div className={classes.content}>
                <h3 className={classes.message}>
                    { question }
                </h3>
            </div>
            <div className={classes.fill} />
            <div className={classes.footer}>
                <div className={classes.fill} />
                <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large" color="primary"/></Button>
            </div>
        </div>);
    }

    moveOutOfRephrase = () => {
        this.setState({
            rephrase: false
        })
    }

    moveToRephrase() {

        return (<Rephrase goBack={this.moveOutOfRephrase} sendException={this.sendException}  submitErrorUpdate={this.props.submitErrorUpdate} submitResultCallback={this.props.submitResultCallback} />);
    }

    formatAnnotationTargetDescription = (description) => {

        if(description && description === "quality_variation") {
            return "Quality";
        }

        return description ? description
            .replace("_", " ") : "Unknown";
    };


    openConfirmAndSkipDialog = () => {

        this.setState({
            confirmSkipOpen: true,
            skipValue: undefined,
        });
    }

    closeConfirmAndSkipDialog = () => {

        this.setState({
            confirmSkipOpen: false,
            skipValue: undefined,
        });
    }


    confirmAndSkip = () => {

        this.setState({
            confirmSkipOpen: false,
            skipValue: "__skipped__",
        });

        this.state.skipValue = "__skipped__";
        this.handleSubmission();
    }

    handleRemoveAnnotation = (annotatedText) => {
        const filterCallback = entity => entity.extract !== annotatedText.extract;

        this.setState((state, props) => ({
            entities: state.entities.filter(filterCallback),
            targetEntities: state.targetEntities.filter(filterCallback)
        }));
    }


    renderAnnotation() {

        let { classes, busy } = this.props;

        let { rephrase, message, metadata, entities, popupPosition, booleanValue, confirmSkipOpen } = this.state;

        if(rephrase) {
            return this.moveToRephrase();
        }

        let booleanChoice = (<></>);

        if(metadata[Headers.INTENT_SLOT_DATA_TYPE] === "boolean") {

            booleanChoice = (<div className={classes.control}>
                    <span className={classes.controlButtonWrapper}>
                        <ButtonGroup variant="contained" color="primary" className={classes.controlButtonGroup}>
                          <Button disabled={ "true" === booleanValue } onClick={this.setYes} className={classes.controlButtonLeft}><FormattedMessage id={"endorses"} defaultMessage={"endorses"} /></Button>
                          <Button disabled={ "false" === booleanValue } onClick={this.setNo} className={classes.controlButtonRight}><FormattedMessage id={"denies"} defaultMessage={"denies"} /></Button>
                        </ButtonGroup>
                    </span>
            </div>);
        }

        let optionalChoice = metadata[Headers.INTENT_SLOT_DATA_TYPE] !== "boolean" && metadata[Headers.INTENT_SLOT_DATA_MULTIPLICITY] === "0..1" ? (<div className={classes.control}>
                    <span className={classes.controlButtonWrapper}>
                        <ButtonGroup variant="contained" color="primary" className={classes.controlButtonGroup}>
                          <Button onClick={this.openConfirmAndSkipDialog} className={classes.controlButton}><FormattedMessage id={"patient.unsure"} defaultMessage={"patient unsure"} /></Button>
                        </ButtonGroup>
                    </span>
        </div>) : (<></>);

        let lastQuestion = metadata[Headers.LAST_QUESTION] ? (<><Typography variant='h4' align='left' className={classes.instructionLastQuestion}>
                "{ metadata[Headers.LAST_QUESTION]}"
            </Typography></>)
            : (<></>);

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    { lastQuestion }
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <Button disabled={busy} onClick={this.clearAnnotations}><Undo fontSize="large" color="primary"/></Button>
                        <FormattedMessage id="annotation.title" defaultMessage="Annotate " /> { this.formatAnnotationTargetDescription(metadata[Headers.INTENT_SLOT_CODE]) }
                    </Typography>
                </div>

                <div className={classes.content}>
                    <h3 className={`${classes.message} annotationText`}
                        onContextMenu={ this.noContextMenu }
                        onTouchEnd={ this.highlightAndOpenContext }
                        onMouseUp={ this.highlightAndOpenContext }
                    >
                        <RenderAnnotated
                            text={message}
                            entities={entities}
                            handleRemoveAnnotation={this.handleRemoveAnnotation}/>
                    </h3>
                    <AnnotationContextMenu
                        targetSlot={ metadata[Headers.INTENT_SLOT_CODE] }
                        menuItemSelected={ this.menuItemSelected }
                        popupPosition={ popupPosition }
                    />
                </div>

                <div className={classes.fill} />
                { booleanChoice }
                { optionalChoice }

                <div className={classes.footer}>
                    <Button disabled={busy}  onClick={this.handleException}>
                        <Close fontSize="large" color="primary"/> <FormattedMessage id="exception" defaultMessage="Exception" />
                    </Button>
                    <div className={classes.fill} />
                    <Button disabled={busy} onClick={this.handleSubmission}>
                        <KeyboardReturn fontSize="large" color="primary"/>
                    </Button>
                </div>
                <Dialog
                    className={classes.unsureDialog}
                    open={ confirmSkipOpen }
                    keepMounted
                    onClose={this.closeConfirmAndSkipDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <FormattedMessage id={'skip.turn.confirmation.dialog.text'} defaultMessage={"Are you sure you want to move onto the next question?"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmAndSkipDialog} color="primary">
                            <FormattedMessage id={'cancel'} defaultMessage={"Cancel"} />
                        </Button>
                        <Button onClick={this.confirmAndSkip} color="primary">
                            <FormattedMessage id={'yes'} defaultMessage={"Yes"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <JobContext.Consumer>
        { value => {
            return (<MessageAnnotator context={ value } { ...props } />);
        }
        }
    </JobContext.Consumer>
));
