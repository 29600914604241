import { Subject } from 'rxjs';
import {
    TECHNICIAN_SESSION_ACTIVATED,
    TECHNICIAN_SESSION_PAUSED,
    TECHNICIAN_SESSION_WAITING,

    INTENT_CLASSIFICATION_JOB,
    MESSAGE_ANNOTATION_JOB,


    generateStateChangeEvent,
    generateJobDoneEvent,
    generateSwitchConversationEvent,

    EXCEPTION,
    INTENT_HPI_PRIORITY_JOB,
    TECHNICIAN_SESSION_CONVERSATION_ADDED,
    TECHNICIAN_SESSION_CONVERSATION_SWITCHED,
    generateManualResponseEvent,
    generateHandbackConversationEvent,
    generateEndEvent,
    TECHNICIAN_SESSION_CONVERSATION_HANDEDBACK,
    TECHNICIAN_SESSION_CONVERSATION_ENDED,
    TECHNICIAN_SESSION_MANUAL_RESPONDED,
    EXCEPTION_SESSION_JOB,
    TECHNICIAN_SESSION_CONVERSATION_MESSAGE_ADDED,
    isValidEvent,
    isValidQuinnEvent,
    fromString,
    isValidRpaEvent,
    Headers,
    publishHitlEvent
} from '../events';
import { authService } from '../auth'
import {STATE_PAUSED} from "../../components/Secure/Jobs/state";

export class JobSessionWebsocket {

    sockerUrl;
    socket;

    lastPing;

    connectionSubject = new Subject();
    stateSubject = new Subject();
    jobSubject = new Subject();
    errorSubject = new Subject();


    constructor(_socketUrl) {

        this.sockerUrl = _socketUrl;
    }


    connect() {

        this.close();

        let $this = this;

        authService.getToken()
            .then((token) => {

                this.socket = new WebSocket(this.sockerUrl, ["access_token", token]);

                this.socket.onmessage = (event) => {

                    if(event.data === "ping") {
                        this.send("pong");
                        return;
                    }

                    const data = fromString(event.data);

                    if(isValidQuinnEvent(data)) {

                        $this.jobSubject.next(data);
                        const headers = {};
                        headers[Headers.CORRELATION_ID] = data[Headers.CORRELATION_ID];
                        headers[Headers.CONVERSATION_ID] = data[Headers.CONVERSATION_ID];
                        headers[Headers.MESSAGE_ID] = data[Headers.MESSAGE_ID];

                        publishHitlEvent(headers,
                            { "messageId": data[Headers.MESSAGE_ID], "event": "received" });

                    } if(isValidRpaEvent(data)) {

                        $this.jobSubject.next(data);
                    } else {

                        switch (data.type) {
                            case TECHNICIAN_SESSION_ACTIVATED:
                            case TECHNICIAN_SESSION_PAUSED:
                            case TECHNICIAN_SESSION_WAITING:

                                $this.stateSubject.next(data.type.replace("technician.session.", ""));
                                publishHitlEvent({}, { "event": data.type});
                                break;
                            case INTENT_CLASSIFICATION_JOB:
                            case MESSAGE_ANNOTATION_JOB:
                            case INTENT_HPI_PRIORITY_JOB:

                                $this.jobSubject.next(data);
                                publishHitlEvent({}, { "event": data.type, "eventId": data.id });
                                break;
                            case EXCEPTION:

                                $this.errorSubject.next(data);
                                const headers = {};
                                publishHitlEvent(headers, { "event": data.type, "message": data, "eventId": data.id });
                                break;
                            default:

                                console.log("Not a supported data type.");
                        }
                    }
                };
                this.socket.onopen = function(event) {

                    $this.connectionSubject.next("on");
                    $this.toggleState(STATE_PAUSED);
                    console.log("Websocket conversations is open!");
                };
                this.socket.onclose = function(event) {

                    if(event.code === 1008) {
                        $this.errorSubject.next({data: {text: "You already have a session open. Please close all sessions, or contact an administrator to force the termination of your sessions."}});
                        publishHitlEvent({}, { "event": "websocket.close", "message": "Multiple sessions not allowed." });
                    }

                    if (!event.wasClean) {

                        if(event.code !== 1008) {
                            $this.errorSubject.next({ data: { text: "Websocket closed abnormally." }});


                            setTimeout(() => {
                                console.log("Reconnecting from close.", event);
                                $this.connect();
                                publishHitlEvent({}, { "event": "websocket.reconnect" });
                            }, 500);

                            publishHitlEvent({}, { "event": "websocket.close", "message": `Websocket closed due to ${event.code}.`, "detail": event });
                        }
                    }

                    $this.connectionSubject.next("closed");
                };

                this.socket.onerror = function(error) {

                    if (error.code === 'ECONNREFUSED') {
                        $this.connectionSubject.next("closed");

                        setTimeout(() => {
                            console.log("Reconnecting from error.", error);
                            $this.connect();
                            publishHitlEvent({}, { "event": "websocket.reconnect" });
                        }, 500);
                        publishHitlEvent({}, { "event": "websocket.error", "message": "Connection refused", "detail": error });
                    }
                }
            });
    }

    registerConnectionCallback(callback) {

        return this.connectionSubject.subscribe(callback);
    }

    registerJobCallback(callback) {

        return this.jobSubject.subscribe(callback);
    }

    registerStateCallback(callback) {

        return this.stateSubject.subscribe(callback);
    }

    registerExceptionCallback(callback) {

        return this.errorSubject.subscribe(callback);
    }

    toggleState(newState) {

        generateStateChangeEvent(newState).then((_event) => this.send(_event));
    }

    submitJobResult(tenant, type, data) {

        generateJobDoneEvent(tenant, type, data).then((_event) => this.send(_event));
    }


    send(message) {
        if (!window.WebSocket) {
            return;
        }
        if (this.socket.readyState === WebSocket.OPEN) {

            this.socket.send(message);
        } else {

            this.errorSubject.next({ data : { text : "Websocket is not open, please refresh." }})
        }
    }

    close() {

        if(!this.socket) return;

        try {

            this.socket.close();
        } catch (e) {

            console.error(e);
        }
    }
}

export class PrioritySessionWebsocket {

    sockerUrl;
    socket;

    lastPing;

    connectionSubject = new Subject();
    stateSubject = new Subject();
    jobSubject = new Subject();
    errorSubject = new Subject();


    constructor(_socketUrl) {

        this.sockerUrl = _socketUrl;
    }


    connect() {

        this.close();

        let $this = this;

        authService.getToken()
            .then((token) => {

                this.socket = new WebSocket(this.sockerUrl, ["access_token", token]);

                this.socket.onmessage = (event) => {

                    if(event.data === "ping") {
                        this.send("pong");
                        return;
                    }

                    const data = fromString(event.data);

                    if(isValidQuinnEvent(data)) {

                        $this.jobSubject.next(data);
                        const headers = {};
                        headers[Headers.CORRELATION_ID] = data[Headers.CORRELATION_ID];
                        headers[Headers.CONVERSATION_ID] = data[Headers.CONVERSATION_ID];
                        headers[Headers.MESSAGE_ID] = data[Headers.MESSAGE_ID];

                        publishHitlEvent(headers,
                            { "messageId": data[Headers.MESSAGE_ID], "event": "received" });

                    } if(isValidRpaEvent(data)) {

                        $this.jobSubject.next(data);
                    } else {

                        switch (data.type) {
                            case TECHNICIAN_SESSION_ACTIVATED:
                            case TECHNICIAN_SESSION_PAUSED:
                            case TECHNICIAN_SESSION_WAITING:

                                $this.stateSubject.next(data.type.replace("technician.session.", ""));
                                publishHitlEvent({}, { "event": data.type});
                                break;
                            case INTENT_CLASSIFICATION_JOB:
                            case MESSAGE_ANNOTATION_JOB:
                            case INTENT_HPI_PRIORITY_JOB:

                                $this.jobSubject.next(data);
                                publishHitlEvent({}, { "event": data.type, "eventId": data.id });
                                break;
                            case EXCEPTION:

                                $this.errorSubject.next(data);
                                const headers = {};
                                publishHitlEvent(headers, { "event": data.type, "message": data, "eventId": data.id });
                                break;
                            default:

                                console.log("Not a supported data type.");
                        }
                    }
                };
                this.socket.onopen = function(event) {

                    $this.connectionSubject.next("on");
                    $this.toggleState(STATE_PAUSED);
                    console.log("Websocket conversations is open!");
                };
                this.socket.onclose = function(event) {

                    if(event.code === 1008) {
                        $this.errorSubject.next({data: {text: "You already have a session open. Please close all sessions, or contact an administrator to force the termination of your sessions."}});
                        publishHitlEvent({}, { "event": "websocket.close", "message": "Multiple sessions not allowed." });
                    }

                    if (!event.wasClean) {

                        if(event.code !== 1008) {
                            $this.errorSubject.next({ data: { text: "Websocket closed abnormally." }});


                            setTimeout(() => {
                                console.log("Reconnecting from close.", event);
                                $this.connect();
                                publishHitlEvent({}, { "event": "websocket.reconnect" });
                            }, 500);

                            publishHitlEvent({}, { "event": "websocket.close", "message": `Websocket closed due to ${event.code}.`, "detail": event });
                        }
                    }

                    $this.connectionSubject.next("closed");
                };

                this.socket.onerror = function(error) {

                    if (error.code === 'ECONNREFUSED') {
                        $this.connectionSubject.next("closed");

                        setTimeout(() => {
                            console.log("Reconnecting from error.", error);
                            $this.connect();
                            publishHitlEvent({}, { "event": "websocket.reconnect" });
                        }, 500);
                        publishHitlEvent({}, { "event": "websocket.error", "message": "Connection refused", "detail": error });
                    }
                }
            });
    }

    registerConnectionCallback(callback) {

        return this.connectionSubject.subscribe(callback);
    }

    registerJobCallback(callback) {

        return this.jobSubject.subscribe(callback);
    }

    registerStateCallback(callback) {

        return this.stateSubject.subscribe(callback);
    }

    registerExceptionCallback(callback) {

        return this.errorSubject.subscribe(callback);
    }

    toggleState(newState) {

        generateStateChangeEvent(newState).then((_event) => this.send(_event));
    }

    submitJobResult(tenant, type, data) {

        generateJobDoneEvent(tenant, type, data).then((_event) => this.send(_event));
    }


    send(message) {
        if (!window.WebSocket) {
            return;
        }
        if (this.socket.readyState === WebSocket.OPEN) {

            this.socket.send(message);
        } else {

            this.errorSubject.next({ data : { text : "Websocket is not open, please refresh." }})
        }
    }

    close() {

        if(!this.socket) return;

        try {

            this.socket.close();
        } catch (e) {

            console.error(e);
        }
    }
}


export class ExceptionSessionWebsocket {

    sockerUrl;
    socket;

    lastPing;

    connectionSubject = new Subject();
    stateSubject = new Subject();
    jobSubject = new Subject();
    errorSubject = new Subject();


    constructor(_socketUrl) {

        this.sockerUrl = _socketUrl;
    }


    connect() {

        this.close();

        let $this = this;

        authService.getToken()
            .then((token) => {

                this.socket = new WebSocket(this.sockerUrl, ["access_token", token]);

                this.socket.onmessage = (event) => {

                    if(event.data === "ping") {
                        this.send("pong");
                        return;
                    }

                    const data = fromString(event.data);

                    if(isValidQuinnEvent(data)) {

                        $this.jobSubject.next(data);

                    } else {

                        switch (data.type) {
                            case TECHNICIAN_SESSION_ACTIVATED:
                            case TECHNICIAN_SESSION_PAUSED:
                            case TECHNICIAN_SESSION_WAITING:

                                $this.stateSubject.next(data.type.replace("technician.session.", ""));
                                break;
                            case TECHNICIAN_SESSION_CONVERSATION_ENDED:
                            case TECHNICIAN_SESSION_CONVERSATION_HANDEDBACK:
                            case TECHNICIAN_SESSION_CONVERSATION_ADDED:
                            case TECHNICIAN_SESSION_CONVERSATION_MESSAGE_ADDED:
                            case TECHNICIAN_SESSION_CONVERSATION_SWITCHED:
                            case TECHNICIAN_SESSION_MANUAL_RESPONDED:
                            case EXCEPTION_SESSION_JOB:

                                $this.jobSubject.next(data);
                                break;
                            case EXCEPTION:

                                $this.errorSubject.next(data);
                                break;
                            default:

                                console.log("Not a supported data type.", data.type);
                        }
                    }
                };
                this.socket.onopen = function(event) {

                    $this.connectionSubject.next("on");
                    console.log("Websocket conversations is open!");
                };
                this.socket.onclose = function(event) {

                    if (!event.wasClean) {
                        $this.errorSubject.next({ data: { text: "Websocket closed abnormally." }});

                        setTimeout(() => {
                            console.log("Reconnecting from close.", event);
                            $this.connect();
                        }, 500);
                    }

                    $this.connectionSubject.next("closed");
                    console.log("Web Socket closed.", event);
                };

                this.socket.onerror = function(error) {

                    if (error.code === 'ECONNREFUSED') {
                        $this.connectionSubject.next("closed");
                        setTimeout(() => {
                            console.log("Reconnecting from error.", error);
                            $this.connect();
                        }, 500);
                    }
                }
            });
    }

    registerConnectionCallback(callback) {

        return this.connectionSubject.subscribe(callback);
    }

    registerJobCallback(callback) {

        return this.jobSubject.subscribe(callback);
    }

    registerStateCallback(callback) {

        return this.stateSubject.subscribe(callback);
    }

    registerExceptionCallback(callback) {

        return this.errorSubject.subscribe(callback);
    }

    toggleState(newState) {

        generateStateChangeEvent(newState).then((_event) => this.send(_event));
    }

    switchConversation(newConversation) {

        generateSwitchConversationEvent(newConversation).then((_event) => this.send(_event));
    }

    submitJobResult(tenant, type, data) {

        generateJobDoneEvent(tenant, type, data).then((_event) => this.send(_event));
    }


    sendManualResponse(tenant, response) {

        generateManualResponseEvent(tenant, response).then((_event) => this.send(_event));
    }

    handback(tenant, response) {

        generateHandbackConversationEvent(tenant, response).then((_event) => this.send(_event));
    }

    end(tenant, response) {

        generateEndEvent(tenant, response).then((_event) => this.send(_event));
    }

    send(message) {
        console.log("Send message", message);
        if (!window.WebSocket) {
            return;
        }
        if (this.socket.readyState === WebSocket.OPEN) {

            this.socket.send(message);
        } else {

            this.errorSubject.next({ data : { text : "Websocket is not open, please refresh." }})
        }
    }

    close() {

        if(!this.socket) return;

        try {

            this.socket.close();
        } catch (e) {

            console.error("websocket:", e);
        }
    }
}

export const federatedSessionWebsocket = new JobSessionWebsocket(process.env.REACT_APP_DH_FEDERATED_WEBSOCKET_URL);
export const prioritySessionWebsocket = new PrioritySessionWebsocket(process.env.REACT_APP_DH_PRIORITY_WEBSOCKET_URL);
export const exceptionSessionWebsocket = new ExceptionSessionWebsocket(process.env.REACT_APP_DH_EXCEPTION_WEBSOCKET_URL);
