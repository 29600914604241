const rules = {
    annotator: {
        static: [
            "jobs:async:perform",
            "jobs:async:annotate",
            "jobs:async:approve",
            "jobs:federated:perform",
            "jobs:annotation:perform",
            "jobs:annotation:review",
            "jobs:summarise:perform",
        ],
    },
    medical: {
        static: [
            "analytics:dashboard:view",
            "jobs:federated:perform",
            "jobs:summarise:perform",
            "jobs:exception:perform",
            "patient:data:read",
            "patient:conversations:search",
            "patient:conversations:read",
            "patient:appointments:create",
            "patient:communication:all",
            "test:entityrecognition:perform",
            "test:differentialdiagnosis:perform",
            "graph:inspect:all",
        ],
    },
    experimental: {
        static: [
            "jobs:summarise:perform",
            "jobs:preparation:perform",
            "jobs:preparation:review",
        ],
    },
    approver: {
        static: [
            "jobs:async:perform",
            "jobs:async:approve",
        ],
    },
    sysadmin: {
        static: [
            "system:redis:all",
        ],
    },
    admin: {
        static: [
            "users:permissions:update",
            "system:administration:health_check",
        ]
    }
};

export default rules;
