import * as rxjs from "rxjs";
import {quinnApi} from "../../../../../utils/services/quinn.api";
import {Headers, publishHitlEvent} from "../../../../../utils/events";
import {IndexBlocEvent} from "../../../global.bloc";
import {
    MESSAGE_ANNOTATION_EVENTS,
    messageAnnotationEventService
} from "../MessageAnnotationPanel/service/message.annotation.event.service";
import {federatedSessionWebsocket} from "../../../../../utils/services/session.websocket";

export class ConversationTurnBloc {

    constructor(event, websocket) {

        this.websocket = websocket;

        this.subject = new rxjs.BehaviorSubject({
            event: event,
            entities: { added: [], removed: [] },
            intents: { added: [], removed: [] },
            contextOverrides: {},
            initialising: true,
            processing: false,
            loaded: false,
        });

        this.events = new rxjs.Subject();
    }

    initialise = (overrides) => {

        this.__loadContextMenu(overrides);
        this.handleWebsocketErrorUpdateSubscription = this.websocket.registerExceptionCallback(this.__handleErrorUpdate);
    }

    close = () => {
        if(this.handleWebsocketErrorUpdateSubscription) {
            this.handleWebsocketErrorUpdateSubscription.unsubscribe();
        }
    }

    __loadContextMenu = (overrides) => {

        let slot = this.slot();

        if(overrides?.contextMenu?.slot) {
            slot = overrides?.contextMenu?.slot;
        }

        if(!slot) {

            this.subject.next({
                ...this.subject.value,
                initialising: false,
            });
        } else {

            this.loadContextMenuOverride(slot);
        }
    }

    __handleErrorUpdate = (event) => {

        this.events.next({ event: ConversationTurnBlocEvent.EXCEPTION, data: {}})
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    loadContextMenuOverride = (target, callback) => {

        let { contextOverrides } = this.subject.value;

        quinnApi.contextMenu(target, this.tenant())
            .then(value => {

                contextOverrides[target] = value.data === "" ? undefined : value.data;

                this.subject.next({
                    ...this.subject.value,
                    initialising: false,
                    processing: false,
                    contextOverrides: contextOverrides,
                });
            }).catch(reason => {

            contextOverrides[target] = undefined;

            this.subject.next({
                ...this.subject.value,
                initialising: false,
                processing: false,
                contextOverrides: contextOverrides,
            });
        }).finally(() => {
            if(callback) callback();
            this.events.next({ event: ConversationTurnBlocEvent.CONTEXT_MENU_LOADED, data: contextOverrides[target]})
        });
    }

    slot = () => {
        return this.subject.value.event.data.slotSpecification.code;
    }

    tenant = () => {
        return this.subject.value.event[Headers.TENANT_ID];
    }

    setIntent = (_intents) => {

        let { intents, event } = this.subject.value;
        intents.added = _intents;

        this.subject.next({
            ...this.subject.value,
            intents: intents,
            processing: true,
        });

        this.loadContextMenuOverride(_intents[0].category, () => {
            this.events.next({ event: ConversationTurnBlocEvent.INTENTS_UPDATED, data: {}})
        });

        const headers = {};
        headers[Headers.CORRELATION_ID] = event[Headers.CORRELATION_ID];
        headers[Headers.CONVERSATION_ID] = event[Headers.CONVERSATION_ID];
        headers[Headers.MESSAGE_ID] = event[Headers.MESSAGE_ID];

        publishHitlEvent(headers, { "event": "intents.set", "count":  _intents?.length || 0 });
    }

    setEntities = (_entities) => {

        let { entities, event } = this.subject.value;
        entities.added = _entities;

        this.subject.next({
            ...this.subject.value,
            entities: entities,
        });

        const headers = {};
        headers[Headers.CORRELATION_ID] = event[Headers.CORRELATION_ID];
        headers[Headers.CONVERSATION_ID] = event[Headers.CONVERSATION_ID];
        headers[Headers.MESSAGE_ID] = event[Headers.MESSAGE_ID];

        publishHitlEvent(headers, { "event": "entities.set", "count":  _entities?.length || 0 });
    }


    answerQuinn = () => {

        let { intents, entities, event : { data : { lastTurn } } } = this.subject.value;

        messageAnnotationEventService.update(MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED, {
            action: "answer",
            conversationId: lastTurn.metadata.conversationId,
            messageId: lastTurn._id,
            tenantId: lastTurn.tenant,
            intentUpdates: {
                add: intents.added,
                remove: [],
            },
            entityUpdates: {
                add: entities.added,
                remove: [],
            },
        });

        const _event = this.subject.value.event;

        const headers = {};
        headers[Headers.CORRELATION_ID] = _event[Headers.CORRELATION_ID];
        headers[Headers.CONVERSATION_ID] = _event[Headers.CONVERSATION_ID];
        headers[Headers.MESSAGE_ID] = _event[Headers.MESSAGE_ID];

        publishHitlEvent(headers,
            { "event": "answered.quinn", "action": MESSAGE_ANNOTATION_EVENTS.MESSAGE_ANNOTATED });
    }

}

export class ConversationTurnBlocEvent {
    static CONTEXT_MENU_LOADED = "CONTEXT_MENU_LOADED";

    static INTENTS_UPDATED = "INTENTS_UPDATED";

    static MESSAGE_ANNOTATED = "MESSAGE_ANNOTATED";
    static MESSAGE_DISCARD = "MESSAGE_DISCARD";
    static QUESTION_REPHRASED = "QUESTION_REPHRASED";
    static EXCEPTION = "EXCEPTION";
}