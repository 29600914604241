import React from "react";
import {withStyles} from "@material-ui/core/styles";
import EntityRecognitionContext from "./context";
import {ButtonGroup, Chip, MenuItem, Tooltip, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

const styles = theme => ({
    title: {
        flex: 0.95
    },
    resultPanel: {
        flex: 1
    },
    titlePanel: {
        padding: "5%",
        paddingBottom: "1%",
        paddingTop: "2%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    renderedOutputBody: {
        paddingTop: "1%",
        paddingBottom: "1%",
        padding: "5%",
        fontSize: "x-large"
    },
    legendChip: {
        fontWeight: "initial",
        fontSize: "large"
    },
    chip: {
        fontWeight: "initial",
        fontSize: "large"
    },
    toolTipText: {
        fontSize: "large"
    }
})

class ResultPanel extends React.Component {

    entityChipColor
    normalisedChipColor

    constructor(props) {
        super(props);

        this.state = ({
            view: "entities"
        })

        this.entityChipColor = "#CF6679"
        this.normalisedChipColor = "#BB86FC"
    }

    renderEntity = (entity) => {

        let tooltipText;
        let chipColor = this.entityChipColor

        if (entity.normalised) {
            chipColor = this.normalisedChipColor
            let displayResults = this.resolveNormalisedItems(entity)
            tooltipText = this.buildNormalisedConcept(displayResults)
        } else if (entity.concepts.length > 0) {
            let bestMatchConcept = entity.concepts.filter(concept => {
                return concept.bestMatch === true
            })
            tooltipText = (<div>
                <Typography style={{fontWeight: 600}}>
                    Best match concept
                </Typography>
                <Divider/>
                <Typography>
                    Display: {bestMatchConcept[0].coding.display}
                </Typography>
                <Typography>
                    Code: {bestMatchConcept[0].coding.code}
                </Typography>
            </div>)

        } else {
            tooltipText = <Typography>No matched concepts</Typography>
        }

        return <Tooltip className={this.props.classes.toolTipText}
                        placement="bottom"
                        title={tooltipText}
        >
            <Chip label={entity.original}
                  style={{backgroundColor: chipColor}}
                  className={this.props.classes.chip}
            /></Tooltip>

    }

    buildNormalisedConcept = (displayResults) => {
        return (<div>
            <Typography style={{fontWeight: 600}}>
                Normalised concept
            </Typography>
            {displayResults.map(result => {
                return (<div>
                    <Divider/>
                    <Typography>
                        Category: {result.category}
                    </Typography>
                    <Typography>
                        Display: {result.display}
                    </Typography>
                    <Typography>
                        Matched Attribute: {result.matchedAttribute}
                    </Typography></div>)
            })}
        </div>)
    }

    resolveNormalisedItems = (extract) => {

        let displayResults = []

        if (extract.observations.length > 0) {
            extract.observations.forEach(observation => {
                    displayResults.push({
                        category: "Observation",
                        display: observation.display,
                        matchedAttribute: observation.matchRelationship.matchedAttribute
                    })
                }
            )
        }
        if (extract.conditions.length > 0) {
            extract.conditions.forEach(condition => {
                    displayResults.push({
                        category: "Condition",
                        display: condition.display,
                        matchedAttribute: condition.matchRelationship.matchedAttribute
                    })
                }
            )
        }

        return displayResults
    }

    renderPhrase = (phrase) => {

        let tooltipText;
        let chipColor = this.entityChipColor

        if (phrase.normalised) {
            chipColor = this.normalisedChipColor
            let displayResults = this.resolveNormalisedItems(phrase)
            tooltipText = this.buildNormalisedConcept(displayResults)
        } else {
            tooltipText = <Typography>No matched concepts</Typography>
        }

        return <Tooltip className={this.props.classes.toolTipText}
                        placement="bottom"
                        title={tooltipText}
        >
            <Chip label={phrase.original}
                  style={{backgroundColor: chipColor}}
                  className={this.props.classes.chip}
            /></Tooltip>

    }

    handleChange = (event, value) => {
        this.setState({
            view: value
        })
    }

    render() {
        let {classes} = this.props;
        let {view} = this.state

        let {resolvedEntities, resolvedPhrases} = this.props

        return (
            <div className={classes.resultPanel}>
                <div className={classes.titlePanel}>
                    <Typography className={classes.title} variant="h4">
                        Named Entity Recognition Result
                    </Typography>
                    <Chip
                        className={classes.legendChip}
                        style={{backgroundColor: this.entityChipColor}}
                        label="Recognised Entity"/>
                    <Chip
                        className={classes.legendChip}
                        style={{backgroundColor: this.normalisedChipColor}}
                        label="Normalised Entity"/>
                </div>
                <div className={classes.titlePanel}>
                    <ToggleButtonGroup value={view} exclusive onChange={this.handleChange}>
                        <ToggleButton value="entities" aria-label="entities">
                            Entities
                        </ToggleButton>
                        <ToggleButton value="phrases" aria-label="phrases">
                            Phrases
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className={classes.renderedOutputBody}>
                    {view === "entities" ? resolvedEntities.map((item) => {
                        return typeof (item) == "object" ? (this.renderEntity(item)) : (<>{item}</>)
                    }) : resolvedPhrases.map((item) => {
                        return typeof (item) == "object" ? (this.renderPhrase(item)) : (<>{item}</>)
                    })
                    }
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(props => (
    <EntityRecognitionContext.Consumer>
        {value => {
            return (<ResultPanel context={value} {...props} />);
        }
        }
    </EntityRecognitionContext.Consumer>
));
