import { Subject } from 'rxjs';

export class NavigationTitleService {

    stateSubject = new Subject();

    registerStateCallback(callback) {
        return this.stateSubject.subscribe(callback);
    }

    update(title) {
        this.stateSubject.next(title);
    }
}

export const navigationTitleService = new NavigationTitleService();