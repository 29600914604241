import React, {Component, PureComponent} from 'react';

import ConversationViewContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar, Badge,
    Box, Chip, Grid,
    LinearProgress,
    Typography, Button
} from "@material-ui/core";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DecodedComponent from "../../../shared/DecodedComponent";
import {ConversationVisualBlocEvent} from "./bloc";

import {PieChart, Pie, ResponsiveContainer, Cell} from 'recharts';

const styles = theme => ({
    root: {
        width: "25vw",
        maxHeight: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    accordion: {
        background: "transparent",
        boxShadow: 'none',
    },
    heading: {
        color: "#4A6A87",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",
        textTransform: "uppercase",
        marginTop: "24px",
        marginBottom: "16px",
    },
    detail: {
        maxHeight: "75vh",
        background: "rgba(16, 29, 41, 0.4)",
        boxShadow: "0px 30px 20px rgba(15, 28, 39, 0.3)",
        backdropFilter: "blur(10px)",
        borderRadius: "12px",
    },
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "4px",
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: "inset 0 0 4px grey",
        },
        '&::-webkit-scrollbar-thumb': {
            background: "#000",
            borderRadius: "4px",
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: "#ccc",
            borderRadius: "4px",
        },
    },
    legendItem: {
        paddingBottom: "18px",
    },
    chip: {
        margin:"10px 14px 0 0",
        background: "#2D3E56",
        color: "#51E2FD",
    },
    dx: {
        color: "#B7B7E7",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",
        marginTop: "12px",
        marginBottom: "16px",
    },
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        background: 'linear-gradient(269.43deg, #8676FF -11.38%, #04D3F9 109.31%);',
    },
}))(LinearProgress);


const badgeStyles = theme => ({
    customBadge: {
        backgroundColor: props => props.color,
    }
});

function SimpleBadge(props) {
    const { classes } = props;
    return (
        <div>
            <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent=" "
                anchorOrigin={{vertical: 'center', horizontal: 'left',}}
            >
                { props.children }
            </Badge>
        </div>
    );
}
const StyledBadge = withStyles(badgeStyles)(SimpleBadge);

class GraphDataPanel extends DecodedComponent {

    constructor(props) {
        super(props);

        this.dialogRef = React.createRef();

        this.bloc = props.context.bloc;
        this.state = { conversationMessages: [], conversationInference: undefined }
    }


    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleState = (state) => {
        this.setState({
            ...state,
        });
        this.__calculate_acuity(state.conversationInference)
    }

    __calculate_acuity = (conversationInference) => {

        if(conversationInference?.value?.inference.length > 0) {

            const inference = conversationInference.value.inference[0];

            let acuity = inference.acuity;

            if(acuity !== "emergent" && inference.complications) {

                for (let i = 0; i < inference.complications.length; i++) {

                    if(inference.complications[i].response === "endorse") {

                        if (inference.complications[i].acuity === "emergent") {
                            acuity = "emergent";
                            break;
                        }
                        if (inference.complications[i].acuity === "urgent") {
                            acuity = "urgent";
                        }
                    }
                }
            }

            let data = [];

            console.log(acuity)

            switch (acuity) {
                case "urgent":
                    data.push([{
                        name: 'Acuity urgent',
                        value: 60,
                        fill: '#389CFE',
                        stroke: '#389CFE',
                    },{
                        name: '',
                        value: 40,
                        fill: "#DBDFF1",
                        stroke: "#DBDFF1",
                        strokeLinecap: "round",
                        opacity: "0.0",
                    }]);
                    break;
                case "emergent":
                    data.push([{
                        name: 'Acuity emergent',
                        value: 100,
                        fill: '#389CFE',
                        stroke: '#389CFE',
                    },{
                        name: '',
                        value: 0,
                        fill: "#DBDFF1",
                        stroke: "#DBDFF1",
                        strokeLinecap: "round",
                        opacity: "0.0",
                    }]);
                    break;
                default:
                    data.push([{
                        name: 'Acuity non-urgent',
                        value: 30,
                        fill: '#389CFE',
                        stroke: '#389CFE',
                    },{
                        name: '',
                        value: 70,
                        fill: "#DBDFF1",
                        stroke: "#DBDFF1",
                        strokeLinecap: "round",
                        opacity: "0.0",
                    }]);
                    break;
            }

            this.setState({
                data: data,
            });
        }
    }

    __openSummary = () => this.setState({ clinicalSummaryOpen: true });
    __closeSummary = () => this.setState({ clinicalSummaryOpen: false });

    render() {

        let { classes } = this.props;
        let { data, conversationMessages, conversationInference, clinicalSummary, clinicalSummaryOpen, } = this.state;



        const dataBackground = [
            [{
                name: '',
                value: 100,
                fill: "#DBDFF1",
                stroke: "#DBDFF1",
                opacity: "0.3",
            }],
            [{
                name: '',
                value: 100,
                fill: "#DBDFF1",
                stroke: "#DBDFF1",
                opacity: "0.3",
            }],
            ];

        return (
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Network Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.detail}>
                        <div className={classes.content}>

                            { clinicalSummary?.document &&
                            <>
                                <Button onClick={this.__openSummary}>Open Clinical Summary</Button>
                                <Dialog
                                    open={clinicalSummaryOpen}
                                    onClose={this.__closeSummary}
                                    scroll={"paper"}
                                    aria-labelledby="scroll-dialog-title"
                                    aria-describedby="scroll-dialog-description"
                                >
                                    <DialogTitle id="scroll-dialog-title">Clinical Summary</DialogTitle>
                                    <DialogContent dividers={false}>
                                        <DialogContentText
                                            id="scroll-dialog-description"
                                            ref={this.dialogRef}
                                            tabIndex={-1}
                                        >
                                            { clinicalSummary.document.sections.map( _section => <>{_section.content.split(/(?:\r\n|\r|\n)/g).map(_split => <>{_split}<br /></>)}</>) }
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.__closeSummary} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                            }

                            <Typography className={classes.heading}>Chief Complaint</Typography>

                            <Grid container>
                                <Grid xs={7} item>
                                    <ResponsiveContainer width="100%" height="100%" minHeight={"200px"}>

                                        <PieChart width={200} height={200}>
                                            {/*<Pie data={dataBackground[0]} dataKey="value" innerRadius={30} outerRadius={50} cornerRadius={40} boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"} labelLine={false} />*/}
                                            {/*{ data && <Pie data={data[0]} dataKey="value" innerRadius={30} outerRadius={50} cornerRadius={40} labelLine={false} /> }*/}
                                            <Pie data={dataBackground[1]} dataKey="value" innerRadius={60} outerRadius={80} cornerRadius={40} boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"} labelLine={false} />
                                            { data && <Pie data={data[0]} dataKey="value" innerRadius={60} outerRadius={80} cornerRadius={40} labelLine={false} /> }
                                        </PieChart>

                                    </ResponsiveContainer>
                                </Grid>
                                <Grid xs={5} item>
                                    { data && data.reverse().map(_item => {

                                        const item = _item[0];

                                        return <div className={classes.legendItem}><StyledBadge color={_item[0].fill}><span style={{ paddingLeft: "32px", }}>{ item.name }</span></StyledBadge></div>;
                                    }) }

                                </Grid>
                            </Grid>

                            <Typography className={classes.heading}>Differential Dx</Typography>

                            { conversationInference && conversationInference?.value?.inference &&
                                conversationInference.value.inference.map(_item =>
                                    <Box>
                                    <Typography className={classes.dx}>{_item.conditionName}</Typography>
                                    <BorderLinearProgress variant="determinate" value={_item.probability * 100} />
                                </Box>)
                            }

                            <Typography className={classes.heading}>Salient Features</Typography>

                            <Box display={"flex"} flexWrap={"wrap"}>
                                {
                                    this.__salientFeatures(conversationMessages)
                                        .map(_entity => <Chip className={classes.chip} label={_entity.display} variant="default" />)
                                }
                            </Box>


                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }

    __checkIfValidUUID = (str) => {

        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    __salientFeatures = (conversationMessages) => {

        let result = [];
        let keys = [];

        conversationMessages
            .flatMap(_message => _message.metadata?.entities || [])
            .filter(_entity => _entity.normalised || !_entity.extract.includes("{"))
            .filter(_entity => _entity.category === "sx" ||  _entity.category === "associated_sx" ||  _entity.category === "observation" ||  _entity.category === "onset" ||  _entity.category === "dx" ||  _entity.category === "tx" ||  _entity.category === "rx")
            .forEach(_entity => {

                const display = _entity.normalised || _entity.extract
                const negated = _entity.negated;
                if(display && !this.__checkIfValidUUID(display) && !_entity.extract.startsWith("/") && !keys.includes(display)) {
                    keys.push(display);
                    result.push({display: display, negated: negated})
                }

                if(_entity.concepts) {

                    _entity.concepts.forEach(_concept => {
                       if(_concept.text && !keys.includes(_concept.text)) {
                           keys.push(_concept.text);
                           result.push({ display: _concept.text, negated: negated, code: _concept.text })
                       }
                       if(_concept.value && !keys.includes(_concept.value)) {
                           keys.push(_concept.value);
                           result.push({ display: _concept.value, negated: negated, code: _concept.value })
                       }
                    });
                }
            })


        return result;
    }
}

export default withStyles(styles)(props => (
    <ConversationViewContext.Consumer>
        {value => {
            return (<GraphDataPanel context={value} {...props} />);
        }
        }
    </ConversationViewContext.Consumer>
));
