import React from 'react';

import { withStyles } from '@material-ui/core/styles';


import { LinearProgress } from "@material-ui/core";

import {navigationTitleService} from "../../../../../utils/title";
import AsyncJobContext from "../context";
import DecodedComponent from "../../../../shared/DecodedComponent";
import {Bloc} from "../bloc";

import JobPipeline from "./components/JobPipeline"

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        padding: "0px 0px 20px 0px",
        display: "flex",
        width: "100%",
    },

});

class Dashboard extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;

        this.state = {
            initialising: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();
        this.bloc.startPipelinePolling();
        navigationTitleService.update("Asynchronous Jobs - Dashboard");
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.bloc.stopPipelinePolling();
    }


    render() {

        let { classes } = this.props;
        let { initialising, pipelines } = this.state;


        let context = {
            bloc: this.bloc,
        };

        return (
            <div className={classes.root}>
               <AsyncJobContext.Provider value={context}>
                   { initialising && <LinearProgress /> }
                   { !initialising && Object.keys(pipelines).map((_pipeline) => {
                       const pipeline = pipelines[_pipeline];
                       return (<JobPipeline pipeline={pipeline}/>);
                       })
                   }
               </AsyncJobContext.Provider>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<Dashboard context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));
