import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {AnnotationBlocEvent} from "../annotation.bloc";
import {uuid} from "uuidv4";
import Button from "@material-ui/core/Button";


const styles = theme => ({
    dataGrid: {
        display: 'flex',
        width: '100%',
        "& .MuiDataGrid-columnHeaderMoving": {
            backgroundColor: "#303030"
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-menuIcon": {
            display: "none"
        },
        "& .MuiDataGrid-iconButtonContainer": {
            display: "none"
        }
    },
});

class RelationshipPanel extends Component {

    eventSubscription;
    stateSubscription;

    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.__handleAnnotationState = this.__handleAnnotationState.bind(this);
        this.__handleAnnotationEvent = this.__handleAnnotationEvent.bind(this);
    }

    __handleAnnotationState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleAnnotationEvent = (event) => {
        const type = event.event;
        const data = event.data;

        switch (type) {
            case AnnotationBlocEvent.ENTITY_ANNOTATED:

                let relationships = this.state.relationships

                relationships.push({
                    id: data.entity.data.id,
                    entity: data.entity.data.extract,
                    category: this.formatCategory(data.entity.data.category),
                    weight: undefined,
                })

                this.setState({
                    relationships: relationships,
                });
                break;
            case AnnotationBlocEvent.ENTITIES_RESET:
                this.setState({
                    relationships: [],
                });
                break;
        }
    }

    componentDidMount() {
        this.stateSubscription = this.props.annotationBloc.subscribeToState(this.__handleAnnotationState);
        this.eventSubscription = this.props.annotationBloc.subscribeToEvents(this.__handleAnnotationEvent);
    }

    componentWillUnmount() {
        this.stateSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
    }

    formatCategory = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().replace("_", " ");
    }


    renderDeleteButton = (params) => {
        return (<Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        this.props.annotationBloc.deleteRelationship(params.id)
                    }}
                >
                    DELETE
                </Button>)
    }


    render() {
        let { classes, annotationBloc }  = this.props
        let { relationships } = annotationBloc.subject.value

        let columns = [
        {field: 'entity', headerName: 'Entity', flex: 2},
        {field: 'category', headerName: 'Category', flex: 2},
        {field: 'weight', headerName: 'Weight', flex: 1.5, editable: true },
        {field: '', renderCell: this.renderDeleteButton},
        ];

        let rows = relationships.map((item) => {
            return {
                id: item.id,
                entity: item.entity,
                category: item.category,
                weight: item.weight
            }
        })

        return (
            <div className={classes.dataGrid}>
                <DataGrid rows={rows}
                          columns={columns}
                          autoHeight={true}
                          disableSelectionOnClick
                          onEditCellChangeCommitted={annotationBloc.handleWeightChangeCommitted}/>
        </div>)
    }
}
export default withStyles(styles)(RelationshipPanel);