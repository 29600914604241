import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {FormattedMessage} from "react-intl";
import {
    FormGroup,
    Paper,
    Button,
    Snackbar,
    IconButton,
    LinearProgress,
    InputBase,
    FormControlLabel, Checkbox, Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from '@material-ui/icons/Search';
import {adminApi} from "../../../../utils/services/admin.api";
import {navigationTitleService} from "../../../../utils/title";
import {notificationService} from "../../../../utils/notification";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        maxHeight: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        width: "100%",
    },
    headerLeft: {},
    headerCenter: {},
    headerRight: {},
    paperFullscreen: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "#000",
        zIndex: "100000",
    },
    paper: {
        backgroundColor: "#000",
        width: "100%",
        minHeight: "calc(100%)",
        maxHeight: "calc(100vh - 64px)",
    },
    paperContent: {
        minHeight: "100%",
        height: "100%",
        margin: "auto",
        paddingTop: "15px",
    },
    paperContentFullscreen: {
        minHeight: "calc(100% - 36px)",
        height: "calc(100% - 36px)",
        margin: "auto",
    },
    paperContentMessage: {
        paddingTop: "65px",
        backgroundColor: "#000",
        height: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        padding: "20px 0px 0px 0px",
        display: "flex",
        width: "100%",
    },
    footerLeft: {},
    footerCenter: {},
    footerRight: {},

    resumeIconBtn: {

        height: "74px",
        paddingBottom: "25px",
    },

    closeError: {
        padding: theme.spacing(0.5),
    },

    inputPaper: {
        marginLeft: "20px",
        marginRight: "20px",
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});

class SystemHealth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: -1,
            busy: false,
        };
    }

    componentWillMount() {
        navigationTitleService.update("System Health Check");
    }

    checkHealth = () => {

        const {tenantId} = this.state;
        this.setState({
            status: -1,
            busy: true,
        });

        adminApi.health(tenantId)
            .then(value => {
                this.setState({
                    status: value.data.status,
                    busy: false,
                });
            }, reason => {
                notificationService.error(`${reason}`);
                this.setState({
                    status: -1,
                    busy: false,
                });
            });
    };

    handleInputChange = (event) => {

        this.setState({tenantId: event.target.value});
    };

    render() {

        let {classes} = this.props;
        let {busy, tenantId, status} = this.state;

        let screen = (<>
            <Typography variant="h3" component="h4">
                <FormattedMessage id={"waiting"} defaultMessage={"Waiting..."} />
            </Typography>
        </>);

        if(status > -1) {
            screen = <>
                <Typography variant="h3" component="h4">
                    <FormattedMessage id={"complete"} defaultMessage={"Complete with status"} /> {status}
                </Typography>
            </>;
        }

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {busy && <LinearProgress/>}
                    <div className={classes.paperContent}>
                        <Paper className={classes.inputPaper}>
                            <InputBase
                                onChange={this.handleInputChange}
                                value={tenantId}
                                className={classes.input}
                                placeholder="Tenant id to perform health check on"
                                inputProps={{'aria-label': 'tenant id to perform health check on'}}
                            />
                            <IconButton onClick={this.checkHealth} className={classes.iconButton} aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                        </Paper>
                        <div className={classes.paperContentMessage}>
                            {screen}
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SystemHealth);
