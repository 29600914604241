import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import Close from '@material-ui/icons/Close';

import {Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import {HPI_PROIRITISED, HPI_PROIRITISED_EXCEPTION} from "../../../../utils/events";

import JobContext from './context';

import {FormattedMessage} from "react-intl";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        padding: "30px 25px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {},
    instruction: {
        color: "#55CCFF",
    },
    content: {},
    message: {
        paddingTop: "50px",
    },
    control: {

        width: "100%",
    },
    controlButtonWrapper: {
        display: "inline-block",
        width: "50%",
    },
    controlButtonGroupLeft: {
        width: "100%",
        paddingRight: "30px",
        height: "80px",
    },
    controlButtonGroupRight: {
        width: "100%",
        paddingLeft: "30px",
        height: "80px",
    },
    controlButton: {
        width: "100%",
    },
    controlButtonLeft: {
        width: "25%",
    },
    controlButtonCenter: {
        color: `#fff !important`,
        backgroundColor: `${theme.palette.primary.dark} !important`,
        width: "50%",
    },
    controlButtonRight: {
        width: "25%",
        countAvatar: {
            width: "2000px",
        }
    },
    footer: {
        marginTop: "25px",
        height: "50px",
        display: "flex",
    },
    footerRightButton: {
        fontSize: "40px",
        color: `${theme.palette.primary.dark} !important`,
    },
    countAvatarLeft: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "-15px",
        zIndex: "1",
    },
    countAvatarRight: {
        color: "#ffffff",
        backgroundColor: "#002a39",
        position: "relative",
        bottom: "-15px",
        left: "15px",
        zIndex: "1",
    },

    choiceForm: {
        paddingTop: "35px",
    },

    choiceLabel: {
        "& span" : {
            fontSize: "50px !important",
        },
    },
});

class PriorityComplaintSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: props.context.message,
            metadata: props.context.metadata,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (!this.props.context.message || this.props.context.message.conversationId !== nextProps.context.message.conversationId) {

            this.setState({
                message: nextProps.context.message,
                metadata: nextProps.context.metadata,
            });
        }
    }

    handleException = () => {

        const { message, selectedValue } = this.state;

        const result = {
            action: "set_priority",
            conversationId: message.conversationId,
            priority: selectedValue,
        };

        this.props.submitResultCallback(HPI_PROIRITISED_EXCEPTION, result);
    };

    handleSelection = (event) => {

        this.setState({selectedValue: event.target.value});
    };

    handleSubmission = () => {

        const { message, selectedValue } = this.state;

        const result = {
            action: "set_priority",
            conversationId: message.conversationId,
            priority: selectedValue,
        };

        this.props.submitResultCallback(HPI_PROIRITISED, result);
    };

    render() {

        let {classes, busy} = this.props;

        let {message, selectedValue} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant='h6' align='left' className={classes.instruction}>
                        <FormattedMessage id={"hpi.chief.complaint.priority"}
                                          defaultMessage={"Select the highest priority complaint."}/>
                    </Typography>
                </div>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant='h3' align='left' className={classes.message}>
                                {message.priority}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset" className={classes.choiceForm}>
                                <RadioGroup
                                    aria-label="priority"
                                    name="priority"
                                    className={classes.group}
                                    value={selectedValue}
                                    onChange={this.handleSelection}
                                >
                                    {
                                        message.choices.map(choice => (<FormControlLabel className={classes.choiceLabel} value={choice.id} control={<Radio/>} label={choice.extract}/>))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.fill}/>
                <div className={classes.footer}>
                    <Button disabled={busy} onClick={this.handleException}><Close fontSize="large" color="primary"/>
                        <span className={classes.footerButtonLabel}><FormattedMessage id="exception"
                                                                                      defaultMessage={`Exception`}/></span></Button>
                    <div className={classes.fill}></div>
                    <Button disabled={busy} onClick={this.handleSubmission}><KeyboardReturn fontSize="large" color="primary"/></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <JobContext.Consumer>
        {value => {
            return (<PriorityComplaintSelection context={value} {...props} />);
        }
        }
    </JobContext.Consumer>
));
