import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select, Typography
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    referenceHeader: {
        fontSize: "1rem"
    },
    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",

    },
    listItems: {
        minHeight: "32px",
    },
    listItem: {
        flex: 1,
    },
    select: {
        display: "flex"
    },
    option: {
        flex: 1,
        margin: "0.5%"
    },
    linkReferenceHeader: {
        fontSize: "1rem",
        color: "#55CCFF"
    }

});

class ObservationsComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;
        this.classType = "observation"

        this.state = {
            initialising: true,
            observation: "",
            observationCUI: "",
            observationClass: "",
            observations: [],
            observationClasses: []

        };

        this.__removeObservation = this.__removeObservation.bind(this);
        this.__addObservation = this.__addObservation.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Condition Observation Job");
        this.__getClasses().then(res => {
            this.setState({
                observationClasses: res.data
            })
        })
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __observationChanged = (event) => {

        if (event === null) {
            return
        }

        this.setState({
            observation: event.canonicalName,
            observationCUI: event.conceptId,
        });
    }

    __addObservation = () => {

        let {observations, observation, observationClass, observationCUI} = this.state;

        if (observation === "" || observationClass === "") {
            notificationService.error("Please complete both observation and observation class.");
            return;
        }

        observations.push({
            id: uuid().toString(),
            observation: observation,
            observationCUI: observationCUI,
            observationClass: observationClass
        });

        this.setState({
            observations: observations,
            observation: undefined,
            observationClass: undefined
        })

    }

    __handleClassChange = (event) => {
        this.setState({
            observationClass: event.target.value,
        });
    }

    __removeObservation = (_observation) => () => {

        let {observations} = this.state;

        observations = observations.filter(observation => observation.id !== _observation.id);

        this.setState({
            observations: observations,
        });
    }

    __submit = () => this.bloc.submitTask({
        data: {
            observations: this.state.observations.map(value => {
                return {
                    "observation": value.observation,
                    "observationCUI": value.observationCUI,
                    "observationClass": value.observationClass
                }
            })
        }
    });

    __discard = (message) => this.bloc.discardTask(message)

    __getOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({query: _inputValue, dataType: this.state.observationClasses.map(o => o.toLowerCase())})
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        const aliases = item.index
                        aliases.unshift(item.canonicalName)
                        const uniqueNames = [...new Set(aliases)]
                        const option = {
                            name: uniqueNames.join(', '),
                            conceptId: item.conceptId,
                            canonicalName: item.canonicalName
                        }

                        options.push(option)

                    })

                    resolve(options)
                }, error => {
                    notificationService.error("Error loading observations");
                    resolve(options)
                });
        })
    }

    __getClasses = () => {

        return new Promise(resolve => {
            let options = {"data": []}

            ontologyApi.classes(this.classType)
                .then(value => {
                    resolve(value)
                }, error => {
                    notificationService.error("Error loading observation classes");
                    resolve(options)
                }).catch(e => {
                notificationService.error("Error loading observation classes");
                resolve(options)
            })
        });
    }

    render() {

        const {classes} = this.props;
        const {initialising, busy, task, observations, observation, observationClass, observationClasses} = this.state;

        if (initialising) return (<LinearProgress/>);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                        title={`Please add all observations related to the following condition`}
                                        titleTypographyProps={{className: classes.cardTitle}}
                                        subheader={`Use the following reference below to collect information`}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Condition: ${task.data.condition}`}
                            </ListSubheader>
                            { task.data.reference ?
                                <a href={task.data.reference} style={{ color: "#55CCFF", fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">
                                    <ListSubheader className={classes.linkReferenceHeader}>Link to reference</ListSubheader>
                                </a> : (<></>)
                            }
                            {task.data.notes ?
                                <ListSubheader className={classes.referenceHeader}>
                                    {`Notes: ${task.data.notes}`}
                                </ListSubheader> : (<></>)
                            }
                            <CardContent className={classes.select}>
                                <FormControl variant="outlined" className={classes.option}>
                                    <InputLabel id="category-standard-label">Class</InputLabel>
                                    <Select
                                        id="select"
                                        key={observationClass}
                                        defaultValue={observationClass}
                                        labelId="demo-simple-select-standard-label"
                                        label="Category"
                                        onChange={this.__handleClassChange}
                                    >
                                        {observationClasses.map(o => {
                                            return <MenuItem value={o}>{o}</MenuItem>
                                        })
                                        }

                                    </Select>
                                </FormControl>
                                <div className={classes.option}>
                                    <AutocompleteField id="id"
                                                       key={observation}
                                                       label="Observation"
                                                       defaultValue={observation}
                                                       setSelectedValue={this.__observationChanged}
                                                       getOptions={this.__getOptions}
                                    />
                                </div>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill}/>
                                <Button startIcon={<PlusOne/>} color={"primary"} variant={"outlined"}
                                        onClick={this.__addObservation}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List subheader={"Observations"} dense={true}>
                                    {this.__renderObservations(classes, task)}
                                    {this.__renderEdits(classes, observations)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={<DeleteOutline/>} color={"primary"}
                                                        variant={"outlined"}
                                                        onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill}/>
                                <CriticalButton disabled={observations.length === 0 || busy} startIcon={<PublishIcon/>}
                                                color={"primary"} variant={"outlined"}
                                                onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderObservations = (classes, task) => {

        return task.observations && task.observations.map(observation => {
            return (<>
                <Divider/>
                <ListItem className={classes.listItems}>
                    <ListItemText className={classes.listItem} primary={observation.observation}/>
                    <ListItemText className={classes.listItem} primary={observation.observationClass}/>
                </ListItem>
            </>);
        });
    }

    __renderEdits = (classes, observations) => {

        return observations.map(observation => {
            return (<>
                <Divider/>
                <ListItem className={classes.listItems}>
                    <ListItemText className={classes.listItem} primary={observation.observation}/>
                    <ListItemText className={classes.listItem} primary={observation.observationClass}/>
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.__removeObservation(observation)} edge="end" aria-label="delete">
                            <DeleteIcon/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </>);
        });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {value => {
            return (<ObservationsComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

