
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText, Typography
} from "@material-ui/core";
import AutocompleteField from "./shared/AutocompleteField";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {Component} from "react";

const styles = theme => ({
    dropdownOption: {
        padding: "1%",
        width: "300px",
        flex: 0.6
    },
    input: {
        display: "flex",
    },
    list: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: "32px",
    },
    listItem: {
        flex: 1
    },
    checkboxItem: {
        display: "flex",
        flex: 0.6,
        justifyContent: "flex-end"
    },
})

class NormaliseInput extends Component {

    constructor(props) {
        super(props);

        this.bloc = props.bloc
    }

    __handleCheckboxChange = (event) => {
        this.bloc.onAnnotationChange(event.target.id, 'checked', event.target.checked)
    };

    render() {
        let {classes, entity} = this.props

        return (<>
            <Divider />
            <ListItem id={entity.id} className={classes.list}>
                <Typography className={classes.listItem} variant="h6">{ entity.extract } </Typography>
                <Typography  className={classes.listItem} variant="body1">{ entity.category } </Typography>
                <div className={classes.dropdownOption}>
                    <AutocompleteField id={entity.id}
                                       label="Concept"
                                       defaultValue=""
                                       setSelectedValue={this.bloc.onDropdownChange.bind(this, entity.id)}
                                       getOptions={this.bloc.getDropdownOptions}
                                       setDisabled={entity.checked}
                    />
                </div>
                <FormControlLabel className={classes.checkboxItem} label={"Unable to normalise"} labelPlacement="end" control={
                    <Checkbox id={entity.id}
                              checked={entity.checked}
                              onChange={this.__handleCheckboxChange}
                              color="default"/>
                }/>
            </ListItem>
        </>);
    }
}
export default withStyles(styles)(NormaliseInput);