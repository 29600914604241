export function playSound() {

    const audioContext = new window.AudioContext();
    const errorNoise = audioContext.createOscillator();

    // this.errorNoise.frequency = "400";
    errorNoise.type = "sine";
    errorNoise.frequency.setValueAtTime(400, audioContext.currentTime);
    errorNoise.frequency.exponentialRampToValueAtTime(
        200,
        audioContext.currentTime + 0.05
    );
    errorNoise.frequency.exponentialRampToValueAtTime(
        100,
        audioContext.currentTime + 0.2
    );

    let errorGain = audioContext.createGain();
    errorGain.gain.exponentialRampToValueAtTime(
        0.01,
        audioContext.currentTime + 0.3
    );

    errorNoise.connect(errorGain).connect(audioContext.destination);

    errorNoise.start();
    errorNoise.stop(audioContext.currentTime + 0.3);
};


export function playSoundAlert() {

    const audioContext = new window.AudioContext();
    const errorNoise = audioContext.createOscillator();

    errorNoise.type = "sine";
    errorNoise.frequency.setValueAtTime(87, audioContext.currentTime);
    errorNoise.frequency.exponentialRampToValueAtTime(
        200,
        audioContext.currentTime + 0.05
    );
    errorNoise.frequency.exponentialRampToValueAtTime(
        100,
        audioContext.currentTime + 0.2
    );

    let errorGain = audioContext.createGain();
    errorGain.gain.exponentialRampToValueAtTime(
        0.01,
        audioContext.currentTime + 0.3
    );

    errorNoise.connect(errorGain).connect(audioContext.destination);

    errorNoise.start();
    errorNoise.stop(audioContext.currentTime + 0.3);
};
