import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent, CardHeader, Divider, FormControl,
    Grid,
    IconButton, Input, InputLabel,
    LinearProgress, Link, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select
} from "@material-ui/core";

import AsyncJobContext from "../../../context";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {navigationTitleService} from "../../../../../../../utils/title";
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteOutline, PlusOne} from "@material-ui/icons";
import {uuid} from "uuidv4";
import {notificationService} from "../../../../../../../utils/notification";
import CriticalButton from "../../../../../../shared/CriticalButton";
import CancelWithReasonButton from "../../../../../../shared/CancelWithReasonButton";
import AutocompleteField from "../AnnotateNormaliseComponent/shared/AutocompleteField";
import {ontologyApi} from "../../../../../../../utils/services/ontology.api";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: "32px",
    },
    fill: {
        flex: "1 1 auto",
    },
    context: {
        paddingBottom: "12px",
    },
    name: {
        fontSize: "24px",
    },
    cardTitle: {
        color: "#55CCFF",
    },
    card: {
        width: "100%",
    },

    input: {
        marginTop: "24px",
        width: "100%",
        fontSize: "32px",

    },
    listItems: {
        minHeight: "32px",
    },
    listItem: {
        flex: 1,
    },
    select: {
        display: "flex"
    },
    option: {
        flex: 1,
        margin: "0.5%"
    },
    subHeader: {
        fontSize: "1.5rem"
    },
    linkReferenceHeader: {
        fontSize: "1rem",
        color: "#55CCFF"
    },
    referenceHeader: {
        fontSize: "1rem",
    },


});

class ComplicationsComponent extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;
        this.searchDataType = "complication"

        this.state = {
            initialising: true,
            complication: "",
            complicationCUI: "",
            complications: [],

        };

        this.__removeComplication = this.__removeComplication.bind(this);
        this.__addComplication = this.__addComplication.bind(this);
    }

    componentWillMount() {

        navigationTitleService.update("Condition Complication Job");
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __complicationChanged = (event) => {

        if (event === null) {
            return
        }

        this.setState({
            complication: event.canonicalName,
            complicationCUI: event.conceptId,
        });
    }

    __addComplication = () => {

        let { complications, complication, complicationCUI } = this.state;

        if(complication === "" ) {
            notificationService.error("Please enter complication.");
            return;
        }

        complications.push({
            id: uuid().toString(),
            complication: complication,
            complicationCUI: complicationCUI
        });

        this.setState({
            complications: complications,
            complication: undefined
        })

    }

    __removeComplication = (_complication) => () => {

        let { complications } = this.state;

        complications = complications.filter(complication => complication.id !== _complication.id);

        this.setState({
            complications: complications,
        });
    }

    __submit = () => this.bloc.submitTask({ data: { complications: this.state.complications.map(value => {
        return {
            "complication": value.complication,
            "complicationCUI": value.complicationCUI
        }
            }) } });

    __discard = (message) => this.bloc.discardTask(message)

    __getOptions = (_inputValue) => {

        return new Promise(resolve => {
            let options = []

            ontologyApi.search({ query: _inputValue, dataType: [this.searchDataType] })
                .then(value => {

                    const items = value.data.results

                    items.forEach(item => {

                        const aliases = item.index
                        aliases.unshift(item.canonicalName)
                        const uniqueNames =  [...new Set(aliases)]
                        const option = {
                            name: uniqueNames.join(', '),
                            conceptId: item.conceptId,
                            canonicalName: item.canonicalName
                        }

                        options.push(option)

                    })

                    resolve(options)
                }, error => {
                    notificationService.error("Error loading complications");
                    resolve(options)
                });
        })
    }

    render() {

        const { classes } = this.props;
        const { initialising, busy, task, complications, complication } = this.state;

        if(initialising) return (<LinearProgress />);

        return (
            <div className={classes.root}>
                <Grid className={classes.container} container>
                    <Grid item xs={"12"}>
                        <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                                title={`Please add all complications related to the following condition`}
                                titleTypographyProps={{ className: classes.cardTitle }}
                                subheader={`Use the following reference below to collect complication information`}
                            />
                            <ListSubheader className={classes.subHeader}>
                                {`Condition: ${task.data.condition}`}
                            </ListSubheader>
                            { task.data.reference ? 
                                <a href={task.data.reference} style={{ color: "#55CCFF", fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">
                                    <ListSubheader className={classes.linkReferenceHeader}>Link to reference</ListSubheader>
                                </a> : (<></>)
                            }
                            {task.data.notes ?
                                <ListSubheader className={classes.referenceHeader}>
                                    {`Notes: ${task.data.notes}`}
                                </ListSubheader> : (<></>)
                            }
                            <CardContent className={classes.select}>
                                <div className={classes.option}>
                                    <AutocompleteField id="id"
                                                       key={complication}
                                                       label="Complication"
                                                       defaultValue={complication}
                                                       setSelectedValue={this.__complicationChanged}
                                                       getOptions={this.__getOptions}
                                    />
                                </div>
                            </CardContent>
                            <CardActions>
                                <span className={classes.fill} />
                                <Button startIcon={ <PlusOne /> } color={"primary"} variant={"outlined"} onClick={this.__addComplication}>
                                    Add
                                </Button>
                            </CardActions>
                            <CardContent>
                                <List subheader={"Complications"} dense={true}>
                                    {this.__renderComplications(classes, task)}
                                    {this.__renderEdits(classes, complications)}
                                </List>
                            </CardContent>
                            <CardActions>
                                <CancelWithReasonButton disabled={busy} startIcon={ <DeleteOutline /> } color={"primary"} variant={"outlined"} onConfirmed={this.__discard}>Discard</CancelWithReasonButton>
                                <span className={classes.fill} />
                                <CriticalButton disabled={complications.length === 0 || busy} startIcon={ <PublishIcon /> } color={"primary"} variant={"outlined"} onConfirmed={this.__submit}>Publish</CriticalButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }

    __renderComplications = (classes, task) => {

       return task.complications && task.complications.map(complication => {
           return (<>
               <Divider />
               <ListItem className={classes.listItems}>
                   <ListItemText className={classes.listItem} primary={ complication.complication }/>
               </ListItem>
           </>);
       });
    }

    __renderEdits = (classes, complications) => {

       return complications.map(complication => {
           return (<>
               <Divider />
               <ListItem className={classes.listItems}>
                   <ListItemText className={classes.listItem} primary={ complication.complication }/>
                   <ListItemSecondaryAction>
                       <IconButton onClick={this.__removeComplication(complication)} edge="end" aria-label="delete">
                           <DeleteIcon />
                       </IconButton>
                   </ListItemSecondaryAction>
               </ListItem>
           </>);
       });
    }
}

export default withStyles(styles)(props => (
    <AsyncJobContext.Consumer>
        {   value => {
            return (<ComplicationsComponent context={value} {...props} />);
        }
        }
    </AsyncJobContext.Consumer>
));

