import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {navigationTitleService} from "../../../../../utils/title";
import InformationScreen from "../../InformationScreen";
import {Button, LinearProgress, Paper} from "@material-ui/core";
import DocumentPreparationJobContext from "../context";
import DataPreparationJobPanel from "./PreparationJobPanel";
import {DataPreparationBlocEvent, DataPreparationBloc} from "../dataPreparation.bloc";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        height: "calc(100vh - 64px)",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        minHeight: "100%",
        maxHeight: "100%",
        height: "100%",
        backgroundColor: "#fff",
        color: "#000"
    },
    grid: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    gridLeft: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        borderRight: "1px solid #dddddd",
    },
    gridRight: {
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: "auto",

    },
    messageWrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        height: "100%",
        maxHeight: "100%",
    },
    messageContent: {
        display: "flex",
    },
    nextJobBtn: {
        width: "100%",
    },
});

class DataPreparationJob extends Component {

    bloc;
    eventSubscription;
    stateSubscription;

    constructor(props) {
        super(props);

        this.bloc = new DataPreparationBloc()

        this.state = {
            busy: false,
            registeredJobList: [],
            job: undefined,
            jobReady: false,
            jobLoading: false,
        };

        this.__handleDataPreparationState = this.__handleDataPreparationState.bind(this);
        this.__handleDataPreparationEvent = this.__handleDataPreparationEvent.bind(this);
    }

    componentDidMount() {

        this.stateSubscription = this.bloc.subscribeToState(this.__handleDataPreparationState);
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleDataPreparationEvent);
        this.bloc.start();

        this.setState({
            busy: true,
        });

        navigationTitleService.update("Data Preparation Jobs");

    }

    componentWillUnmount() {
        this.bloc.close();
        this.stateSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();

    }
    __handleDataPreparationState = (event) => {
        this.setState({
            ...event
        });
    }

    __handleDataPreparationEvent = (event) => {

        const type = event.event;

        switch (type) {
            case DataPreparationBlocEvent.JOBS_LOADED:
                this.setState({
                    busy: false,
                });
                break;
            case DataPreparationBlocEvent.JOB_SELECTED:
                this.setState({
                    jobLoading: false,
                    jobReady: true
                });
                break;
            case DataPreparationBlocEvent.JOB_FINISHED:
                this.setState({
                    jobReady: false,
                });
                navigationTitleService.update("Data Preparation Jobs");
                this.bloc.clearJob()
                break;
            case DataPreparationBlocEvent.JOBS_LOADED_ERROR:
                this.setState({
                    busy: false,
                });
                break;
            case DataPreparationBlocEvent.JOB_SELECTED_ERROR:
                this.setState({
                    jobLoading: false,
                    jobReady: false,
                });
                break;
        }
    }

    getNext = () => {

        let {jobLoading} = this.state;
        if (!jobLoading) {

            this.setState({
                jobLoading: true,
            });

            let nextJobID = this.bloc.subject.value.registeredJobList[0].key
            this.bloc.selectDataPreparationJob(nextJobID)
        }
    };



    render() {

        let { classes } = this.props;

        let { busy, job, registeredJobList, jobReady, jobLoading } = this.state;

        let bloc = this.bloc

        let queueSize = registeredJobList.length

        let screen = (
            <div className={classes.messageWrapper}>
                <div className={classes.fill}></div>
                <div className={classes.messageContent}>
                    <div className={classes.fill}></div>
                    <div>
                        <InformationScreen
                            message={`There ${queueSize !== 1 ? "are" : "is"} ${queueSize} job${queueSize !== 1 ? "s" : ""} in the queue.`}/>
                        {queueSize > 0 ? (<Button color={"primary"} variant={"outlined"} onClick={this.getNext}
                                                  className={classes.nextJobBtn} disabled={busy || jobLoading}>
                            Get next job
                        </Button>) : (<></>)}
                    </div>
                    <div className={classes.fill}></div>
                </div>
                <div className={classes.fill}></div>
            </div>
        );

        let context = {
            job: job,
            bloc: bloc
        }

        return (
            <DocumentPreparationJobContext.Provider value={context}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        {(busy || jobLoading) && <LinearProgress/>}
                        {
                            jobReady ? (<DataPreparationJobPanel/>) :
                                screen
                        }
                    </Paper>
                </div>
             </DocumentPreparationJobContext.Provider>
        );

    }
}

DataPreparationJob.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataPreparationJob);
